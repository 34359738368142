/* @flow weak */
import * as actions from './actions'
import * as authActions from '../../auth/actions'
import { getIn, setIn, deleteIn } from '../../helpers'

const initialState = {
  OrdersListPage: {},
}

const listsReducer = (state = initialState, action) => {
  switch (action.type) {
    // omega specialities
    case authActions.SIGN_OUT: {
      return {
        ...initialState,
      }
    }
    case actions.LISTS_RESET_LIST: {
      const { path } = action.payload
      return deleteIn(state, path)
    }

    case actions.LISTS_RESET_FILTERS: {
      const { path } = action.payload
      return deleteIn(state, path)
    }

    case actions.LISTS_SET_FILTER: {
      const { path, value } = action.payload
      return setIn(state, path, value)
    }

    case actions.LISTS_SET_PAGE: {
      const { path, value } = action.payload
      return setIn(state, path, value)
    }

    case actions.LISTS_SET_PAGE_LIMIT: {
      const { path, value } = action.payload
      return setIn(state, path, value)
    }

    case actions.LISTS_SET_SORT: {
      const { path } = action.payload
      let { value } = action.payload
      const currentValue = getIn(state, path)
      const reverseValue = `-${currentValue}`
      // const fieldName = value.startsWith('-') ? value.substring(1) : value
      // console.log('fieldNamefieldName', fieldName)
      // only reverse when new value does not start with dash
      if (currentValue === value && !value.startsWith('-')) value = reverseValue
      return setIn(state, path, value)
    }

    default:
      return state
  }
}

export default listsReducer
