import { useState, useRef, useEffect } from 'react'

export default function useOnResizeAndScroll(callback) {
  useEffect(() => {
    window.addEventListener('scroll', callback)
    window.addEventListener('resize', callback)

    callback()

    return () => {
      window.removeEventListener('scroll', callback)
      window.removeEventListener('resize', callback)
    }
  }, [callback])

  return {}
}
