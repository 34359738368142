import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { createTranslatedMessage } from '../../common/helpers'

const Error = ({ error, t }) => {
  const message =
    typeof error.message === 'object' ? createTranslatedMessage(error.message, t) : error.message

  return <div>{message}</div>
}

Error.propTypes = {
  error: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
}

export default withTranslation()(Error)
