import * as actions from './actions'
import * as notifs from '../lib/notifs'
import * as authActions from '../auth/actions'
import { processVca } from '../lib/vca'
import { SELECT_VCA, SAVE_DEFORMED_SELECTED, SAVE_DRAWING_VCA } from '../vca/actions'
import { orderTypes } from '../config'
import i18n from '../i18n/i18n'
import {
  transformServerErrorsToMessages,
  transformServerErrorsToNotifMessageComponent,
} from '../../browser/helpers'

const State = {
  all: [],
  meta: {},
  loading: false, // all orders
  stepShapeEnabled: false,
  stepSummaryEnabled: false,
  activeStep: 1,
  sendingOrder: false,
  sendingOrderError: null,
  currentId: null,
  sendingOrdersIds: {},
  currentOrder: null,
  currentOrderPlaceholder: null,
  selectedToSend: [],
  secondPairId: null,
  isMasterUser: false,
  // indicates, if something in order was changed except fields (vca, shape modification, etc.)
  isOrderFormDirty: false,
  isReferenceUnique: true,
  shouldUpdate: false,
  // indicates if some catalog data are lost after copy order with old catalog
  isSomethingLostAfterCopy: false,

  // miLens - old
  // miLenses: [],
  // areMiLensesLoading: false,
  // miLensesErrored: false,
  importedMiLens: null,
  importedMiLensLoading: false,
  importedMiLensErrored: false,
  // create fusion visualisation in progress
  miLensCreateOrderInProgress: false,
  // miLens - new
  webMiLenses: [],
  webMiLensesMeta: {},
  webMiLensesLoading: false,
  webMiLensesError: false,
  tabletMiLenses: [],
  tabletMiLensesMeta: {},
  tabletMiLensesLoading: false,
  tabletMiLensesError: false,
  // miLens detail
  miLensDetail: null,
  miLensDetailLoading: false,
  miLensDetailError: null,

  currentOrderPrices: null,
}

const resetOrderReducerState = {
  stepShapeEnabled: false,
  stepSummaryEnabled: false,
  activeStep: 1,
  currentId: null,
  currentOrder: null,
  currentOrderPlaceholder: null,
  secondPairId: null,

  isOrderFormDirty: false,
  isReferenceUnique: true,
  isSomethingLostAfterCopy: false,

  currentOrderPrices: null,
}

const ordersReducer = (state = State, action) => {
  const { type, payload } = action

  switch (type) {
    case authActions.SIGN_OUT: {
      return {
        ...State,
      }
    }
    case actions.FETCH_ORDER_PRICES_SUCCESS: {
      return { ...state, currentOrderPrices: payload.data }
    }
    case actions.SEND_ORDER_START: {
      const { _id } = action.meta.action
      const sendingOrdersIds = {
        ...state.sendingOrdersIds,
        [_id]: true,
      }
      return {
        ...state,
        sendingOrdersIds,
      }
    }
    case actions.SELECT_TO_SEND: {
      return {
        ...state,
        selectedToSend: payload.selected,
      }
    }
    case actions.DELETE_ORDER_SUCCESS: {
      const { _id } = action.payload.data
      return {
        ...state,
        all: state.all.filter(o => o._id !== _id),
      }
    }
    case actions.CANCEL_ORDER_SUCCESS: {
      const { order } = action.payload.data
      const { _id } = action.meta.action

      notifs.success({
        title: i18n.t('The order was cancelled successfully'),
      })

      return {
        ...state,
        all: state.all.map(o => {
          if (o._id !== order._id) {
            return o
          }
          return order
        }),
      }
    }
    case actions.CANCEL_ORDER_ERROR: {
      const { _id } = action.meta.action
      const errors = action.payload
      console.log('cancel order errors', errors)

      const msgs = errors.map(err => err.message)

      notifs.error({
        title: i18n.t('The order was not cancelled'),
        message: msgs,
      })

      return {
        ...state,
      }
    }
    case actions.SEND_ORDER_SUCCESS: {
      const { _id } = action.meta.action
      const { order } = action.payload.data

      notifs.success({
        // title: 'Odeslání objednávky proběhlo úspěšně',
        title: i18n.t('the order was sent successfully'),
      })

      const sendingOrdersIds = {
        ...state.sendingOrdersIds,
      }
      delete sendingOrdersIds[_id]

      return {
        ...state,
        all: state.all.map(o => {
          if (o._id !== _id) {
            return o
          }
          return order
        }),
        sendingOrdersIds,
      }
    }
    case actions.SEND_ORDER_ERROR: {
      const { _id } = action.meta.action

      const notifErrorMessage =
        transformServerErrorsToNotifMessageComponent(action.payload) ||
        i18n.t('the order was not sent - message')

      notifs.error({
        title: i18n.t('the order was not sent'),
        message: notifErrorMessage,
      })

      const sendingOrdersIds = {
        ...state.sendingOrdersIds,
      }
      delete sendingOrdersIds[_id]

      return {
        ...state,
        sendingOrdersIds,
      }
    }

    case actions.CREATE_OR_UPDATE_ORDER_START: {
      return {
        ...state,
        sendingOrder: true,
        sendingOrderError: null,

        isOrderFormDirty: false,
      }
    }

    case actions.CREATE_MILENS_START: {
      return {
        ...state,
        miLensCreateOrderInProgress: true,
      }
    }
    case actions.CREATE_MILENS_ERROR:
    case actions.CREATE_MILENS_SUCCESS: {
      return {
        ...state,
        miLensCreateOrderInProgress: false,
      }
    }
    // case actions.FETCH_MILENS_START: {
    //   return {
    //     ...state,
    //     areMiLensesLoading: true,
    //     miLensesErrored: false,
    //     importedMiLens: null, // this resets last time imported milens orders records
    //   }
    // }
    // case actions.FETCH_MILENS_SUCCESS: {
    //   const { miLenses } = action.payload.data
    //   return {
    //     ...state,
    //     areMiLensesLoading: false,
    //     miLenses,
    //     miLensesErrored: false,
    //   }
    // }
    // case actions.FETCH_MILENS_ERROR: {
    //   // TODO: translations
    //   notifs.error({
    //     title: 'MiLens error',
    //     message: 'Něco se pokazilo, zkuste to znova.',
    //     // message: i18n.t('Server error notif message - contact support'),
    //   })

    //   return {
    //     ...state,
    //     areMiLensesLoading: false,
    //     miLenses: [],
    //     miLensesErrored: true,
    //   }
    // }

    case actions.FETCH_MILENS_START: {
      const { origin } = action.meta.action

      if (origin === 'web') {
        return {
          ...state,
          webMiLensesLoading: true,
          webMiLensesError: false,
          // importedMiLens: null, // this resets last time imported milens orders records
        }
      }
      if (origin === 'tablet') {
        return {
          ...state,
          tabletMiLensesLoading: true,
          tabletMiLensesError: false,
          // importedMiLens: null, // this resets last time imported milens orders records
        }
      }
      return state
    }
    case actions.FETCH_MILENS_SUCCESS: {
      const { origin } = action.meta.action
      const { data, meta } = action.payload.data

      console.log('origin', origin, action)

      if (origin === 'web') {
        return {
          ...state,
          webMiLensesLoading: false,
          webMiLenses: data,
          webMiLensesMeta: meta,
        }
      }
      if (origin === 'tablet') {
        return {
          ...state,
          tabletMiLensesLoading: false,
          tabletMiLenses: data,
          tabletMiLensesMeta: meta,
        }
      }
      return state
    }
    case actions.FETCH_MILENS_ERROR: {
      notifs.error({
        title: i18n.t('error'),
        message: i18n.t('Error - try again'),
      })

      const { origin } = action.meta.action

      if (origin === 'web') {
        return {
          ...state,
          webMiLensesLoading: false,
          webMiLensesError: true,
          webMiLenses: [],
        }
      }
      if (origin === 'tablet') {
        return {
          ...state,
          tabletMiLensesLoading: false,
          tabletMiLensesError: true,
          tabletMiLenses: [],
        }
      }

      return state
    }

    case actions.FETCH_ONE_MILENS_START: {
      return {
        ...state,
        miLensDetail: null,
        miLensDetailLoading: true,
        miLensDetailError: null,
      }
    }
    case actions.FETCH_ONE_MILENS_SUCCESS: {
      const { data } = action.payload

      return {
        ...state,
        miLensDetail: data,
        miLensDetailLoading: false,
        miLensDetailError: null,
      }
    }
    case actions.FETCH_ONE_MILENS_ERROR: {
      notifs.error({
        title: i18n.t('error'),
        message: i18n.t('Error - try again'),
      })

      return {
        ...state,
        miLensDetail: null,
        miLensDetailLoading: false,
        miLensDetailError: true,
      }
    }

    case actions.IMPORT_MILENS_START: {
      return {
        ...state,
        importedMiLensLoading: true,
        importedMiLensErrored: false,
      }
    }
    case actions.IMPORT_MILENS_SUCCESS: {
      const { data } = action.payload
      return {
        ...state,
        importedMiLensLoading: false,
        importedMiLens: data,
        importedMiLensErrored: false,
      }
    }
    case actions.IMPORT_MILENS_ERROR: {
      // TODO: translations
      notifs.error({
        title: 'MiLens error',
        message: 'Něco se pokazilo, zkuste to znova.',
        // message: i18n.t('Server error notif message - contact support'),
      })

      return {
        ...state,
        importedMiLensLoading: false,
        importedMiLens: null,
        importedMiLensErrored: true,
      }
    }

    case actions.CREATE_OR_UPDATE_ORDER_SUCCESS: {
      const { isConcept, _id: originalId, sendImmediately } = action.meta.action
      const { order } = action.payload.data
      const { _id } = order
      const title = !isConcept
        ? sendImmediately
          ? i18n.t('the order was sent')
          : i18n.t('the order is prepared to send')
        : originalId
        ? i18n.t('the concept was saved')
        : i18n.t('the concept was created successfully')

      notifs.success({
        title,
      })

      let { all } = state
      // we are creating pure new order,  we should be optimistic and put it to all orders
      let alreadyInAll = false
      all = all.map(o => {
        if (o._id !== _id) {
          return o
        }
        alreadyInAll = true
        return {
          ...o,
          ...order,
        }
      })
      if (!alreadyInAll) {
        all = [order, ...all]
      }

      return {
        ...state,
        sendingOrder: false,
        sendingOrderError: null,
        currentId: _id,
        currentOrder: {
          ...state.currentOrder,
          ...order,
        },
        all,
      }
    }
    case actions.CREATE_OR_UPDATE_ORDER_ERROR: {
      const { isConcept } = action.meta.action
      const title = !isConcept
        ? i18n.t('Error sending the order')
        : i18n.t('Error saving the concept')

      // const showableError = action?.payload?.[0]?.detail?.errors?.find(e => e.showOnFrontend) || ''

      const showableError = transformServerErrorsToMessages(action.payload).join(', ')

      const serverErrorMessage = i18n.t('Server error notif message - contact support')
      const finalErrorMessage = `${serverErrorMessage}
      ${showableError}
      `

      // this cannot be built...
      // const finalErrorMessage = (<div>
      //   <div>{serverErrorMessage}</div>
      //   {showableError && (
      //     <div>
      //       <br />
      //       <div style={{ fontWeight: 700}}>Details:</div>
      //       <div>{showableError.title}</div>
      //       <div>{showableError.message}</div>
      //     </div>
      //   )}
      // </div>)

      notifs.error({
        title,
        message: finalErrorMessage,
      })

      // add temporarly bugsnag notif, co we have breadcrumbs from FE

      console.notifyWarning(
        new Error('Server error for step 4 - sending order - should not happen from summary'),
        { errors: action?.payload?.[0]?.detail?.errors }
      )

      return {
        ...state,
        sendingOrder: false,
        sendingOrderError: action.payload || 'server',
      }
    }
    case actions.FETCH_ORDERS_START:
      return {
        ...state,
        loading: true,
        selectedToSend: [],
      }

    case actions.FETCH_ORDERS_ERROR:
      return {
        ...state,
        loading: false,
      }

    case actions.FETCH_ORDERS_SUCCESS:
      return {
        ...state,
        all: payload.data.orders,
        isMasterUser: payload.data.isMasterUser,
        meta: payload.meta,
        loading: false,
        shouldUpdate: false,
      }

    case actions.FETCH_ORDER_START: {
      const { all } = state
      const { _id } = action.meta.action
      const currentOrderPlaceholder = all.find(o => o._id === _id)
      return {
        ...state,
        // has to be null
        currentOrder: null,
        currentOrderPlaceholder,
      }
    }

    case actions.FETCH_ORDER_SUCCESS: {
      const { data, meta: { dataFromNewCatalog = {} } = {} } = action.payload

      const currentOrder =
        data.orderType !== orderTypes.DIAMETER_ONLY ? processVca(data, data) : data

      return {
        ...state,
        currentOrder,
        // kvůli kopii objednávky, kde se po prvním nastavení v initial statu currentOrder smaže
        currentOrderPlaceholder: currentOrder,
        currentId: currentOrder._id,
        isSomethingLostAfterCopy: !!dataFromNewCatalog.isSomethingLost,
      }
    }

    case actions.ENABLE_ORDER_STEP: {
      const { summary, shape } = payload

      let { stepShapeEnabled, stepSummaryEnabled } = state

      if (typeof shape !== 'undefined') {
        stepShapeEnabled = shape
      }

      if (typeof summary !== 'undefined') {
        stepSummaryEnabled = summary
      }

      return {
        ...state,
        stepShapeEnabled,
        stepSummaryEnabled,
      }
    }
    case actions.SET_ACTIVE_ORDER_STEP: {
      const { value } = payload

      return {
        ...state,
        activeStep: value,
      }
    }
    case actions.SET_CURRENT_ORDER: {
      const { _id } = payload

      return {
        ...state,
        currentId: _id,
        currentOrder: state.all.find(o => o._id === _id),
      }
    }
    case actions.SET_SECOND_PAIR: {
      const { _id } = payload

      return {
        ...state,
        secondPairId: _id,
      }
    }
    case actions.RESET_ORDER: {
      return {
        ...state,
        ...resetOrderReducerState,
      }
    }

    case SELECT_VCA:
    case SAVE_DEFORMED_SELECTED:
    case SAVE_DRAWING_VCA: {
      return {
        ...state,
        isOrderFormDirty: true,
      }
    }

    case actions.IS_REFERENCE_UNIQUE_CHECK_SUCCESS: {
      const { data } = action.payload
      return {
        ...state,
        isReferenceUnique: data,
      }
    }

    case actions.SET_SHOULD_UPDATE_ORDERS: {
      return {
        ...state,
        shouldUpdate: true,
      }
    }

    default:
      return state
  }
}

export default ordersReducer
