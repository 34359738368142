import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import styled from 'styled-components'
import { withTranslation } from 'react-i18next'

import { fields } from '../../../common/lib/redux-fields'
import { TextField, CheckboxField, SelectField, SwitchField, NumberField } from '../fields'
import { FieldsGroup } from '.'
import { Box, Row, Col, Button, Gap } from '..'
import { setTabletConfig } from '../../../common/config/actions'
import Validation from '../../../common/lib/validation'
import { withNotifs } from '../hoc'

const MeasurementTool = styled.div`
  width: ${({ width }) => width}px;
  height: 20px;
  background: ${({ theme: { colors } }) => colors.secondary};
`

class SettingsTabletForm extends Component {
  submitForm = () => {
    const { fields, setTabletConfig, notifs, t } = this.props

    const errors = fields.$validate()
    if (!errors || !errors.length) {
      const { width, height } = fields.$values()
      setTabletConfig({ width, height })
      // TODO - překlad message?
      notifs.success({ title: t('settings'), message: 'Nastavení tabletu uloženo' })
    } else {
      notifs.error({ title: t('error'), message: 'Nastavení tabletu selhalo' })
    }
  }

  render() {
    const { fields, t } = this.props

    return (
      <Col>
        <Gap gap="1rem" />
        <Row>
          <NumberField required label={t('Tablet width (cm)')} {...fields.width} />
          <Gap gap="2rem" />
          <NumberField required label={t('Tablet height (cm)')} {...fields.height} />
        </Row>
        <Row>
          <Button onClick={this.submitForm}>{t('confirm settings')}</Button>
        </Row>
      </Col>
    )
  }
}

SettingsTabletForm.propTypes = {
  fields: PropTypes.object.isRequired,
  setTabletConfig: PropTypes.func.isRequired,
  notifs: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
}

const enhance = compose(
  connect(
    state => ({
      tablet: state.config.tablet,
    }),
    {
      setTabletConfig,
    },
  ),
  fields({
    path: 'SettingsTabletForm',
    fields: ['width', 'height'],
    getInitialState: ({ tablet = {} }) => ({
      width: tablet.width,
      height: tablet.height,
    }),
    validationFunc: ({ dataToValidate }) => {
      const validator = new Validation(dataToValidate)
      validator.isFloatGreaterThan('width', 1)
      validator.isFloatGreaterThan('height', 1)
      // TODO: dodelat odlozeni validace az na klik
      return validator.validate()
    },
  }),
  withNotifs,
  withTranslation(),
)

export default enhance(SettingsTabletForm)
