import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'

import { addIoListener, getIo } from '../io'
import Button from './Button'
import { textStyle } from './Text'
import Gap from './Gap'
import Emoji from './Emoji'
import Popup from './popups/Popup'
import T from './T'
import BasicRow from './BasicRow'
import { LottieAnimation } from './LottieAnimation'

const Wrapper = styled.div`
  ${textStyle}
  background: white;
  text-align: center;
  padding: 10px 20px 10px;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-bottom: #efefef solid 1px;
  @media print {
    display: none;
  }
`

function AppVersionReloader() {
  const { t } = useTranslation()
  const [serverVersion, setServerVersion] = useState(null)

  useEffect(
    () =>
      addIoListener('appVersion', version => {
        // console.log('appVersion', version, process.env.APP_VERSION)
        setServerVersion(version)
      }),
    []
  )

  const [shown, setShown] = useState(true)

  const timerRef = useRef(null)

  function setTimer() {
    if (timerRef.current) {
      clearTimeout(timerRef.current)
    }

    // console.log('snooze')

    timerRef.current = setTimeout(() => {
      // console.log('showing')
      setShown(true)
    }, 1000 * 60)
  }

  // console.log('shownshown', shown)

  return (
    serverVersion &&
    serverVersion !== process.env.APP_VERSION && (
      <>
        <Wrapper>
          <Emoji id="tada" set="apple" size={20} />
          <Gap />
          <div>
            {t(
              'New version is here! Refresh page to get the latest features and ensure the app functions correctly!'
            )}
          </div>
          <Gap />
          <Button
            onClick={() => {
              window.location.reload(true)
            }}
          >
            {t('Refresh')}
          </Button>
        </Wrapper>

        {shown && (
          <Popup
            defaultOpen
            title={t('New version is here!')}
            closeOnOutsideClick
            closeOnEsc
            largeButtons
            onClose={opts => {
              // console.log('clicked', opts)
              if (opts.ok) {
                window.location.reload(true)
              } else {
                setTimer()
                setShown(false)
              }
            }}
            okText={t('Refresh')}
            cancelText={t('Snooze for 1 minute')}
            renderBeforeBody={<LottieAnimation />}
            text={
              <>
                <div>
                  <T>
                    Refresh the page to access the latest features and ensure the app functions
                    correctly.
                  </T>
                </div>
                <Gap gap="2rem" />
                <div>
                  <T>If you have unfinished work, you can snooze this message for 1 minute.</T>
                </div>
              </>
            }
          />
        )}
      </>
    )
  )
}

export default AppVersionReloader
