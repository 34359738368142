import React, { useCallback, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import * as notifs from '../../common/lib/notifs'

import { addIoListener, emitStats, getIo, useEmitAuth } from '../io'
import { useServerConnectionStatus } from './ServerConnectionWatcher'
import { editMe, fetchNews, fetchWorkers } from '../../common/app/actions'
import { createTracer, fetchTracers, mergeConfig } from '../../common/config/actions'
import { setShouldUpdateOrders } from '../../common/orders/actions'

import { tracers as defaultTracers } from '../../common/config/tracerConfig'

function DataMigrationService() {
  const dispatch = useDispatch()

  // tracers
  const tracers = useSelector(state => state.config.tracers)
  const tracersLoaded = useSelector(state => state.config.tracersLoaded)
  const tracerKey = useSelector(state => state.config.tracerKey)
  const oldTracer = useSelector(state => state.config.tracer)
  const port = useSelector(state => state.config.tracerPort)
  const bridgeIp = useSelector(state => state.config.tracerBridgeIp)

  const tracerMigrationStarted = useRef(false)

  // console.log('tracerKey', tracerKey)
  // console.log('tracers', tracers, !tracers?.length)
  // console.log('tracersLoaded', tracersLoaded)
  // console.log('oldTracer', oldTracer)

  useEffect(() => {
    if (
      tracersLoaded &&
      !tracers?.length &&
      tracerKey &&
      oldTracer &&
      !tracerMigrationStarted.current
    ) {
      tracerMigrationStarted.current = true
      console.log('hook used');
      (async () => {
        console.log('tracer found in localstorage and not on server  --> start migration to DB')
        if (oldTracer.fileOnly || tracerKey === 'fileOnly') {
          console.log(
            'oldTracer is file only, skipping creating tracer in DB, save tracerEnabled to DB only',
          )

          await dispatch(editMe({ tracerEnabled: true })).meta.action.payload
          console.log('tracerEnabled saved! delete it from app localStorage')
          await dispatch(
            mergeConfig({
              tracer: null,
              tracerKey: null,
              tracerEnabled: null,
            }),
          )
          console.log('Migration DONE!')

          return
        }
        const newTracer = {
          ...oldTracer,
          bridgeIp,
          port,
          tracerKey,
          name: defaultTracers[tracerKey]?.name || 'Unnamed',
          usersName: '',
        }

        console.log('newTracer', newTracer)

        try {
          await dispatch(createTracer(newTracer)).meta.action.payload
          await dispatch(editMe({ tracerEnabled: true })).meta.action.payload

          // delete old values
          dispatch(
            mergeConfig({
              tracer: null,
              tracerKey: null,
              tracerEnabled: null,
            }),
          )

          notifs.success({ title: 'One time tracer migration to DB done' })
        } catch (e) {
          notifs.error({
            title: 'Tracer migration to DB failed',
            message: 'Try to fill tracer again in settings',
          })
        }

        // dispatch(
        //   setDefaultTracer(
        //     defaultTracerId === tracer._id ? null : tracer._id,
        //   ),
        // )
      })()
    }
  }, [bridgeIp, dispatch, oldTracer, port, tracerKey, tracers, tracersLoaded])

  // render nothing
  return null
}

export default DataMigrationService
