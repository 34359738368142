import React, { useEffect, useMemo } from 'react'
import { compose } from 'recompose'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import withRouter from '../components/hoc/withRouter'
import { selectViewerId } from '../../common/app/reducer'
import { parseQuery } from '../app/helpers'
import { oauthSignInAction } from '../../common/auth/actions'
import Loading, { WrappedLoading } from '../components/Loading'
import { Page, Gap, Row, Col, Text } from '../components'
import { withNotifs } from '../components/hoc'

const log = console.getLogger('AUTH')

const OAuthPage = ({ t, notifs }) => {
  const dispatch = useDispatch()
  const viewerId = useSelector(selectViewerId)
  // const hostConf = useSelector(selectHostConf)
  const navigate = useNavigate()
  const { pathname, search } = useLocation()

  if (pathname !== '/auth/jaikudo-oauth') {
    // this won't happen, but just in case
    navigate('/', { replace: true })
  }

  const query = useMemo(() => parseQuery(search), [search])

  useEffect(() => {
    const startSignIn = async () => {
      const { code, state } = query
      const dispatched = dispatch(oauthSignInAction({ code, state }))
      try {
        await dispatched.meta.action.payload
      } catch (error) {
        log.error('oatuh login error: ', error)
        notifs.error({ title: t('Something went wrong') })
        navigate('/', { replace: true })
      }
    }

    startSignIn()
  }, [query, dispatch, navigate, notifs, t])

  useEffect(() => {
    if (viewerId) {
      navigate('/', { replace: true })
    }
  }, [viewerId, navigate])

  return (
    <div>
      <Col alignItems="center">
        <Gap gap="32px" />
        <Row alignItems="center">
          <Loading type="puff" />
          <Gap gap="32px" />
          <Text size="20px">{t('Loading')}</Text>
        </Row>
      </Col>
    </div>
  )
}

const enhance = compose(withTranslation(), withNotifs, withRouter)

export default enhance(OAuthPage)
