import { apiGet, apiDelete, apiPost, apiPut } from '../lib/api'
import { userStatsKeys } from '../userStatsConfig'

export const APP_SET_LOCATION = 'APP_SET_LOCATION'
export const APP_START = 'APP_START'
export const APP_STORAGE_LOAD = 'APP_STORAGE_LOAD'
export const APP_SUSPENSE_DONE = 'APP_SUSPENSE_DONE'
export const TOGGLE_NAVIGATION = 'TOGGLE_NAVIGATION'
export const SET_SCROLL_LOCATION = 'SET_SCROLL_LOCATION'

export const FETCH_HOST_CONFIG = 'FETCH_HOST_CONFIG'
export const FETCH_HOST_CONFIG_START = 'FETCH_HOST_CONFIG_START'
export const FETCH_HOST_CONFIG_SUCCESS = 'FETCH_HOST_CONFIG_SUCCESS'
export const FETCH_HOST_CONFIG_ERROR = 'FETCH_HOST_CONFIG_ERROR'

export const FETCH_NEWS = 'FETCH_NEWS'
export const FETCH_NEWS_START = 'FETCH_NEWS_START'
export const FETCH_NEWS_SUCCESS = 'FETCH_NEWS_SUCCESS'
export const FETCH_NEWS_ERROR = 'FETCH_NEWS_ERROR'

export const FETCH_WORKERS = 'FETCH_WORKERS'
export const FETCH_WORKERS_START = 'FETCH_WORKERS_START'
export const FETCH_WORKERS_SUCCESS = 'FETCH_WORKERS_SUCCESS'
export const FETCH_WORKERS_ERROR = 'FETCH_WORKERS_ERROR'

export const CREATE_WORKER = 'CREATE_WORKER'
export const CREATE_WORKER_START = 'CREATE_WORKER_START'
export const CREATE_WORKER_SUCCESS = 'CREATE_WORKER_SUCCESS'
export const CREATE_WORKER_ERROR = 'CREATE_WORKER_ERROR'

export const DELETE_WORKER = 'DELETE_WORKER'
export const DELETE_WORKER_START = 'DELETE_WORKER_START'
export const DELETE_WORKER_SUCCESS = 'DELETE_WORKER_SUCCESS'
export const DELETE_WORKER_ERROR = 'DELETE_WORKER_ERROR'

export const EDIT_WORKER = 'EDIT_WORKER'
export const EDIT_WORKER_START = 'EDIT_WORKER_START'
export const EDIT_WORKER_SUCCESS = 'EDIT_WORKER_SUCCESS'
export const EDIT_WORKER_ERROR = 'EDIT_WORKER_ERROR'

export const SEND_STATS = 'SEND_STATS'
export const SEND_STATS_START = 'SEND_STATS_START'
export const SEND_STATS_SUCCESS = 'SEND_STATS_SUCCESS'
export const SEND_STATS_ERROR = 'SEND_STATS_ERROR'

export const FETCH_ME = 'FETCH_ME'
export const FETCH_ME_START = 'FETCH_ME_START'
export const FETCH_ME_SUCCESS = 'FETCH_ME_SUCCESS'
export const FETCH_ME_ERROR = 'FETCH_ME_ERROR'

export const EDIT_ME = 'EDIT_ME'
export const EDIT_ME_START = 'EDIT_ME_START'
export const EDIT_ME_SUCCESS = 'EDIT_ME_SUCCESS'
export const EDIT_ME_ERROR = 'EDIT_ME_ERROR'

export const READ_NEWS = 'READ_NEWS'
export const SET_BROWSER_ID = 'SET_BROWSER_ID'
export const SET_ORDERS_TABLE_FULL_WIDTH = 'SET_ORDERS_TABLE_FULL_WIDTH'

export const FETCH_PUPPET_USERS_LIST = 'FETCH_PUPPET_USERS_LIST'
export const FETCH_PUPPET_USERS_LIST_START = 'FETCH_PUPPET_USERS_LIST_START'
export const FETCH_PUPPET_USERS_LIST_SUCCESS = 'FETCH_PUPPET_USERS_LIST_SUCCESS'

export const SET_PUPPET_USER = 'SET_PUPPET_USER'

export const fetchPuppetUsersList = (customQuery) => ({ getState, fetch }) => ({
  type: FETCH_PUPPET_USERS_LIST,
  payload: apiGet({
    fetch,
    endpoint: '/users',
    getState,
    customQuery,
  }),
})

export const setPuppetUser = user => ({
  type: SET_PUPPET_USER,
  payload: user,
})

export const fetchMe = () => ({ getState, fetch }) => ({
  type: FETCH_ME,
  payload: apiGet({
    fetch,
    endpoint: '/users/me',
    getState,
  }),
})

export const editMe = body => ({ getState, fetch }) => ({
  type: EDIT_ME,
  payload: apiPut({
    fetch,
    endpoint: '/users/me',
    getState,
    body,
  }),
})

export const fetchNews = () => ({ getState, fetch }) => ({
  type: FETCH_NEWS,
  payload: apiGet({
    fetch,
    endpoint: '/news',
    getState,
  }),
})

export const fetchWorkers = () => ({ getState, fetch }) => ({
  type: FETCH_WORKERS,
  payload: apiGet({
    fetch,
    endpoint: '/workers',
    getState,
  }),
})

export const deleteWorker = _id => ({ getState, fetch }) => ({
  type: DELETE_WORKER,
  _id,
  payload: apiDelete({
    fetch,
    endpoint: `/workers/${_id}`,
    getState,
  }),
})

export const editWorker = (_id, body) => ({ getState, fetch }) => ({
  type: EDIT_WORKER,
  _id,
  payload: apiPut({
    fetch,
    endpoint: `/workers/${_id}`,
    getState,
    body,
  }),
})

export const createWorker = body => ({ getState, fetch }) => ({
  type: CREATE_WORKER,
  payload: apiPost({
    fetch,
    endpoint: '/workers',
    getState,
    body,
  }),
})

export const sendStats = () => ({ getState, fetch }) => {
  const state = getState()

  const { browserId } = state.app
  const { tracer, ...otherConfig } = state.config

  // const tracerData = userStatsKeys.tracer.reduce((acc, key) => {
  //   acc[key] = tracer[key]
  //   return acc
  // }, {})
  const otherConfigData = userStatsKeys.other.reduce((acc, key) => {
    acc[key] = otherConfig[key]
    return acc
  }, {})

  const body = {
    browserId,
    // ...tracerData,
    ...otherConfigData,
  }

  // console.log('body', body)
  return {
    type: SEND_STATS,
    payload: apiPost({
      fetch,
      endpoint: '/stats/user-stats',
      getState,
      body,
    }),
  }
}

export const fetchHostConf = hostname => ({ getState, fetch }) => ({
  type: FETCH_HOST_CONFIG,
  payload: apiGet({
    fetch,
    endpoint: `/app/page-versions/${hostname}`,
    getState,
  }),
})

export const setLocation = location => ({
  type: APP_SET_LOCATION,
  payload: { location },
})

export const setScrollLocation = location => ({
  type: SET_SCROLL_LOCATION,
  payload: { location },
})

export const start = () => {
  const loadStorage = async (dispatch, storageEngine) => {
    const state = await storageEngine.load()
    dispatch({ type: APP_STORAGE_LOAD, payload: state })
  }
  return ({ dispatch, storageEngine }) => {
    loadStorage(dispatch, storageEngine).finally(() => {})
    return {
      type: APP_START,
    }
  }
}

export const toggleNavigation = () => ({
  type: TOGGLE_NAVIGATION,
})

export const finishSuspense = () => ({
  type: APP_SUSPENSE_DONE,
})

export const readNews = () => ({
  type: READ_NEWS,
})
export const setBrowserId = browserId => ({
  type: SET_BROWSER_ID,
  payload: { browserId },
})

export const setOrdersTableFullWidth = value => ({
  type: SET_ORDERS_TABLE_FULL_WIDTH,
  payload: value,
})
