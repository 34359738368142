export class CustomError extends Error {
  constructor(errorName, options = {}, defaultOptions = {}) {
    super()
    this.displayName = 'CustomError'
    this.name = errorName
    this.status = options.status || defaultOptions.status || 400
    this.message = options.message || defaultOptions.message || 'Custom Error'
    this.detail = options.detail || defaultOptions.detail || ''
    this.toResponseObject = () => ({
      status: this.status,
      name: this.name,
      message: this.message,
      detail: this.detail,
      stack: process.env.NODE_ENV !== 'production' ? this.stack : null,
    })
    this.showOnFrontend = defaultOptions.showOnFrontend

    Error.captureStackTrace(this, this.constructor)
  }
}

function createError(errorName, defaultValues) {
  return options => {
    if (typeof options === 'string') {
      return new CustomError(errorName, { message: options }, defaultValues)
    }
    return new CustomError(errorName, options, defaultValues)
  }
}

export const testCustomError = createError('testCustomError', {
  status: 400,
  message: 'Defaultní text testovacího erroru',
  detail: 'detail erroru',
})

export const badRequest = createError('BadRequest', {
  message: 'Bad Request',
})

export const invalidAccessData = createError('InvalidAccessData', {})

export const tokenIsRequired = createError('TokenIsExpired', {
  message: 'access token is required',
})

export const tokenIsExpired = createError('TokenIsExpired', {
  message: 'access token is expired',
})

export const restrictedArea = createError('RestrictedArea', {
  message: 'Restricted Area. Access token needed',
})

export const invalidLoginData = createError('InvalidLoginData', {
  message: 'Invalid login data',
})

export const unconfirmedEmail = createError('UnconfirmedEmail', {
  message: 'Email has not been confirmed',
})

export const invalidFormData = createError('InvalidFormData', { showOnFrontend: true })

export const emailInUse = createError('EmailInUse', {
  message: 'This email is already in use. Please choose another.',
  detail: {
    field: 'email',
  },
})

export const usernameInUse = createError('usernameInUse', {
  message: 'This username is already in use. Please choose another.',
  detail: {
    field: 'username',
  },
})

export const resetPossibleOnceADay = createError('ResetPossibleOnceADay', {
  message: 'You already reset your password today',
})

export const userNotFound = createError('UserNotFound', {
  status: 404,
  message: 'User could not be found',
})

export const mailError = createError('MailError', {
  status: 405,
  message: 'Message could not be sent',
})
