import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { withTranslation } from 'react-i18next'
import { createTranslatedMessage } from '../../../../common/helpers'

const ErrorsContainer = styled.div`
  position: absolute;
  display: flex;
`

const Error = styled.div`
  font-size: 1.2rem;
  color: ${({ theme }) => theme.colors.error};
`

const Errors = ({ errors, t, areColumned, ...rest }) => {
  const errorMessage =
    errors &&
    errors.length >= 0 &&
    errors.reduce(
      (result, err) => {
        if (!err.message) return result

        const message =
          typeof err.message === 'object' ? createTranslatedMessage(err.message, t) : err.message
        if (areColumned) {
          result.push(message)
          return result
        }
        return `${result + message}, `
      },
      areColumned ? [] : '',
    )
  return (
    <ErrorsContainer {...rest}>
      <Error>
        {areColumned
          ? errorMessage.map((e, key) => <div key={key}>{e}</div>)
          : errorMessage.substring(0, errorMessage.length - 2)}
      </Error>
    </ErrorsContainer>
  )
}

Errors.defaultProps = {
  errors: [],
  areColumned: false,
}

Errors.propTypes = {
  errors: PropTypes.array,
  t: PropTypes.func.isRequired,
  areColumned: PropTypes.bool,
}

export default withTranslation()(Errors)
