import React from 'react'
import Omega from './PricesSection.omega'
import Reize from './PricesSection.reize'
import { SwitchByAppType } from '../..'

const variants = {
  Omega,
  Reize,
}

export default props => <SwitchByAppType variants={variants} props={props} />
