import React from 'react'
import Omega from './IndividualSection.omega'
import Reize from './IndividualSection.reize'
import Jzo from './IndividualSection.jzo'
import Jaikudo from './IndividualSection.jaikudo'
import { SwitchByAppType } from '../..'

const variants = {
  Omega,
  Reize,
  Jzo,
  Jaikudo,
}

export default props => <SwitchByAppType variants={variants} props={props} />
