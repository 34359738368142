import React, { useEffect, useState } from 'react'
import styled, { css, keyframes } from 'styled-components'
import useLocalStorageState from 'use-local-storage-state'
import CloseIcon from './CloseIcon'
import T from './T'

const fadeInKeyframes = keyframes`
 0%{
    opacity: 0;
    transform: translate(-50%, 0) scale(0.9);
  }

  100%{
    opacity: 1;
    transform: translate(-50%, 0) scale(1);
  }
`

const Wrapper = styled.div`
  ${({ theme: { colors, page } }) => css`
    border-radius: 0.8rem;
    background: white;
    position: fixed;
    z-index: 100;
    padding: 16px 32px;
    bottom: 10rem;
    max-width: ${page.maxWidth};
    max-width: calc(${page.maxWidth} - 40px);
    width: 100%;
    width: calc(100% - 16px);
    left: 50%;
    transform: translate(-50%, 0);
    box-shadow: 0px 8px 37px 5px rgba(0, 0, 0, 0.4);

    opacity: 0;
    animation-name: ${fadeInKeyframes};
    animation-duration: 300ms;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
  `}
`

const CloseIconWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 0;
`

function CookiesConsent() {
  const [consent, setConsent] = useLocalStorageState('generalCookieConsent', false)
  const [isShown, setIsShown] = useState(false)

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsShown(true)
    }, 2000)

    return () => clearTimeout(timeout)
  }, [])

  return (
    !consent &&
    isShown && (
      <Wrapper>
        <CloseIconWrapper>
          <CloseIcon
            justIcon
            onClick={() => {
              setConsent(true)
            }}
          />
        </CloseIconWrapper>
        <T>
          cookies consent description
        </T>
      </Wrapper>
    )
  )
}

export default CookiesConsent
