import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import { nanoid } from 'nanoid'
import { bugsnagConfig } from '../common/config' // eslint-disable-line
import { applyMetadata } from '../common/helpers'

// export default {}

const logger = console.getLogger('BUGSNAG')
// eslint-disable-next-line
export const bugsnagClient = Bugsnag.start({
  ...bugsnagConfig,
  apiKey: process.env.BUGSNAG_KEY,
  appType: process.env.APP_TYPE,
  plugins: [new BugsnagPluginReact()],
  onError: defaultOnError,
  logger,
  // log is missing in production, TODO:  github issue waiting
  // enabledBreadcrumbTypes: ['navigation', 'request', 'process', 'log', 'user', 'state', 'error', 'manual'],
})
logger.info('bugsnag started')

function defaultOnError(event) {
  const appState = require('./main').getState()

  const app = {
    ...appState.app,
  }
  const user = {
    ...app.viewer,
  }
  delete app.user

  event.setUser(user._id, user.username, user.name)
  event.addMetadata('user', {
    ...user,
  })
  event.addMetadata('state', {
    // if needed, add sth from state, but check limit (1 MB)
    config: appState.config,
    app: appState.app,
  })

  try {
    const viewport = {
      width: window.innerWidth || document.documentElement.clientWidth,
      height: window.innerHeight || document.documentElement.clientHeight,

      layout: {
        width: document.documentElement.clientWidth,
        height: document.documentElement.clientHeight,
      },
    }
    viewport.zoom = viewport.layout.width / viewport.width

    event.addMetadata('device', {
      cookieEnabled: window.navigator.cookieEnabled,
      ...viewport,
    })
  } catch (error) {
    console.log('inaccessible device data', error)
  }

  // add issue id, and display it to user for better issue lookup
  try {
    const issueId = nanoid()
    event.addMetadata('issue', {
      issueId,
    })
    window.issueId = issueId
  } catch (error) {
    console.log('issue id not generated', error)
  }

  if (process.env.NODE_ENV !== 'production') {
    // too big dev console statements (limit is 1 MB)
    logger.debug('deleting breadcrumbs')
    delete event.breadcrumbs
  }
}

// new api - use this for custom message
// if needed, we can implement manipulation of original metadata
export const notifyInfo = (err, customMetadata, metadata = {}) =>
  Bugsnag.notify(err, event => {
    defaultOnError(event)
    event.severity = 'info'
    applyMetadata(event, customMetadata, metadata)
  })

export const notifyWarning = (err, customMetadata, metadata = {}) =>
  Bugsnag.notify(err, event => {
    defaultOnError(event)
    event.severity = 'warning'
    applyMetadata(event, customMetadata, metadata)
  })

export const notifyError = (err, customMetadata, metadata = {}) =>
  Bugsnag.notify(err, event => {
    defaultOnError(event)
    applyMetadata(event, customMetadata, metadata)
  })

// propagate to console
console.notifyInfo = notifyInfo
console.notifyWarning = notifyWarning
console.notifyError = notifyError
