import styled from 'styled-components'
import PropTypes from 'prop-types'

const Input = styled.input.attrs(props => ({
  autoComplete: props.type !== 'password' ? 'off' : '',
}))`
  box-shadow: none;
  display: block;
  outline: none;
  transition: all .3s;
  background: white;
  /* border: ${({ theme }) => theme.field.borderWidth} solid; */
  border-radius: 6px;
  font-size: 1.4rem;
  line-height: 3rem;
  padding: 0 1rem;
  width: 100%;
  border: 1px solid transparent;
  border-color: ${({ theme, isValid }) => isValid ? 'transparent' : theme.colors.error};
  vertical-align: center;

  &:enabled:not([type="checkbox"]) {
    border-bottom: 1px solid ${({ theme, isValid }) => isValid ? '#DDD' : theme.colors.error};
    box-shadow: inset 0 1px 2px rgba(0,0,0,0.29), 0px 1px 1px #FFF, 0 1px 0 #FFF;
    border-width: 1px;
  };
  &:disabled {
    background: ${({ theme }) => theme.field.disabledInputColor};
  };
  &:enabled:not([type="checkbox"]):focus {
    border: 1px solid ${({ theme }) => theme.field.focusInputColor};
  };
`

Input.defaultProps = {
  isValid: true,
}

Input.propTypes = {
  isValid: PropTypes.bool,
}

export default Input
