import produce from 'immer'
import * as actions from './actions'

import { setIn, deleteIn } from '../../helpers'

const immerSetIn = (draft, path, value) => {
  let ref = draft
  for (let i = 0; i < path.length - 1; i += 1) {
    const key = path[i]
    if (typeof ref[key] === 'undefined') {
      ref[key] = {}
    }
    ref = ref[key]
  }
  ref[path[path.length - 1]] = value
}

const fieldsReducer = (state = {}, action) =>
  produce(state, draft => {
    switch (action.type) {
      case actions.FIELDS_RESET_FIELDS: {
        const { path } = action.payload
        delete draft[path]
        // return deleteIn(draft, path)
        break
      }

      case actions.FIELDS_SET_FIELD: {
        const { path, value } = action.payload
        immerSetIn(draft, path, value)
        break
      }

      case actions.FIELDS_SET_MULTIPLE_FIELDS: {
        const { options } = action.payload

        options.forEach(({ path, value }) => {
          immerSetIn(draft, path, value)
        })
        break
      }

      case actions.FIELDS_RESET_ALL: {
        return {}
      }

      // no default
    }
  })

export default fieldsReducer
