/* eslint-disable max-classes-per-file */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { withTranslation } from 'react-i18next'

import { Row, Col, FieldsGroup, Button } from '..'
import { NumberField, withMemoize } from '.'
import { Errors as ErrorsBase } from './components'
import { getFieldConfig } from '../../../common/schemaConfig'
import { appTypeConfig } from '../../../common/config'
import keyboardKey from '../../../common/lib/keyboardKey'

const Content = styled.div`
  visibility: ${({ isHidden }) => isHidden ? 'hidden' : 'visible'};
`

const Errors = styled(ErrorsBase)`
  position: relative;
`

const SideLabel = styled(Col)`
  align-self: flex-end;
  margin-bottom: 0.7rem;
  margin-right: 1rem;
  width: 0.8rem;
`

const Overlay = styled.div`
  visibility: visible;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
`

const ParamCol = styled(Col)`
  max-width: ${({ theme: { field } }) => field.numberFieldMaxWidth};
`
/* compact key={key} style={{ maxWidth: '7rem' }} */

class LensParamsField extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isOpen: props.isDefaultOpen,
    }
  }

  fieldsRefs = {}

  isSomeFieldFilled = () => {
    const { options } = this.props
    return options.some(({ r, l }) => r.value || l.value)
  }

  handleEnterPress = e => {
    if (e.which === 13) {
      e.preventDefault()
      const { name } = e.target
      const nextIndex = this.fieldsRefs[name].index + 1
      const nextRefIndex = Object.keys(this.fieldsRefs).find(
        key => this.fieldsRefs[key].index === nextIndex,
      )
      const nextRef = this.fieldsRefs[nextRefIndex]
      if (nextRef) {
        this.fieldsRefs[nextRefIndex].ref.focus()
      } else {
        // TODO pořešit focusnutí na další blok
      }
    }
  }

  wireInnerRef = ref => {
    if (ref) {
      this.fieldsRefs[ref.name] = { ref, index: parseInt(ref.getAttribute('data-index'), 10) }
    }
  }

  errorsPlaceholder = [{ message: '' }]

  renderItem = ({
    field,
    disabled,
    onChangeAction,
    affectsLensList = false,
    showSign,
    options,
    index,
  }) => (
    <NumberField
      innerRef={this.wireInnerRef}
      data-index={index}
      disabled={disabled}
      onKeyPress={this.handleEnterPress}
      {...options}
      {...field}
      showSign={showSign}
      decimals={getFieldConfig(field.name).decimals}
      onBlur={(...args) => {
        onChangeAction(affectsLensList)
        const { decimals, minStep } = getFieldConfig(field.name)
        let { step } = getFieldConfig(field.name)
        if (minStep) step = minStep
        let newValue = field.value
        if (field.value !== '') {
          const isValueCorrectionAvailable = !(
            appTypeConfig.isSphAndCylInHundredthsEnabled &&
            ['sphereR', 'sphereL', 'cylinderR', 'cylinderL'].includes(field.name)
          )

          if (isValueCorrectionAvailable && typeof step !== 'undefined') {
            const roundCoefficient = 1 / step
            newValue = Math.round(newValue * roundCoefficient) / roundCoefficient
          }
          if (typeof decimals !== 'undefined' && newValue && newValue.toFixed) {
            newValue = newValue.toFixed(decimals)
          }
          if (field.value !== newValue) {
            field.onChange({ value: newValue })
          }
        }
        field.onBlur(...args)
        return newValue
      }}
      step={getFieldConfig(field.name).step}
      value={disabled ? '' : field.value}
      errors={!disabled && field.errors.length > 0 ? this.errorsPlaceholder : null}
    />
  )

  render() {
    const {
      title,
      options,
      rightDisabled,
      leftDisabled,
      onChangeActionsR,
      onChangeActionsL,
      showSideLabels,
      t,
      ...rest
    } = this.props
    const { isOpen } = this.state

    if (!isOpen && this.isSomeFieldFilled()) this.setState({ isOpen: true })

    const errors = options.reduce((result, o) => {
      if (rightDisabled) {
        o.l.errors.forEach(e => result.push(e))
      } else if (leftDisabled) {
        o.r.errors.forEach(e => result.push(e))
      } else {
        o.r.errors.forEach(e => result.push(e))
        o.l.errors.forEach(e => {
          const sameErrorExists = result.some(result => {
            if (result.message === e.message) {
              return true
            }
            if (
              result.message.keys &&
              e.message.keys &&
              result.message.keys.length === e.message.keys.length
            ) {
              let sameErrorExists = true
              for (let i = 0; i < result.message.keys.length; i += 1) {
                const areEquals = result.message.keys[i] === e.message.keys[i]
                if (!areEquals) sameErrorExists = false
              }
              return sameErrorExists
            }
            return false
          })
          if (!sameErrorExists) {
            result.push(e)
          }
        })
      }
      return result
    }, [])

    return (
      <FieldsGroup title={title} isContentHidden={!isOpen} {...rest}>
        {!isOpen && (
          <Overlay>
            <Button onClick={() => this.setState({ isOpen: true })} maxWidth="14rem">
              + {title}
            </Button>
          </Overlay>
        )}
        <Content isHidden={!isOpen}>
          <Row compact alignItems="flex-end">
            {options.map(({ r, disabledR, affectsLensList, showSign, ...options }, key) => (
              <React.Fragment key={key}>
                {key === 0 && showSideLabels && <SideLabel>{t('R')}</SideLabel>}
                <ParamCol compact key={key}>
                  {this.renderItem({
                    field: r,
                    disabled: rightDisabled || disabledR,
                    onChangeAction: onChangeActionsR,
                    affectsLensList,
                    options,
                    index: key,
                    showSign,
                  })}
                </ParamCol>
              </React.Fragment>
            ))}
          </Row>
          <Row compact>
            {options.map(({ l, disabledL, affectsLensList, showSign }, key) => (
              <React.Fragment key={key}>
                {key === 0 && showSideLabels && <SideLabel>{t('L')}</SideLabel>}
                <ParamCol compact key={key}>
                  {this.renderItem({
                    field: l,
                    disabled: leftDisabled || disabledL,
                    affectsLensList,
                    onChangeAction: onChangeActionsL,
                    index: key + options.length,
                    showSign,
                  })}
                </ParamCol>
              </React.Fragment>
            ))}
          </Row>
          <Row compact>
            <Errors errors={errors} areColumned />
          </Row>
        </Content>
      </FieldsGroup>
    )
  }
}

LensParamsField.propTypes = {
  title: PropTypes.string,
  options: PropTypes.array.isRequired,
  rightDisabled: PropTypes.bool,
  leftDisabled: PropTypes.bool,
  isDefaultOpen: PropTypes.bool,
  showSideLabels: PropTypes.bool,
  onChangeActionsR: PropTypes.func,
  onChangeActionsL: PropTypes.func,
  onFocusLeaveR: PropTypes.func,
  onFocusLeaveL: PropTypes.func,
}

LensParamsField.defaultProps = {
  title: '',
  rightDisabled: false,
  leftDisabled: false,
  isDefaultOpen: false,
  showSideLabels: false,
  onChangeActionsR: () => {},
  onChangeActionsL: () => {},
  onFocusLeaveR: null,
  onFocusLeaveL: null,
}

export default withTranslation()(withMemoize(LensParamsField))
