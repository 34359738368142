import styled from 'styled-components'
import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { TextField, SelectLanguageField } from '../fields'
import { fields } from '../../../common/lib/redux-fields'
import { Row, Col, Button, Link } from '..'
import { signIn } from '../../../common/auth/actions'
import { validateSignIn } from '../../../common/auth/validations'
import { withNotifs } from '../hoc'

const SignInButton = styled(Button)`
  min-width: 14rem;
`

const ProblemLink = styled(Link)`
  font-size: 1.2rem;
`

const submit = async ({ fields, notifs, t }) => {
  try {
    const isSubmitted = await fields.$submit()
    if (!isSubmitted) {
      fields.password.onChange('')
    } else {
      fields.$reset()
    }
  } catch (errors) {
    // console.log('ERRORS', errors)
    fields.password.onChange('')
    const errorMessage = errors.reduce((acc, e) => {
      acc = `${acc}${t(e.message)}
              `
      return acc
    }, '')
    notifs.error({ title: t('login error'), message: errorMessage })
    return false
  }
}

const AuthForm = ({ t, fields, notifs }) => (
  <form>
    <Row>
      <Col width="100%">
        <TextField label={t('username')} {...fields.username} />
      </Col>
    </Row>
    <Row>
      <Col width="100%">
        <TextField type="password" label={t('password')} {...fields.password} />
      </Col>
    </Row>
    <Row>
      <Col width="100%">
        <SelectLanguageField />
      </Col>
    </Row>
    <Row marginTop="4rem">
      <Col alignItems="center" width="100%">
        <SignInButton
          primary
          large
          onClick={e => {
            e.preventDefault()
            submit({ fields, notifs, t })
          }}
        >
          {t('sign in_verb')}
        </SignInButton>
      </Col>
      {/*
        TODO doplnit link nebo zprávu
      <Col grow="1" alignItems="flex-end">
        <ProblemLink to="#">Problém s přihlášením?</ProblemLink>
      </Col> */}
    </Row>
  </form>
)

AuthForm.propTypes = {
  fields: PropTypes.object.isRequired,
  signIn: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  notifs: PropTypes.object.isRequired,
}

const enhance = compose(
  connect(() => ({}), {
    signIn,
  }),
  fields({
    path: 'authForm',
    fields: ['username', 'password'],
    validationFunc: validateSignIn,
    submitFunc: (data, { signIn }) => signIn(data),
  }),
  withTranslation(),
  withNotifs,
)

export default enhance(AuthForm)
