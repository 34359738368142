import styled, { css } from 'styled-components'

export const TableLikeRowsWrapper = styled.div`
  border: 2px solid ${({ theme }) => theme.colors.tableBorderColor};
  border-radius: 6px;
  width: 100%;
  overflow: hidden;
`

export const TableLikeRow = styled.div`
  ${({ theme: { colors}, padding = '1.2rem 1.6rem', justifyContent, clickable, selected }) => css`
    padding: ${padding};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: ${justifyContent};

    cursor: ${clickable && 'pointer'};

    transition: 0.3s;
    &:nth-child(odd) {
      background-color: ${colors.tableOddRowBackground};
    }
    &:hover {
      background-color: #f0f0f0;
    }
    background-color: ${selected && colors.primaryVeryLight} !important;
  `}
`
