import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { useTranslation } from 'react-i18next'
import PopupPresentational from './PopupPresentational'

const ItemTitle = styled.div`
  ${({ theme: { colors } }) => css`
    color: ${colors.text2};
    text-align: 'left';
    font-size: 1.6rem;
    font-weight: 600;
    padding-bottom: 0.4rem;
  `}
`

const ItemLink = styled.a`
  ${({ theme: { colors } }) => css`
    display: block;
    text-align: 'left';
    font-size: 1.4rem;
    font-weight: 600;
    width: auto;
    padding-top: 0.8rem;
    color: ${colors.primary};
    text-decoration: none;

    &:hover,
    &:active {
      text-decoration: none;
      color: ${colors.primaryDark};
    }
  `}
`

const Divider = styled.div`
  width: 100%;
  background: ${p => p.theme.colors.navbarDivider};
  height: 1px;
  margin: 16px 1rem;
`

const NewsPopup = ({ close, args }) => {
  const { news } = args
  const { t } = useTranslation()
  const filteredNews = news.filter(n => !n.read)
  return (
    <PopupPresentational
      hideCancel
      close={result => {
        if (result && result.ok) {
          args.onConfirm()
        } else if (args.onCancel) {
          args.onCancel()
        }
        if (args.onClose) {
          args.onClose()
        }
        close()
      }}
      {...args}
    >
      {filteredNews.map((n, key) => (
        <div>
          <ItemTitle>{n.title}</ItemTitle>
          {n.text.split('\n').map((chunk, index) => (
            <Fragment key={index}>
              {index !== 0 && <br />}
              {chunk}
            </Fragment>
          ))}
          {n.link && (
            <ItemLink rel="noreferrer" href={n.link} target="_blank">
              {t('Find out more!')}
            </ItemLink>
          )}
          {key !== filteredNews.length - 1 && <Divider />}
        </div>
      ))}
    </PopupPresentational>
  )
}

NewsPopup.propTypes = {
  args: PropTypes.object.isRequired,
  close: PropTypes.func.isRequired,
}

export default NewsPopup
