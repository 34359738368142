import React from 'react'
import PropTypes from 'prop-types'
import PopupPresentational from './PopupPresentational'

const ConfirmPopup = ({ close, args }) => (
  <PopupPresentational
    hideCancel
    close={result => {
      if (result && result.ok) {
        args.onConfirm()
      } else if (args.onCancel) {
        args.onCancel()
      }
      if (args.onClose) {
        args.onClose()
      }
      close()
    }}
    {...args}
  />
)

ConfirmPopup.propTypes = {
  args: PropTypes.object.isRequired,
  close: PropTypes.func.isRequired,
}

export default ConfirmPopup
