import React from 'react'
import Text from './Text'
import Icon from './Icon'
import Row from './Row'
import Col from './Col'
import { getTheme } from '../app/App'

const WarningMessage = props => (
  <div>
    <Row alignItems="center">
      <Col compact>
        <div>
          <Icon size={24} name="MdAlert" color={getTheme().colors.primary} />
        </div>
      </Col>
      <Col>
        <Text {...props} />
      </Col>
    </Row>
  </div>
)

export default WarningMessage
