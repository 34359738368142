import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { OrderStep2Form } from '../forms'
import { Steps } from '..'
import Page from '../Page'
import { fetchVca } from '../../../common/vca/actions'
import { setActiveOrderStep } from '../../../common/orders/actions'

class CreateOrderStep2Page extends Component {
  componentDidMount() {
    // TODO: optimalizace tohoto. Neukladat lokalne vsechna data ale dostahnout
    this.props.fetchVca()
    this.props.setActiveOrderStep(2)
    if (process.env.IS_BROWSER && process.env.NODE_ENV !== 'development') {
      window.scrollTo(0, 0)
    }
  }

  render() {
    return <OrderStep2Form {...this.props} />
  }
}

CreateOrderStep2Page.propTypes = {
  fetchVca: PropTypes.func.isRequired,
  setActiveOrderStep: PropTypes.func.isRequired,
}

const enhance = compose(
  connect(() => ({}), {
    fetchVca,
    setActiveOrderStep,
  }),
)

export default enhance(CreateOrderStep2Page)
