/* @flow weak */
import storage from 'redux-storage'
import storageDebounce from 'redux-storage-decorator-debounce'
import createStorageEngine from 'redux-storage-engine-localstorage'
import { APP_STORAGE_LOAD } from '../app/actions'
import { getIn, setIn } from '../helpers'

const APP_NAME = 'omega'

const stateToSave = [
  ['app', 'viewer'],
  ['app', 'puppetUser'],
  ['app', 'news'], // must be saved - we want to know what was read
  ['app', 'browserId'],
  ['app', 'lastStatsSendAt'],
  ['app', 'workers'],
  ['app', 'ordersTableFullWidth'],
  ['fields'],
  ['catalog'],
  ['config'],
  ['vca'],
  ['lists'],
  ['orders'],
]

// TODO: pokud sem dame orders tak dat do vyjimek sendingOrdersIds (v pripade bloknuti chceme na refresh umoznit unblock)
const exceptions = {
  vca: ['standardLib', 'standardLibDeformed', 'myLib', 'myLibDeformed', 'uploadedVca'],
  catalog: [
    // 'lensesListR',
    // 'lensesListL',
  ],
  orders: ['all', 'sendingOrder'],
  fields: ['SettingsTracerForm'],
  config: ['tracersLoaded'],
}

// TODO: napad na vylepseni - pridat neco jako check na nahrani dat - pokud nahrana data nebudou odpovidat, vrati se vychozi stav.. (tohle vyresi kolize v pripade zmeny topologie statu apod..)
// this transform data from persistent form to life redux form
const updateState = (state, storageStateJson) => {
  const empty = !storageStateJson || !Object.keys(storageStateJson).length
  if (empty) return state
  try {
    JSON.parse(storageStateJson).forEach(({ feature, featurePath, value }) => {
      // TODO: tohle jsem schoval, interaguje s
      // ['app', 'ordersTableFullWidth'],
      // nevim jaky byl jiny zamer, ale zda se, ze se to pro nic jineho nevola
      // const canSet = state[feature] && !!getIn(state[feature], featurePath)
      // if (!canSet) {
      //   console.log('canSetcanSetfeaturePath', featurePath, value, canSet, getIn(state[feature], featurePath))
      //   return
      // }
      if (featurePath.length === 0) {
        // whole reducer - merge it together, not replace
        state[feature] = {
          ...state[feature],
          ...value,
        }
      } else {
        state[feature] = setIn(state[feature], featurePath, value)

        // console.log('featurePath', featurePath, value)
      }
    })
  } catch (error) {
    console.log(error) // eslint-disable-line no-console
  }
  return state
}

// this is used to tell app when to store state to persistent storage,
// also filters out part of state we dont want to store
const storageFilter = engine => ({
  ...engine,
  save(state) {
    if (!state) return Promise.resolve()
    if (!state.app.storageLoaded) return Promise.reject()

    // We don't filter by actions but by the app state structure.
    // That's fine because saving is debounced.
    const saveState = stateToSave.map(([feature, ...featurePath]) => {
      let value = state[feature] && getIn(state[feature], featurePath)

      // console.log('value',featurePath, value)
      if (exceptions[feature]) {
        value = { ...value }
        exceptions[feature].forEach(path => {
          delete value[path]
        })
      }
      return {
        feature,
        featurePath,
        value,
      }
    })
    return engine.save(JSON.stringify(saveState))
  },
})

const createStorageMiddleware = storageEngine => {
  let decoratedEngine = storageFilter(storageEngine)
  decoratedEngine = storageDebounce(decoratedEngine, 600)
  return storage.createMiddleware(decoratedEngine)
}

export const updateStateOnStorageLoad = reducer => (state, action) => {
  if (action.type === APP_STORAGE_LOAD) {
    state = updateState(state, action.payload)
  }
  return reducer(state, action)
}

const configureStorage = () => {
  const storageEngine = createStorageEngine && createStorageEngine(`redux-storage:${APP_NAME}`)
  const storageMiddleware = storageEngine && createStorageMiddleware(storageEngine)

  return {
    STORAGE_SAVE: storage.SAVE,
    storageEngine,
    storageMiddleware,
  }
}

export default configureStorage
