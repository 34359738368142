import React, { useState, useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { getStore } from '../main'
import { fetchHostConf } from '../../common/app/actions'
import { selectApp } from '../../common/app/reducer'

const AppGate = ({ children }) => {
  const dispatch = useDispatch()
  const { isHostConfError } = useSelector(selectApp)

  useEffect(() => {
    dispatch(fetchHostConf(window.location.hostname))
    // on initial mount only
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {!isHostConfError && children}
      {isHostConfError && (
        <div>
          <h1>Error!</h1>
          <p>Try to reload application. If it does not help, contact us please!</p>
        </div>
      )}
    </>
  )
}

export default AppGate
