import { tracerDefaultConfig } from './config/reducer'

// eslint-disable-next-line import/prefer-default-export
export const userStatsKeys = {
  tracer: Object.keys(tracerDefaultConfig),
  other: [
    // 'tracerEnabled',
    // 'tracerKey',
    // 'tracerBridgeIp',
    // 'tracerPort',
    // 'bridgeIp',
    // 'port',
    'orderListTimeType',
    'isReferenceDuplicityCheckEnabled',
    'isPriceShown',
    'priceType',
    'visualiserScale',
  ],
}

export const allKeys = [...userStatsKeys.tracer, ...userStatsKeys.other]
