import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { Box, Row, Col } from '../..'
import { NumberField, SwitchField, SelectField, LensParamsField } from '../../fields'
import { appTypeConfig } from '../../../../common/config'
import i18n from '../../../../common/i18n/i18n'

const { individualMenus } = appTypeConfig
const SWITCH_BUTTON_MIN_WIDTH = '10rem'

const createApproxOptions = (spec, t) =>
  spec.map(o => ({
    value: o.translationKey,
    label: t(o.translationKey),
    exactValue: o.value,
  }))

const FaceFormAngle = props => (
  <NumberField
    label={i18n.t('face form angle')}
    errorsStyle={{ position: 'relative' }}
    noMaxWidth
    {...props}
  />
)

const Bvd = ({ fields, ...props }) => (
  <NumberField
    label={i18n.t('bvd')}
    errorsStyle={{ position: 'relative' }}
    noMaxWidth
    {...props}
    onChange={e => {
      fields.bvdR.onChange(e)
      fields.bvdL.onChange(e)
    }}
  />
)

const Panto = props => (
  <NumberField
    label={i18n.t('pantoscopic tilt')}
    errorsStyle={{ position: 'relative' }}
    noMaxWidth
    {...props}
  />
)

const enhanceFields = (fields, menuConf) => {
  Object.keys(menuConf).forEach(key => {
    const fieldConf = menuConf[key]
    const isRequired = typeof fieldConf === 'object' ? fieldConf.isRequired : fieldConf === true

    let field = fields[key]
    if (key === 'bvd') field = fields.bvdR
    if (field) {
      field.required = isRequired
    }
  })
}

const isFieldVisible = fieldConf => typeof fieldConf !== 'undefined'

const cleanErrors = field => {
  if (field.errors && field.errors.length > 1) {
    field.onBlur()
  }
}

class IndividualSection extends React.Component {
  constructor(props) {
    super(props)
    const { fields } = props
    this.state = {
      // used only for validation after individual menu change
      inMenuKey: fields.individualMenu && fields.individualMenu.value,
    }
  }

  componentDidUpdate() {
    const { inMenuKey } = this.state
    const { fields } = this.props
    const newInMenuKey = fields.individualMenu && fields.individualMenu.value
    if (inMenuKey !== newInMenuKey) {
      this.setState({ inMenuKey: newInMenuKey }) // eslint-disable-line
      if (newInMenuKey) {
        const menuConf = individualMenus[newInMenuKey]
        Object.keys(menuConf).forEach(fieldKey => {
          let field = fields[fieldKey]
          if (fieldKey === 'bvd') field = fields.bvdR

          cleanErrors(field)
        })
      }
    }
  }

  render() {
    const { fields, lensL, lensR, t } = this.props
    if (
      !(
        (lensR && fields.lensR.value && lensR.individualMenu) ||
        (lensL && fields.lensL.value && lensL.individualMenu)
      )
    ) {
      return null
    }
    const menuConf = individualMenus[fields.individualMenu && fields.individualMenu.value]
    if (!menuConf) return null
    enhanceFields(fields, menuConf)

    // // proglen is always required now - but solve this later TODO
    // if (menuConf.proglen && menuConf.proglen.options) {
    //   const configOptions = menuConf.proglen.options
    //   const proglenValue = fields.proglen && fields.proglen.value
    //   if (proglenValue && !configOptions.includes(proglenValue) && configOptions.includes('')) {
    //     fields.proglen.onChange({ value: '' })
    //   }
    // }

    const itemsCount = Object.keys(menuConf || {}).length
    const columnWidth = [4, 5].includes(itemsCount) ? '25%' : '33%'

    return (
      <Box>
        <Box.Header>
          <Box.Title>{t('individual data')}</Box.Title>
        </Box.Header>
        <Box.Content>
          <Row alignItems="flex-start">
            {/* FACEFORMANGLE */}
            {isFieldVisible(menuConf.frameBowAngle) && (
              <Col width={columnWidth}>
                <FaceFormAngle {...fields.frameBowAngle} />
              </Col>
            )}

            {/* BVD */}
            {isFieldVisible(menuConf.bvd) && (
              <Col width={columnWidth}>
                <Bvd fields={fields} {...fields.bvdR} />
              </Col>
            )}

            {/* PANTO */}
            {isFieldVisible(menuConf.panto) && (
              <Col width={columnWidth}>
                <Panto {...fields.panto} />
              </Col>
            )}
          </Row>

          {/* PROGLEN */}
          {isFieldVisible(menuConf.proglen) && (
            <Col width={columnWidth}>
              <SelectField
                label={t('Progression length')}
                options={menuConf.proglen.options}
                // options={menuConf.proglen.options.map(o => ({ value: o.value, label: t(o.label) }))}
                {...fields.proglen}
              />
            </Col>
          )}
        </Box.Content>
      </Box>
    )
  }
}

IndividualSection.defaultProps = {
  lensR: {},
  lensL: {},
}

IndividualSection.propTypes = {
  fields: PropTypes.object.isRequired,
  lensR: PropTypes.object,
  lensL: PropTypes.object,
  t: PropTypes.func.isRequired,
}

const enhance = compose(
  connect(
    ({ catalog }) => ({
      lensR: catalog.currentLensR,
      lensL: catalog.currentLensL,
    }),
    {},
  ),
  withTranslation(),
)

export default enhance(IndividualSection)
