import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import styled from 'styled-components'
import { withTranslation } from 'react-i18next'
import Visualiser from './Visualiser'

import {
  deformSelected,
  saveSelectedVcaDeformed,
  resetSelectedVcaDeformed,
  addOrEditHole,
  removeAllHoles,
} from '../../../common/vca/actions'

import { NumberField, SwitchField } from '../fields'
import { FieldsGroup, Row, Col, WarningMessage, Gap, Button, Text } from '..'

const StyledVisualiser = styled(Visualiser)`
  /* border: red solid thin; */
`

// eslint-disable-next-line
class Driller extends Component {
  state = {
    mouseX: null,
    mouseY: null,
    startX: '',
    startY: '',
    side: 'B',
  }

  onVisualiserMouseMove = ({ shapeMouseX, shapeMouseY }) => {
    this.setState({ mouseX: shapeMouseX, mouseY: shapeMouseY })
  }

  onVisualiserMouseLeave = () => {
    this.setState({ mouseX: null, mouseY: null })
  }

  onVisualiserMouseClick = () => {
    const { mouseX, mouseY, side } = this.state

    // TODO: kontrola na to jestli je uvnitr shape?
    this.props.addOrEditHole({
      startX: mouseX.toFixed(2),
      startY: -mouseY.toFixed(2),
      side,
    })
  }

  render() {
    const { selectedVca, order, currentLensR, addOrEditHole, removeAllHoles, t } = this.props

    let { holes } = this.props

    const { mouseX, mouseY, startX, startY, side } = this.state

    const { holes: neverUsed, ...vcaWithoutHoles } = selectedVca

    // select correct holes according to side
    // we only want R or Both
    holes = holes.filter(h => h.side === 'B' || h.side === 'R')

    return (
      <div>
        <Row>
          <Col>
            <Visualiser
              {...vcaWithoutHoles}
              rightOnly
              width={600}
              holes={holes}
              showGrid
              onMouseMove={this.onVisualiserMouseMove}
              onClick={this.onVisualiserMouseClick}
              onMouseLeave={this.onVisualiserMouseLeave}
              // leftOnly={side === 'L'}
            />
          </Col>

          <Col grow={1}>
            <FieldsGroup title={t('drill data')} direction="row">
              {/* <Row>
                <SwitchField
                  label={('Zvolte stranu')}
                  options={[
                    { text: 'Obě', value: 'B' },
                    { text: 'Pravá', value: 'R' },
                    { text: 'Levá', value: 'L' },
                  ]}
                  name="side"
                  value={side}
                  onChange={(test) => {
                    this.setState({ side: test.value })
                  }}
                />
              </Row> */}
              {holes.map((h, index) => (
                <Row key={h.id} alignItems="flex-end">
                  <Text size="1.6rem" height="2.5rem">
                    {index + 1}&nbsp;
                  </Text>
                  <NumberField
                    label={index === 0 && t('Start X')}
                    value={h.startX}
                    onChange={e => addOrEditHole({ id: h.id, startX: e.value })}
                  />
                  <NumberField
                    label={index === 0 && t('Start Y')}
                    value={h.startY}
                    onChange={e => addOrEditHole({ id: h.id, startY: e.value })}
                  />
                  <NumberField
                    label={index === 0 && t('End X')}
                    value={h.endX}
                    onChange={e => addOrEditHole({ id: h.id, endX: e.value })}
                  />
                  <NumberField
                    label={index === 0 && t('End Y')}
                    value={h.endY}
                    onChange={e => addOrEditHole({ id: h.id, endY: e.value })}
                  />
                  <NumberField
                    label={index === 0 && t('Diameter')}
                    value={h.diameter}
                    onChange={e => addOrEditHole({ id: h.id, diameter: e.value })}
                  />
                  <NumberField
                    label={index === 0 && t('Depth')}
                    value={h.depth}
                    onChange={e => addOrEditHole({ id: h.id, depth: e.value })}
                  />
                  <Button onClick={() => addOrEditHole({ id: h.id, remove: true })}>X</Button>
                </Row>
              ))}
              {holes.length < 8 && (
                <Row alignItems="flex-end">
                  <Text size="1.6rem" height="2.5rem">
                    {holes.length + 1}&nbsp;
                  </Text>
                  <NumberField
                    innerRef={r => {
                      this.startXRef = r
                    }}
                    value={mouseX ? mouseX.toFixed(2) : startX}
                    onChange={e => this.setState({ startX: e.value })}
                    onBlur={() => {
                      if (startY !== '') {
                        addOrEditHole({ startX, startY, side })
                        this.setState({ startX: '', startY: '' })
                        this.startXRef.focus()
                      }
                    }}
                  />
                  <NumberField
                    value={mouseY ? mouseY.toFixed(2) : startY}
                    onChange={e => this.setState({ startY: e.value })}
                    onBlur={() => {
                      if (startX !== '') {
                        addOrEditHole({ startY, startX, side })
                        this.setState({ startX: '', startY: '' })
                        this.startXRef.focus()
                      }
                    }}
                  />
                  <NumberField disabled />
                  <NumberField disabled />
                  <NumberField disabled />
                  <NumberField disabled />
                  <Gap gap="3.2rem" />
                </Row>
              )}
              <Row justifyContent="center" marginTop="2rem">
                <Button onClick={e => removeAllHoles()}>{t('Delete all holes')}</Button>
              </Row>
            </FieldsGroup>
          </Col>
        </Row>
      </div>
    )
  }
}

Driller.defaultProps = {
  order: {},
  currentLensR: null,
  holes: [],
}

Driller.propTypes = {
  selectedVca: PropTypes.object.isRequired,
  order: PropTypes.object,
  currentLensR: PropTypes.object,
  holes: PropTypes.array,
  removeAllHoles: PropTypes.func.isRequired,
  addOrEditHole: PropTypes.func.isRequired,
}

const enhance = compose(
  connect(
    state => ({
      selectedVca: state.vca.selectedVca,
      selectedVcaDeformed: state.vca.selectedVcaDeformed,
      deformSelectedRight: state.vca.deformSelectedRight,
      deformSelectedTop: state.vca.deformSelectedTop,
      deformSelectedLeft: state.vca.deformSelectedLeft,
      deformSelectedBottom: state.vca.deformSelectedBottom,
      holes: state.vca.holes,
      order: state.fields.OrderForm,
      currentLensR: state.catalog.currentLensR,
    }),
    {
      deformSelected,
      saveSelectedVcaDeformed,
      resetSelectedVcaDeformed,
      addOrEditHole,
      removeAllHoles,
    },
  ),
  withTranslation(),
)

export default enhance(Driller)
