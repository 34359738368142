import React, { Component } from 'react'
import memoize from 'memoize-one'
import PropTypes from 'prop-types'

class WithMemoize extends React.PureComponent {
  onBlur = (...args) => this.props.onBlur(...args)

  onChange = (...args) => this.props.onChange(...args)

  // memoize last array; custom check via stringify, since memoize-one does only shallow compare
  getOptions = memoize(
    options => options,
    (newOpts, lastOpts) => JSON.stringify(newOpts) === JSON.stringify(lastOpts),
  )

  render() {
    const { FieldToWrap, onBlur, onChange, ...restProps } = this.props
    let { options } = this.props

    options = this.getOptions(options)

    return (
      <FieldToWrap
        {...restProps}
        options={options}
        onBlur={onBlur ? this.onBlur : undefined}
        onChange={onChange ? this.onChange : undefined}
      />
    )
  }
}

WithMemoize.defaultProps = {
  onBlur: undefined,
  onChange: undefined,
  options: undefined,
}

WithMemoize.propTypes = {
  FieldToWrap: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  options: PropTypes.array,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
}

const withMemoize = FieldToWrap => props => <WithMemoize {...props} FieldToWrap={FieldToWrap} />

export default withMemoize
