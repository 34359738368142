// This loader is for translation suspension watching only
import React from 'react'
import { connect } from 'react-redux'
import { finishSuspense } from '../../common/app/actions'

class LoadingWatcher extends React.Component {
  componentWillUnmount() {
    console.l.debug('translation suspense done!')
    this.props.finishSuspense()
  }

  render() {
    return null
  }
}

export default connect(null, { finishSuspense })(LoadingWatcher)
