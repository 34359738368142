import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import styled from 'styled-components'
import { withTranslation } from 'react-i18next'

import { fields } from '../../../common/lib/redux-fields'
import { TextField, CheckboxField, SelectField, SwitchField, NumberField } from '../fields'
import { FieldsGroup } from '.'
import { Box, Row, Col, Button, Gap } from '..'
import { setVisualiserScaleConfig } from '../../../common/config/actions'
import Validation from '../../../common/lib/validation'
import { withNotifs } from '../hoc'
import { separate, MUST_BE_BETWEEN, AND, FEMALE } from '../../../common/validationHelpers'

const MeasurementTool = styled.div`
  width: ${({ width }) => width}px;
  height: 20px;
  background: ${({ theme: { colors } }) => colors.secondary};
`

const measurementPixels = 400

class SettingsMeasurementForm extends Component {
  submitForm = () => {
    const { fields, setVisualiserScaleConfig, notifs, t } = this.props

    fields.$validate()
    if (fields.$isValid()) {
      const { measuredLength } = fields.$values()
      const scale = measurementPixels / measuredLength / 10
      setVisualiserScaleConfig(scale)
      notifs.success({ title: t('settings'), message: t('new scale was set') })
    } else {
      notifs.error({ title: t('error'), message: t('New scale was not set') })
    }
  }

  render() {
    const { fields, t, setVisualiserScaleConfig, notifs } = this.props

    return (
      <Col>
        <MeasurementTool width={measurementPixels} />
        <Gap gap="1rem" />
        <NumberField required label={t('line length (cm)')} {...fields.measuredLength} />
        <Gap gap="2rem" />
        <Row>
          <Button primary onClick={this.submitForm}>
            {t('confirm settings')}
          </Button>
          <Gap gap="1rem" />
          <Button
            onClick={() => {
              setVisualiserScaleConfig(null)
              fields.$setValue('measuredLength', null)
              fields.$submit() // so we reset validation
              notifs.success({ title: t('settings'), message: t('The scale was reset') })
            }}
          >
            {t('Reset')}
          </Button>
        </Row>
      </Col>
    )
  }
}

SettingsMeasurementForm.propTypes = {
  fields: PropTypes.object.isRequired,
  setVisualiserScaleConfig: PropTypes.func.isRequired,
  notifs: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
}

const enhance = compose(
  connect(
    state => ({
      visualiserScale: state.config.visualiserScale,
    }),
    {
      setVisualiserScaleConfig,
    },
  ),
  fields({
    path: 'SettingsMeasurementForm',
    fields: ['measuredLength'],
    getInitialState: ({ visualiserScale }) => ({
      measuredLength: visualiserScale ? measurementPixels / 10 / visualiserScale : 0,
    }),
    submitFunc: () => {},
    validationFunc: ({ dataToValidate }) => {
      const validator = new Validation(dataToValidate)

      const translationKey = 'line length (cm)'

      validator.isFloatInRange(
        'measuredLength',
        { min: 4, max: 50 },
        {
          keys: separate`${translationKey}${MUST_BE_BETWEEN}${4}${AND}${50}`,
          args: { context: FEMALE },
        },
      )

      console.log('validator.validate()', validator.validate())
      return validator.validate()
    },
  }),
  withNotifs,
  withTranslation(),
)

export default enhance(SettingsMeasurementForm)
