import React from 'react'
import Icon from 'react-icon-base'

const Stock = props => (
  <Icon viewBox="0 0 31 31" {...props}>
    <path d="M30.236 15.1223C30.236 23.4718 23.4677 30.24 15.1177 30.24C6.76875 30.24 0 23.4718 0 15.1223C0 6.77333 6.76875 0.0039991 15.1177 0.0039991C23.4677 0.0039991 30.236 6.77333 30.236 15.1223Z" fill="#BDBCBC" />
    <path d="M1.65417 16.2332C2.17084 16.4744 3.32292 16.9311 4.27084 16.9311C5.21927 16.9311 5.90364 16.6655 5.96355 15.7785C6 15.1181 5.0276 14.7816 3.86303 14.4097C3.58751 14.326 3.45521 14.2775 3.38281 14.2421C1.47448 13.5816 1.52188 12.0452 1.52188 12.0088C1.52188 10.5687 2.62708 9.63267 4.78697 9.63267C5.56771 9.63267 6.67188 9.98013 7.08021 10.1603V11.4093C6.15573 11.0728 5.50781 10.8807 4.76303 10.8807C3.51511 10.8807 3.02292 11.4327 2.99844 12.0692C2.99844 12.1649 2.98697 12.7171 3.83907 12.9692L4.30729 13.1255C6.14375 13.7015 7.53645 14.2181 7.44011 15.8864C7.29636 17.6499 6.01197 18.1671 4.27084 18.1671C3.28697 18.1671 2.31459 17.8192 1.65417 17.5666V16.2332" fill="white" />
    <path d="M7.53386 13.2931V12.2249H8.4823V10.9171L9.88698 10.5207V12.2249H11.1594V13.2931H9.88698V16.1983C9.88698 16.5707 9.91094 16.8816 10.7995 16.8816H11.1714V18.1186H10.5953C9.11875 18.1186 8.4823 17.7348 8.4823 16.3796V13.2931H7.53386" fill="white" />
    <path d="M16.3792 15.1056C16.3792 13.9416 15.6839 13.1979 14.6631 13.1979C13.6432 13.1979 12.9464 13.9416 12.9464 15.1056C12.9464 16.3296 13.6432 17.0515 14.6631 17.0515C15.6839 17.0515 16.3792 16.3296 16.3792 15.1056V15.1056ZM17.8084 15.154C17.8084 17.339 16.0912 18.202 14.6511 18.1671C13.2469 18.202 11.5182 17.339 11.5182 15.154C11.5182 12.9332 13.2469 12.0452 14.6511 12.0812C16.0912 12.0452 17.8084 12.9332 17.8084 15.154" fill="white" />
    <path d="M19.7031 15.1181C19.7031 16.1135 20.124 17.0744 21.5271 17.0744C21.888 17.0629 22.3552 17.0265 23.0036 16.8947V17.9509C22.6683 18.0353 21.9245 18.1671 21.1197 18.1671C19.7031 18.1671 18.238 17.0744 18.2265 15.1301C18.238 13.1853 19.7031 12.0812 21.1197 12.0812C21.9245 12.0812 22.6683 12.2135 23.0036 12.2973V13.3531C22.3552 13.2217 21.888 13.1853 21.5271 13.1733C20.1355 13.1733 19.7031 14.1337 19.7031 15.1181" fill="white" />
    <path d="M27.2145 18.0353C26.6875 17.2072 26.0625 16.4493 25.1745 15.766V18.0353H23.7579V9.51187H25.1745V13.8212L26.6145 12.2249H28.3437L25.9307 14.7457C26.9521 15.5624 28.164 16.9416 28.836 18.0353H27.2145" fill="white" />
  </Icon>
)

export default Stock

