import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'
import { connect } from 'react-redux'
import { compose } from 'recompose'

import { Page, Box, Row, Col, Button, Link, HiddenInPrint } from '..'
import { withNotifs } from '../hoc'

import { bugsnagClient, notifyWarning } from '../../bugsnag'
import { findThicknessExtremes } from '../../../common/lib/vca'

import { hideAllPopups } from '../../../common/popups/actions'
import withRouter from '../hoc/withRouter'
import { roundNumber } from '../../../common/lib/numbers'

const Wrapper = styled.div`
  position: relative;
  ${({ theme: { colors }, width, height, noBorder, isFullscreen }) => css`
    /* width: ${width}px;
    height: ${height}px; */
    border: ${!noBorder && `solid 1px ${colors.groupBorder}`};
    border-radius: 0.6rem;
    flex-grow: 0;
    width: ${isFullscreen && '100%'};
    height: ${isFullscreen && '100%'};
  `}
`

Wrapper.displayName = 'VisualiserWrapper'

const Canvas = styled.canvas`
  border-radius: 0.6rem;
  touch-action: none;

  /* border: solid thin lightgray; */
  /* box-sizing: border-box; */
`

Canvas.displayName = 'VisualiserCanvas'

const OverlayMessage = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  background: white;
  padding: 1rem;
  /* display: flex; */
`
const MessageWrapper = styled.div`
  position: absolute;

  left: 50%;
  transform: translate(-50%);
  text-align: center;
  background: white;
  padding: 1rem;
  /* display: flex; */
  ${({ top, bottom }) => css`
    top: ${top && 0};
    bottom: ${bottom && 0};
  `}
`

const ButtonsWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  padding: 1rem;
  flex-direction: row;
  display: flex;
  ${({ bottom }) =>
    bottom &&
    css`
      bottom: 0;
      top: initial;
      left: 0;
      justify-content: center;
    `}
`
const WrappedButton = styled.div`
  margin-right: 1rem;
`

const CanvasPrintImage = styled.img`
  border: 0.5mm solid gray;
  width: 190mm; // todo možná nastavit někam do configu a podle toho i počítat scale visualiseru pro tisk
`

class Visualiser extends Component {
  state = {
    realSize: false, // todo matoucí název, evokuje to hodnotu, ne true / false
    canvasPrintImage: null,
  }

  isMouseDown = false

  componentDidMount() {
    this.drawCanvas()
    this.bindMouseListeners()
    this.setPrintImage()
  }

  componentDidUpdate() {
    this.drawCanvas()
  }

  setPrintImage = () => {
    if (!this.props.isPrint) return
    const canvasPrintImage = this.canvasRef?.toDataURL('image/png')
    this.setState({ canvasPrintImage })
  }

  getDrawStyles = () => {
    const drawStyles = {
      ...defaultDrawStyles,
    }
    // merge one level deep
    Object.keys(defaultDrawStyles).forEach(key => {
      drawStyles[key] = { ...defaultDrawStyles[key], ...this.props.drawStyles[key] }
    })
    return drawStyles
  }

  calcRelativeMouseCoords = e => {
    const {
      width,
      height,
      lenseOffsetXR,
      lenseOffsetXL,
      lenseOffsetYR,
      lenseOffsetYL,
      rightOnly,
      leftOnly,
    } = this.props

    const rect = this.canvasRef.getBoundingClientRect()
    const x = e.clientX - rect.left
    const y = e.clientY - rect.top
    const relativeX = x - width / 2
    const relativeY = y - height / 2
    let relativeToLenseX = relativeX
    let relativeToShapeX = relativeX
    let relativeToLenseY = relativeY
    const relativeToShapeY = relativeY
    let onRight = true

    if (!rightOnly && !leftOnly) {
      // TODO: do for one eye if needed
      if (relativeX < 0) {
        // right side
        relativeToLenseX -= (this.shapeCenterXR + lenseOffsetXR) * this.desiredScale
        relativeToShapeX -= this.shapeCenterXR * this.desiredScale
        relativeToLenseY = relativeY - lenseOffsetYR * this.desiredScale
      } else {
        // left side
        relativeToLenseX -= (this.shapeCenterXL - lenseOffsetXL) * this.desiredScale
        relativeToShapeX -= this.shapeCenterXL * this.desiredScale
        relativeToLenseY = relativeY - lenseOffsetYL * this.desiredScale
        onRight = false
      }
    }

    const relativeToLenseXmm = relativeToLenseX / this.desiredScale
    const relativeToLenseYmm = relativeToLenseY / this.desiredScale

    return {
      lenseMouseX: relativeToLenseXmm,
      lenseMouseY: relativeToLenseYmm,
      shapeMouseX: relativeToShapeX / this.desiredScale,
      shapeMouseY: relativeToShapeY / this.desiredScale,
      onRight,
      xPx: x,
      yPx: y,
    }
  }

  onMouseMove = (e, isTouch) => {
    const { onMouseMove, onMouseDownMove } = this.props

    // sometimes when having touch events (some tablets) trigger also mouse event. We want to debounce that!
    if (this.debounceMove && !isTouch) {
      // console.log('[DEBUG] debouncing down')
      return
    }
    if (isTouch) {
      this.debounceMove = true
      clearTimeout(this.debounceMoveInterval)
      this.debounceMoveInterval = setTimeout(() => {
        this.debounceMove = false
      }, 5000)
    }

    if (typeof onMouseMove !== 'function' && typeof onMouseDownMove !== 'function') {
      return
    }
    const coords = this.calcRelativeMouseCoords(e)
    if (typeof onMouseMove === 'function') {
      onMouseMove(coords)
    }
    if (typeof onMouseDownMove === 'function' && this.isMouseDown) {
      // console.log('[DEBUG] mouse DOWN Move', e)

      onMouseDownMove(coords)
    }
  }

  onMouseDown = (e, isTouch) => {
    // sometimes when having touch events (some tablets) trigger also mouse event. We want to debounce that!
    if (this.debounceDown && !isTouch) {
      // console.log('[DEBUG] debouncing down')
      return
    }
    if (isTouch) {
      this.debounceDown = true
      clearTimeout(this.debounceDownInterval)
      this.debounceDownInterval = setTimeout(() => {
        this.debounceDown = false
      }, 5000)
    }

    this.isMouseDown = true
    // console.log('[DEBUG] onMouseDown')

    const { onMouseDown } = this.props
    if (onMouseDown) {
      const coords = this.calcRelativeMouseCoords(e)
      onMouseDown(coords)
    }
  }

  onMouseUp = (e, isTouch) => {
    if (this.debounceUp && !isTouch) {
      // console.log('[DEBUG] debouncing up')
      return
    }
    if (isTouch) {
      this.debounceUp = true
      clearTimeout(this.debounceUpInterval)
      this.debounceUpInterval = setTimeout(() => {
        this.debounceUp = false
        // console.log('[DEBUG] cleaning debounce up')
      }, 5000)
    }

    this.isMouseDown = false
    // console.log('[DEBUG] onMouseUp', e)

    const { onMouseUp } = this.props
    if (onMouseUp) {
      let coords
      if (e) {
        // only for mouse events
        coords = this.calcRelativeMouseCoords(e)
      }
      onMouseUp(coords)
    }
  }

  // touch events have multiple touches. Select only first one, afterwards, it should be same as mouse events
  onTouchStart = e => {
    // console.log('[DEBUG] TOUCH START',)
    if (e && e.touches && e.touches[0]) {
      this.onMouseDown(e.touches[0], true)
    }
  }

  onTouchMove = e => {
    // console.log('[DEBUG] TOUCH MOVE', e && e.touches && e.touches[0])
    if (e && e.touches && e.touches[0]) {
      this.onMouseMove(e.touches[0], true)
    }
  }

  onTouchEnd = e => {
    // console.log('[DEBUG] TOUCH END', e)
    // touchend has no position, so we dont send event :(
    this.onMouseUp(null, true)
  }

  bindMouseListeners = () => {
    this.canvasRef.addEventListener('mousemove', this.onMouseMove)
    this.canvasRef.addEventListener('mousedown', this.onMouseDown)
    this.canvasRef.addEventListener('mouseup', this.onMouseUp)
    this.canvasRef.addEventListener('touchstart', this.onTouchStart)
    this.canvasRef.addEventListener('touchend', this.onTouchEnd)
    this.canvasRef.addEventListener('touchmove', this.onTouchMove)
  }

  drawCanvas = () => {
    if (!this.canvasRef || !this.ctx) return
    const {
      monitorScale,
      width,
      height,
      rightOnly,
      leftOnly,
      showRadius,
      fitsRadius,
      shapeDataXY,
      dbl,
      box,
      smallestEnclosingCircleRadius,
      showPupil,
      showMultifocal,
      showBifocal,
      heightR,
      heightL,
      pdR,
      pdL,
      forceRealSize,
      d3,
      edgeThickness,
      withoutShape,
      calculatedDiameterR,
      calculatedDiameterL,
      holes,
      showGrid,
      debug,
      decXR,
      placeholder,
      withDbl,
      forceScale,
      hideHoles,
      isPrint,
      disableR,
    } = this.props

    if (placeholder) {
      return
    }

    // TODO: showlenses? showPoins? RightOnly? lines/measurements? arrows? popisky/ohniska/stredy?
    // pridat vrtani, osa, styl tvaru?

    // do props jen subset vca? - shapeDataXY, box, radius, dbl?? -- asi ano
    // pocitat shapeData na serveru? ulozit do DB pro nahledy a jejich modifikace?

    const { realSize } = this.state

    // mandatory draw props --? not true for blank lenses (without shape)
    // if (!shapeDataXY || !dbl || !box) {
    //   console.log('visualiser missing mandatory draw props', box, dbl)
    //   return
    // }

    // calc drawing max width
    let wholeShapeWidth
    let wholeShapeHeight
    if (!withoutShape) {
      if (!box.r) {
        console.log(box)
        notifyWarning('pro petra: visualiser: undefined, box.r')
      }
      if (!box.l) {
        console.log(box)
        notifyWarning('pro petra: visualiser: undefined, box.l')
      }

      wholeShapeWidth = box.r.hbox + box.l.hbox + dbl
      wholeShapeHeight = box.r.vbox

      if (rightOnly) {
        wholeShapeWidth -= box.l.hbox + dbl
      }
      if (leftOnly) {
        wholeShapeWidth -= box.r.hbox + dbl
      }
    } else {
      const gap = 10 // 20 - default gap between lenses
      // if we have only one side
      const diaR = calculatedDiameterR || calculatedDiameterL
      const diaL = calculatedDiameterL || calculatedDiameterR

      wholeShapeWidth = diaL + diaR + gap
      wholeShapeHeight = Math.max(diaR || diaL)
      this.shapeCenterXR = (-diaR - gap) / 2
      this.shapeCenterXL = (diaL + gap) / 2
    }

    const padding = 7 // mm
    // and auto guess scale if not set
    if (isPrint) {
      // this.desiredScale = 3.84
      this.desiredScale = 3.81
    } else if ((realSize || forceRealSize) && monitorScale) {
      this.desiredScale = monitorScale
    } else if (forceScale) {
      this.desiredScale = forceScale
    } else {
      wholeShapeWidth += 2 * padding
      wholeShapeHeight += 2 * padding
      const widthScale = width / wholeShapeWidth
      const heightScale = height / wholeShapeHeight
      this.desiredScale = widthScale
      if (rightOnly) {
        this.desiredScale = Math.min(widthScale, heightScale)
      }

      // we want to show the whole radius
      if (fitsRadius) {
        const radiusHeight = smallestEnclosingCircleRadius * 2 + 2 * padding
        let radiusWidth = smallestEnclosingCircleRadius * 2 + 3 * padding + dbl
        if (!rightOnly) {
          radiusWidth += smallestEnclosingCircleRadius * 2
        }

        const heightScale = height / radiusHeight
        const widthScale = width / radiusWidth
        this.desiredScale = Math.min(heightScale, widthScale)
      }
    }

    // global drawing calculations
    // lens shape centers (used for all deformations, therefore bind to this)
    if (!withoutShape) {
      this.shapeCenterXR = -(box.r.maxX + dbl / 2)
      this.shapeCenterXL = -box.l.minX + dbl / 2
      this.pdOffsetYR = box.r.maxY - heightR // maxY = lowest point relative to frame center
      this.pdOffsetYL = box.l.maxY - heightL
    }
    this.pdOffsetXR = -pdR
    this.pdOffsetXL = pdL

    // if we show only right shape - like in deformator
    if (rightOnly) {
      this.shapeCenterXR = 0
      // use left side, if right is not filled - when ordering only left side
      const pd = pdR || pdL
      const height = heightR || heightL
      this.pdOffsetXR = dbl / 2 + box.r.maxX - pd
      this.pdOffsetYR = box.r.maxY - height
    }
    if (leftOnly) { // left only is never used
      this.shapeCenterXL = 0
      this.pdOffsetXR = dbl / 2 + box.l.maxX - pdL
    }

    // if only left lens is being ordered
    // box is not defined in order detail
    if (disableR && box?.l) {
      this.pdOffsetXR = dbl / 2 + box.l.maxX - pdL
    }

    this.prepareCanvas()
    if (!withoutShape) {
      this.drawShapes()
    }
    if (showGrid) {
      this.drawGrid()
    }
    if (showRadius) {
      // for step 2
      if (!withoutShape && !calculatedDiameterR && !calculatedDiameterL) {
        this.drawLensesRadius()
      } else {
        // for step 3 (and without shape)
        this.drawCalculatedDiameters()
      }
    }

    if (withoutShape && (calculatedDiameterR || calculatedDiameterR)) {
      this.drawCalculatedDiameters()
    }

    if (showMultifocal) {
      this.drawMultifocals()
    }
    if (showBifocal) {
      this.drawBifocals()
    }
    if (showPupil) {
      this.drawPd()
    }
    if (d3 && d3.original) {
      this.draw3dPoints()
    }
    if (edgeThickness) {
      this.drawShapesThickness()
    }
    if (holes && holes.length > 0 && !hideHoles) {
      this.drawHoles()
    }
    if (withDbl) {
      this.drawDbl()
    }

    // if (withoutShape) {
    //   this.drawDecentrationCenter()
    // }
  }

  draw3dPoints = () => {
    const {
      d3,
      lenseOffsetXR,
      lenseOffsetYR,
      lenseOffsetXL,
      lenseOffsetYL,
      thicknessSet,
      closestPointSide,
    } = this.props
    const { ctx } = this
    ctx.save()
    ctx.fillStyle = 'red'
    this.drawOneSide3dPoints({
      centerX: this.shapeCenterXR + lenseOffsetXR,
      centerY: lenseOffsetYR,
      points: d3.original.r,
      highlightClosestPoint: closestPointSide === 'r',
    })
    this.drawOneSide3dPoints({
      centerX: this.shapeCenterXL - lenseOffsetXL,
      centerY: lenseOffsetYL,
      points: d3.original.l,
      highlightClosestPoint: closestPointSide === 'l',
    })
    ctx.restore()
  }

  drawOneSide3dPoints = ({ centerX, centerY, points, highlightClosestPoint }) => {
    const { closest3dIndex, thicknessSet, debug } = this.props
    const { ctx } = this
    ctx.save()
    ctx.translate(centerX, centerY)
    // ctx.rotate(Math.PI / 180 * 180)
    points.forEach(p => {
      ctx.save()
      ctx.beginPath()
      ctx.rotate((p.drawAngle * Math.PI) / 180)
      ctx.arc(p.diameter, 0, Math.abs(Math.abs(p.depth1) - Math.abs(p.depth2)) / 10, 0, 2 * Math.PI)
      if (debug) {
        ctx.fill()
      }
      ctx.closePath()
      ctx.restore()
    })
    ctx.fillStyle = 'yellow'
    points.forEach((p, index) => {
      ctx.save()
      ctx.beginPath()
      ctx.arc(p.x, p.y, p.thickness / 10, 0, 2 * Math.PI)
      if (closest3dIndex === index && thicknessSet === 'd3' && highlightClosestPoint) {
        ctx.fillStyle = 'purple'
        ctx.fill()
      }
      ctx.fillStyle = 'yellow'
      ctx.closePath()
      ctx.restore()
    })
    ctx.beginPath()
    ctx.fillStyle = 'green'
    ctx.arc(0, 0, 0.5, 0, 2 * Math.PI)
    ctx.closePath()
    // this is calc thickness points
    if (debug) {
      ctx.fill()
    }
    ctx.restore()
  }

  drawGrid = () => {
    const { rightOnly, leftOnly } = this.props

    // const {
    //   strokeStyle,
    //   lineWidth,
    //   length,
    //   centerGap,
    // } = this.getDrawStyles().pd

    const { ctx } = this
    ctx.save()

    ctx.strokeStyle = 'rgba(0,0,0,0.3)'
    ctx.lineWidth = 0.2
    // ctx.fillStyle = strokeStyle

    // ctx.beginPath()
    // ctx.arc(0, 0, 0.3, 0, 2 * Math.PI)
    // ctx.fill()

    // right eye
    ctx.save()
    if (!leftOnly) {
      ctx.translate(this.shapeCenterXR, 0)
    } else {
      ctx.translate(this.shapeCenterXL, 0)
    }
    const distance = 500
    for (let i = -100; i < 100; i++) {
      ctx.lineWidth = 0.05
      if (i % 10 === 0) {
        ctx.lineWidth = 0.2
      }
      if (i === 0) {
        ctx.lineWidth = 0.35
      }
      this.drawLine({ fromX: -distance, toX: distance, fromY: i, toY: i })
      this.drawLine({ fromX: i, toX: i, fromY: -distance, toY: distance })
    }

    ctx.restore()
  }

  drawHoles = () => {
    const { rightOnly, holes } = this.props

    // const {
    //   strokeStyle,
    //   lineWidth,
    //   length,
    //   centerGap,
    // } = this.getDrawStyles().pd

    const { ctx } = this
    ctx.save()

    ctx.strokeStyle = 'red'
    ctx.lineWidth = 1
    ctx.lineCap = 'round'

    // ctx.beginPath()
    // ctx.arc(0, 0, 0.3, 0, 2 * Math.PI)
    // ctx.fill()

    // console.log('holes', holes)

    // right eye
    ctx.save()
    ctx.translate(this.shapeCenterXR, 0)
    holes
      .filter(h => h.side === 'B' || h.side === 'R')
      .forEach(h => {
        this.drawHole({ ...h, isRight: true })
      })
    ctx.restore()

    if (!rightOnly) {
      // left eye
      ctx.save()
      ctx.translate(this.shapeCenterXL, 0)
      holes
        .filter(h => h.side === 'B' || h.side === 'L')
        .forEach(h => {
          this.drawHole(h)
        })
      ctx.restore()
    }

    ctx.restore()
  }

  drawHole = ({
    side,
    diameter = 1,
    startX,
    startY,
    endX,
    endY,
    temporal,
    box = this.props.box.r || this.props.box.l,
    isRight,
  } = {}) => {
    const { ctx } = this
    ctx.save()
    const { rightOnly } = this.props
    // if (!temporal) {
    //   startX = box.maxX - startX
    //   endX = box.maxX - endX
    // } else {
    //   startX = box.minX + startX
    //   endX = box.minX + endX
    // }

    startX *= !rightOnly && !isRight && side === 'B' ? -1 : 1
    endX *= !rightOnly && !isRight && side === 'B' ? -1 : 1

    ctx.beginPath()
    ctx.moveTo(startX, -startY)
    ctx.lineTo(endX || startX, -endY || -startY)
    ctx.lineWidth = diameter
    ctx.lineCap = 'round'
    ctx.stroke()
    ctx.closePath()

    ctx.restore()
  }

  drawLine = ({ fromX, fromY, toX, toY }) => {
    const { ctx } = this
    ctx.save()

    ctx.beginPath()
    ctx.moveTo(fromX, fromY)
    ctx.lineTo(toX, toY)
    ctx.stroke()
    ctx.closePath()

    ctx.restore()
  }

  drawPd = () => {
    const { rightOnly, disableR, disableL } = this.props

    const { strokeStyle, lineWidth, length, centerGap } = this.getDrawStyles().pd

    if (!this.pdOffsetXR || !this.pdOffsetYR) {
      console.log('!!not sufficient data for pd draw')
      return
    }

    const { ctx } = this
    ctx.save()

    ctx.strokeStyle = strokeStyle
    ctx.lineWidth = lineWidth

    // right eye
    if (!disableR) {
      ctx.save()
      ctx.translate(this.pdOffsetXR, this.pdOffsetYR)
      this.drawCross({ centerGap, length })
      ctx.restore()
    }

    if (!rightOnly && !disableL) {
      // left eye
      ctx.save()
      ctx.translate(this.pdOffsetXL, this.pdOffsetYL)
      this.drawCross({ centerGap, length })
      ctx.restore()
    }

    ctx.restore()
  }

  drawMultifocals = () => {
    // let {
    // multifocalConfig: {
    //   upX,
    //   upY,
    //   downX,
    //   downY,
    // } = {},
    // } = this.props
    const {
      rightOnly,
      multifocalConfigL: mcL,
      multifocalConfig: mc,
      disableR,
      disableL,
    } = this.props

    const { strokeStyle, lineWidth } = this.getDrawStyles().multifocals

    if (!this.pdOffsetXR || !this.pdOffsetYR) {
      console.log('!!not sufficient data for multifocal draw')
      return
    }

    const { ctx } = this
    ctx.save()

    ctx.strokeStyle = strokeStyle
    ctx.lineWidth = lineWidth

    // right eye - UP
    if (mc && !disableR) {
      ctx.save()
      ctx.translate(this.pdOffsetXR + mc.upX, this.pdOffsetYR + mc.upY)
      this.drawFocalCircle({ type: 'up' })
      ctx.restore()

      // right eye - DOWN
      ctx.save()
      ctx.translate(this.pdOffsetXR + mc.downX, this.pdOffsetYR + mc.downY)
      this.drawFocalCircle({ type: 'down' })
      ctx.restore()
    }

    if (!rightOnly && mcL && !disableL) {
      // left eye - UP
      ctx.save()
      ctx.translate(this.pdOffsetXL - mcL.upX, this.pdOffsetYL + mcL.upY)
      this.drawFocalCircle({ type: 'up' })
      ctx.restore()

      // left eye - DOWN
      ctx.save()
      ctx.translate(this.pdOffsetXL - mcL.downX, this.pdOffsetYL + mcL.downY)
      this.drawFocalCircle({ type: 'down' })
      ctx.restore()
    }

    ctx.restore()
  }

  drawBifocals = () => {
    // let {
    // multifocalConfig: {
    //   upX,
    //   upY,
    //   downX,
    //   downY,
    // } = {},
    // } = this.props
    const { rightOnly, bifocalConfigR: bcR, bifocalConfigL: bcL, disableR, disableL } = this.props

    const { strokeStyle, lineWidth, prpColor } = this.getDrawStyles().bifocals

    if (!this.pdOffsetXR || !this.pdOffsetYR) {
      console.log('!!not sufficient data for multifocal draw')
      return
    }

    const { ctx } = this
    ctx.save()

    ctx.strokeStyle = strokeStyle
    ctx.lineWidth = lineWidth

    const topCircleAngleCenter = 1.5 * Math.PI
    const bottomCircleAngleCenter = 0.5 * Math.PI

    // right eye - UP
    if (bcR && !disableR) {
      ctx.save()
      ctx.translate(this.pdOffsetXR + bcR.topX, this.pdOffsetYR + bcR.topY)
      ctx.beginPath()
      ctx.arc(
        0,
        0,
        bcR.topR,
        topCircleAngleCenter - bcR.topAngle / 2,
        topCircleAngleCenter + bcR.topAngle / 2,
      )
      ctx.stroke()
      ctx.restore()

      // DOWN
      ctx.save()
      ctx.translate(this.pdOffsetXR + bcR.bottomX, this.pdOffsetYR + bcR.bottomY)
      ctx.beginPath()

      ctx.arc(
        0,
        0,
        bcR.bottomR,
        bottomCircleAngleCenter - bcR.bottomAngle / 2,
        bottomCircleAngleCenter + bcR.bottomAngle / 2,
      )
      ctx.stroke()
      ctx.restore()

      // far point / PRP draw
      ctx.save()
      ctx.translate(this.pdOffsetXR, this.pdOffsetYR)
      this.drawPoint({ x: bcR.prp.in, y: bcR.prp.up, color: prpColor, radius: 0.4 })
      ctx.restore()
    }

    // left eye - UP
    if (bcL && !disableL) {
      ctx.save()
      ctx.translate(this.pdOffsetXL + bcL.topX, this.pdOffsetYL + bcL.topY)
      ctx.beginPath()
      ctx.arc(
        0,
        0,
        bcL.topR,
        topCircleAngleCenter - bcL.topAngle / 2,
        topCircleAngleCenter + bcL.topAngle / 2,
      )
      ctx.stroke()
      ctx.restore()

      // DOWN
      ctx.save()
      ctx.translate(this.pdOffsetXL + bcL.bottomX, this.pdOffsetYL + bcL.bottomY)
      ctx.beginPath()

      ctx.arc(
        0,
        0,
        bcL.bottomR,
        bottomCircleAngleCenter - bcL.bottomAngle / 2,
        bottomCircleAngleCenter + bcL.bottomAngle / 2,
      )
      ctx.stroke()
      ctx.restore()

      // far point / PRP draw
      ctx.save()
      ctx.translate(this.pdOffsetXL, this.pdOffsetYL)
      this.drawPoint({ x: -bcL.prp.in, y: bcL.prp.up, color: prpColor, radius: 0.4 })
      ctx.restore()
    }

    // if (!rightOnly && mcL && !disableL) {
    //   // left eye - UP
    //   ctx.save()
    //   ctx.translate(this.pdOffsetXL - mcL.upX, this.pdOffsetYL + mcL.upY)
    //   this.drawFocalCircle({ type: 'up' })
    //   ctx.restore()

    //   // left eye - DOWN
    //   ctx.save()
    //   ctx.translate(this.pdOffsetXL - mcL.downX, this.pdOffsetYL + mcL.downY)
    //   this.drawFocalCircle({ type: 'down' })
    //   ctx.restore()
    // }

    ctx.restore()
  }

  drawCross = ({ length = 2, centerGap = 0.4 } = {}) => {
    const { ctx } = this
    ctx.save()

    ctx.beginPath()
    ctx.moveTo(centerGap, 0)
    ctx.lineTo(length, 0)
    ctx.moveTo(-centerGap, 0)
    ctx.lineTo(-length, 0)
    ctx.moveTo(0, centerGap)
    ctx.lineTo(0, length)
    ctx.moveTo(0, -centerGap)
    ctx.lineTo(0, -length)
    ctx.stroke()

    ctx.restore()
  }

  drawFocalCircle = ({ type = 'up' } = {}) => {
    let startingAngle = Math.PI * 0.7
    let endingAngle = Math.PI * 0.3
    let radius = 6

    if (type === 'down') {
      startingAngle = 0
      endingAngle = Math.PI * 2
      radius = 3.5
    }

    const { ctx } = this
    ctx.save()

    ctx.beginPath()
    ctx.arc(0, 0, radius, startingAngle, endingAngle)
    ctx.stroke()

    ctx.restore()
  }

  drawCalculatedDiameters = () => {
    const {
      calculatedDiameterR,
      calculatedDiameterL,
      calculatedDiameterER,
      calculatedDiameterEL,
      lenseOffsetXR,
      lenseOffsetXL,
      lenseOffsetYR,
      lenseOffsetYL,
    } = this.props

    // console.log('calculatedDiameterR', calculatedDiameterR)

    const { lensesRadius: radiusStyle } = this.getDrawStyles()

    this.drawRadius({
      radius: calculatedDiameterR / 2,
      radiusE: calculatedDiameterER / 2,
      center: {
        x: this.shapeCenterXR + lenseOffsetXR,
        y: lenseOffsetYR,
      },
      style: radiusStyle,
    })

    this.drawRadius({
      radius: calculatedDiameterL / 2,
      radiusE: calculatedDiameterEL / 2,
      center: {
        x: this.shapeCenterXL - lenseOffsetXL,
        y: lenseOffsetYL,
      },
      style: radiusStyle,
    })
  }

  // for step 2 - lenses with shape
  // finalRadii has to be provided for visualiser (not part of vca anymore)
  drawLensesRadius = () => {
    const {
      rightOnly,
      finalRadii,
      showLensesGeometryCenter,
      debug,
      disableR,
      disableL,
    } = this.props

    if (!finalRadii) {
      return
    }

    const { final } = finalRadii

    const { lensesRadius: radiusStyle, lensesCenter: lensesCenterStyle } = this.getDrawStyles()

    const debugStyles = {
      smallest: {
        lineWidth: 0.2,
        color: 'green',
      },
      stock: {
        lineWidth: 0.2,
        color: 'purple',
      },
    }

    if (!disableR) {
      if (debug) {
        if (finalRadii.stockRadiiWithoutMargin.shapeR) {
          this.drawOneSideXY(finalRadii.stockRadiiWithoutMargin.shapeR, {
            center: {
              x: this.shapeCenterXR + finalRadii.stockRadiiWithoutMargin.xR,
              y: finalRadii.stockRadiiWithoutMargin.yR,
            },
            fillStyle: 'transparent',
          })
        }
        this.drawRadius({
          radius: finalRadii.stockRadiiWithoutMargin.rR,
          center: {
            x: this.shapeCenterXR + finalRadii.stockRadiiWithoutMargin.xR,
            y: finalRadii.stockRadiiWithoutMargin.yR,
          },
          style: debugStyles.stock,
        })
        this.drawRadius({
          radius: finalRadii.smallestEnclosingCircleWithoutMargin.radius,
          center: {
            x: this.shapeCenterXR + finalRadii.smallestEnclosingCircleWithoutMargin.x,
            y: finalRadii.smallestEnclosingCircleWithoutMargin.y,
          },
          style: debugStyles.smallest,
        })
      }

      // final radius R
      this.drawRadius({
        radius: final.rR,
        center: {
          x: this.shapeCenterXR + final.xR,
          y: final.yR,
        },
        style: radiusStyle,
        withCenter: showLensesGeometryCenter,
      })
    }

    if (rightOnly || disableL) {
      return
    }

    // final radius L
    this.drawRadius({
      radius: final.rL,
      center: {
        x: this.shapeCenterXL - final.xL,
        y: final.yL,
      },
      style: radiusStyle,
      withCenter: showLensesGeometryCenter,
    })
  }

  prepareCanvas = () => {
    // center of canvas is center center, set by scale
    const { width, height } = this.props
    const { ctx } = this
    ctx.setTransform(1, 0, 0, 1, 0, 0)
    ctx.fillStyle = 'white'
    ctx.fillRect(0, 0, width, height)
    ctx.setTransform(this.desiredScale, 0, 0, this.desiredScale, width / 2, height / 2)
    ctx.save()
  }

  drawShapes = ({ ...commonConfig } = {}) => {
    const { shapeDataXY, shapeData, rightOnly, leftOnly, edgeThickness, debug } = this.props

    if (!leftOnly) {
      this.drawOneSideXY(shapeDataXY.r, {
        center: {
          x: this.shapeCenterXR,
          y: 0,
        },
        ...commonConfig,
      })
      if (debug) {
        this.drawOneSideShape(shapeData.r, {
          center: {
            x: this.shapeCenterXR,
            y: 0,
          },
          ...commonConfig,
        })
      }
    }

    if (rightOnly) {
      return
    }

    this.drawOneSideXY(shapeDataXY.l, {
      center: {
        x: this.shapeCenterXL,
        y: 0,
      },
      ...commonConfig,
    })
    if (debug) {
      this.drawOneSideShape(shapeData.l, {
        center: {
          x: this.shapeCenterXL,
          y: 0,
        },
        ...commonConfig,
      })
    }
  }

  drawShapesThickness = ({ ...commonConfig } = {}) => {
    const {
      shapeDataXY,
      rightOnly,
      edgeThickness,
      closestPointSide,
      lenseOffsetXR,
      lenseOffsetYR,
      calcedData,
      stockLensR,
      stockLensL,
      comparedStockLens,
    } = this.props

    if (edgeThickness.original) {
      // in jzo, when lenseOffsetX (FCOCIN)  returned from calc for stock lenses, we need to move
      // calced shape thickness by this offset. only shape, everything else is ok.
      // lenseOffset is not the same as lenseOffset in visualiser, that is taken from totalInset (FCSGIN)
      // so only STOCK lenses, with that offset, and only shape thickness

      const calcOffsetXR = (stockLensR && calcedData?.lenseOffsetXR?.original) || 0
      const calcOffsetYR = (stockLensR && calcedData?.lenseOffsetYR?.original) || 0
      const calcOffsetXL = (stockLensL && calcedData?.lenseOffsetXL?.original) || 0
      const calcOffsetYL = (stockLensL && calcedData?.lenseOffsetYL?.original) || 0

      this.drawOneSideThicknessXY(edgeThickness.original.r, {
        center: {
          x: this.shapeCenterXR + calcOffsetXR,
          y: 0 - calcOffsetYR,
        },
        highlightClosestPoint: closestPointSide === 'r',
      })
      this.drawOneSideThicknessXY(edgeThickness.original.l, {
        center: {
          x: this.shapeCenterXL - calcOffsetXL,
          y: 0 - calcOffsetYL,
        },
        highlightClosestPoint: closestPointSide === 'l',
      })
    }

    if (edgeThickness.compared) {
      const calcOffsetXR = (comparedStockLens && calcedData?.lenseOffsetXR?.compared) || 0
      const calcOffsetYR = (comparedStockLens && calcedData?.lenseOffsetYR?.compared) || 0
      const calcOffsetXL = (comparedStockLens && calcedData?.lenseOffsetXL?.compared) || 0
      const calcOffsetYL = (comparedStockLens && calcedData?.lenseOffsetYL?.compared) || 0

      this.drawOneSideThicknessXY(edgeThickness.compared.r, {
        center: {
          x: this.shapeCenterXR + calcOffsetXR,
          y: 0 - calcOffsetYR,
        },
        highlightClosestPoint: closestPointSide === 'r',
        comparing: true,
      })
      this.drawOneSideThicknessXY(edgeThickness.compared.l, {
        center: {
          x: this.shapeCenterXL - calcOffsetXL,
          y: 0 - calcOffsetYL,
        },
        highlightClosestPoint: closestPointSide === 'l',
        comparing: true,
      })
    }
    // console.log('edgeThickness', edgeThickness)
  }

  drawPoint = ({ x, y, color, radius = 0.3 }) => {
    const { ctx } = this
    ctx.save()
    ctx.fillStyle = color
    ctx.beginPath()
    ctx.arc(x, y, radius, 0, 2 * Math.PI)
    ctx.fill()
    // restores canvas before this drawing
    ctx.restore()
  }

  // is called inside draw => ctx is set to lense center
  drawOneSideCenters = () => {
    const { ctx } = this
    ctx.save()

    const {
      showShapeGeometryCenter,
      // TODO: other centers, PDs etc...
    } = this.props

    const { shape: centerStyles } = this.getDrawStyles()

    // mark center
    if (showShapeGeometryCenter) {
      ctx.fillStyle = centerStyles.color
      ctx.beginPath()
      ctx.arc(0, 0, 0.3, 0, 2 * Math.PI)
      ctx.fill()
    }

    // restores canvas before this drawing
    ctx.restore()
  }

  drawDbl = () => {
    const { dbl } = this.props
    if (!dbl) {
      console.log('visualiser - shape does not have dbl')
      return
    }
    const { ctx } = this
    ctx.save()

    const { fillStyle, lineWidth, font, color } = this.getDrawStyles().dbl

    ctx.strokeStyle = color
    ctx.lineWidth = lineWidth
    ctx.fillStyle = fillStyle
    ctx.font = font

    // console.log('dbl', dbl)
    const y = this.pdOffsetYR || this.pdOffsetYL || 0
    ctx.beginPath()
    // line between lenses
    ctx.moveTo(-dbl / 2, y)
    ctx.lineTo(dbl / 2, y)
    ctx.stroke()

    // text
    ctx.textAlign = 'center'
    ctx.textBaseline = 'bottom'
    ctx.fillText('DBL', 0, y)
    ctx.fillText(`${dbl.toFixed(1)}`, 0, y + 4)

    // restores canvas before this drawing
    ctx.restore()
  }

  // is called inside draw => ctx is set to lense center
  drawOneSideBox = ({ isRight }) => {
    let { box } = this.props
    if (!box || !box.r || !box.l) {
      console.log('visualiser - shape does not have box')
      return
    }
    // boxes should be same for both sides
    box = box.r || box.l
    const { ctx } = this
    ctx.save()

    const { fillStyle, font, color, lineWidth } = this.getDrawStyles().box

    ctx.strokeStyle = color
    ctx.fillStyle = fillStyle
    ctx.lineWidth = lineWidth
    ctx.font = font

    // console.log('box', box)
    const overflow = 3
    ctx.beginPath()
    // left
    ctx.moveTo(box.minX, box.minY - overflow)
    ctx.lineTo(box.minX, box.maxY + overflow)
    // top
    ctx.moveTo(box.minX - overflow, box.minY)
    ctx.lineTo(box.maxX + overflow, box.minY)
    // right
    ctx.moveTo(box.maxX, box.minY - overflow)
    ctx.lineTo(box.maxX, box.maxY + overflow)
    // bottom
    ctx.moveTo(box.minX - overflow, box.maxY)
    ctx.lineTo(box.maxX + overflow, box.maxY)
    ctx.stroke()

    // hbox text
    ctx.textAlign = 'center'
    ctx.textBaseline = 'bottom'
    ctx.fillText(`HBOX ${box.hbox.toFixed(2)}`, 0, box.minY - 1)

    // vbox text
    const x = isRight ? box.minX - 1 : box.maxX + 1
    ctx.textAlign = isRight ? 'right' : 'left'
    ctx.textBaseline = 'center'
    ctx.fillText('VBOX', x, -1.5)
    ctx.fillText(`${box.vbox.toFixed(2)}`, x, 1.5)

    // restores canvas before this drawing
    ctx.restore()
  }

  // is called inside draw => ctx is set to lense center
  drawOneSideTitle = () => {
    const { ctx } = this
    ctx.save()

    const { title } = this.props

    const { fillStyle, font } = this.getDrawStyles().title

    if (title) {
      ctx.fillStyle = fillStyle
      ctx.font = font
      ctx.textAlign = 'center'
      ctx.textBaseline = 'middle'
      ctx.fillText(title, 0, 0)
    }

    // restores canvas before this drawing
    ctx.restore()
  }

  // is called inside draw => ctx is set to lense center
  drawOneSideArrows = () => {
    const { shapeData, box } = this.props

    // const radii = box.r // the use case is only for RIGHT side
    const lengths = [
      {
        length: box.r.maxX,
        rotation: 0,
        color: 'green', // TODO: from config???
        title: 'A',
        titleX: 0,
        titleY: 3,
      },
      {
        length: Math.abs(box.r.minY),
        rotation: -Math.PI / 2,
        color: 'red',
        title: 'B',
        titleX: 2,
        titleY: 0,
      },
      {
        length: Math.abs(box.r.minX),
        rotation: -Math.PI,
        color: 'orange',
        title: 'C',
        titleX: 0,
        titleY: 3,
      },
      {
        length: box.r.maxY,
        rotation: (-Math.PI / 2) * 3,
        color: 'purple',
        title: 'D',
        titleX: 2,
        titleY: 0,
      },
    ]

    const { ctx } = this
    ctx.save()
    ctx.lineWidth = 0.8

    lengths.forEach(l => {
      ctx.save()

      ctx.rotate(l.rotation)
      ctx.strokeStyle = l.color
      ctx.fillStyle = l.color

      // arrow body
      ctx.beginPath()
      ctx.moveTo(1, 0)
      ctx.lineTo(l.length - 1.5, 0)
      ctx.closePath()
      ctx.stroke()

      // arrow head
      ctx.beginPath()
      ctx.moveTo(l.length, 0)
      ctx.lineTo(l.length - 1.5, 1.5)
      ctx.lineTo(l.length - 1.5, -1.5)
      ctx.closePath()
      ctx.fill()

      // arrow title
      ctx.translate(l.length / 2, 0) // move to center of arrow body
      ctx.rotate(Math.PI * 2 - l.rotation)
      ctx.font = 'bold 2.5px Roboto'
      ctx.textAlign = 'center'
      // ctx.textBaseline = 'middle'
      ctx.fillText(l.title, l.titleX, l.titleY)

      ctx.restore()
    })

    // restores canvas before this drawing
    ctx.restore()
  }

  drawOneSideXY = (
    data,
    {
      center: { x = 0, y = 0 } = {},
      color = this.getDrawStyles().shape.color,
      lineWidth = this.getDrawStyles().shape.lineWidth,
      fillStyle = this.getDrawStyles().shape.fillStyle,
    } = {},
  ) => {
    const { showArrows, withBox, emphasizePoint } = this.props

    const { ctx } = this
    ctx.save()

    // move canvas to center of shape
    ctx.translate(x, y)

    // draw shape
    ctx.beginPath()
    data.forEach(d => {
      ctx.lineTo(d.x, d.y)
    })
    ctx.closePath()
    ctx.strokeStyle = color
    ctx.lineWidth = lineWidth
    ctx.fillStyle = fillStyle
    ctx.stroke()
    ctx.fill()

    // debug only
    if (typeof emphasizePoint !== 'undefined') {
      if (!data[emphasizePoint]) {
        console.log(data[emphasizePoint])
        console.log(emphasizePoint)
        console.log(data)
        notifyWarning('Pro Petra: data[emphasizePoint] je undefined, proverit zachyceny log')
      }
      ctx.save()
      ctx.fillStyle = 'green'
      // ctx.fillRect(data[emphasizePoint].x, data[emphasizePoint].y, 1, 1)
      ctx.beginPath()
      ctx.arc(data[emphasizePoint].x, data[emphasizePoint].y, 0.6, 0, 2 * Math.PI)
      ctx.closePath()
      ctx.fill()
      ctx.restore()
    }

    // call inner draw fns
    this.drawOneSideCenters()
    this.drawOneSideTitle()
    if (showArrows) {
      this.drawOneSideArrows()
    }
    if (withBox) {
      this.drawOneSideBox({ isRight: x < 0 })
    }

    // restores canvas before this drawing
    ctx.restore()
  }

  drawOneSideShape = (
    data,
    {
      center: { x = 0, y = 0 } = {},
      color = 'orange',
      lineWidth = this.getDrawStyles().shape.lineWidth,
      fillStyle = this.getDrawStyles().shape.fillStyle,
    } = {},
  ) => {
    const { ctx } = this
    ctx.save()

    const angles = data.length
    const oneAngle = (2 * Math.PI) / angles
    // let currAngle = 0
    // move canvas to center of shape
    ctx.translate(x, y)

    // draw shape
    ctx.beginPath()
    data.forEach(d => {
      ctx.lineTo(d, 0)
      ctx.rotate(-oneAngle)
    })
    ctx.closePath()
    ctx.strokeStyle = color
    ctx.lineWidth = lineWidth
    ctx.fillStyle = fillStyle
    ctx.stroke()
    // ctx.fill()

    // restores canvas before this drawing
    ctx.restore()
  }

  drawOneSideThicknessXY = (
    data,
    { center: { x = 0, y = 0 } = {}, highlightClosestPoint, comparing } = {},
  ) => {
    const variantColor = comparing ? 'green' : 'orange'
    const { closest3dIndex, thicknessSet, debug } = this.props
    const { ctx } = this
    ctx.save()

    // find thickest and thinnest point
    const extremes = findThicknessExtremes(data)
    // console.log('data', data, extremes)

    // move canvas to center of shape
    ctx.translate(x, y)
    ctx.textAlign = 'center'
    ctx.textBaseline = 'middle'
    ctx.font = '2.5px Roboto'

    ctx.fillStyle = 'green'
    data.forEach((p, index) => {
      ctx.beginPath()
      let pRadius = p.thickness / 10

      if (pRadius < 0) {
        console.notifyWarning('step3 - thickness radius is negative - probably bad calculation')
        pRadius = 0
      }

      let pColor = 'green'
      let isExtreme = false
      if (extremes.minIndex === index || extremes.maxIndex === index) {
        isExtreme = true
        const relativeIndex = index / data.length
        const offset = 1.3
        let xOffset = -offset * comparing ? 1 : -1
        let yOffset = 0
        let textAlign = comparing ? 'left' : 'right'
        let textBaseline = 'middle'

        if (relativeIndex > 0.1) {
          xOffset = 0
          yOffset = offset * comparing ? 1 : -1
          textAlign = 'center'
          textBaseline = !comparing ? 'bottom' : 'top'
        }
        if (relativeIndex > 0.4) {
          xOffset = offset * comparing ? 1 : -1.5
          yOffset = 0
          textAlign = comparing ? 'left' : 'right'
          textBaseline = 'middle'
        }
        if (relativeIndex > 0.625 && relativeIndex < 0.875) {
          xOffset = 0
          yOffset = offset * comparing ? -1 : 1
          textAlign = 'center'
          textBaseline = comparing ? 'bottom' : 'top'
        }
        ctx.textAlign = textAlign
        ctx.textBaseline = textBaseline
        // pRadius = comparing ? 0.6 : 1
        pRadius = 0.8
        pColor = variantColor
        ctx.fillStyle = pColor
        ctx.fillText(roundNumber(p.thickness, 1), p.x + xOffset, p.y + yOffset)
      }

      ctx.arc(p.x, p.y, pRadius, 0, 2 * Math.PI)
      ctx.fillStyle = pColor
      ctx.strokeStyle = pColor
      ctx.lineWidth = 0.3

      // highlithing current point
      if (thicknessSet === 'edgeThickness' && index === closest3dIndex && highlightClosestPoint) {
        ctx.fillStyle = 'purple'
        ctx.fill()
      }
      if (isExtreme || debug) {
        if (comparing) {
          ctx.stroke()
        } else {
          ctx.fill()
        }
      }
      ctx.fillStyle = 'green'

      ctx.closePath()
    })

    // restores canvas before this drawing
    ctx.restore()
  }

  drawRadius = ({ radius, radiusE, center, style, withCenter }) => {
    const { ctx } = this
    ctx.save()
    ctx.strokeStyle = style.color
    ctx.lineWidth = style.lineWidth
    ctx.beginPath()
    ctx.ellipse(center.x, center.y, radius, radiusE || radius, 0, 0, 2 * Math.PI)
    ctx.stroke()
    ctx.restore()

    if (withCenter) {
      this.drawPoint({
        x: center.x,
        y: center.y,
        color: style.color,
      })
    }
  }

  toggleScale = e => {
    const { realSize } = this.state
    const { monitorScale, notifs, history, t, hideAllPopups } = this.props
    if (!monitorScale) {
      notifs.error({
        title: t('lens visualization'),
        message: t('you have to calibrate display first'),
        action: {
          label: t('go to settings'),
          callback: () => {
            hideAllPopups()
            history.push(Link.SETTINGS)
          },
        },
      })
    } else {
      this.setState({ realSize: !realSize })
    }
    e.stopPropagation()
  }

  render() {
    const {
      width,
      height,
      hideToggleButton,
      buttons,
      bottomButtons,
      onClick,
      onMouseLeave,
      overlayMessage,
      topMessage,
      bottomMessage,
      noBorder,
      hidden,
      fullscreenRef,
      isFullscreen,
      isPrint,
    } = this.props

    const { realSize, canvasPrintImage } = this.state

    return (
      <Wrapper
        width={width}
        height={height}
        onClick={onClick}
        onMouseLeave={onMouseLeave}
        noBorder={noBorder}
        ref={fullscreenRef}
        isFullscreen={isFullscreen}
        style={{
          background: hidden && 'white',
        }}
      >
        {isPrint && canvasPrintImage && <CanvasPrintImage src={canvasPrintImage} />}

        <Canvas
          ref={ref => {
            this.canvasRef = ref
            if (ref) {
              this.ctx = ref.getContext('2d')
            }
          }}
          width={width}
          height={height}
          style={{
            opacity: hidden ? 0 : 1,
            display: isPrint ? 'none' : 'block',
          }}
        />

        <ButtonsWrapper>
          {buttons.map((b, index) =>
            <WrappedButton key={index}>{b}</WrappedButton>)}
          {!hideToggleButton && (
            <HiddenInPrint>
              <Button onClick={this.toggleScale} active={realSize}>
                1:1
              </Button>
            </HiddenInPrint>
          )}
        </ButtonsWrapper>
        {bottomButtons && bottomButtons.length > 0 && (
          <ButtonsWrapper bottom>
            {bottomButtons.map((b, index) =>
              <WrappedButton key={index}>{b}</WrappedButton>)}
          </ButtonsWrapper>
        )}
        {overlayMessage && <OverlayMessage>{overlayMessage}</OverlayMessage>}
        {topMessage && <MessageWrapper top>{topMessage}</MessageWrapper>}
        {bottomMessage && <MessageWrapper bottom>{bottomMessage}</MessageWrapper>}
      </Wrapper>
    )
  }
}

const colors = {
  blue: '#0265ab',
  // lightGray: '#FBFBFB',
}

const defaultDrawStyles = {
  pd: {
    strokeStyle: '#ff9800',
    lineWidth: 0.35,
    length: 2.1,
    centerGap: 0.3,
  },
  multifocals: {
    strokeStyle: 'orange',
    lineWidth: 0.2,
  },
  bifocals: {
    strokeStyle: '#ff9800',
    lineWidth: 0.1,
    prpColor: '#ff9800',
  },
  lensesRadius: {
    lineWidth: 0.2,
    color: 'rgba(200,0,0,.5)',
  },
  lensesCenter: {
    color: 'red',
  },
  title: {
    fillStyle: 'red',
    font: '5px Roboto',
  },
  shape: {
    lineWidth: 0.2,
    color: colors.blue,
    fillStyle: 'none',
    // picked shape color is defined in lib popup
  },
  box: {
    color: 'gray',
    fillStyle: 'gray',
    font: '3px Roboto',
    lineWidth: 0.2,
  },
  dbl: {
    color: 'gray',
    fillStyle: 'gray',
    font: '3px Roboto',
    lineWidth: 0.2,
  },
}

Visualiser.defaultProps = {
  forceRealSize: undefined,
  width: 900,
  height: 500,
  showArrows: false,
  hideToggleButton: false,
  fitsRadius: false,
  showLensesGeometryCenter: false,
  showShapeGeometryCenter: false,
  rightOnly: false,
  title: null,
  buttons: [],
  bottomButtons: [],
  pdR: undefined,
  pdL: undefined,
  dbl: undefined,
  showPupil: false,
  heightR: null,
  heightL: null,
  showLegend: false,
  multifocalConfig: null,
  multifocalConfigL: null,
  showMultifocal: false,
  showRadius: false,
  monitorScale: null,
  smallestEnclosingCircleRadius: null,
  drawStyles: defaultDrawStyles,
  onClick: () => {},
  onMouseLeave: () => {},
  withoutShape: false,
  decXR: 0,
  decYR: 0,
  decXL: 0,
  decYL: 0,
  placeholder: false,
  hidden: false,
  withBox: false,
  withDbl: false,
  disableR: false,
  disableL: false,
  isPrint: false,
}

Visualiser.propTypes = {
  forceRealSize: PropTypes.bool,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  history: PropTypes.object.isRequired,
  notifs: PropTypes.object.isRequired,
  hideToggleButton: PropTypes.bool,
  fitsRadius: PropTypes.bool, // when autoscale, want to see whole radius?
  showLensesGeometryCenter: PropTypes.bool, // show blue dot lens center
  showShapeGeometryCenter: PropTypes.bool, // show red dot geometry center
  rightOnly: PropTypes.bool, // only right lense / form
  showArrows: PropTypes.bool, // show ABCD deformation arrows
  title: PropTypes.string, // when filled, title is shown at center of each lense
  buttons: PropTypes.array, //
  bottomButtons: PropTypes.array, //
  shapeData: PropTypes.object, //
  shapeDataXY: PropTypes.object, //
  pdR: PropTypes.oneOfType([PropTypes.number, PropTypes.string]), //
  pdL: PropTypes.oneOfType([PropTypes.number, PropTypes.string]), //
  dbl: PropTypes.number, //
  heightR: PropTypes.oneOfType([PropTypes.number, PropTypes.string]), //
  heightL: PropTypes.oneOfType([PropTypes.number, PropTypes.string]), //
  showLegend: PropTypes.bool, //
  box: PropTypes.object, // sth like box system
  // --- most far left/right/top/bottom point relative to center - redux calculates this
  showPupil: PropTypes.bool, // draw pd (yellow cross) zornicka =  PD + vyska
  showMultifocal: PropTypes.bool, //
  multifocalConfig: PropTypes.object, // e.g. { upX: 0, upY: -6, downX: 2, downY: 13, }
  multifocalConfigL: PropTypes.object, // e.g. { upX: 0, upY: -6, downX: 2, downY: 13, }
  withoutShape: PropTypes.bool, // no frame (order type 1)
  showRadius: PropTypes.bool, //
  disableR: PropTypes.bool, // if lens is disabled in step 1 (affects drawPd and multifocals)
  disableL: PropTypes.bool, //
  placeholder: PropTypes.bool, //
  withBox: PropTypes.bool,
  withDbl: PropTypes.bool,
  hidden: PropTypes.bool, //
  monitorScale: PropTypes.number, //
  smallestEnclosingCircleRadius: PropTypes.number, //
  smallestEnclosingCircleX: PropTypes.number, //
  smallestEnclosingCircleY: PropTypes.number, //
  drawStyles: PropTypes.object, //
  onClick: PropTypes.func,
  onMouseLeave: PropTypes.func,
  decXR: PropTypes.oneOfType([PropTypes.number, PropTypes.string]), //
  decYR: PropTypes.oneOfType([PropTypes.number, PropTypes.string]), //
  decXL: PropTypes.oneOfType([PropTypes.number, PropTypes.string]), //
  decYL: PropTypes.oneOfType([PropTypes.number, PropTypes.string]), //
  // : PropTypes., //
  t: PropTypes.func.isRequired,
  hideAllPopups: PropTypes.func.isRequired,
  isPrint: PropTypes.bool,
}

const enhance = compose(
  withRouter,
  withNotifs,
  withTranslation(),
  connect(
    state => ({
      monitorScale: state.config.visualiserScale,
    }),
    {
      hideAllPopups,
    },
  ),
)

export default enhance(Visualiser)
