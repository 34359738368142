import React from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { useLocation, useNavigate, NavLink as RouterNavLink } from 'react-router-dom'

import { Link, NavLink as BaseNavLink } from './Link'
import Icon from './Icon'
import Popup from './popups/Popup'
import { NewsPopoverMenu } from './popoverMenus'
import { appTypeConfig } from '../../common/config'
import { getAreOrderFieldsDirty } from '../app/helpers'
import { signOut } from '../../common/auth/actions'

const enhanceLink = compose(
  withTranslation(),

  connect(
    ({ orders = {}, fields = {}, vca = {} }) => ({
      currentOrder: orders.currentOrder,
      fieldsValues: fields && fields.OrderForm ? fields.OrderForm : {},
      isOrderFormDirty: orders.isOrderFormDirty,
    }),
    {}
  )
)

const activeStyle = css`
  color: ${({ theme: { colors } }) => appTypeConfig.headerActiveFontColor || colors.primary};
  font-weight: ${appTypeConfig.headerActiveFontWeight || 400};
`

const NavLink = styled(BaseNavLink)`
  font-size: 1.6rem;
  padding: 1rem;
  flex-grow: 1;
  display: block;
  transition: 0.4s color;
  cursor: pointer;

  font-weight: ${appTypeConfig.headerFontWeight || 400};

  ${appTypeConfig.headerFontColor &&
  css`
    color: ${appTypeConfig.headerFontColor};
  `}

  &:hover {
    ${activeStyle}
    font-weight: ${appTypeConfig.headerFontWeight || 400};
    color: ${appTypeConfig.headerHoverFontColor || appTypeConfig.headerFontColor};
  }
  ${({ isActive }) => isActive && activeStyle}

  &.active {
    ${activeStyle}
  }
`

const StyledIcon = styled(Icon)`
  color: ${p => appTypeConfig.headerIconsColor || p.theme.colors.navbarIcons};
`

const LogoutIcon = styled.div`
  margin-left: 1rem;
  padding: 1rem;
  cursor: pointer;
  transition: 0.4s color;

  ${StyledIcon} {
    transition: 0.4s color;
    color: ${p => appTypeConfig.headerFontColor || p.theme.colors.text2};
  }
  &:hover {
    ${activeStyle}
    ${StyledIcon} {
      ${activeStyle}
    }
  }
`

const PopupLink = styled(NavLink).attrs(() => ({ as: 'div' }))``

const WrappedNavLink = ({ to, t, currentOrder, fieldsValues, isOrderFormDirty, ...props }) => {
  // if we are in creating order process - ask user to leave first

  // check, if something in fields was changed compared to current order in redux
  const areOrderFieldsDirty = getAreOrderFieldsDirty({ fieldsValues, currentOrder })
  const isDirty = areOrderFieldsDirty || isOrderFormDirty

  const location = useLocation()
  const navigate = useNavigate()

  if (isDirty && location?.pathname?.startsWith('/create-order')) {
    return (
      <Popup
        title={t('Leave order?')}
        text={t('LEAVE_ORDER_WARNING_MESSAGE')}
        closeOnOutsideClick
        closeOnEsc
        disableHistoryManipulation
        onClose={opts => {
          if (opts.ok) {
            navigate(to)
          }
        }}
        okText={t('leave (order form filling)')}
        closeText={t('cancel')}
      >
        {({ open }) => (
          <>
            <PopupLink onClick={open} {...props} end />
          </>
        )}
      </Popup>
    )
  }

  return <NavLink to={to} {...props} end />
}
WrappedNavLink.defaultProps = {
  currentOrder: {},
  fieldsValues: {},
  isOrderFormDirty: false,
}
WrappedNavLink.propTypes = {
  t: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  to: PropTypes.string.isRequired,
  currentOrder: PropTypes.object,
  fieldsValues: PropTypes.object,
  isOrderFormDirty: PropTypes.bool,
}

const EnhancedNavLink = enhanceLink(WrappedNavLink)

const Container = styled.div`
  ${({ theme }) => css`
    position: relative;
    background: ${appTypeConfig.headerBackgroundColor || 'white'};
    padding: ${theme.page.paddingHorizontal};
    padding-top: 0;
    padding-bottom: 0;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.07);

    border-bottom: ${appTypeConfig.headerBottomBorder || 'unset'};

    @media print {
      display: none;
    }
  `}
`

const Content = styled.div`
  ${({ theme }) => css`
    height: 6rem;
    display: flex;
    align-items: center;
    justify-content: ${appTypeConfig.isLogoOnRight ? 'flex-start' : 'space-between'};
    max-width: ${theme.page.maxWidth};
    min-width: ${theme.page.minWidth};
    margin: auto;
  `}
`

const LogoWrapper = styled(EnhancedNavLink)`
  align-items: center;
  display: ${appTypeConfig.isLogoOnRight ? 'block' : 'flex'};
  margin-right: ${appTypeConfig.isLogoOnRight ? '0' : '1rem'};

  ${appTypeConfig.isLogoOnRight &&
  css`
    z-index: 1000;
    position: absolute;
    right: 0;
    top: 0;
    /* specific for reize
    logo is overflowing to content
    so it cannot be clickable everywhere */
    width: 17rem;
    height: 9rem;
    padding: 0;
  `}
`

const Logo = styled.img`
  ${appTypeConfig.isLogoOnRight
    ? css`
    width: auto;
    padding: 0;
    position: absolute;
    right: 0;
    top: 0;
    pointer-events: none;
    ${p =>
      p.maxHeight &&
      css`
        max-height: ${p.maxHeight}px;
        /* Only applied for reize page versions */
        right: 10px;
        top: 5px;
      `}
  }`
    : css`
        ${({ maxHeight }) => css`
          max-height: ${maxHeight ? `${maxHeight}rem` : '5.4rem'};
        `}
      `}
`

const Nav = styled.nav`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
`

const Divider = styled.div`
  width: 1px;
  background: ${p => p.theme.colors.navbarDivider};
  height: 100%;
  margin: 0 1rem;
`
const WithFlag = styled.span`
  position: relative;

  ::after {
    content: '';
    position: absolute;
    display: none;
    display: ${p => p.showFlag && 'block'};
    top: -1px;
    right: -7px;
    background: ${p => p.theme.colors.red};
    width: 0.7rem;
    height: 0.7rem;
    border-radius: 10rem;
  }
`

// eslint-disable-next-line arrow-body-style
const Header = ({ t, puppetUser, hostConf, signOut }) => {
  // console.log('matches', matchHome, matchSettings)
  return (
    <Container>
      <Content>
        <Nav>
          <LogoWrapper to={Link.HOME}>
            <Logo
              alt="logo"
              src={appTypeConfig.headerLogoPath || hostConf.logo?.publicPath}
              maxHeight={hostConf.navbarLogoMaxHeight}
              style={hostConf?.logo?.styles?.image || {}}
            />
          </LogoWrapper>
          <EnhancedNavLink to={Link.HOME}>{t('my orders')}</EnhancedNavLink>
        </Nav>
        <Nav>
          {appTypeConfig.isGoToWowEnabled && hostConf.isGoToWowEnabled && (
            <>
              <NavLink
                href={hostConf.wowUrl || 'https://webtracking.omega-optix.cz/OmegaDirectWeb/'}
                external
                target="_blank"
              >
                {t('go to WOW')}
              </NavLink>
              <Divider />
            </>
          )}
          <NewsPopoverMenu
            isToRight={appTypeConfig.isLogoOnRight}
            renderLinkComponent={({ isOpen, showFlag }) => (
              <PopupLink>
                <WithFlag showFlag={showFlag}>{t('news')}</WithFlag>
                <StyledIcon
                  name={isOpen ? 'IosArrowUp' : 'IosArrowDown'}
                  style={{
                    marginTop: !isOpen ? '-2px' : '-4px',
                    marginLeft: '0.5rem',
                  }}
                />
              </PopupLink>
            )}
          />
          {appTypeConfig.arePromotionsEnabled && (
            <EnhancedNavLink to={Link.PROMOTIONS}>{t('Promotions')}</EnhancedNavLink>
          )}

          <EnhancedNavLink to={Link.SETTINGS}>
            {t('settings')}
            <StyledIcon
              name="MdSettings"
              style={{
                marginTop: '-2px',
                marginLeft: '0.5rem',
              }}
            />
          </EnhancedNavLink>
          {appTypeConfig.hasLogoutInHeader && (
            <LogoutIcon
              onClick={() => {
                window.location.hash = 'logout'
                setTimeout(() => {
                  signOut()
                }, 1)
              }}
            >
              <StyledIcon
                name="IosLogOut"
                size={19}
                style={{
                  // color: '#333333',
                  marginTop: '-2px',
                }}
              />
            </LogoutIcon>
          )}
        </Nav>
        {puppetUser && (
          <div style={{ position: 'fixed', right: '20px', bottom: '20px' }}>
            {puppetUser.label}
          </div>
        )}
      </Content>
    </Container>
  )
}

Header.propTypes = {
  t: PropTypes.func.isRequired,
  // TODO - bylo tady array ale předával se objekt - pokud je to vždy objekt tak array smazat --> objekt je spatne
  news: PropTypes.oneOfType([PropTypes.array]).isRequired,
  hostConf: PropTypes.object.isRequired,
  signOut: PropTypes.func.isRequired,
}

const enhance = compose(
  withTranslation(),
  connect(
    ({ app }) => ({
      hostConf: app.hostConf,
      news: app.news,
      puppetUser: app.puppetUser,
    }),
    {
      signOut,
    }
  )
)

export default enhance(Header)
