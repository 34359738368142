import React from 'react'
import Icon from 'react-icon-base'

const Draw = props => (
  <Icon viewBox="0 0 21 20" {...props}>
    <path d="M0.5 11.271L9.58448 2.18656L10.5988 3.20089L1.50981 12.2854H0.5V11.271ZM1.46837 12.3268C1.46847 12.3267 1.46858 12.3266 1.46868 12.3265L1.46845 12.3267L1.46837 12.3268ZM11.3927 0.5C11.4545 0.5 11.5226 0.524033 11.5792 0.569415L12.2194 1.20964C12.2574 1.2551 12.2868 1.32442 12.2899 1.40747C12.2887 1.48259 12.2643 1.54181 12.2243 1.5891L11.9429 1.87052L11.1755 1.10307L10.9253 0.85289L11.2061 0.569601C11.2592 0.52484 11.3252 0.5 11.3927 0.5Z" transform="translate(7.91016 0.875)" />
    <path d="M6.67841 6.7332L6.67841 6.7332C6.44825 6.96336 6.31735 7.27751 6.31735 7.60274V9.49772C6.31735 10.1757 6.86998 10.7283 7.54795 10.7283H9.43836C9.76359 10.7283 10.0777 10.5974 10.3079 10.3673L10.3442 10.331L10.3444 10.3307L16.5502 4.13331V12.9087C16.5502 14.112 15.5732 15.089 14.3699 15.089H2.68037C1.47706 15.089 0.5 14.112 0.5 12.9087V2.68037C0.5 1.47706 1.47706 0.5 2.68037 0.5H12.9116L6.71494 6.69667L6.71494 6.69668L6.67841 6.7332Z" transform="translate(0.239258 4.28613)" />
  </Icon>
)

export default Draw