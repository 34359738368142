import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

const GroupTitle = styled.h4`
  font-size: 1.4rem;
  letter-spacing: 0.2rem;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.groupTitle};
  margin: 0 0 1rem 0;
  font-weight: bold;
  max-width: fit-content;
`

export default GroupTitle
