import { Store } from 'react-notifications-component'
import { NotificationContent } from '../../browser/components/Notifications'

export const defaultOptions = {
  insert: 'top',
  container: 'top-right',
  dismiss: {
    duration: 8000,
    waitForAnimation: true,
    click: false,
    showIcon: true,
    touch: true,
  },

  animationIn: ['animate__animated', 'animate__bounceIn', 'animate__fast'],
  animationIn: ['animate__animated', 'animate__fadeInRightBig', 'animate__faster'],
  animationIn: ['animate__animated', 'animate__zoomIn'],
  animationOut: ['animate__animated', 'animate__zoomOut', 'animate__fast'],
  slidingEnter: {
    duration: 100,
    timingFunction: 'ease-out',
    delay: 0,
  },
  touchSlidingExit: {
    swipe: {
      duration: 400,
      timingFunction: 'ease-out',
      delay: 0,
    },
    fade: {
      duration: 400,
      timingFunction: 'ease-out',
      delay: 0,
    },
  },
  isMobile: false, // not responsive
}

const createNotif = args =>
  Store.addNotification({
    ...defaultOptions,
    ...args,
    content: NotificationContent,
    level: args.type,
  })

export const removeNotif = id => Store.removeNotification(id)

export const success = args => createNotif({ ...args, type: 'success' })

export const error = args => createNotif({ ...args, type: 'danger' })

export const warning = args => createNotif({ ...args, type: 'warning' })

export const info = args => createNotif({ ...args, type: 'info' })
