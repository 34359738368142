import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import styled from 'styled-components'
import { withTranslation } from 'react-i18next'

import {
  deformSelected,
  saveSelectedVcaDeformed,
  resetSelectedVcaDeformed,
} from '../../../common/vca/actions'

import { getMultifocalConfig } from '../../../common/lib/vca'
import { FieldsGroup, Row, Col, WarningMessage, Gap } from '..'
import Visualiser from './Visualiser'
import { NumberField } from '../fields'

// const InnerWrapper = styled.div`
//   display: flex;
//   direction: row;
//   flex-grow: 1;
// `

const StyledVisualiser = styled(Visualiser)`
  border: red solid thin;
`

const EquationWrapper = styled.div`
  display: flex;
  direction: row;
  align-items: baseline;
`
const EquationVariable = styled.span`
  color: ${({ color }) => color};
  font-weight: bold;
`
const EquationInitPoint = styled.span`
  width: 5rem;
  padding: 0 0 0 1rem;
`

const roundNumber = num => Math.round(num * 10) / 10

// eslint-disable-next-line
class Deformator extends Component {
  render() {
    const {
      selectedVca,
      selectedVcaDeformed,
      deformSelectedRight,
      deformSelectedTop,
      deformSelectedLeft,
      deformSelectedBottom,
      deformSelected,
      currentLensR,
      currentLensL,
      t,

      pdR,
      pdL,
      heightR,
      heightL,
      proglen,
    } = this.props

    const equations = {
      right: {
        original: roundNumber(selectedVca.box.r.maxX),
        value: deformSelectedRight,
        result: roundNumber(selectedVcaDeformed.box.r.maxX),
        variable: 'A',
        color: 'green',
      },
      top: {
        original: Math.abs(roundNumber(selectedVca.box.r.minY)),
        value: deformSelectedTop,
        result: Math.abs(roundNumber(selectedVcaDeformed.box.r.minY)),
        variable: 'B',
        color: 'red',
      },
      left: {
        original: Math.abs(roundNumber(selectedVca.box.r.minX)),
        value: deformSelectedLeft,
        result: Math.abs(roundNumber(selectedVcaDeformed.box.r.minX)),
        variable: 'C',
        color: 'orange',
      },
      bottom: {
        original: Math.abs(roundNumber(selectedVca.box.r.maxY)),
        value: deformSelectedBottom,
        result: Math.abs(roundNumber(selectedVcaDeformed.box.r.maxY)),
        variable: 'D',
        color: 'purple',
      },
    }

    const multifocalConfig = getMultifocalConfig(currentLensR || currentLensL, proglen)

    // this is not enough. -5 + 5 = 0 for example
    // const sum = deformSelectedRight + deformSelectedTop + deformSelectedLeft + deformSelectedBottom
    // delete later 
    
    const isDeformed =
      deformSelectedRight || deformSelectedTop || deformSelectedLeft || deformSelectedBottom

    return (
      <div>
        <Row>
          <Col>
            <StyledVisualiser
              {...selectedVcaDeformed}
              pdR={pdR}
              pdL={pdL}
              heightR={heightR}
              heightL={heightL}
              // showRadius
              showShapeGeometryCenter
              showArrows
              rightOnly
              fitsRadius
              width={600}
              height={350}
              showPupil
              showMultifocal={!!multifocalConfig}
              multifocalConfig={multifocalConfig}
            />
          </Col>

          <Col grow={1}>
            <FieldsGroup title={t('proportions')} direction="row">
              {Object.keys(equations).map(key => {
                const e = equations[key]
                return (
                  <EquationWrapper key={key}>
                    <EquationVariable color={e.color}>{e.variable}:</EquationVariable>
                    <EquationInitPoint>{e.original.toFixed(1)}</EquationInitPoint>
                    +&nbsp;
                    <NumberField
                      name={`deform-${key}`}
                      onChange={e => {
                        deformSelected({ [key]: e.value })
                      }}
                      onBlur={e => {
                        const value = parseFloat(e.target.value)
                        if (isNaN(value)) deformSelected({ [key]: 0 })
                      }}
                      onFocus={e => {
                        e.target.select()
                      }}
                      value={e.value}
                    />
                    &nbsp;= {e.result}
                  </EquationWrapper>
                )
              })}
            </FieldsGroup>
            <Gap />
            {!!isDeformed && 
              <WarningMessage>{t('SHAPE_PROPORTIONS_CHANGED_WARNING_MESSAGE')}</WarningMessage>
            }
          </Col>
        </Row>
      </div>
    )
  }
}

Deformator.defaultProps = {
  deformSelectedRight: 0,
  deformSelectedTop: 0,
  deformSelectedLeft: 0,
  deformSelectedBottom: 0,
  currentLensR: null,
  currentLensL: null,
}

Deformator.propTypes = {
  selectedVca: PropTypes.object.isRequired,
  selectedVcaDeformed: PropTypes.object.isRequired,
  deformSelectedRight: PropTypes.number,
  deformSelectedTop: PropTypes.number,
  deformSelectedLeft: PropTypes.number,
  deformSelectedBottom: PropTypes.number,
  deformSelected: PropTypes.func.isRequired,
  saveSelectedVcaDeformed: PropTypes.func.isRequired,
  resetSelectedVcaDeformed: PropTypes.func.isRequired,
  currentLensR: PropTypes.object,
  currentLensL: PropTypes.object,
  t: PropTypes.func.isRequired,
  pdR: PropTypes.number,
  pdL: PropTypes.number,
  heightR: PropTypes.number,
  heightL: PropTypes.number,
}

const enhance = compose(
  connect(
    state => ({
      selectedVca: state.vca.selectedVca,
      selectedVcaDeformed: state.vca.selectedVcaDeformed,
      deformSelectedRight: state.vca.deformSelectedRight,
      deformSelectedTop: state.vca.deformSelectedTop,
      deformSelectedLeft: state.vca.deformSelectedLeft,
      deformSelectedBottom: state.vca.deformSelectedBottom,
      currentLensR: state.catalog.currentLensR,
      currentLensL: state.catalog.currentLensL,
    }),
    {
      deformSelected,
      saveSelectedVcaDeformed,
      resetSelectedVcaDeformed,
    },
  ),
  withTranslation(),
)

export default enhance(Deformator)
