/* @flow weak */
export const LISTS_RESET_LIST = 'LISTS_RESET_LIST'
export const LISTS_RESET_FILTERS = 'LISTS_RESET_FILTERS'
export const LISTS_SET_FILTER = 'LISTS_SET_FILTER'
export const LISTS_SET_PAGE = 'LISTS_SET_PAGE'
export const LISTS_SET_PAGE_LIMIT = 'LISTS_SET_PAGE_LIMIT'
export const LISTS_SET_SORT = 'LISTS_SET_SORT'

export const resetList = path => ({
  type: LISTS_RESET_LIST,
  payload: { path },
})
export const resetFilters = path => ({
  type: LISTS_RESET_FILTERS,
  payload: { path },
})
export const setFilter = (path, value) => ({
  type: LISTS_SET_FILTER,
  payload: { path, value },
})
export const setPage = (path, value) => ({
  type: LISTS_SET_PAGE,
  payload: { path, value },
})
export const setPageLimit = (path, value) => ({
  type: LISTS_SET_PAGE_LIMIT,
  payload: { path, value },
})
export const setSort = (path, value) => ({
  type: LISTS_SET_SORT,
  payload: { path, value },
})
