import { apiGet, apiDelete, apiPost, apiPut } from '../lib/api'

export const FETCH_PROMOTIONS = 'FETCH_PROMOTIONS'
export const FETCH_PROMOTIONS_START = 'FETCH_PROMOTIONS_START'
export const FETCH_PROMOTIONS_SUCCESS = 'FETCH_PROMOTIONS_SUCCESS'
export const FETCH_PROMOTIONS_ERROR = 'FETCH_PROMOTIONS_ERROR'

export const fetchPromotions = () => ({ getState, fetch }) => ({
  type: FETCH_PROMOTIONS,
  payload: apiGet({
    fetch,
    endpoint: '/promotions',
    getState,
  }),
})
