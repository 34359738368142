import { createGlobalStyle, css } from 'styled-components'
import { getFontsCss } from '../../common/lib/fonts'
import { fonts } from './fonts'

const fontsCss = css`
  ${getFontsCss({ fonts })}
`

const GlobalStyle = createGlobalStyle`
  ${fontsCss}

  * {
    box-sizing: border-box;
  }

  svg {
    pointer-events: none;
  }

  html {
    tap-highlight-color: $color-transparent;
    text-size-adjust: 100%;
    height: 100%;

    overflow: auto;
    // overflow-x: hidden;

    @media not print {
      min-width: 74rem;
      min-width: 1156px;
    }

    font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;

    // fix usercentrics fucking with our css
    min-height: initial;
    height: 100% !important;
    padding: 0 !important;

    @media print {
      /* reize cookies */
      #usercentrics-button { 
        display: none;
      }
    }
  }

  body,
  html {
    font-family: 'Roboto', sans-serif;
    // font-size: 8px;
    // disable smallest font breakpoint, it breaks the ui
    font-size: 9px;
    height: 100%;
    width: 100%;
    margin: 0;
  }

  // over 992px
  @media (min-width: 992px) {
    body,
    html {
      font-size: 9px;
    }
  }

  // over 1200px
  @media (min-width: 1200px) {
    body,
    html {
      font-size: 10px;
    }
  }
  /*
//svg
.svg {
  path,
  rect {
    stroke-width: 0;
  }
}*/

  .app,
  .app-container {
    min-height: 100%;
    height: 100%;
    /* overflow: hidden; */
    font-size: 1.6rem;

    background-color: #f4f4f4;
    @media print {
      background-color: white;
    }
  }

  .app-container {
    min-height: 100%;
    height: 100%;
    font-size: 1.6rem;
  }

  .app-loader {
    // display: none !important;
  }

  // firefox input arrow hide
  input[type='number']::-webkit-outer-spin-button,
  input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }
`

export default GlobalStyle
