import React from 'react'
import { Player, Controls } from '@lottiefiles/react-lottie-player'
import styled from 'styled-components'
import { up } from '../app/theme'

const AnimWrapper = styled.div`
  position: absolute;
  top: -0px;
  height: max(100vh, 100vw);
  width: max(100vh, 100vw);
  overflow: hidden;

  overflow: auto;
  max-height: none;
`

const StyledPlayer = styled(Player)`
  min-height: max(100vh, 100vw);
  min-width: max(100vh, 100vw);
  min-height: 100%;
  min-width: 100%;
  position: relative;

  & > svg {
    /* min-height: max(100vh, 100vw);
    min-width: max(100vh, 100vw);
    width: auto !important;
    height: auto  !important; */

    mask-image: linear-gradient(to top, transparent, black 50%);
    -webkit-mask-image: linear-gradient(to top, transparent, black 50%);
  }
`

export const LottieAnimation = () => {
  return (
    <AnimWrapper>
      <StyledPlayer
        // loop
        autoplay
        src="/public/lottie/confetti-desktop.json"
      />
    </AnimWrapper>
  )
}
