// return distance between two points in 2D space
const getDistance = (point1, point2) => {
  const { x: x1, y: y1 } = point1
  const { x: x2, y: y2 } = point2
  const xDiff = x1 - x2
  const yDiff = y1 - y2
  const distance = Math.sqrt(xDiff ** 2 + yDiff ** 2)
  return distance
}

// return approximated circumference of shape by summing up distances between given points
const getCircumference = (shapeDataXY, roundCoef) => {
  if (!shapeDataXY) return 0
  let distanceSum = 0
  let previousPoint = null

  shapeDataXY.forEach(point => {
    // first point
    if (!previousPoint) {
      previousPoint = point
      return
    }

    distanceSum += getDistance(previousPoint, point)
    
    previousPoint = point
  })

  // last point to first point
  distanceSum += getDistance(previousPoint, shapeDataXY[0])

  if (roundCoef) {
    distanceSum = Math.round(distanceSum * roundCoef) / roundCoef
  }

  return distanceSum
}

export default getCircumference
