import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import styled from 'styled-components'
import { withTranslation } from 'react-i18next'
import { Visualiser } from '../vca'
import { Button } from '..'
import PopupPresentational from './PopupPresentational'
import { withNotifs } from '../hoc'

import { uploadVca, selectVca } from '../../../common/vca/actions'

// eslint-disable-next-line
class VcaUploadPopup extends Component {
  close = ({ ok } = {}) => {
    console.log('was ok', ok)
    // do anything you want on close
    const { selectVca } = this.props

    if (ok) {
      selectVca({ uploaded: true })
    } else {
      selectVca()
    }

    // finally hide the popup itself
    this.props.close()
  }

  handleFileUpload = async e => {
    const { uploadVca, notifs } = this.props
    try {
      await uploadVca({ files: e.target.files }).meta.action.payload
    } catch (e) {
      notifs.error({
        title: 'Upload failed',
        message: 'Probably incorrect file format. Check file and try again.',
      })
    }
  }

  render() {
    const { uploadedVca, selectVca, t } = this.props

    return (
      <PopupPresentational
        title={t('uploading custom shape')}
        close={this.close}
        type="big"
        okText={t('save shape')}
        cancelText={t('cancel')}
        okDisabled={!uploadedVca}
      >
        <input type="file" name="file" onChange={this.handleFileUpload} />

        {uploadedVca && <Visualiser {...uploadedVca} />}
      </PopupPresentational>
    )
  }
}

const enhance = compose(
  connect(
    state => ({
      uploadedVca: state.vca.uploadedVca,
    }),
    {
      uploadVca,
      selectVca,
    },
  ),
  withNotifs,
  withTranslation(),
)

export default enhance(VcaUploadPopup)

VcaUploadPopup.defaultProps = {}

VcaUploadPopup.propTypes = {
  close: PropTypes.func.isRequired, // is injected by PopupsGate
  t: PropTypes.func.isRequired,
}
