/* eslint-disable import/prefer-default-export */
import { detect } from 'detect-browser'
import { forbiddenBrowsers, minBrowserVersions } from '../config'

export function isBrowserSupported() {
  const browser = detect() || {}

  if (forbiddenBrowsers.includes(browser.name)) {
    return false
  }

  let { version = '0.0' } = browser

  version = parseInt(version.split('.')[0], 10)

  const minVersion = minBrowserVersions[browser.name]

  // console.log('version', version)
  // console.log('minVersion', minVersion)

  if (!minVersion) {
    return true
  }

  if (minVersion > version) {
    return false
  }

  return true
}
