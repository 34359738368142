export const removeDiacritics = text => text.normalize('NFD').replace(/[\u0300-\u036f]/g, '')

// just for showcase and testing...
const accents =
  'ÀÁÂÃÄÅĄàáâãäåąßÒÓÔÕÕÖØŐòóôőõöøĎďDŽdžÈÉÊËĘèéêëęðÇçČčĆćÐÌÍÎÏìíîïÙÚÛÜŰùűúûüĽĹŁľĺłÑŇŃňñńŔŕŠŚšśŤťŸÝÿýŽŻŹžżź'
const accentsOut =
  'AAAAAAAaaaaaaasOOOOOOOOoooooooDdDZdzEEEEEeeeeeeCcCcCcDIIIIiiiiUUUUUuuuuuLLLlllNNNnnnRrSSssTtYYyyZZZzzz'

export function fullNormalizeForSearch(text = '') {
  return (
    text
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '') // remove diacritics
      // .replace(/\.|:|,/g, ' ') // remove dots, colons, commas
      .replace(/:|-|_/g, ' ') // remove colons, dashes, underscores
      .replace(/,/g, '.') // replace commas with dots
      .replace(/\s\s+/g, ' ') // multiple spaces
      .trim()
  ) // trim spaces
}

export function filterLensByNameFunction(item, searchText = '') {
  const itemSearchText = item.data?.search || item.names?.[0].search
  if (!itemSearchText) return true
  const inputSearchText = fullNormalizeForSearch(searchText)
  const searchTextParts = inputSearchText.split(' ')

  return searchTextParts.every(part => itemSearchText.includes(part))
}
