import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import * as notifs from '../../common/lib/notifs'

import { addIoListener, getIo, useEmitAuth } from '../io'

export function useServerConnectionStatus({
  onFirstConnected,
  onConnected,
  onReconnected,
  onDisconnected,
}) {
  const [serverConnected, setServerConnected] = useState(true)

  useEffect(() => {
    // console.log('useServerConnectionStatus EFFECT')

    const removeOnConnectedListener = addIoListener('connect', () => {
      if (!serverConnected) {
        onReconnected?.()
      } else {
        onFirstConnected?.()
      }
      onConnected?.()
      setServerConnected(true)
    })

    const removeOnDisconnectedListener = addIoListener('disconnect', () => {
      onDisconnected?.()
      setServerConnected(false)
    })

    return () => {
      removeOnConnectedListener()
      removeOnDisconnectedListener()
    }
  }, [onFirstConnected, onConnected, onReconnected, onDisconnected, serverConnected])

  return null
}

function ServerConnectionWatcher() {
  const emitAuth = useEmitAuth()

  const [notifsToDismiss, setNotifs] = useState([])

  useServerConnectionStatus({
    onReconnected: () => {
      // dismiss all dis/re/connected notifs
      notifsToDismiss.forEach(notif => {
        notifs.removeNotif(notif)
      })
      // let user know we are reconnected
      const connectionRestoredNotif = notifs.info({ title: 'Server connection restored' })
      // save last notif for possible clearing if quickly reconnected multiple times
      setNotifs([connectionRestoredNotif])

      // emit auth on server reconnection!
      emitAuth()
    },
    onDisconnected: () => {
      const notif = notifs.warning({
        title: 'Server disconnected',
        message: 'Check your internet connection',
        autoDismiss: 0,
      })

      setNotifs([...notifsToDismiss, notif])
    },
  })

  return null
}

export default React.memo(ServerConnectionWatcher)
