import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { OrderStep1Form } from '../forms'
import { setActiveOrderStep } from '../../../common/orders/actions'

class CreateOrderStep1Page extends Component {
  componentDidMount() {
    this.props.setActiveOrderStep(1)
    if (process.env.IS_BROWSER) {
      window.scrollTo(0, 0)
    }
  }

  render() {
    return <OrderStep1Form {...this.props} />
  }
}

CreateOrderStep1Page.propTypes = {
  setActiveOrderStep: PropTypes.func.isRequired,
}
const enhance = compose(
  connect(() => ({}), {
    setActiveOrderStep,
  }),
)

export default enhance(CreateOrderStep1Page)
