import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { Box, Row, Col } from '../..'
import { NumberField } from '../../fields'
import { appTypeConfig } from '../../../../common/config'

const { individualMenus } = appTypeConfig

const enhanceFields = (fields, menuConf) => {
  Object.keys(menuConf).forEach(key => {
    // console.log('key', key)
    const fieldConf = menuConf[key]
    const isRequired = typeof fieldConf === 'object' ? fieldConf.isRequired : fieldConf === true

    if (key === 'bvd') key = 'bvdR'
    const field = fields[key]
    if (field) {
      field.required = isRequired
      // console.log('field', field)
    }
  })
}

const IndividualSection = ({ fields, lensL, lensR, t }) => {
  if (
    !(
      (lensR && fields.lensR.value && lensR.individualMenu) ||
      (lensL && fields.lensL.value && lensL.individualMenu)
    )
  ) {
    return null
  }

  const menuConf = individualMenus[fields.individualMenu && fields.individualMenu.value]
  if (!menuConf) return null
  enhanceFields(fields, menuConf)
  // console.log('fields', fields)

  return (
    <Box>
      <Box.Header>
        <Box.Title>{t('individual data')}</Box.Title>
      </Box.Header>
      <Box.Content>
        <Row alignItems="flex-start">
          {menuConf.bvd !== false && (
            <Col width="33%">
              <NumberField
                label={t('back vertex distance')}
                {...fields.bvdR}
                onChange={e => {
                  fields.bvdL.onChange(e)
                  fields.bvdR.onChange(e)
                }}
                // required={fields.bvd.required}
                noMaxWidth
                errorsStyle={{ position: 'relative' }}
              />
            </Col>
          )}
          {menuConf.frameBowAngle !== false && (
            <Col width="33%">
              <NumberField
                // TODO - jak se bude jmenovat?
                // field máme frameBowAngle ale ve staré app je face form angle
                label={t('face form angle')}
                {...fields.frameBowAngle}
                // required={menuConf.frameBowAngle}
                noMaxWidth
                errorsStyle={{ position: 'relative' }}
              />
            </Col>
          )}
          {menuConf.panto !== false && (
            <Col width="33%">
              <NumberField
                label={t('pantoscopic tilt')}
                {...fields.panto}
                // required={menuConf.panto}
                noMaxWidth
                errorsStyle={{ position: 'relative' }}
              />
            </Col>
          )}
        </Row>
      </Box.Content>
    </Box>
  )
}

IndividualSection.defaultProps = {
  lensR: {},
  lensL: {},
}

IndividualSection.propTypes = {
  fields: PropTypes.object.isRequired,
  lensR: PropTypes.object,
  lensL: PropTypes.object,
  t: PropTypes.func.isRequired,
}

const enhance = compose(
  connect(
    ({ catalog }) => ({
      lensR: catalog.currentLensR,
      lensL: catalog.currentLensL,
    }),
    {},
  ),
  withTranslation(),
)

export default enhance(IndividualSection)
