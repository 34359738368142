import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import ReactDOM from 'react-dom'
import { withTranslation } from 'react-i18next'
import { Text, Row, Col } from '.'
import Loading from './Loading'
import Gap from './Gap'

const FlatListWrapper = styled.div`
  position: relative;
`

const DefaultListWrapper = styled.div`
  position: relative;
`

const LoadingWrapper = styled.div`
  ${({ theme }) =>
    css`
      background-color: rgba(255, 255, 255, 0.9);
      /* background-color: red; */
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      /* transition: 0.3s; */
    `}
`
const LoadingWrapperInner = styled.div`
  ${({ theme }) =>
    css`
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      flex-direction: column;
      align-items: center;
    `}
`

// INFO: pokud bude pomale, zkusit implementovat react-window, https://web.dev/virtualize-long-lists-react-window/
class FlatList extends React.PureComponent {
  componentDidMount() {}

  render() {
    const {
      data,
      renderItem,
      ListEmptyComponent,
      ListHeaderComponent,
      ListWrapperComponent,
      isLoading,
      t,
    } = this.props

    const WrapperComponent = ListWrapperComponent || DefaultListWrapper

    const isEmpty = data.length === 0 && !isLoading

    return (
      <FlatListWrapper>
        {isEmpty && ListEmptyComponent && <ListEmptyComponent />}
        {data?.length > 0 && (
          <WrapperComponent>
            {ListHeaderComponent && data.length !== 0 && <ListHeaderComponent />}
            {data.map(renderItem)}
          </WrapperComponent>
        )}
        {isLoading && (
          <LoadingWrapper>
            <LoadingWrapperInner>
              <Loading />
              <Gap gap="2rem" />
              <Text center big>
                {t('omega; Loading your data from MiLens')}...
              </Text>
            </LoadingWrapperInner>
          </LoadingWrapper>
        )}
      </FlatListWrapper>
    )
  }
}

FlatList.propTypes = {}

export default withTranslation()(FlatList)
