import * as actions from './actions'
import { APP_STORAGE_LOAD } from '../app/actions'
import * as authActions from '../auth/actions'
import * as ordersActions from '../orders/actions'
import Lens from './Lens'
import { R, L, BOTH } from '../config'
import { processVca } from '../lib/vca'

const initialState = {
  lensesListR: [],
  lensesListL: [],
  lensesListIsLoadingR: false,
  lensesListIsLoadingL: false,
  shouldUpdateLensesListR: true,
  shouldUpdateLensesListL: true,

  catalogExtensions: [],

  framesList: [],
  frameListIsLoading: false,

  canSelectStockLensR: true,
  canSelectStockLensL: true,

  currentLensR: null,
  currentLensL: null,

  validatingParamsR: false,
  validatingParamsL: false,
  areParamsValidR: true,
  areParamsValidL: true,

  priceTypes: [],

  lensNotFound: false,
}

const fetchLensSelectListStart = (state, side) => ({
  ...state,
  [`lensesListIsLoading${side}`]: true,
})

const fetchLensSelectListError = (state, side) => ({
  ...state,
  [`lensesListIsLoading${side}`]: false,
})

const LensesReducer = (state = initialState, action) => {
  switch (action.type) {
    case authActions.SIGN_OUT: {
      return {
        ...initialState,
      }
    }

    case APP_STORAGE_LOAD: {
      return {
        ...state,
        shouldUpdateLensesListR: true,
        shouldUpdateLensesListL: true,
      }
    }

    case ordersActions.RESET_ORDER: {
      return {
        ...state,
        currentLensR: null,
        currentLensL: null,
        lensNotFound: false,
      }
    }

    case actions.FETCH_LENS_SELECT_LIST_R_START:
      return fetchLensSelectListStart(state, R)
    case actions.FETCH_LENS_SELECT_LIST_L_START:
      return fetchLensSelectListStart(state, L)

    case actions.FETCH_LENS_SELECT_LIST_R_ERROR:
      return fetchLensSelectListError(state, R)
    case actions.FETCH_LENS_SELECT_LIST_L_ERROR:
      return fetchLensSelectListError(state, L)

    case actions.FETCH_CATALOG_EXTENSIONS_SUCCESS:
      return {
        ...state,
        catalogExtensions: action.payload?.data || [],
      }

    case actions.VALIDATE_LENS_R_PARAMS_START:
      return {
        ...state,
        validatingParamsR: true,
      }
    case actions.VALIDATE_LENS_L_PARAMS_START:
      return {
        ...state,
        validatingParamsL: true,
      }
    case actions.VALIDATE_LENS_R_PARAMS_SUCCESS: {
      const { data } = action.payload
      return {
        ...state,
        validatingParamsR: false,
        areParamsValidR: data.result,
      }
    }
    case actions.VALIDATE_LENS_L_PARAMS_SUCCESS: {
      const { data } = action.payload
      return {
        ...state,
        validatingParamsL: false,
        areParamsValidL: data.result,
      }
    }

    case actions.VALIDATE_LENS_R_PARAMS_ERROR: {
      return {
        ...state,
        validatingParamsR: false,
        areParamsValidR: false,
      }
    }
    case actions.VALIDATE_LENS_L_PARAMS_ERROR: {
      return {
        ...state,
        validatingParamsL: false,
        areParamsValidL: false,
      }
    }

    case actions.FETCH_LENS_SELECT_LIST_R_SUCCESS: {
      const { data } = action.payload
      const lensesList = data.map(lensData => Lens(lensData))
      return {
        ...state,
        lensesListR: lensesList,
        lensesListIsLoadingR: false,
      }
    }
    case actions.FETCH_LENS_SELECT_LIST_L_SUCCESS: {
      const { data } = action.payload
      const lensesList = data.map(lensData => Lens(lensData))
      return {
        ...state,
        lensesListL: lensesList,
        lensesListIsLoadingL: false,
      }
    }

    case actions.FETCH_LENS_R_SUCCESS: {
      const { data } = action.payload
      const foundLenseFromList = state.lensesListR.find(l => l._id === data._id)
      const diameters = foundLenseFromList ? foundLenseFromList.diameters : []
      return {
        ...state,
        currentLensR: {
          ...data,
          diameters,
        },
      }
    }
    // TODO - je tu FETCH_LENS_R_SUCCESS i FETCH_LENS_SUCCESS, akce s uvedenou stranou se možná už nepoužívají nebo nevim. Prověřit!
    case actions.FETCH_LENS_L_SUCCESS: {
      const { data } = action.payload
      const foundLenseFromList = state.lensesListL.find(l => l._id === data._id)
      const diameters = foundLenseFromList ? foundLenseFromList.diameters : []
      return {
        ...state,
        currentLensL: {
          ...data,
          diameters,
        },
      }
    }
    case actions.FETCH_LENS_START: {
      return {
        ...state,
        lensNotFound: false,
      }
    }
    case actions.FETCH_LENS_SUCCESS: {
      const { data } = action.payload
      const { side } = action.meta.action
      const diameters = {
        [R]: data.diameters,
        [L]: data.diameters,
      }
      let sides = side === BOTH ? [R, L] : [side]
      let leftLensIsNotAvailable = false
      // TODO - diametry se vrátí jen když je fetch lens zavoláno s korekcemi
      // asi by to šlo sjednotit.
      if (!data.diameters) {
        sides.forEach(side => {
          const foundLenseFromList = state[`lensesList${side}`].find(l => l._id === data._id)
          diameters[side] = foundLenseFromList ? foundLenseFromList.diameters : []
          if (side === L && !foundLenseFromList) leftLensIsNotAvailable = true
        })
        // do not save left lens if it is not available in list
        if (leftLensIsNotAvailable) sides = [R]
      }

      return {
        ...state,
        ...sides.reduce((result, side) => {
          result[`currentLens${side}`] = {
            ...data,
            diameters: diameters[side],
          }
          return result
        }, {}),
      }
    }

    case actions.FETCH_LENS_ERROR: {
      const errors = action.payload
      // TODO - toto se může stát při Failed to fetch (bez internetu, nebo bez serveru)
      // možná do budoucna aspoň vyhodit notifikaci?
      if (!errors) return state
      const lensNotFound = errors.some(e => e.code === 404)

      return {
        ...state,
        lensNotFound,
      }
    }

    case actions.FETCH_FRAMES_START: {
      return {
        ...state,
        frameListIsLoading: true,
      }
    }
    case actions.FETCH_FRAMES_ERROR: {
      return {
        ...state,
        frameListIsLoading: false,
      }
    }
    case actions.FETCH_FRAMES_SUCCESS: {
      const { data } = action.payload
      return {
        ...state,
        frameListIsLoading: false,
        framesList: data.map(vca => processVca(vca)),
      }
    }

    case actions.FETCH_LENS_PRICE_L_SUCCESS: {
      const { data } = action.payload
      const currentLensL = { ...state.currentLensL, prices: data }
      return {
        ...state,
        currentLensL,
      }
    }
    case actions.FETCH_LENS_PRICE_R_SUCCESS: {
      const { data } = action.payload
      const currentLensR = { ...state.currentLensR, prices: data }
      return {
        ...state,
        currentLensR,
      }
    }

    case actions.CLEAR_CURRENT_LENS: {
      const { side } = action.payload
      return {
        ...state,
        [`currentLens${side}`]: null,
      }
    }

    case actions.FETCH_PRICE_TYPES_SUCCESS: {
      const { data } = action.payload
      return {
        ...state,
        priceTypes: data,
      }
    }

    case actions.SET_SHOULD_UPDATE_LENSES_LIST: {
      const { side, value } = action.payload
      return {
        ...state,
        [`shouldUpdateLensesList${side}`]: value,
      }
    }

    case actions.SET_CAN_SELECT_STOCK_LENS: {
      const { side, value } = action.payload
      return {
        ...state,
        [`canSelectStockLens${side}`]: value,
      }
    }

    case actions.RESET_CATALOG: {
      return {
        ...initialState,
      }
    }

    default:
      return state
  }
}

export default LensesReducer
