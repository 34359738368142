import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import styled from 'styled-components'
import { withTranslation } from 'react-i18next'

import i18n from '../../../common/i18n/i18n'
import { fields } from '../../../common/lib/redux-fields'
import { TextField, CheckboxField, SelectField, SwitchField, NumberField } from '../fields'
import { FieldsGroup } from '.'
import { Box, Row, Col, Button, Gap, InfoMessage, Link } from '..'
import {
  setTracerConfig,
  createTracer,
  editTracer,
  fetchTracers,
  deleteTracer,
} from '../../../common/config/actions'
import { getTracerSettingsKeys, State, tracerDefaultConfig } from '../../../common/config/reducer'
import {
  tracers as defaultTracers,
  options,
  allTracerConfigKeys,
} from '../../../common/config/tracerConfig'
import { appTypeConfig } from '../../../common/config'
import Validation from '../../../common/lib/validation'
import { withNotifs } from '../hoc'
import { validateRequired } from '../../../common/validationHelpers'
import Text from '../Text'
import { invalidFormData } from '../../../server/lib/errors'

const OS = require('browser-os')()

const usbOrLanDisabled = ['baud', 'flow', 'parity', 'data', 'stop', 'port']

const Wrapper = styled.div`
  padding: 0 2rem 2rem;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
`

class SettingsTracerForm extends Component {
  state = {
    showBridgeSettings: false,
  }

  componentDidMount() {
    this.props.fields.$resetToInitial()
  }

  componentWillUnmount() {
    this.props.fields.$reset()
  }

  submitForm = async () => {
    const { fields, notifs, setTracerConfig, t, onDone, editingId } = this.props
    const { createTracer, editTracer } = this.props

    const values = fields.$values()

    // remove whitespace from port, whitespace is never in port name
    values.port = values.port.replace(/\s/g, '')
    if (OS.includes('Windows')) {
      // on windows, transform port to uppercase, it is always uppercase on windows
      values.port = values.port.toUpperCase()
    }

    // if some value is function, run it first (e.g. urlName)
    Object.keys(values).forEach(key => {
      if (typeof values[key] === 'function') {
        values[key] = values[key](values)
      }
    })

    fields.$validate()
    if (!fields.$isValid()) {
      notifs.error({ title: t('error'), message: t('Tracer settings failed') })
      return
    }

    try {
      if (!editingId) {
        await createTracer(fields.$values()).meta.action.payload
        notifs.success({ title: t('saving success') })
        fields.$reset()
        onDone()
      } else {
        await editTracer(editingId, fields.$values()).meta.action.payload
        notifs.success({ title: t('saving success') })
        fields.$reset()
        onDone()
      }
    } catch (e) {
      notifs.error({ title: t('saving failed') })
    }
  }

  deleteTracer = async _id => {
    const { deleteTracer, notifs, t } = this.props

    try {
      await deleteTracer(_id).meta.action.payload
      notifs.success({ title: t('delete success') })
    } catch (e) {
      notifs.error({ title: t('delete error') })
    }
  }

  render() {
    let { fields, t, onCancel, editingId } = this.props
    const { showBridgeSettings } = this.state

    // do not mutate
    fields = { ...fields }

    Object.keys(fields)
      .filter(k => !k.startsWith('$'))
      .forEach(key => {
        // console.log('key', key, fields[key])
        // fields[key].onChangeOriginal = fields[key].onChangeOriginal || fields[key].onChange
        // fields[key].onChange = (e, event) => {
        //   fields[key].onChangeOriginal(e, event)
        //   this.setState({ changed: true })
        // }
        if (usbOrLanDisabled.includes(key) && (fields.lan.value || fields.usb.value)) {
          console.log('key', key, true)

          // do not mutate
          fields[key] = {
            ...fields[key],
            disabled: true,
          }
        }
        if (key === 'port' && !fields[key].disabled) {
          fields[key] = {
            ...fields[key],
            required: true,
          }
        }
      })

    return (
      <Wrapper>
        {/* <Row>
          <CheckboxField label={t('i have a tracer')} {...fields.tracerEnabled} />
        </Row> */}

        <Col marginRight="0">
          <TextField
            grow="1"
            label={t('Your tracer name')}
            tooltip={t('Give your tracer unique name, so you can recognise it later')}
            {...fields.usersName}
          />
          <Gap gap="2rem" />

          <Row alignItems="flex-end">
            <SelectField
              {...fields.tracerKey}
              grow="1"
              flexBasis="0"
              required
              maxWidth="20px"
              label={t('brand and model')}
              tooltip={t('brand and model - tooltip')}
              options={Object.keys(defaultTracers).map(k => ({
                value: k,
                text: defaultTracers[k].name,
                label: defaultTracers[k].name,
              }))}
              maxMenuHeight="250px"
              onChange={e => {
                console.log('eeee', e)
                fields.tracerKey.onChange(e)

                const selectedDefaults = defaultTracers[e.value]
                console.log('fields.tracerKey.onChange(e)', e, fields.tracerKey, selectedDefaults)

                if (selectedDefaults) {
                  allTracerConfigKeys.forEach(k => {
                    fields.$setValue(
                      k,
                      typeof selectedDefaults[k] !== 'undefined'
                        ? selectedDefaults[k]
                        : tracerDefaultConfig[k],
                    )
                  })
                  // if it's USB or LAN, reset COM port
                  if (selectedDefaults.lan || selectedDefaults.usb) {
                    fields.$setValue('port', '')
                  }
                }
              }}
            />
            <Gap gap="2rem" />

            <Col>
              <CheckboxField label="VCA / OMA" {...fields.vca} disabled={!fields.showVca.value} />
              <Gap gap="2px" />
            </Col>
          </Row>

          <Gap />
          <Row alignItems="flex-end">
            <TextField
              grow="1"
              flexBasis="0"
              label="COM port"
              tooltip={t('COM port - tooltip')}
              {...fields.port}
            />
            <Gap gap="2rem" />
            <SelectField
              grow="1"
              flexBasis="0"
              label={t("Flow")}
              options={options.flow}
              {...fields.flow}
            />
            <Gap gap="2rem" />
            <SelectField
              flexBasis="0"
              grow="1"
              label={t("Baud")}
              options={options.baud}
              {...fields.baud}
            />
          </Row>
          <Gap />
          <Row>
            <SelectField grow="1" label="Parity" options={options.parity} {...fields.parity} />
            <Gap gap="2rem" />
            <SelectField grow="1" label="Data bit" options={options.data} {...fields.data} />
            <Gap gap="2rem" />
            <SelectField grow="1" label="Stop bit" options={options.stop} {...fields.stop} />
          </Row>
          <Gap gap="1.6rem" />
          <Row alignItems="baseline">
            <Text bold>{t('Advanced')}:</Text>
            <Gap />

            {!showBridgeSettings && (
              <Button
                onClick={() => {
                  this.setState(state => ({ showBridgeSettings: !state.showBridgeSettings }))
                }}
              >
                {t('Edit bridge IP address (not recommended)')}
              </Button>
            )}
            {showBridgeSettings && (
              <>
                <TextField
                  grow="0.5"
                  tooltip={t('bridge ip address - tooltip')}
                  label={t('bridge ip address')}
                  {...fields.bridgeIp}
                />
              </>
            )}
          </Row>

          <Gap />
          <Gap />
        </Col>

        <Row justifyContent="center">
          <Button primary onClick={this.submitForm} alignSelf="flex-start">
            {t('Save tracer')}
          </Button>
          <Gap />

          <Button onClick={onCancel} alignSelf="flex-start">
            {t('Cancel')}
          </Button>
        </Row>
      </Wrapper>
    )
  }
}

SettingsTracerForm.propTypes = {
  fields: PropTypes.object.isRequired,
  notifs: PropTypes.object.isRequired,
  setTracerConfig: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
}

const enhance = compose(
  connect(
    state => ({
      tracer: state.config.tracer,
      tracers: state.config.tracers,
      bridgeIp: state.config.bridgeIp,
      tracerKey: state.config.tracerKey,
      tracerEnabled: state.app.viewer.tracerEnabled,
      port: state.config.port,
    }),
    {
      setTracerConfig,
      fetchTracers,
      createTracer,
      editTracer,
      deleteTracer,
    },
  ),
  withTranslation(),
  fields({
    path: 'SettingsTracerForm',
    fields: [...getTracerSettingsKeys(), 'usersName'],
    getInitialState: ({ tracer, tracers, editingId, bridgeIp, tracerKey, ...rest }) => {
      // console.log('getting initial state', editingId)
      const selectedTracer = tracers.find(t => t._id === editingId)
      return {
        ...tracerDefaultConfig,
        ...selectedTracer,
        // ...rest,
        // ...tracer,
        // bridgeIp: bridgeIp || '127.0.0.1:33333',
        // tracerKey: tracerKey || 'fileOnly',
      }
    },
    validationFunc: ({ dataToValidate, allData, props: { allTracers, editingId, t } }) => {
      const validator = new Validation(dataToValidate)

      validateRequired({
        validator,
        translationKey: 'brand and model',
        fieldName: 'tracerKey',
      })

      if (!dataToValidate.usb && !dataToValidate.lan) {
        validateRequired({
          validator,
          translationKey: 'COM port',
          fieldName: 'port',
        })
      }

      validator.addCondition(data => {
        const { usersName } = data

        if (!usersName) {
          // we allow empty name
          return null
        }

        const allNames = allTracers
          .filter(tracer => tracer._id !== editingId)
          .map(tracer => tracer.usersName)

        if (allNames.includes(usersName)) {
          return invalidFormData({
            message: t('Tracer name has to be unique'),
            detail: {
              field: 'usersName',
              currentValue: usersName,
            },
          })
        }

        return null
      })

      return validator.validate()
    },
  }),
  withNotifs,
)

export default enhance(SettingsTracerForm)
