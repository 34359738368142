import React from 'react'
import Icon from 'react-icon-base'

const Stock = props => (
  <Icon viewBox="0 0 25 26" {...props}>
    <circle cx="12.5" cy="12.5" r="12.5" transform="translate(0 0.333252)" fill="#FF96B8" />
    <path d="M17.1907 1.32733C17.0797 1.16804 16.8918 1.09628 16.7078 1.12378C16.7008 1.1221 16.6938 1.11908 16.6868 1.11807L9.15387 0.00542099C9.07156 -0.00698654 8.98759 0.00206767 8.90927 0.0309068L2.87593 2.25621C2.87327 2.25722 2.87093 2.2589 2.86827 2.2599C2.86193 2.26259 2.85594 2.26661 2.84927 2.26896C2.81628 2.28371 2.78562 2.30115 2.7573 2.3216C2.74863 2.32798 2.7403 2.33435 2.73164 2.34139C2.70065 2.36688 2.67232 2.39471 2.64866 2.42657C2.64733 2.42858 2.64533 2.42958 2.64399 2.4316L0.096361 5.93857C-0.00127889 6.07304 -0.0266053 6.24808 0.0290461 6.40502C0.0846975 6.5623 0.214329 6.68101 0.374951 6.72259L2.58568 7.29702V12.7969C2.58568 13.0179 2.7293 13.2134 2.93958 13.2781L11.034 15.767C11.0367 15.7683 11.0397 15.7673 11.0424 15.7687C11.0857 15.7808 11.131 15.7885 11.1763 15.7888C11.1773 15.7888 11.1787 15.7895 11.18 15.7895H11.1803C11.228 15.7895 11.2746 15.7808 11.3213 15.767C11.3359 15.7623 11.3496 15.7559 11.3646 15.7496C11.3843 15.7422 11.4036 15.7368 11.4229 15.7268L17.2313 12.483C17.3903 12.3942 17.4886 12.2258 17.4886 12.0431V6.54419L19.4534 5.59518C19.5857 5.53147 19.6833 5.41175 19.7206 5.2689C19.7576 5.12571 19.7306 4.9738 19.6463 4.85274L17.1907 1.32733ZM9.13421 1.01948L15.0139 1.88801L11.1137 3.71292L4.93737 2.56774L9.13421 1.01948ZM3.26616 3.28067L10.3432 4.59319L8.26312 7.7333L1.33835 5.93421L3.26616 3.28067ZM3.5854 12.4247V7.55657L8.35676 8.79632C8.39841 8.80672 8.44007 8.81242 8.48172 8.81242C8.64601 8.81242 8.8033 8.7306 8.89761 8.58841L10.6801 5.89799V14.6061L3.5854 12.4247ZM16.4885 11.7466L11.6795 14.4317V6.48651L12.5576 8.36407C12.6146 8.48513 12.7172 8.57902 12.8422 8.62329C12.8965 8.64341 12.9532 8.65246 13.0095 8.65246C13.0838 8.65246 13.1574 8.63569 13.2258 8.6035L16.4879 7.02808L16.4885 11.7466ZM13.2481 7.4771L11.8461 4.47984L16.6122 2.24984L18.4896 4.94496L13.2481 7.4771Z" transform="translate(2.63184 5.59644)" fill="white" />
  </Icon>
)

export default Stock
