import React from 'react'
import Icon from 'react-icon-base'

const Driller = props => (
  <Icon viewBox="-2 -3 23 23" {...props}>
    <path d="M19.9322 3.325V6.65H16.6102V9.975H6.64407V16.625H3.32203V9.975H0V0H16.6102V3.325H19.9322Z" transform="translate(0.490234 0.75)" />
  </Icon>
)

export default Driller
