import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { Input, Label, Errors, Warning, Wrapper, TextArea } from './components'
import Icon from '../Icon'
import { withMemoize } from '.'

const IconWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0.1rem;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

const SIcon = styled(Icon)`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const InputAndIconWrapper = styled.div`
  ${({ isRelative }) => css`
    position: ${isRelative && 'relative'};
  `}
`

class TextField extends React.PureComponent {
  render() {
    const {
      disabled,
      errors,
      label,
      onBlur,
      onChange,
      required,
      type,
      name,
      value,
      className,
      tooltip,
      warning,
      multiline,
      icon,
      onIconClick = () => {},
      grow,
      flexBasis,
      iconSize = 26,
      ...props
    } = this.props

    const InputComponent = multiline ? TextArea : Input

    return (
      <Wrapper grow={grow} className={className} flexBasis={flexBasis}>
        {label && (
          <Label htmlFor={name} required={required} tooltip={tooltip}>
            {label}
          </Label>
        )}
        <InputAndIconWrapper isRelative={!!icon}>
          <InputComponent
            id={name}
            disabled={disabled}
            name={name}
            onChange={e => onChange(e.target)}
            required={required || undefined}
            type={type}
            value={value}
            onBlur={onBlur}
            isValid={!errors || errors.length === 0}
            {...props}
          />
          {!!icon && (
            <IconWrapper onClick={onIconClick}>
              {/* this is only used in search on homepage for now... */}
              <SIcon name={icon} size={iconSize} color={value ? '#656565' : 'lightgray'} />
            </IconWrapper>
          )}
        </InputAndIconWrapper>
        {!!errors && <Errors errors={errors} />}
        {!!warning && <Warning text={warning} />}
      </Wrapper>
    )
  }
}

TextField.defaultProps = {
  disabled: false,
  errors: null,
  warning: '',
  className: '',
  onBlur: () => {},
  required: false,
  type: 'text',
  value: '',
  tooltip: '',
  multiline: false,
  label: '',
  icon: null,
  grow: undefined,
  flexBasis: undefined,
}

TextField.propTypes = {
  disabled: PropTypes.bool,
  errors: PropTypes.array,
  warning: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  type: PropTypes.string,
  value: PropTypes.string,
  tooltip: PropTypes.string,
  grow: PropTypes.string,
  flexBasis: PropTypes.string,
  multiline: PropTypes.bool,
  icon: PropTypes.string,
}

export default withMemoize(TextField)
