export const roundNum = (num, places = 2) => Math.round(num * 10 ** places) / 10 ** places
export const ceilNum = (num, places = 2) => Math.ceil(num * 10 ** places) / 10 ** places

export const consoleEnhance = () => {
  // const { IS_BROWSER, SERVER_ENV } = process.env // cannot be used in webpack

  console.log('process.env.IS_BROWSER', process.env.IS_BROWSER)
  console.log('process.env.SERVER_ENV', process.env.SERVER_ENV)

  console.dev = (...args) => {
    if (
      (process.env.IS_BROWSER && window.SERVER_ENV !== 'prod') ||
      (!process.env.IS_BROWSER && process.env.SERVER_ENV !== 'prod')
    ) {
      console.log(...args)
    }
  }
}

export function getSearchParams() {
  const prmstr = window.location.search.substr(1)
  return prmstr != null && prmstr != '' ? transformToAssocArray(prmstr) : {}
}

function transformToAssocArray(prmstr) {
  const params = {}
  const prmarr = prmstr.split('&')
  for (let i = 0; i < prmarr.length; i++) {
    const tmparr = prmarr[i].split('=')
    params[tmparr[0]] = tmparr[1]
  }
  return params
}

export function setSearchParam(key, value) {
  key = encodeURI(key)
  value = encodeURI(value)

  const kvp = document.location.search.substr(1).split('&')

  let i = kvp.length
  let x
  while (i--) {
    x = kvp[i].split('=')

    if (x[0] == key) {
      x[1] = value
      kvp[i] = x.join('=')
      break
    }
  }

  if (i < 0) {
    kvp[kvp.length] = [key, value].join('=')
  }

  // this will reload the page, it's likely better to store this until finished
  document.location.search = kvp.join('&')
}
