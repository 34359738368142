import { useState, useRef, useEffect } from 'react'

export default function useIsSticky(bottom, bottomOffset = 0) {
  const [isSticky, setIsSticky] = useState(false)
  const ref = useRef(null)
  const positionerRef = useRef(null)

  useEffect(() => {
    const calculateIsSticky = () => {
      if (ref.current) {
        if (bottom) {
          // console.log(
          //   'ref.current.getBoundingClientRect().bottom === window.innerHeight',
          //   ref.current.getBoundingClientRect().bottom,
          //   window.innerHeight + bottomOffset,
          // )
          setIsSticky(
            ref.current.getBoundingClientRect().bottom >= window.innerHeight + bottomOffset,
          )
        } else {
          setIsSticky(ref.current.getBoundingClientRect().top <= 0)
        }
      }
    }
    window.addEventListener('scroll', calculateIsSticky)
    window.addEventListener('resize', calculateIsSticky)

    calculateIsSticky()

    return () => {
      window.removeEventListener('scroll', calculateIsSticky)
      window.removeEventListener('resize', calculateIsSticky)
    }
  }, [bottom, bottomOffset])

  return {
    isSticky,
    ref,
    positionerRef,
  }
}
