import React, { useRef, useState } from 'react'
import styled, { useTheme } from 'styled-components'
import { bgImages } from './popups/FusionPopup'

const frameShapePath =
  'M391 5.49998C236.6 -4.50002 96 6 54 26.5C10.5 46 4.49999 87 1.49999 116C-0.786129 138.099 6.16667 221 13 261.5C25.5 322.333 42.5 434.5 85 462.5C111.352 479.861 199.5 493.667 240 498.5C308.5 505.167 463.9 510.9 537.5 480.5C629.5 442.5 662.526 268.302 681 170.5C698 80.5 652.167 56.3333 627 46C612.667 36.6666 545.4 15.5 391 5.49998Z'
const frameShape = new Path2D(frameShapePath)

const SourceImage = styled.img`
  display: none;
`

const CanvasesWrapper = styled.div`
  position: relative;
  width: 720px;
  height: 540px;
  overflow: hidden;
  border-radius: 6px;
`

const Canvas = styled.canvas`
  position: absolute;
  width: 100%;
  height: 100%;
  transform: scale(1.01);
`

function MilensVisualiser({
  softWide,
  farNear,
  showLines,
  defaultFarNear,
  defaultSoftWide,
  activeBgImage,
}) {
  const canvas = useRef(null)
  const imgCanvas = useRef(null)
  const shapeCanvas = useRef(null)
  const images = {
    outdoor: useRef(),
    driving: useRef(),
    golf: useRef(),
    pc: useRef(),
  }

  const [imgLoaded, setImgLoaded] = useState(false)

  const { primary } = useTheme().colors

  drawCanvas({
    canvas: canvas.current,
    imgCanvas: imgCanvas.current,
    shapeCanvas: shapeCanvas.current,
    image: images[activeBgImage]?.current,
    drawLines: showLines,
    softWide,
    farNear,
    defaultFarNear,
    defaultSoftWide,
    primaryColor: primary,
  })

  // console.log('activeBgImage', activeBgImage, images, imgLoaded)
  return (
    <div>
      <CanvasesWrapper>
        <Canvas ref={imgCanvas} width="720" height="540" />
        <Canvas ref={canvas} width="720" height="540" />
        <Canvas ref={shapeCanvas} width="720" height="540" />
      </CanvasesWrapper>

      <div>
        {bgImages.map(imageKey => (
          <SourceImage
            onLoad={() => {
              // console.log('loaded')
              setImgLoaded(true)
            }}
            ref={images[imageKey]}
            src={`/public/images/milens/3-${imageKey}.png`}
            key={imageKey}
          />
        ))}
      </div>
    </div>
  )
}

export default MilensVisualiser

function drawCanvas({
  canvas,
  imgCanvas,
  shapeCanvas,
  softWide,
  defaultSoftWide,
  defaultFarNear,
  farNear,
  image,
  drawLines,
  primaryColor,
}) {
  if (!canvas || !imgCanvas || !image || !shapeCanvas) {
    return
  }

  const ctx = canvas.getContext('2d')
  const iCtx = imgCanvas.getContext('2d')
  const shapeCtx = shapeCanvas.getContext('2d')
  if (!ctx || !iCtx || !shapeCtx) {
    return
  }

  function drawImage(dest = ctx) {
    dest.drawImage(image, 0, 0, canvas.width, (canvas.width * image.height) / image.width)
  }

  ctx.save()

  const W = canvas.width
  const H = canvas.height
  const tempratio = farNear / 10
  const temphard = softWide / 10 - 6
  const v = 1.5 // scaling factor
  const cor = 50 // how much it is close to center

  drawLeftSide({ ctx, cor, tempratio, temphard, v, W, H })
  drawRightSide({ ctx, cor, tempratio, temphard, v, W, H })

  let blur = (softWide + 20) / 20 // no it is from 0 to 6
  blur += 3 // add initial blur
  // create blur effect
  // set filter to all next canvas draws
  ctx.filter = `blur(${blur}px)`
  // draw full blurred image
  drawImage()

  // The existing canvas content is kept where both the new shape and existing
  // canvas content overlap. Everything else is made transparent.
  ctx.globalCompositeOperation = 'destination-in'
  ctx.fillStyle = '#000000'
  // this fills our shape with black --> and thanks to composite operation
  // only this part of image remains visible
  ctx.fill()

  // reset blur to zero
  ctx.filter = 'none'
  // draw beneath all we have now
  ctx.globalCompositeOperation = 'destination-over'
  // draw image again
  drawImage()

  // reset composition to default value
  ctx.globalCompositeOperation = 'source-over'
  ctx.restore()

  // default lines
  if (drawLines) {
    ctx.save()
    const tempratio = defaultFarNear / 10
    const temphard = defaultSoftWide / 10 - 6

    drawLeftSide({ ctx, cor, tempratio, temphard, v, W, H })
    drawRightSide({ ctx, cor, tempratio, temphard, v, W, H })

    ctx.strokeStyle = primaryColor
    ctx.lineWidth = 3
    ctx.setLineDash([10, 4])
    ctx.stroke()
    ctx.setLineDash([]) // reset
    ctx.restore()
  }

  // user defined lines
  if (drawLines) {
    ctx.save()
    const tempratio = farNear / 10
    const temphard = softWide / 10 - 6

    drawLeftSide({ ctx, cor, tempratio, temphard, v, W, H })
    drawRightSide({ ctx, cor, tempratio, temphard, v, W, H })

    ctx.strokeStyle = 'white'
    ctx.lineWidth = 3
    ctx.setLineDash([10, 4])
    ctx.stroke()
    ctx.setLineDash([]) // reset
    ctx.restore()
  }

  // draw frame shape and outside blur
  shapeCtx.save()
  shapeCtx.filter = 'blur(6px)'
  drawImage(shapeCtx)

  shapeCtx.translate(18, 18)
  shapeCtx.filter = 'none'
  shapeCtx.globalCompositeOperation = 'destination-out'
  shapeCtx.fillStyle = '#000000'
  shapeCtx.fill(frameShape)
  shapeCtx.globalCompositeOperation = 'destination-over'
  shapeCtx.strokeStyle = 'white'
  shapeCtx.lineWidth = 3
  shapeCtx.stroke(frameShape)
  shapeCtx.restore()
}

// left side
function drawLeftSide({ ctx, cor, tempratio, temphard, v, W, H }) {
  function drawCurve(x1, y1, x2, y2, x3, y3) {
    ctx.bezierCurveTo(x1 - cor * v, y1, x2 - cor * v, y2, x3 - cor * v, y3)
  }

  ctx.beginPath()
  const startPoint = [-3 * v, (150 - 5 * tempratio + 2 * temphard) * v]
  ctx.moveTo(startPoint[0], startPoint[1])

  let x1 = 70 * v
  let y1 = (145 - 3.5 * tempratio + 2 * temphard) * v
  let x2 = 125 * v
  let y2 = (150 - 2 * tempratio + temphard) * v
  let x3 = 170 * v
  let y3 = (165 - 1 * tempratio + temphard) * v
  // drawing first curve
  // Path1->Data->CurveTo(TPointF(x1-cor*v,y1),TPointF(x2-cor*v,y2),TPointF(x3-cor*v,y3));
  drawCurve(x1, y1, x2, y2, x3, y3)

  x1 = 196 * v
  y1 = (178 - 0.5 * tempratio + 0.3 * temphard) * v
  x2 = 215 * v
  y2 = (200 - 0.5 * tempratio + 0.3 * temphard) * v
  x3 = 209 * v
  y3 = (226 - 0.5 * tempratio + 0.3 * temphard) * v
  // drawing second curve
  // Path1->Data->CurveTo(TPointF(x1-cor*v,y1),TPointF(x2-cor*v,y2),TPointF(x3-cor*v,y3));
  drawCurve(x1, y1, x2, y2, x3, y3)

  x1 = (202 - 1 * tempratio - 0.5 * temphard) * v
  y1 = 248 * v
  x2 = (192 - 2 * tempratio - 0.5 * temphard) * v
  y2 = 268 * v
  x3 = (180 - 2 * tempratio - 0.5 * temphard) * v
  y3 = 290 * v
  // drawing third curve
  // Path1->Data->CurveTo(TPointF(x1-cor*v,y1),TPointF(x2-cor*v,y2),TPointF(x3-cor*v,y3));
  drawCurve(x1, y1, x2, y2, x3, y3)

  x1 = (176 - 2 * tempratio - 0.5 * temphard) * v
  y1 = 298 * v
  x2 = (160 - 2 * tempratio - 0.5 * temphard) * v
  y2 = 330 * v
  x3 = (170 - 3 * tempratio - 0.5 * temphard) * v
  y3 = (H + 3) * v
  // drawing fourth curve
  // Path1->Data->CurveTo(TPointF(x1-cor*v,y1),TPointF(x2-cor*v,y2),TPointF(x3-cor*v,y3));
  drawCurve(x1, y1, x2, y2, x3, y3)
  // Path1->Data->LineTo(TPointF(-3*v,(H + 3)*v));
  ctx.lineTo(-3 * v, (H + 3) * v)
  ctx.closePath()
}

// right side

function drawRightSide({ ctx, cor, tempratio, temphard, v, W, H }) {
  function drawCurve(x1, y1, x2, y2, x3, y3) {
    ctx.bezierCurveTo(x1 + cor * v, y1, x2 + cor * v, y2, x3 + cor * v, y3)
  }

  const startPoint = [(W + 3) * v, (180 - 5 * tempratio + 2 * temphard) * v]
  ctx.moveTo(startPoint[0], startPoint[1])

  let x1 = 411 * v
  let y1 = (165 - 3.5 * tempratio + 2 * temphard) * v
  let x2 = 350 * v
  let y2 = (160 - 1 * tempratio + 0.3 * temphard) * v
  let x3 = 325 * v
  let y3 = (173 - 1 * tempratio + 0.3 * temphard) * v
  drawCurve(x1, y1, x2, y2, x3, y3, true)

  // drawing first curve Path1->Data->CurveTo(TPointF(x1+cor*v,y1),TPointF(x2+cor*v,y2),TPointF(x3+cor*v,y3));
  x1 = 310 * v
  y1 = (176 - 0.5 * tempratio + 0.3 * temphard) * v
  x2 = 295 * v
  y2 = (195 - 0.5 * tempratio + 0.3 * temphard) * v
  x3 = 292 * v
  y3 = (228 - 0.5 * tempratio + temphard) * v

  drawCurve(x1, y1, x2, y2, x3, y3, true)

  // drawing third curve Path1->Data->CurveTo(TPointF(x1+cor*v,y1),TPointF(x2+cor*v,y2),TPointF(x3+cor*v,y3));
  x1 = (296 + 1 * tempratio + 0.5 * temphard) * v
  y1 = 248 * v
  x2 = (310 + 2 * tempratio + 0.5 * temphard) * v
  y2 = 274 * v
  x3 = (323 + 2 * tempratio + 0.5 * temphard) * v
  y3 = 294 * v
  drawCurve(x1, y1, x2, y2, x3, y3, true)

  // drawing third curve Path1->Data->CurveTo(TPointF(x1+cor*v,y1),TPointF(x2+cor*v,y2),TPointF(x3+cor*v,y3));
  x1 = (330 + 2 * tempratio + temphard) * v
  y1 = 307 * v
  x2 = (350 + 2 * tempratio + temphard) * v
  y2 = 328 * v
  x3 = (335 + 3 * tempratio + temphard) * v
  y3 = (H + 3) * v
  drawCurve(x1, y1, x2, y2, x3, y3, true)

  ctx.lineTo((W + 3) * v, (H + 3) * v)

  ctx.closePath()
}
