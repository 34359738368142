import React from 'react'
import PropTypes from 'prop-types'
import styled, { css, keyframes } from 'styled-components'

const smallPopoverKeyframes = keyframes`
 0%{
    opacity: 0;
    transform:  translate3d(0,0,0) scale(0.3);
    /* border-radius: 1000px; */
  }

  60%{
    /* opacity: 0.9; */
    transform:  scale(1.08);
    border-radius: 0.8rem;
    opacity: 1;


  }
  100%{
    opacity: 1;

    transform: translate3d(0,0,0)  scale(1);
  }
`

const bigPopoverKeyframes = keyframes`
 0%{

    opacity: 0;
    transform:  translate3d(0,0,0) scale(0.1);
    /* border-radius: 1000px; */
  }

  60%{
    opacity: 1;
  }
  70%{

    transform:  scale(1.04);
    /* transform:  scale(0.1); */
  }
  100%{

    opacity: 1;
    transform: translate3d(0,0,0)  scale(1);
  }
`

export const Wrapper = styled.div`
  ${({ animationVariant = 'small', animationOrigin = 'right top' }) => css`
    min-width: 10rem;
    max-width: 20rem;
    max-width: ${p => p.maxWidth};
    /* min-width: ${props => props.width || (props.type === 'small' ? 60 : 102)}rem; */
    /* min-height: 10rem; */
    /* max-height: 95vh; */
    background: white;

    top: 0;
    right: 0;
    position: absolute;
    /* transform: translate(-50%, 0); */
    display: flex;
    flex-direction: column;
    /* justify-content: stretch; */
    align-items: stretch;
    z-index: 100;
    border-radius: 0.8rem;
    box-shadow: 0px 2px 20px rgb(0 0 0 / 30%);
    overflow: hidden;

    transform-origin: ${animationOrigin};
    opacity: 0;
    animation-duration: ${animationVariant === 'small' ? '350ms' : '300ms'};
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
    animation-name: ${animationVariant === 'small' ? smallPopoverKeyframes : bigPopoverKeyframes};

    /* @supports (backdrop-filter: none) or (-webkit-backdrop-filter: none) {
      background: rgba(255, 255, 255, 0.5);
      backdrop-filter: blur(13px);
    } */
  `}
`

const Anchor = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`

export const Item = styled.div`
  text-align: 'center';
  cursor: pointer;
  padding: 1rem 1.2rem;
  flex-grow: 1;
  flex-shrink: 0;
  transition: 0.1s;
  white-space: nowrap;

  ${({ theme: { colors }, unclickable }) => css`
    cursor: ${unclickable ? 'auto' : 'pointer'};
    border-bottom: solid 1px ${colors.groupBorder};
    &:hover {
      background: ${!unclickable && colors.groupBorder};
      /* color: white; */
    }
  `}

  &:first-child {
    padding-top: 1.5rem;
  }
  &:last-child {
    padding-bottom: 1.5rem;
    /* border: none; */
  }
`

const PopoverPresentational = ({
  close,
  options,
  onSelect,
  selectDisabled,
  renderItem,
  WrapperComponent = Wrapper,
  animationVariant,
  animationOrigin,
}) => {
  const closeOk = (e, o) => {
    e.preventDefault()
    e.stopPropagation()
    if (!selectDisabled) {
      onSelect({ ...o })
      close()
    }
  }

  const renderItemFunc =
    renderItem ||
    (o => (
      <Item key={o.key || o._id} onClick={e => closeOk(e, o)}>
        {o.title || o.key}
      </Item>
    ))

  return (
    <Anchor>
      <WrapperComponent
        name="popover-menu-wrapper"
        animationOrigin={animationOrigin}
        animationVariant={animationVariant}
      >
        {options && Array.isArray(options) && options.map(renderItemFunc)}
      </WrapperComponent>
    </Anchor>
  )
}

PopoverPresentational.defaultProps = {
  selectDisabled: false,
  renderItem: undefined,
  WrapperComponent: undefined,
}

PopoverPresentational.propTypes = {
  close: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  renderItem: PropTypes.func,
  options: PropTypes.array.isRequired,
  selectDisabled: PropTypes.bool,
  WrapperComponent: PropTypes.node,
}

export default PopoverPresentational
