import React from 'react'
import { components as selectComponents } from 'react-select'
import styled from 'styled-components'
import { PopTooltip } from '../../Tooltip'
import { BrandsIconsList } from '../../order/FrameBrandIcons'

const OptionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const ValueWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

// can override default select option component and implement additional features
// as tooltip, etc.
export const SelectOption = ({ children, ...rest }) => {
  const { data } = rest || {}
  const item = data?.tooltipText ? (
    <PopTooltip text={data?.tooltipText} tooltipStyle={{ width: '100%' }}>
      {children}
    </PopTooltip>
  ) 
    : children
  

  return (
    <selectComponents.Option {...rest}>
      <OptionWrapper>
        <div>{item}</div>
        <BrandsIconsList brands={data?.compatibleFrameBrands} />
      </OptionWrapper>
    </selectComponents.Option>
  )
}

export const SelectValue = ({ children, ...rest }) => {
  const { data } = rest || {}
  return (
    <selectComponents.SingleValue {...rest}>
      <ValueWrapper>
        <div style={{ paddingRight: '4px' }}>{children}</div>
        <BrandsIconsList brands={data?.compatibleFrameBrands} />
      </ValueWrapper>
    </selectComponents.SingleValue>
  )
}

