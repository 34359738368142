import React from 'react'
import ReactSlider from 'react-slider'
import styled, { css } from 'styled-components'
import Col from './Col'
import Gap from './Gap'
import Row from './Row'
import Text from './Text'

const StyledSlider = styled(ReactSlider)`
  ${({ orientation }) => css`
    ${orientation === 'horizontal' &&
    css`
      height: 26px;
      width: 200px;
    `}
    ${orientation === 'vertical' &&
    css`
      height: 200px;
      width: 26px;
    `}
    position: relative;
    /* If slider's movement is too quick --> disable popup initial open animation! */
  `}
`

const StyledThumb = styled.div`
  ${({ theme: { colors } }) => css`
    height: 24px;
    line-height: 24px;
    width: 24px;
    text-align: center;
    background-color: ${colors.primary};
    background-color: white;
    box-shadow: 0px 0 2px 1px rgb(0 0 0 / 30%);

    color: #fff;
    border-radius: 50%;
    cursor: grab;
    transform: translateX(-50%);
    left: 50%;
    transition: 0.3s box-shadow;

    &:hover {
      box-shadow: 0px 0 7px 1px rgb(0 0 0 / 30%);
    }
    &:active {
      box-shadow: 0px 0 7px 3px rgb(0 0 0 / 30%);
    }
  `}
`
// you can print value here via state.valueNow
const Thumb = (props, state) => <StyledThumb {...props} />

const StyledTrack = styled.div`
  ${({ theme: { colors }, index }) => css`
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 999px;

    /* background: ${index === 0 && 'white'}; */
    background: #ebebeb;
    border: solid 1px #dbdbdb;

    ${index === 0 &&
    css`
      /* border-radius: 999px 999px 0 0;
      border-bottom: none; */
    `}

    ${index === 1 &&
    css`
      /* border-top: none;
      border-radius: 0 0 999px 999px; */
    `}
  `}
`

const Track = (props, state) => <StyledTrack {...props} index={state.index} />

function SliderRange({ minLabel, maxLabel, orientation = 'horizontal', ...props }) {
  const Wrapper = orientation === 'vertical' ? Col : Row
  // TODO: so far this is tested only for vertical

  return (
    <Wrapper alignItems="center">
      {minLabel && (
        <>
          <Text>{minLabel}</Text>
          <Gap gap="8px" />
        </>
      )}
      <StyledSlider renderTrack={Track} renderThumb={Thumb} orientation={orientation} {...props} />
      {maxLabel && (
        <>
          <Gap gap="8px" />
          <Text>{maxLabel}</Text>
        </>
      )}
    </Wrapper>
  )
}

export default SliderRange
