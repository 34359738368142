import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { compose } from 'recompose'
import { Row } from '..'
import { fields } from '../../../common/lib/redux-fields'
import { SwitchField } from '../fields'
import { setStep2Config } from '../../../common/config/actions'

function Step2SettingForm(props) {
  const { fields } = props

  const dispatch = useDispatch()
  const { t } = useTranslation()

  const defaultChamferOptions = [
    { label: t('Checked'), value: true },
    { label: t('Unchecked'), value: false },
  ]

  const handleDefaultChamferChange = e => {
    fields.defaultChamfer.onChange(e)
    const values = fields.$values()
    dispatch(setStep2Config(values))
  }

  return (
    <>
      <Row>
        <SwitchField
          label={t('Default chamfer')}
          options={defaultChamferOptions}
          required
          {...fields.defaultChamfer}
          onChange={handleDefaultChamferChange}
        />
      </Row>
    </>
  )
}

Step2SettingForm.propTypes = {
  t: PropTypes.func.isRequired,
  fields: PropTypes.object.isRequired,
  defaultChamfer: PropTypes.bool.isRequired,
}

const enhance = compose(
  connect(({ config = {} }) => ({
    defaultChamfer: config.defaultChamfer,
  })),
  fields({
    path: 'step2SettingForm',
    fields: ['defaultChamfer'],
    getInitialState: ({ defaultChamfer }) => ({
      defaultChamfer,
    }),
  }),
)

export default enhance(Step2SettingForm)
