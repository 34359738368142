import 'isomorphic-fetch'
import URI from 'urijs'

let host
if (process.env.IS_BROWSER) {
  host = window.location.origin
} else {
  const config = require('../../server/config')
  host = config.SSR_URL
}

const ensureAbsoluteUrl = input => {
  if (typeof input !== 'string') return input
  if (URI(input).is('absolute')) return input
  return URI(host + input)
    .normalize()
    .toString()
}

const parseJSON = response => response ? response.json() : { code: 204, status: 'Ok' }

export default function fetchData(input, init) {
  input = ensureAbsoluteUrl(input)
  return fetch(input, init).then(parseJSON)
}
