import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { withTranslation } from 'react-i18next'
import { R, L, appTypeConfig } from '../../../common/config'
import SummaryTitle from './SummaryTitle'
import HiddenInPrint from '../HiddenInPrint'
/**
 * col props
 * width
 * minWidth
 * fitContent
 */

const getBasicBorderStyle = theme => `solid ${theme.field.borderWidth} ${theme.colors.gray}`
const getHeaderBorderStyle = theme => `solid ${theme.field.borderWidth} ${theme.colors.grayDark}`
const getPrintBorderStyle = theme => `solid 1pt ${theme.colors.grayDark} !important`

const isFilled = value => typeof value !== 'undefined'

const createGrid = ({ data, isDisplayedInColumn, isPair }) => {
  // console.log('data', data)

  // if (isDisplayedInColumn) {
  //   // const header = data[0]
  //   return css`
  //     grid-template-columns: minmax(auto, max-content) auto;
  //   `
  // }

  const gridString = data
    .map(({ colProps = {} }, key) => {
      // if (isDisplayedInColumn && key === data.length - 1) return 'auto' // todo zkusit obecnější řešení?
      const { width, minWidth, maxWidth } = colProps
      if (isFilled(width)) return width
      const min = isFilled(minWidth) ? minWidth : 'auto'
      const max = isFilled(maxWidth) ? maxWidth : 'max-content'
      return `minmax(${min}, ${max})`
    })
    .reduce((result, item) => `${result} ${item}`, isPair ? 'max-content' : '')

  return css`
    grid-template-columns: ${gridString};
  `
}

const TableContainer = styled.div.attrs(() => ({
  name: 'summary-table',
}))`
  ${({ theme, data, isDisplayedInColumn, isPair, numberOfColumns, maxWidth }) => css`
    display: grid;
    max-width: ${maxWidth};
    border-radius: ${theme.field.borderRadius};
    border: ${getBasicBorderStyle(theme)};
    @media print {
      border: ${getPrintBorderStyle(theme)};
      border-radius: 0;
    }
    ${TableCellWrapper} {
      ${
        isPair &&
        css`
          &:nth-child(2),
          &:nth-child(${numberOfColumns + 2}),
          &:nth-child(${numberOfColumns * 2 + 2}) {
            padding-left: 1.5rem; // todo, okraje dát někam do proměnné
          }
          &:nth-child(${numberOfColumns}),
          &:nth-child(${numberOfColumns * 2}),
          &:nth-child(${numberOfColumns * 3}) {
            padding-right: 2rem; // todo, okraje dát někam do proměnné
          }
        `
      }
      ${
        !isPair &&
        !isDisplayedInColumn &&
        css`
          &:nth-child(1),
          &:nth-child(${numberOfColumns + 1}) {
            padding-left: 2rem; // todo, okraje dát někam do proměnné
          }
          &:nth-child(${numberOfColumns}),
          &:nth-child(${numberOfColumns * 2}) {
            padding-right: 2rem; // todo, okraje dát někam do proměnné
          }
        `
      }

${
  isDisplayedInColumn &&
  css`
    &:nth-child(odd) {
      padding-left: 2rem; // todo, okraje dát někam do proměnné
    }
    &:nth-child(even) {
      padding-right: 2rem; // todo, okraje dát někam do proměnné
    }
  `
}
      // clear bottom cell border on the last line
      &:nth-last-child(-n + ${numberOfColumns}) {
        border-bottom: none;
      }
      // set correct radius for corner cells
      &:first-child {
        border-top-left-radius: ${theme.field.borderRadius};
      }
      &:nth-child(${numberOfColumns}) {
        border-top-right-radius: ${theme.field.borderRadius};
      }
      &:nth-last-child(${numberOfColumns}) {
        border-bottom-left-radius: ${theme.field.borderRadius};
      }
      &:last-child(${numberOfColumns}) {
        border-bottom-right-radius: ${theme.field.borderRadius};
      }
      @media print {
        &:border-radius: 0 !important;
        &:first-child {
        border-top-left-radius: 0;
        }
        &:nth-child(${numberOfColumns}) {
          border-top-right-radius: 0;
        }
        &:nth-last-child(${numberOfColumns}) {
          border-bottom-left-radius: 0;
        }
        &:last-child(${numberOfColumns}) {
          border-bottom-right-radius: 0;
        }
        &:nth-last-child(-n + ${numberOfColumns}) {
          border-bottom: none !important;
        }
      }
    }
    /* border-spacing: 0; */
    /* grid-template-columns: ${data.reduce(result => `${result} 1fr`, '')}; */
    /* grid-template-columns: repeat(${data.length}, minmax(auto, auto)); */
    // last item is always auto, to fill all available space
    ${createGrid({
      data,
      isDisplayedInColumn,
      isPair,
    })}/* grid-template-columns: repeat(${numberOfColumns - 1}, minmax(auto, max-content)) auto; */
  `}
`
TableContainer.displayName = 'TableContainer'

const getBorder = ({ theme, isHeader, isSideHeader }) => {
  if (isSideHeader) {
    return css`
      border-right: ${getHeaderBorderStyle(theme)};
      border-bottom: ${getBasicBorderStyle(theme)};
      @media print {
        border-bottom: ${getPrintBorderStyle(theme)};
        border-right: ${getPrintBorderStyle(theme)};
      }
    `
  }
  if (isHeader) {
    return css`
      border-bottom: ${getHeaderBorderStyle(theme)};
      @media print {
        border-bottom: ${getPrintBorderStyle(theme)};
      }
    `
  }
  return css`
    border-bottom: ${getBasicBorderStyle(theme)};
    @media print {
      border-bottom: ${getPrintBorderStyle(theme)};
    }
  `
}

const TableCellContent = styled.div`
  ${({ theme, isDisabled }) => css`
    opacity: ${isDisabled ? 0.3 : 'inherit'};
  `}
`

const TableCellWrapper = styled.div`
  ${({ theme, isHeader, isSideHeader, isSmall, textAlign, compactColumns }) => css`
    color: black;

    display: flex;
    align-items: center;
    justify-content: ${isSideHeader || textAlign === 'right' ? 'flex-end' : 'flex-start'};

    padding-left: 1.5rem;
    padding-right: 1.5rem;
    ${compactColumns &&
    css`
      padding-left: 1rem;
      padding-right: 1rem;
    `}

    padding-top: 1rem;
    padding-bottom: 1rem;
    ${(isHeader || isSmall) &&
    css`
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
    `}

    background-color: ${(isHeader || isSideHeader) && theme.colors.gray2};
    text-align: ${textAlign || isSideHeader ? 'right' : 'left'};
    ${getBorder({ theme, isHeader, isSideHeader })}
    @media print {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      padding-left: 1rem;
      padding-right: 1rem;

      ${compactColumns &&
      css`
        padding-left: 0.5rem;
        padding-right: 0.5rem;
      `}

      font-size: 90%;
      background-color: none;
    }
  `}
`

const TableCell = ({ children, ...props }) => (
  <TableCellWrapper {...props}>
    <TableCellContent {...props}>{children}</TableCellContent>
  </TableCellWrapper>
)

const VisibilityManager = ({
  children,
  isHiddenIfEmpty = false,
  data,
  values,
  isPair,
  render = props => props,
}) => {
  if (appTypeConfig.areEmptySectionsInPrintHidden && isHiddenIfEmpty) {
    const isEmpty = getIsEmpty({ data, values, isPair })
    if (isEmpty) return null
  }
  // return null
  return render(children)
}

const getValue = ({ values, fieldName, side, fakePair }) => {
  const transformedFieldName =
    (side === R || side === L) && !fakePair ? `${fieldName}${side}` : fieldName
  return values[transformedFieldName]
}

const getTextValue = ({ values, fieldName, side, fakePair, disableR, disableL }) => {
  const value = getValue({ values, fieldName, side, fakePair })
  return (!value && value !== 0) || (side === R && disableR) || (side === L && disableL)
    ? '---'
    : value
}

const renderItem = ({
  renderValue,
  values,
  fieldName,
  side,
  fakePair,
  disableR,
  disableL,
  isValueHidden,
}) => {
  if (isValueHidden) return '---'
  const value = renderValue
    ? renderValue({
        value: getValue({ values, fieldName, side, fakePair }),
        values,
        fieldName,
        side,
      })
    : getTextValue({ values, fieldName, side, fakePair })

  if ((side === R && disableR) || (side === L && disableL)) {
    return value ? '---' : null
  }
  return value
}

const renderTitle = title => {
  if (!title) return null
  return <SummaryTitle>{title}</SummaryTitle>
}

const getIsEmpty = ({ data, values, isPair }) => {
  const sides = [R, L]
  if (isPair) {
    let isEmpty = true
    sides.forEach(side => {
      const itemValues = data.map(d => renderItem({ values, side, ...d }))
      const isSideEmpty = itemValues.every(value => value === '---')
      if (!isSideEmpty) isEmpty = false
    })
    return isEmpty
  }
  const itemValues = data.map(d => renderItem({ values, ...d }))
  const isEmpty = itemValues.every(value => value === '---')
  return isEmpty
}

// todo disable položek
const SummaryTable = props => {
  const {
    title,
    values,
    isPair,
    isDisplayedInColumn,
    columnConfig,
    compactColumns,
    disableR,
    disableL,
    isFullWidth,
    t,
    maxWidth,
    isVisible = true,
  } = props

  if (!isVisible) return null

  let { data } = props
  data = data.filter(d => d)

  // todo možná oddělit? Je to trochu zmatek
  if (isDisplayedInColumn) {
    return (
      <VisibilityManager {...props} data={data}>
        {renderTitle(title)}
        <TableContainer
          maxWidth={maxWidth}
          data={[{ colProps: columnConfig.header }, { colProps: columnConfig.data }]}
          isDisplayedInColumn
          numberOfColumns={2}
        >
          {data.map(({ header, valueProps, rowProps, fieldName, renderValue }) => (
            <React.Fragment key={`header${fieldName}`}>
              <TableCell
                key={`header${fieldName}`}
                {...columnConfig.header}
                isSideHeader
                isSmall
                {...rowProps}
              >
                {header}
              </TableCell>
              <TableCell key={fieldName} {...columnConfig.data} isSmall {...rowProps}>
                {renderItem({ values, fieldName, renderValue, ...valueProps })}
              </TableCell>
            </React.Fragment>
          ))}
        </TableContainer>
      </VisibilityManager>
    )
  }

  // fill remaining space
  if (isFullWidth) {
    data.push({ colProps: { width: 'auto' }, renderValue: () => null })
  }

  const isSideDisabled = side => (side === 'R' && disableR) || (side === 'L' && disableL)

  if (isPair) {
    return (
      <VisibilityManager {...props} data={data}>
        {renderTitle(title)}
        <TableContainer data={data} numberOfColumns={data.length + 1} isPair maxWidth={maxWidth}>
          <TableCell isHeader />
          {data.map(({ header, colProps, fieldName }, key) => (
            <TableCell
              key={`header${fieldName || key}`}
              {...colProps}
              compactColumns={compactColumns}
              isHeader
            >
              {header}
            </TableCell>
          ))}
          {[R, L].map(side => (
            <React.Fragment key={`side${side}`}>
              <TableCell isSideHeader isDisabled={isSideDisabled(side)}>
                {t(side)}
              </TableCell>
              {data.map(({ fieldName, colProps, renderValue, fakePair, isValueHidden }, key) => (
                <TableCell
                  key={side + fieldName + key}
                  {...colProps}
                  isDisabled={isSideDisabled(side) || colProps?.isDisabled}
                  compactColumns={compactColumns}
                >
                  {renderItem({
                    values,
                    fakePair,
                    fieldName,
                    renderValue,
                    side,
                    disableR,
                    disableL,
                    isValueHidden,
                  })}
                </TableCell>
              ))}
            </React.Fragment>
          ))}
        </TableContainer>
      </VisibilityManager>
    )
  }

  return (
    <VisibilityManager {...props} data={data}>
      {renderTitle(title)}
      <TableContainer data={data} numberOfColumns={data.length} maxWidth={maxWidth}>
        {data.map(({ header, colProps, fieldName }) => (
          <TableCell
            key={`header${fieldName}`}
            {...colProps}
            isHeader
            compactColumns={compactColumns}
          >
            {header}
          </TableCell>
        ))}
        {data.map(({ fieldName, colProps, renderValue }) => (
          <TableCell key={fieldName} {...colProps} compactColumns={compactColumns}>
            {renderItem({ values, fieldName, renderValue })}
          </TableCell>
        ))}
      </TableContainer>
    </VisibilityManager>
  )
}

SummaryTable.defaultProps = {
  isPair: false,
  isDisplayedInColumn: false,
  columnConfig: {
    header: {},
    data: { width: 'auto' },
  },
  compactColumns: false,
  disableR: false,
  disableL: false,
  isFullWidth: false,
}

SummaryTable.propTypes = {
  values: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  isPair: PropTypes.bool,
  isDisplayedInColumn: PropTypes.bool,
  columnConfig: PropTypes.object,
  compactColumns: PropTypes.bool,
  disableR: PropTypes.bool,
  disableL: PropTypes.bool,
  isFullWidth: getPrintBorderStyle,
}

const TranslatedSummaryTable = withTranslation()(SummaryTable)

export default ({ data, ...props }) => 
  <TranslatedSummaryTable data={data.filter(d => d && !d.hidden)} {...props} />

