import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Button } from '.'

class SelectFileButton extends React.Component {
  constructor(props) {
    super(props)
    this.inputRef = React.createRef()
  }

  onClick = () => {
    this.inputRef.current.value = null
    this.inputRef.current.click()
  }

  render() {
    const { onSelect, ...props } = this.props
    return (
      <>
        <input
          ref={this.inputRef}
          type="file"
          name="file"
          multiple
          style={{ display: 'none' }}
          onChange={onSelect}
        />
        <Button {...props} onClick={this.onClick} />
      </>
    )
  }
}

SelectFileButton.defaultProps = {}

SelectFileButton.propTypes = {
  onSelect: PropTypes.func.isRequired,
}

export default SelectFileButton
