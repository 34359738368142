import omega from './config/omega'
import reize from './config/reize'
import jaikudo from './config/jaikudo'
import jzo from './config/jzo'

import { appVersion } from '../../app-version.json'

if (console.getLogger) {
  const log = console.getLogger('ENV')
  log.debug('Loaded appVersion from app-version.json', appVersion)
} else {
  console.log('Loaded appVersion from app-version.json', appVersion)
}

const appTypeConfigs = {
  omega,
  reize,
  jaikudo,
  jzo,
}

// moved here from helpers, so we do not need to load all things (like i18n)
// when just requiring config (e.g. for builds, server startups...)
export const switchByAppType = data => {
  switch (process.env.APP_TYPE) {
    case 'reize':
      return data.Reize ? data.Reize : data.reize
    case 'omega':
      return data.Omega ? data.Omega : data.omega
    case 'jaikudo':
      return data.Jaikudo || data.jaikudo || data.JaiKudo || data.Omega || data.omega
    case 'jzo':
      return data.Jzo || data.jzo || data.JZO || data.Omega || data.omega
    default:
      return data.omega
  }
}

export const appTypeConfig = {
  ...switchByAppType(appTypeConfigs),
}

const { individualMenus = {} } = appTypeConfig

const allIndividualMenuFieldKeysSet = new Set()
const allIndividualMenuFieldKeys = []

Object.keys(individualMenus || {}).forEach(individualMenuKey => {
  const individualMenuConf = individualMenus[individualMenuKey]
  Object.keys(individualMenuConf).forEach(fieldKey => allIndividualMenuFieldKeysSet.add(fieldKey))
})

allIndividualMenuFieldKeysSet.forEach(key => allIndividualMenuFieldKeys.push(key))
appTypeConfig.allIndividualMenuFieldKeys = allIndividualMenuFieldKeys

export const uniqueTranslationKeyPrefixes = Object.keys(appTypeConfigs).map(
  key => appTypeConfigs[key].uniqueTranslationKeyPrefix,
)

export const APP_VERSION = appVersion || 'NOT READ'

// from now on, it's only for browser, so we can uploads version dependent source maps
// serverKey is in server/config
// export const bugsnagKey = 'b4233e12aa63b7cac909f286917e3881'
// key was moved to ecosystem -> process.env.BUGSNAG_KEY

// shared by server and browser
export const bugsnagConfig = {
  appVersion: process.env.APP_VERSION,
  // no localhost, this is server env (eco). Full names are fallback if ENV goes wrong.
  enabledReleaseStages: ['prod', 'dev', 'production', 'development'],
  // enabledReleaseStages: ['prod', 'dev', 'production', 'development', 'local'],
  releaseStage: process.env.SERVER_ENV,
  metadata: {
    env: {
      SERVER_ENV: process.env.SERVER_ENV,
      APP_TYPE: process.env.APP_TYPE,
      NODE_ENV: process.env.NODE_ENV,
      IS_BROWSER: !!process.env.IS_BROWSER,
      APP_VERSION: process.env.APP_VERSION,
    },
  },
  redactedKeys: [
    'tokens',
    /^password$/i, // case-insensitive: "password", "PASSWORD", "PaSsWoRd"
    'street',
    'city',
    'zipcode',
    'x-access-token',
  ],
}

export const R = 'R'
export const L = 'L'
export const BOTH = 'BOTH'

export const userRoles = {
  USER: 'USER',
  ADMIN: 'ADMIN',
}

export const roleTypes = {
  [userRoles.USER]: [userRoles.USER],
  [userRoles.ADMIN]: [userRoles.USER, userRoles.ADMIN],
}

export const orderTypes = {
  DIAMETER_ONLY: 'DIAMETER_ONLY',
  WITH_SHAPE: 'WITH_SHAPE',
  EDGING: 'EDGING', // remote edging
  EDGING_COMPLETION: 'EDGING_COMPLETION', // glazing
  WITH_FRAME: 'WITH_FRAME',
}

export const bevelPositions = {
  AUTO: 'AUTO',
  FRONT: 'FRONT', // front mm
  HALF: 'HALF',
  PERCENT: 'PERCENT', // front percent
  SMART: 'SMART',
  FRAMECURVE: 'FRAMECURVE',
  BACK: 'BACK', // back mm -  reize only
  MANUAL: 'MANUAL', // reize only - we dont know, and it does not have modifier :O  https://trello.com/c/kLFcJu21/30-step-2-bevel-positions-options
  RELATION30: 'RELATION30',
  RELATION40: 'RELATION40',
  RELATION60: 'RELATION60',
  RELATION70: 'RELATION70',
}

export const frameTypes = {
  METAL: 'METAL',
  PLASTIC: 'PLASTIC',
  SPECIAL: 'SPECIAL', // titan
  NYLOR: 'NYLOR',
  DRILLED: 'DRILLED',
  GROOVEDDRILLED: 'GROOVEDDRILLED',
  OPTYL: 'OPTYL',
}

export const orderStatuses = {
  IMPORTED: '05_IMPORTED',
  CONCEPT: '10_CONCEPT',
  NOT_SENT: '20_NOT_SENT',
  WAIT_FOR_SEND: '25_WAIT_FOR_SEND',
  SENDING: '30_SENDING',
  // ^^ order added to batch and waiting for FTP send
  SENT: '35_SENT',
  // ^^ order uploaded via FTP
  AT_MANUFACTURER: '40_AT_MANUFACTURER',
  // ^^ order received at least one status update from manufacturer
  DONE: '50_DONE',
  CANCELLED: '60_CANCELLED',
  ARCHIVED: '80_ARCHIVED',
}

export const finalManufacturerSubStatus = 'Shipping'
export const cancelManufacturerSubStatus = 'Cancelled'

export const lensMaterials = {
  PLASTIC: 'PLASTIC',
  TRIVEX: 'TRIVEX',
  POLYCARBONATE: 'POLYCARBONATE',
  MINERAL: 'GLASS',
}

export const lensMaterialsFilters = {
  PLASTIC150: {
    label: 'Plast 1.5',
    material: lensMaterials.PLASTIC,
    indexFrom: 1.5,
    indexTo: 1.5,
  },
  PLASTIC153: {
    label: 'Plast 1.53 - 1.59',
    material: lensMaterials.PLASTIC,
    indexFrom: 1.52,
    indexTo: 1.59,
  },
  PLASTIC160: {
    label: 'Plast 1.60 - 1.66',
    material: lensMaterials.PLASTIC,
    indexFrom: 1.6,
    indexTo: 1.66,
  },
  PLASTIC167: {
    label: 'Plast 1.67 - 1.74',
    material: lensMaterials.PLASTIC,
    indexFrom: 1.67,
    indexTo: 1.74,
  },
  TRIVEX: {
    label: 'Trivex',
    material: lensMaterials.TRIVEX,
  },
  POLYCARBONATE: {
    label: 'Polykarbonát',
    material: lensMaterials.POLYCARBONATE,
  },
  GLASS: {
    // MINERAL
    label: 'Minerál',
    material: lensMaterials.MINERAL,
  },
}

export const progressiveLengths = {
  U: 'UltraShort',
  E: 'ExtraShort',
  S: 'Short',
  R: 'Regular',
}

export const lensOptionsSelectTypes = {
  EXPRESS: 'EXPRESS',
  COATING: 'COATING',
  COLOR: 'COLOR',
  UV: 'UV',
  SECOND: 'SECOND',
  CTO: 'CTO',
  ECS: 'ECS',
  OTHER: 'OTHER',
}

export const lensVariantSelectTypes = {
  WHITE: 'WHITE',
  OTHER: 'OTHER',
}

export const lateralities = {
  right: 'reize; Right hander',
  left: 'reize; Left hander',
}

export const shownPriceTypes = {
  RECOM_SELLINGPRICE_LEVEL1: 'RECOM_SELLINGPRICE_LEVEL1',
  RECOM_SELLINGPRICE_LEVEL2: 'RECOM_SELLINGPRICE_LEVEL2',
}

export const orderImportTypes = {
  B2B: 'B2B',
  EYESTATION: 'EYESTATION',
  SOAP: 'SOAP',
}

export const currenciesTexts = {
  SFR: 'CHF',
}

const getDecimalSeparator = () => {
  let n = 1.1
  n = n.toLocaleString().substring(1, 2)
  return n === ',' ? ',' : '.'
}

export const decimalSeparator = getDecimalSeparator()

export const orderListTimeTypes = {
  ALL_STATES: 'ALL_STATES',
  SENT_ONLY: 'SENT_ONLY',
}

export const minBrowserVersions = {
  chrome: 82,
  firefox: 78,
  'edge-chromium': 82,
  safari: 12,
  opera: 70,
}

export const forbiddenBrowsers = ['edge', 'ie']

const completeExport = {
  ...module.exports,
}

export const catalogExtensionsRulesOptions = {
  FORBIDDEN_COMBINATION: 'forbidden combination',
  ONLY_POSSIBLE_COMBINATION: 'only possible combination',
  MANDATORY_COMBINATION: 'mandatory combination',
  EXPRESS_DISABLED: 'express delivery disabled',
  FRAME_ONLY_WITH_GROUP: 'frame only with group',

  FRAME_COMPATIBLE_OPTIONS: 'frame compatible options',
  DISABLED_WITHOUT_EVO: 'disabled without evo',
  FRAME_TYPE_DISABLED: 'frame type disabled',
}

export const catalogExtensionsRules = Object.keys(catalogExtensionsRulesOptions).reduce(
  (acc, key) => ({ ...acc, [key]: key }),
  {},
)

export const expressCodes = {
  EXP24: 'EXP24',
  EXP48: 'EXP48',
}

export const expressCodesArray = Object.values(expressCodes)

export default completeExport
