import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { withTranslation } from 'react-i18next'
import { SwitchField } from '../fields'
import { orderTypes, appTypeConfig } from '../../../common/config'

const getAllOptionsObject = t =>
  Object.keys(orderTypes).reduce((result, key) => {
    const orderType = orderTypes[key]
    result[orderType] = {
      text: t(orderType),
      value: orderType,
    }
    return result
  }, {})

const getOrderTypeTooltip = ({ tracerEnabled, t }) => (
  <div style={{ textAlign: 'left' }}>
    <div>
      <strong>{t(orderTypes.DIAMETER_ONLY)}</strong>: {t('DIAMETER_ONLY_DESCRIPTION')}
    </div>
    <br />
    <div>
      <strong>{t(orderTypes.WITH_SHAPE)}</strong>: {t('WITH_SHAPE_DESCRIPTION')}
    </div>
    {tracerEnabled && (
      <>
        <br />
        <div>
          <strong>{t(orderTypes.EDGING)}</strong>: {t('EDGING_DESCRIPTION')}
        </div>
        <br />
        <div>
          <strong>{t(orderTypes.EDGING_COMPLETION)}</strong>: {t('EDGING_COMPLETION_DESCRIPTION')}
        </div>
      </>
    )}

    {/* <br /> // zapnout po dodání překladu
    <div>
      <strong>{t(orderTypes.WITH_FRAME)}</strong>: {t('WITH_FRAME_DESCRIPTION')}
    </div> */}

  </div>
)

const getOptions = ({ tracerEnabled, enabledOrderTypes, t }) => {
  const allOptionsObject = getAllOptionsObject(t)
  const orderTypeOptions = [allOptionsObject.DIAMETER_ONLY, allOptionsObject.WITH_SHAPE]
  if (tracerEnabled) {
    orderTypeOptions.push(allOptionsObject.EDGING)
    orderTypeOptions.push(allOptionsObject.EDGING_COMPLETION)
  }
  if (appTypeConfig.areFramesEnabled) {
    orderTypeOptions.push(allOptionsObject.WITH_FRAME)
  }

  const filteredOrderTypesOptions = enabledOrderTypes
    ? orderTypeOptions.filter(({ value }) => enabledOrderTypes[value])
    : orderTypeOptions

  return filteredOrderTypesOptions.length !== 0 ? filteredOrderTypesOptions : orderTypeOptions
}

const OrderTypeField = ({ t, tracerEnabled, enabledOrderTypes, ...props }) => (
  <SwitchField
    label={t('order type')}
    options={getOptions({ tracerEnabled, enabledOrderTypes, t })}
    name="type"
    tooltip={getOrderTypeTooltip({ tracerEnabled, t })}
    tooltipDown
    required
    {...props}
  />
)

OrderTypeField.defaultProps = {
  tracerEnabled: false,
  enabledOrderTypes: null,
}

OrderTypeField.propTypes = {
  t: PropTypes.func.isRequired,
  tracerEnabled: PropTypes.bool,
  enabledOrderTypes: PropTypes.object,
}

const enhance = compose(
  connect(({ app: { hostConf = {}, viewer } = {} }) => {
    let enabledOrderTypes = hostConf.enabledOrderTypes || null

    // use enabledOrderTypes on user level if filled
    if (
      viewer.enabledOrderTypes &&
      Object.keys(viewer.enabledOrderTypes).some(key => viewer.enabledOrderTypes[key] === true)
    ) {
      enabledOrderTypes = viewer.enabledOrderTypes
    }
     
    // if we have no enabledOrderTypes on user or hostConf level, use default from appTypeConfig  
    const defaultEnabledOrderTypes = appTypeConfig.getEnabledOrderTypes?.()
    if (!enabledOrderTypes && defaultEnabledOrderTypes) {
      enabledOrderTypes = defaultEnabledOrderTypes
    }

    return {
      tracerEnabled: viewer.tracerEnabled,
      enabledOrderTypes,
    }
  }, {}),
  withTranslation(),
)

export default enhance(OrderTypeField)
