// forked PopupWithState
// https://github.com/tajo/react-portal

import React from 'react'
import PropTypes from 'prop-types'
import { Portal } from 'react-portal'

import PopoverPresentational from './PopoverPresentational'

const KEYCODES = {
  ESCAPE: 27,
}

class PopoverMenu extends React.Component {
  constructor(props) {
    super(props)
    this.portalNode = null
    this.state = { active: !!props.defaultOpen }
    this.openPortal = this.openPortal.bind(this)
    this.closePortal = this.closePortal.bind(this)
    this.handleOutsideMouseClick = this.handleOutsideMouseClick.bind(this)
    this.handleKeydown = this.handleKeydown.bind(this)
  }

  componentDidMount() {
    if (this.props.closeOnEsc) {
      document.addEventListener('keydown', this.handleKeydown)
    }
    if (this.props.closeOnOutsideClick) {
      document.addEventListener('click', this.handleOutsideMouseClick)
    }
  }

  componentWillUnmount() {
    if (this.props.closeOnEsc) {
      document.removeEventListener('keydown', this.handleKeydown)
    }
    if (this.props.closeOnOutsideClick) {
      document.removeEventListener('click', this.handleOutsideMouseClick)
    }
  }

  openPortal(e) {
    // e.stopPropagation()
    if (this.state.active) {
      return
    }
    if (e && e.nativeEvent) {
      // Bránilo to zavření předchozího menu, zatím vypadá, že zakomentování nevadí
      // e.nativeEvent.stopImmediatePropagation()
    }
    this.setState({ active: true }, this.props.onOpen)
  }

  closePortal(value = {}) {
    if (!this.state.active) {
      return
    }
    this.setState({ active: false }, () => this.props.onClose(value))
  }

  handleOutsideMouseClick(e) {
    if (!this.state.active) {
      return
    }
    const root =
      this.portalNode &&
      ((this.portalNode.props && this.portalNode.props.node) || this.portalNode.defaultNode)
    if (!root || root.contains(e.target) || (e.button && e.button !== 0)) {
      return
    }
    this.closePortal()
  }

  handleKeydown(e) {
    if (e.keyCode === KEYCODES.ESCAPE && this.state.active) {
      this.closePortal()
    }
  }

  render() {
    const { active } = this.state
    const { children, ...rest } = this.props
    return (
      <>
        {this.props.children({
          open: this.openPortal,
          close: this.closePortal,
          isOpen: this.state.active,
        })}
        {active && (
          <Portal
            node={document && document.getElementById(this.props.nodeId)}
            key="react-portal"
            ref={portalNode => {
              this.portalNode = portalNode
            }}
          >
            <PopoverPresentational {...rest} close={this.closePortal} />
          </Portal>
        )}
      </>
    )
  }
}

PopoverMenu.propTypes = {
  children: PropTypes.func.isRequired,
  defaultOpen: PropTypes.bool,
  // node: PropTypes.any,
  nodeId: PropTypes.string.isRequired, // id of element - where we want to show popover menu!
  openByClickOn: PropTypes.element,
  closeOnEsc: PropTypes.bool,
  closeOnOutsideClick: PropTypes.bool,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
}

PopoverMenu.defaultProps = {
  onOpen: () => {},
  onClose: () => {},
}

export default PopoverMenu
