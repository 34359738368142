/* @flow weak */
import { subDays } from 'date-fns'
import * as actions from './actions'
import * as authActions from '../auth/actions'
import { appTypeConfig } from '../config'

// most of the state is not saved -- see configure/storage
const State = {
  browserId: null,
  location: null,
  storageLoaded: false,
  suspenseDone: false,
  errors: [],
  navigationVisible: false,
  navigationOverlayVisible: false,
  viewer: null,
  hostConf: {
    // default values
    isGoToWowEnabled: true,
    wowUrl: '',
    htmlTitle: 'LensOrderingTool',
    isMiLensEnabled: true,
    areDeliveryNotesEnabled: false,
    // appTypeConfig.headerLogoPath || hostConf.logo?.publicPath
    isGetOrderPriceEnabled: false,
  },
  isHostConfLoaded: false,
  isHostConfError: false,
  news: [],
  workers: [],
  // archived are not updated when edit/create/delete, its only use as read only and are fetched every time
  workersWithArchived: [],
  lastStatsSendAt: null,
  isMeEditing: false,
  ordersTableFullWidth: false,

  puppetUsersList: [],
  puppetUser: null,
}

export const selectApp = state => state.app
export const selectViewerId = state => state.app.viewer?._id
export const selectViewer = state => state.app.viewer
export const selectHostConf = state => state.app.hostConf

const appReducer = (state = State, action) => {
  // This is how we can handle all async actions rejections.
  if (action.type.endsWith('_ERROR')) {
    const errors = (action.payload && action.payload.errors) || action.payload || []
    const newState = { errors: [...state.errors, ...errors] }
    if (errors.some && errors.some(({ name }) => name === 'TokenIsExpired')) {
      newState.viewer = null
    }
    return {
      ...state,
      ...newState,
    }
  }

  switch (action.type) {
    case actions.SET_ORDERS_TABLE_FULL_WIDTH:
      return {
        ...state,
        ordersTableFullWidth: action.payload,
      }
    case actions.APP_SET_LOCATION:
      return {
        ...state,
        location: action.payload.location,
      }
    case actions.SET_SCROLL_LOCATION:
      return {
        ...state,
        navigationOverlayVisible: action.payload.location > 50,
      }

    case actions.APP_STORAGE_LOAD:
      return {
        ...state,
        storageLoaded: true,
      }

    case actions.APP_SUSPENSE_DONE:
      return {
        ...state,
        suspenseDone: true,
      }

    case actions.TOGGLE_NAVIGATION:
      return {
        ...state,
        navigationVisible: !state.navigationVisible,
      }

    case authActions.SIGN_IN_SUCCESS: {
      return {
        ...state,
        viewer: action.payload.data,
      }
    }
    case 'OAUTH_SIGN_IN_SUCCESS': {
      return {
        ...state,
        viewer: action.payload.data,
      }
    }
    case actions.EDIT_ME_SUCCESS:
    case actions.FETCH_ME_SUCCESS: {
      return {
        ...state,
        isMeEditing: false,

        viewer: {
          ...state.viewer,
          ...action.payload.data,
        },
      }
    }

    case actions.EDIT_ME_START: {
      return {
        ...state,
        isMeEditing: true,
      }
    }

    case actions.EDIT_ME_ERROR: {
      return {
        ...state,
        isMeEditing: false,
      }
    }

    case authActions.SIGN_OUT: {
      // clear everything tied to logged user
      return {
        ...state,
        viewer: null,
        news: [],
        workers: [],
        workersWithArchived: [],
        puppetUsersList: [],
        puppetUser: null,
      }
    }
    case actions.FETCH_HOST_CONFIG_SUCCESS: {
      const { data } = action.payload
      return {
        ...state,
        isHostConfLoaded: true,
        hostConf: {
          ...state.hostConf,
          ...data,
        },
      }
    }

    case actions.FETCH_HOST_CONFIG_ERROR: {
      return {
        ...state,
        isHostConfError: true,
      }
    }

    case actions.FETCH_WORKERS_SUCCESS: {
      const { workers, workersWithArchived } = action.payload.data
      return {
        ...state,
        workers,
        workersWithArchived,
      }
    }

    case actions.DELETE_WORKER_SUCCESS: {
      const { workers } = state
      const { _id } = action.meta.action
      return {
        ...state,
        workers: workers.filter(w => w._id !== _id),
      }
    }

    case actions.EDIT_WORKER_SUCCESS: {
      const { workers } = state
      const { _id } = action.meta.action
      const { worker } = action.payload.data
      return {
        ...state,
        workers: workers.map(w => {
          if (w._id !== _id) {
            return w
          }
          return {
            ...w,
            ...worker,
          }
        }),
      }
    }
    case actions.CREATE_WORKER_SUCCESS: {
      const { workers } = state
      const { worker } = action.payload.data
      return {
        ...state,
        workers: [...workers, worker],
      }
    }

    case actions.FETCH_NEWS_SUCCESS: {
      const { data } = action.payload
      let { news } = state
      if (!news || !Array.isArray(news)) {
        news = []
      }
      news = data.map(d => {
        const currentOneNews = news.find(n => n._id === d._id)
        if (currentOneNews) {
          return {
            ...currentOneNews,
            ...d,
          }
        }
        return {
          ...d,
        }
      })

      return {
        ...state,
        news,
      }
    }

    case actions.READ_NEWS: {
      const news = state.news
        ? state.news?.map?.(d => ({
          ...d,
          read: true,
        }))
        : []

      return {
        ...state,
        news,
      }
    }

    case actions.SET_BROWSER_ID: {
      return {
        ...state,
        browserId: action.payload.browserId,
      }
    }

    case actions.SEND_STATS_SUCCESS: {
      return {
        ...state,
        lastStatsSendAt: new Date().toISOString(),
      }
    }

    case actions.FETCH_PUPPET_USERS_LIST_SUCCESS: {
      return {
        ...state,
        puppetUsersList: action.payload.data,
      }
    }

    case actions.SET_PUPPET_USER: {
      return {
        ...state,
        puppetUser: action.payload,
      }
    }

    default:
      return state
  }
}

export default appReducer
