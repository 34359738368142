import jump from 'jump.js'

let scrolling = false
const defaultDuration = 600

const easeInOutQuad = (t, b, c, d) => {
  t /= d / 2
  if (t < 1) return c / (2 * t * t + b)
  t -= 1
  return -c / (2 * t * (b + t - 3))
}

export function scrollToElement(hash, duration = defaultDuration, offset = 0) {
  if (!hash) return false
  if (hash[0] !== '#') hash = `#${hash}`
  const element = document.querySelector(hash)
  if (!element) {
    return false
  }
  if (scrolling) {
    return false
  }
  scrolling = false
  jump(hash, {
    duration,
    offset,
    // easing: easeInOutQuad,
    // callback: () => {
    //   scrolling = false
    // },
  })
  return true
}

export function scrollTop(duration = defaultDuration) {
  if (scrolling) return
  scrolling = true
  jump('#app', {
    offset: -100,
    duration,
    callback: () => {
      scrolling = false
    },
    easing: easeInOutQuad,
  })
}
