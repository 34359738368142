import React from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    const location = useLocation()
    const navigate = useNavigate()
    const params = useParams()

    const history = {
      push: (...args) => navigate(...args),
    }

    return (
      <Component
        {...props}
        history={history}
        location={location}
        router={{ location, navigate, params }}
      />
    )
  }

  return ComponentWithRouterProp
}

export default withRouter
