import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { compose } from 'recompose'
import { Row } from '..'
import { fields } from '../../../common/lib/redux-fields'
import { CheckboxField, SwitchField } from '../fields'
import { fetchPriceTypes } from '../../../common/catalog/actions'
import { setPricesConfig } from '../../../common/config/actions'
import { shownPriceTypes } from '../../../common/config'

class PricesSettingForm extends React.Component {
  componentDidMount() {
    // price types are now static in setting
    // this.props.fetchPriceTypes()
  }

  onShowPriceChange = e => {
    const { fields } = this.props
    fields.isPriceShown.onChange(e)
    setPricesConfig()
    this.confirmForm()
  }

  onPriceTypeChange = e => {
    const { fields } = this.props
    fields.priceType.onChange(e)
    this.confirmForm()
  }

  confirmForm = () => {
    const { fields, setPricesConfig } = this.props
    const values = fields.$values()
    setPricesConfig(values)
  }

  render() {
    const { t, fields } = this.props

    const priceTypeOptions = Object.keys(shownPriceTypes).map(key => {
      const priceType = shownPriceTypes[key]
      return {
        label: t(`reize; ${priceType}`),
        value: priceType,
      }
    })

    const showPriceType = fields.isPriceShown.value
    return (
      <>
        <Row>
          <CheckboxField
            label={t('reize; Show prices')}
            {...fields.isPriceShown}
            onChange={this.onShowPriceChange}
          />
        </Row>
        {showPriceType && (
          <Row>
            <SwitchField
              label={t('reize; Price type')}
              options={priceTypeOptions}
              required
              {...fields.priceType}
              onChange={this.onPriceTypeChange}
            />
          </Row>
        )}
      </>
    )
  }
}

PricesSettingForm.defaultProps = {
  priceTypes: [],
}

PricesSettingForm.propTypes = {
  t: PropTypes.func.isRequired,
  fields: PropTypes.object.isRequired,
  fetchPriceTypes: PropTypes.func.isRequired,
  priceTypes: PropTypes.array,
  setPricesConfig: PropTypes.func.isRequired,
}

const enhance = compose(
  connect(
    ({ catalog, config = {} }) => ({
      priceTypes: catalog.priceTypes,
      isPriceShown: config.isPriceShown,
      priceType: config.priceType,
      config,
    }),
    { fetchPriceTypes, setPricesConfig },
  ),
  fields({
    path: 'pricesSettingForm',
    fields: ['isPriceShown', 'priceType'],
    getInitialState: ({ isPriceShown, priceType }) => ({
      isPriceShown,
      priceType,
    }),
  }),
  withTranslation(),
)

export default enhance(PricesSettingForm)
