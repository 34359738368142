import axios from 'axios'

// fetches url to redirect to and redirects to it
export const redirectToOAuthSignIn = async appTypeName => {
  const response = await axios({
    method: 'get',
    baseURL: '/api/v1',
    url: '/auth/authorize',
  })

  const url = response?.data?.data?.url
  window.location.href = url
}
