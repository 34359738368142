import React, { Component, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Row, Text, Gap, Button } from '.'

const ErrorTryAgain = ({ onClick }) => {
  const { t } = useTranslation()
  return (
    <Row justifyContent="center" alignItems="center">
      <Text center big>
        ☹️ {t('Something went wrong')}
      </Text>
      <Gap />
      <Button onClick={onClick}>{t('Try again!')}</Button>
    </Row>
  )
}

export default ErrorTryAgain
