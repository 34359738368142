import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { withTranslation } from 'react-i18next'

import { compose } from 'redux'
import styled, { css } from 'styled-components'
import {
  fetchTracers,
  deleteTracer,
  setDefaultTracer,
  setTracerConfig,
} from '../../common/config/actions'
import { SettingsTracerForm } from './forms'
import { Box, Row, Col, Button, Gap, InfoMessage, Link } from '.'
import i18n from '../../common/i18n/i18n'
import { appTypeConfig } from '../../common/config'
import { withNotifs } from './hoc'
import Popup from './popups/Popup'
import { StyledDeleteIconWrapper, StyledEditIconWrapper } from './forms/SettingsWorkersForm'
import Icon from './Icon'
import { FixedGap } from './Gap'
import { PopTooltip } from './Tooltip'
import Text from './Text'
import { CheckboxField } from './fields'
import { TableLikeRow, TableLikeRowsWrapper } from './TableLikeRows'
import BasicRow from './BasicRow'
import { editMe, fetchMe } from '../../common/app/actions'

const ItemWrapper = styled.div`
  display: flex;
  direction: row;
  align-items: center;
`

export const StarIconWrapper = styled.div`
  ${({ selected, theme }) => css`
    color: ${!selected ? '#4e4e4e' : theme.colors.primary};
    opacity: ${selected ? '1' : '0.5'};
    transition: 0.3s;
    cursor: pointer;
    padding: 0 0.8rem;
    &:hover {
      opacity: 1;
      /* color: ${p => p.theme.colors.primaryLight}; */
    }
  `}
`

export function getTracerLabel(tracer) {
  return `${tracer.usersName} ${tracer.usersName ? '(' : ''}${tracer.name}${
    tracer.usersName ? ')' : ''
  }
  `
}

function TracersSettings(props) {
  const { t } = props

  const dispatch = useDispatch()
  const tracers = useSelector(state => state.config.tracers)
  const defaultTracerId = useSelector(state => state.config.defaultTracer)
  const tracerEnabled = useSelector(state => state.app.viewer.tracerEnabled)
  const isMeEditing = useSelector(state => state.app.isMeEditing)

  useEffect(() => {
    dispatch(fetchTracers())
  }, [dispatch])

  useEffect(() => {
    dispatch(fetchMe())
  }, [dispatch])

  const popupRef = useRef(null)

  const [editingId, setEditingId] = useState()

  const defaultTracerTooltipText = t(
    'If you select default tracer then on the step 2 this tracer will be used without asking.'
  )

  const lang = i18n.language.split('-')[0]
  const useCustomManual = appTypeConfig.tracerManualCustomLanguages?.includes(lang)

  return (
    <div>
      <FixedGap gap="1rem" />
      <Row>
        <CheckboxField
          label={t('i have a tracer')}
          name="tracerEnabled"
          value={tracerEnabled}
          disabled={isMeEditing}
          onChange={async () => {
            dispatch(
              editMe({
                tracerEnabled: !tracerEnabled,
              })
            ).meta.action.payload
          }}
        />
      </Row>
      <FixedGap gap="1rem" />

      {tracerEnabled && (
        <>
          <Popup
            ref={popupRef}
            title={editingId ? t('Edit tracer') : t('Add new tracer')}
            displayTextInMessage={false}
            text={
              <>
                <SettingsTracerForm
                  allTracers={tracers}
                  editingId={editingId}
                  onCancel={() => {
                    popupRef.current?.close?.()
                  }}
                  onDone={() => {
                    popupRef.current?.close?.()
                  }}
                />
              </>
            }
            closeOnOutsideClick
            closeOnEsc
            disableAutoClose
            onClose={opts => {
              if (opts.ok) {
                // this.submitForm()
              } else {
                popupRef.current?.close?.()
              }
            }}
            showControlButtons={false}
          >
            {({ open }) => (
              <>
                {!tracers?.length && (
                  <Col>
                    <Text size="1.6rem">{t('No tracers yet')}</Text>
                    {/* <Gap gap="0.3rem" />
                    <Text>Start with adding new tracer below</Text> */}
                  </Col>
                )}

                {tracers?.length > 0 && (
                  <>
                    <Col>
                      <Text bold>{t('Available tracers')}</Text>
                      <Gap gap="0.5rem" />
                    </Col>

                    <TableLikeRowsWrapper>
                      {tracers.map(tracer => (
                        <TableLikeRow
                          key={tracer._id}
                          justifyContent="space-between"
                          padding="1.2rem 0.8rem"
                        >
                          <BasicRow alignItems="center">
                            <PopTooltip text={defaultTracerTooltipText} interactive={false}>
                              <StarIconWrapper
                                onClick={e => {
                                  dispatch(
                                    setDefaultTracer(
                                      defaultTracerId === tracer._id ? null : tracer._id
                                    )
                                  )
                                }}
                                selected={defaultTracerId === tracer._id}
                              >
                                <Icon
                                  name={
                                    defaultTracerId === tracer._id ? 'IosStar' : 'IosStarOutline'
                                  }
                                  size={20}
                                />
                              </StarIconWrapper>
                            </PopTooltip>
                            {getTracerLabel(tracer)}
                          </BasicRow>
                          <BasicRow>
                            <StyledEditIconWrapper
                              onClick={e => {
                                setEditingId(tracer._id)
                                open(e)
                              }}
                            >
                              <Icon name="MdCreate" size={20} />
                            </StyledEditIconWrapper>
                            <Popup
                              title={t('Are you sure?')}
                              text={`${t('Do you want to delete tracer')} ${getTracerLabel(
                                tracer
                              )}?`}
                              closeOnOutsideClick
                              closeOnEsc
                              onClose={opts => {
                                if (opts.ok) {
                                  dispatch(deleteTracer(tracer._id))
                                }
                              }}
                              okText={t('Delete')}
                              closeText={t('cancel')}
                            >
                              {({ open }) => (
                                <>
                                  <StyledDeleteIconWrapper onClick={open}>
                                    <Icon name="IosTrash" size={20} />
                                  </StyledDeleteIconWrapper>
                                </>
                              )}
                            </Popup>
                          </BasicRow>
                        </TableLikeRow>
                      ))}
                    </TableLikeRowsWrapper>
                  </>
                )}

                <FixedGap gap="1rem" />
                <Row>
                  <Button
                    primary
                    onClick={e => {
                      setEditingId(null)
                      open(e)
                    }}
                  >
                    {t('Add new tracer')}
                  </Button>
                  <Gap />
                  {/* {tracers?.length ? (
                    <PopTooltip text={defaultTracerTooltipText} interactive={false}>
                      <Button
                        onClick={e => {
                          dispatch(setDefaultTracer(null))
                        }}
                      >
                        Remove default tracer
                      </Button>
                    </PopTooltip>
                  ) : null} */}
                </Row>
              </>
            )}
          </Popup>
          <FixedGap gap="2rem" />

          <Row>
            <Col>
              <InfoMessage>
                <Link blue external target="_blank" href="/public/LensTracer.exe">
                  {t('settings - tracer - bridge download')}
                </Link>
                <br />
                {t('settings - tracer - bridge info')}
                <br />
              </InfoMessage>
              <Gap />

              <InfoMessage>
                <Link blue external target="_blank" href="/public/LensTracer.dmg">
                  {t('settings - tracer - bridge download for MacOS')}
                </Link>
              </InfoMessage>

              <Gap />
              <InfoMessage>
                <Link
                  blue
                  external
                  target="_blank"
                  href={`/public/manual/${
                    useCustomManual ? `${appTypeConfig.APP_TYPE}/` : ''
                  }manual-${lang}.pdf`}
                >
                  {t('settings - tracer - manual download')}
                </Link>
              </InfoMessage>
            </Col>
          </Row>
        </>
      )}
    </div>
  )
}

const enhance = compose(withNotifs, withTranslation())

export default enhance(TracersSettings)
