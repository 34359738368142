import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import MaterialTooltip from '@material-ui/core/Tooltip'
import { withStyles } from '@material-ui/core/styles'
import Icon from './Icon'

const ChildrenWrapper = styled.div`
  cursor: pointer;
  display: flex;
`

const ChildrenCenter = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const StyledTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: 'rgba(50,50,50,0.95)',
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.3)',
    padding: 16,
    fontSize: '1.4rem',
    borderRadius: 8,
    lineHeight: '2.1rem',
    color: 'white',
    border: 'none',
  },
  arrow: {
    color: 'rgba(50,50,50,0.95)',
    marginBottom: '-9.1px !important',
    marginTop: '-8.6px !important',
  },
}))(MaterialTooltip)

export const TinyPopTooltip = ({ children, down, text, leaveDelay }) => (
  <StyledTooltip
    title={text}
    arrow
    placement={down ? 'bottom' : 'top'}
    leaveDelay={leaveDelay}
    interactive
    // open
  >
    <div>{children}</div>
  </StyledTooltip>
)

export const PopTooltip = ({
  children,
  down,
  text,
  leaveDelay,
  interactive = true,
  tooltipStyle,
}) => (
  <ChildrenCenter>
    <StyledTooltip
      title={text}
      arrow
      placement={down ? 'bottom' : 'top'}
      leaveDelay={leaveDelay}
      interactive={interactive}
      style={tooltipStyle}
    >
      <ChildrenWrapper>{children}</ChildrenWrapper>
    </StyledTooltip>
  </ChildrenCenter>
)

const Tooltip = ({ text, down, interactive = true }) => (
  <StyledTooltip
    title={text}
    arrow
    placement={down ? 'bottom' : 'top'}
    leaveDelay={400}
    interactive={interactive}
    // open
  >
    <ChildrenWrapper>
      <Icon size={20} name="IosHelpCircleOutline" style={{ opacity: 0.6 }} />
    </ChildrenWrapper>
  </StyledTooltip>
)

export default Tooltip
