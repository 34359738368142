// this our more modern row from current (2021) projects, without responsivity

import React from 'react'
import styled, { css } from 'styled-components'

const BasicRow = styled.div`
  ${({
    responsive,
    align,
    alignItems,
    alignSelf,
    justify,
    justifyContent,
    mobileAlignItems,
    mobileAlign,
    maxWidth,
    mobileMaxWidth,
    position,
    margin,
    mobileMargin,
    padding,
    mobilePadding,
    wrap,
    width,
    shrink,
    grow,
    mobileWidth,
    height,
    mobileFlexDirection,
  }) => css`
    display: flex;
    align-items: ${mobileAlignItems || mobileAlign || alignItems || align};
    align-self: ${alignSelf};
    justify-content: ${justifyContent || justify};
    max-width: ${mobileMaxWidth || maxWidth};
    width: ${mobileWidth || width};
    height: ${height};
    position: ${position};
    flex-shrink: ${shrink};
    flex-grow: ${grow};
    margin: ${mobileMargin || margin};
    padding: ${mobilePadding || padding};
    flex-direction: ${mobileFlexDirection || (responsive ? 'column' : 'row')};

    flex-wrap: ${wrap};
  `}
`
export default BasicRow
