import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { withTranslation } from 'react-i18next'
import { Link } from '../components'

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 10rem;
  ${({ theme: { colors } }) => css`
    color: ${colors.text2};
    background: ${colors.pageBackground};
  `}
`
const StyledLink = styled(Link)`
  ${({ theme: { colors } }) => css`
    color: ${colors.primary};
  `}
`

function NotFoundPage({ t }) {
  return (
    <Wrapper>
      <h1>{t('page not found')} :(</h1>
      <StyledLink to="/">{t('Back to homepage')}</StyledLink>
    </Wrapper>
  )
}

NotFoundPage.propTypes = {
  t: PropTypes.func.isRequired,
}

export default withTranslation()(NotFoundPage)
