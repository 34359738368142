import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { fields } from '../../../common/lib/redux-fields'
import { TextField, CheckboxField, SelectField, SwitchField, NumberField } from '../fields'
import { Box, Row, Col, Button } from '..'

import Validation from '../../../common/lib/validation'

const validate = data => {
  console.log('VALIDATION FUNC')
  const validator = new Validation(data)
  if (typeof data.reference !== 'undefined') validator.isRequired('reference', 'Reference je povinná')
  return validator.validate()
}

class TestForm extends React.Component {
  submitForm = () => {
    const { fields } = this.props
    const values = fields.$values()
    const isValid = fields.$validate()
    console.log('IS FORM VALID', isValid)
    console.log('SUBMIT FORM', values)

    // use cases
    // 1. takes $values() and calls submitFunc from down definition
    fields.$submit()
    // 2. custom data + calls submitFunc
    fields.$submit(values)
    // 3. takes $values() and call custom func
    fields.$submit(() => {
      console.log('FORM SUBMITED - CUSTOM FUNC 3')
    })
    // 4. both data and func are custom
    fields.$submit(values, () => {
      console.log('FORM SUBMITED - CUSTOM FUNC 4')
    })
  }

  render() {
    console.log('>>>> TEST FORM PROPS', this.props)
    const { fields } = this.props
    return (
      <form>
        {fields.$errors.map((e, key) =>
          <h3 key={key}>{e.message}</h3>)}
        <Box>
          <Box.Header>
            <Box.Title>TEST</Box.Title>
          </Box.Header>
          <Box.Content>
            <Row>
              <Col width="25%">
                <TextField
                  required
                  tooltip="tooltip test"
                  label="Reference"
                  {...fields.reference}
                />
              </Col>
              <Col width="25%">
                <TextField label="Jméno klienta" {...fields.clientName} />
              </Col>
              <Col width="50%">
                <TextField label="Interní poznámka" {...fields.internalNote} />
              </Col>
            </Row>
            <Row justifyContent="flex-end">
              <Button large primary onClick={this.submitForm}>
                Odeslat
              </Button>
            </Row>
          </Box.Content>
        </Box>
      </form>
    )
  }
}

TestForm.propTypes = {
  fields: PropTypes.object.isRequired,
}

const enhance = compose(
  connect(() => ({}), {}),
  fields({
    path: 'TestForm',
    fields: ['reference', 'clientName', 'internalNote'],
    validationFunc: validate,
    submitFunc: () => {
      console.log('SUBMIT FUNCTION CALLED 1')
    },
  }),
)

export default enhance(TestForm)
