import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000052;
  color: white;
  padding: 10rem;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;

`

// eslint-disable-next-line
class ErrorFallback extends React.Component {
  state = {
    issueId: null,
  }

  componentDidMount() {
    this.interval = setInterval(this.checkForIssueId, 1000)
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  checkForIssueId = () => {
    this.setState({ issueId: window.issueId })
  }

  render() {
    const { issueId } = this.state

    return (
      <Wrapper>
        <h1>
          {/* Ajajaj! Stala se chyba! :( */}
          Oh, some error happened! :(
        </h1>
        <h2>
          {/* Ale buďte v klidu, již jsme vyslali tým cvičených opic, aby chybu vyřešil. */}
          But stay calm, we have already assigned a team of highly trained developers to solve this
          issue. 🐒
        </h2>
        <br />
        <p>In the meantime, you can try:</p>
        <ul>
          <li>Refresh this page.</li>
          <li>Try again in 30 minutes.</li>
          <li>Send us an email or call us.</li>
        </ul>
        <br />
        {issueId && <p>Issue ID: {issueId}</p>}
      </Wrapper>
    )
  }
}

export default ErrorFallback
