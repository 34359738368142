import styled from 'styled-components'
import Input from './Input'

export default styled(Input).attrs({
  rows: 5,
  as: 'textarea',
})`
  line-height: 2rem;
  font-family: ${({ theme }) => theme.font.fontFamily};
  resize: vertical;
`
