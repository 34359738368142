import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import ReactDOM from 'react-dom'
import { IoIosArrowUp, IoIosArrowDown } from 'react-icons/io'
import { Text, Row, Col } from '.'

const TABLE_SIDE_PADDING = '2rem'
const CELL_SIDE_PADDING = '0.7rem'

const TableSidePadding = css`
  &:first-child {
    padding-left: ${TABLE_SIDE_PADDING};
  }
  &:last-child {
    padding-right: ${TABLE_SIDE_PADDING};
  }
`

const ScrollableWrapper = styled.div`
  max-height: 70vh;
  overflow-y: auto;

  border-style: solid;
  border-width: ${({ theme }) => theme.field.borderWidth};
  border-color: ${({ theme }) => theme.colors.groupBorder};
  border-radius: ${({ theme }) => theme.field.borderRadius};
`

const StyledTable = styled.table`
  text-align: left;
  width: 100%;
  border: none;
  border-collapse: collapse;
  position: relative;
  /* border: solid 1px transparent; */
`

const HeaderRow = styled.tr`
  /* border-bottom: 10px solid #c4c4c4; */
  border-color: black;
  position: sticky;
  top: 0;
  background: white;
  opacity: 1;
  z-index: 1000;
  transform: translateY(-1px);
`

const Body = styled.tbody``

const TableHeading = styled.th`
  ${({ compact }) => css`
    position: relative;
    cursor: pointer;
    user-select: none;
    padding: 1.2rem ${CELL_SIDE_PADDING};

    ${TableSidePadding};

    &::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      border-bottom: 1px solid #c4c4c4;
    }
  `}
`

const rowWrapperColor = ({ theme, isActive, isOdd }) => {
  if (isActive) return theme.colors.tableActiveRow
  if (isOdd) return theme.colors.tableOddRowBackground
  return 'inherit'
}

const RowWrapper = styled.tr`
  ${({ theme, isActive, isDisabled }) => css`

    &:nth-child(odd) {
      background-color: ${rowWrapperColor({ theme, isActive, isDisabled, isOdd: true })};
    }
    &:hover {
      background-color: #f0f0f0;
    }
    ${({ isActive, theme }) => css`
      background-color: ${rowWrapperColor({ theme, isActive, isDisabled, isOdd: false })};
    `}
    opacity: ${isDisabled ? 0.3 : 1};
  `}
`

const TableCell = styled.td`
  ${({ compact }) => css`
    ${TableSidePadding};
    padding: 0.1rem ${CELL_SIDE_PADDING};
  `}
`

const TableRow = ({
  row,
  columns, //eslint-disable-line
  isActive,
  compact,
  ...props
}) => {
  const { isDisabled } = row
  return (
    <RowWrapper isActive={isActive} isDisabled={isDisabled} {...props}>
      {columns.map(({ colProps, item, renderCol }, key) => (
        <TableCell key={key} {...colProps} compact={compact}>
          {renderCol ? renderCol(row[item], row) : <Text color="black">{row[item]}</Text>}
        </TableCell>
      ))}
    </RowWrapper>
  )
}

const Table = ({ name, columns, data, compact, onBodyScroll, onRowClick, activeItemId }) => (
  <ScrollableWrapper>
    <StyledTable name={name}>
      <HeaderRow>
        {columns.map(
          (
            {
              colProps,
              headerColProps,
              header,
              renderHeader,
            },
            key,
          ) => (
            <TableHeading
              key={key}
              {...colProps}
              {...headerColProps}
              compact={compact}
            >
              {renderHeader ? renderHeader() : <Text color="black">{header}</Text>}
            </TableHeading>
          ),
        )}
      </HeaderRow>
      <Body name={`${name}-body`} onScroll={onBodyScroll}>
        {data.map(row => (
          <TableRow
            row={row}
            key={`${row._id}`}
            columns={columns}
            onClick={() => onRowClick && onRowClick(row)}
            isActive={activeItemId === row._id}
            style={onRowClick && { cursor: 'pointer' }}
            compact={compact}
          />
        ))}
      </Body>
    </StyledTable>
  </ScrollableWrapper>
)

Table.defaultProps = {
  data: [],
  sortBy: '',
  sortAsc: true,
  sortHandler: () => {},
  name: '',
  onRowClick: null,
  activeItemId: null,
  compact: false,
}

Table.propTypes = {
  data: PropTypes.array,
  columns: PropTypes.array.isRequired,
  sortBy: PropTypes.string,
  sortAsc: PropTypes.bool,
  sortHandler: PropTypes.func,
  name: PropTypes.string,
  onRowClick: PropTypes.func,
  activeItemId: PropTypes.string,
  compact: PropTypes.bool,
}

export default Table
