import React from 'react'
import Icon from 'react-icon-base'

const Manufactured = props => (
  <Icon viewBox="0 0 25 26" {...props}>
    <circle cx="12.5" cy="12.5" r="12.5" transform="translate(0 0.333252)" fill="#56CCF2" />
    <path d="M10.3786 0.384615C10.002 0.148237 9.44511 0 8.86018 0C8.28726 0 7.64623 0.10016 6.98918 0.36859C5.1262 1.12981 4.02444 2.1875 3.73998 2.46795C3.45953 2.7484 3.06691 3.1891 2.82252 3.53365C2.57812 3.88221 2.89864 4.375 2.57812 4.69551C2.25761 5.01603 1.58854 4.69551 1.58854 4.69551C1.53245 4.69551 1.47236 4.71554 1.43229 4.75962L0.0661058 6.11779C-0.0220352 6.20192 -0.0220352 6.34615 0.0661058 6.43029L2.61018 8.95833C2.65425 9.0024 2.71034 9.02244 2.76643 9.02244C2.82252 9.02244 2.88261 9.0024 2.92268 8.95833L4.2528 7.5641C4.34095 7.47997 4.34095 7.33574 4.2528 7.2516C4.2528 7.2516 4.04046 7.04327 3.76803 6.77083C3.49559 6.4984 3.80008 5.9976 3.98037 5.83333C4.16066 5.66907 4.44511 5.5609 4.82572 5.5609C4.998 5.5609 5.12219 5.58894 5.28245 5.63301C5.77123 5.76923 6.31611 6.27003 7.32171 7.26763L7.16546 7.52804C7.07332 7.68029 7.12941 7.86058 7.21755 7.94471C7.21755 7.94471 7.01723 7.7484 7.74239 8.46955L9.74559 6.57853C8.98037 5.82131 9.16466 6.01362 9.16466 6.01362C9.10857 5.95753 9.01242 5.92147 8.91226 5.92147C8.85617 5.92147 8.79607 5.93349 8.74399 5.96154L8.51963 6.08173C7.39784 4.96795 7.10537 4.47115 7.14543 3.60978C7.1895 2.71234 7.63822 2.1234 8.36739 1.4984C9.35697 0.653045 10.7953 0.889423 10.7953 0.889423C11.1158 0.885417 10.627 0.544872 10.3786 0.384615ZM17.8866 14.0986C17.8866 14.0986 15.1262 11.8069 12.6703 9.46314L10.7592 11.4904C13.103 13.9183 15.3626 16.6026 15.3626 16.6026C15.4067 16.6466 15.4627 16.6667 15.5188 16.6667C15.5749 16.6667 15.635 16.6466 15.6751 16.6026L17.8826 14.4111C17.9708 14.3269 17.9708 14.1827 17.8866 14.0986Z" transform="translate(2.77734 4.49976)" fill="white"/>
    <path d="M15.9747 3.08494L13.8754 5.19231L11.8281 4.81971L11.4595 2.77244L13.5589 0.665064C13.1141 0.220353 12.4851 -3.05665e-08 11.9964 -3.05665e-08C11.9683 -3.05665e-08 11.9403 6.11927e-08 11.9162 0.00400647C11.4154 0.0280449 10.3457 0.3125 9.51638 1.19391C8.7151 2.03926 7.86975 3.62179 8.61895 5.3726C8.70709 5.58494 8.80725 5.86138 8.51077 6.16186C8.45068 6.21795 8.11414 6.53846 7.60933 7.01522C7.4651 7.15144 7.31286 7.29567 7.14459 7.45593C6.82407 7.76042 6.45949 8.10497 6.07087 8.46555C5.91863 8.60978 5.76238 8.75401 5.60613 8.90625C3.32247 11.0617 0.521991 13.6939 0.521991 13.6939C-0.199163 14.3149 -0.147079 15.4607 0.517985 16.1298C0.85853 16.4663 1.31927 16.6426 1.77199 16.6426C2.2127 16.6426 2.64939 16.4744 2.95388 16.1178C2.95388 16.1178 5.57808 13.3213 7.73353 11.0337C7.88177 10.8734 8.03 10.7212 8.17424 10.5649C8.54683 10.1683 8.89539 9.79968 9.20789 9.47116C9.36414 9.30289 9.51238 9.14664 9.64859 9.00641C10.1093 8.51763 10.4218 8.1891 10.4899 8.125C10.6502 7.96875 10.7984 7.92067 10.9347 7.92067C11.0669 7.92067 11.183 7.96875 11.2792 8.01683C11.6758 8.22115 12.1205 8.3093 12.5773 8.3093C13.647 8.3093 14.7808 7.8125 15.4659 7.1274C16.4435 6.14984 16.6197 5.20833 16.6518 4.72356C16.6758 4.2508 16.5076 3.61779 15.9747 3.08494ZM2.28081 15.1362C2.06045 15.3526 1.70388 15.3526 1.48754 15.1362C1.27119 14.9159 1.27119 14.5593 1.48754 14.343C1.70789 14.1266 2.06446 14.1266 2.28081 14.343C2.49715 14.5633 2.49715 14.9199 2.28081 15.1362Z" transform="translate(5.3584 4.51172)" fill="white"/>
  </Icon>
)

export default Manufactured
