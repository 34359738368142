import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

import { saveVcaToMyShapes } from '../../../common/vca/actions'
import Popup from '../popups/Popup'
import { Row, Button } from '..'
import { TextField } from '../fields'

// TODO: nice to have - focus on popup open
class SaveShapePopupForm extends Component {
  state = {
    name: this.props.initialShapeName,
  }

  render() {
    const { onClose, children, t } = this.props
    const { name } = this.state
    return (
      <Popup
        title={t('save shape')}
        closeOnOutsideClick
        closeOnEsc
        onClose={opts => {
          onClose(opts, this.state.name)
          this.setState({ name: '' })
        }}
        okText={t('save')}
        closeText={t('cancel')}
        text={(
          <>
            <Row>{t('Name your shape')}</Row>
            <Row>
              <TextField
                name="shape-name"
                label={t('shape name')}
                value={name}
                onChange={e => {
                  this.setState({ name: e.value })
                }}
              />
            </Row>
          </>
        )}
      >
        {children}
      </Popup>
    )
  }
}

SaveShapePopupForm.propTypes = {
  t: PropTypes.func.isRequired,
}

export default withTranslation()(SaveShapePopupForm)
