import React from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import { Link as RouterLink, NavLink as RouterNavLink } from 'react-router-dom'
import Button from './Button'
import links from '../app/links'

const transformBooleanValue = value => value ? 1 : 0

const transformProps = data => {
  const response = { ...data }
  response.primary = transformBooleanValue(response.primary)
  response.large = transformBooleanValue(response.large)
  response.blue = transformBooleanValue(response.blue)
  delete response.tReady
  delete response.staticContext
  return response
}

// Assigns links as statics properties
const assignStaticsLinks = component => {
  Object.keys(links).forEach(key => {
    component[key] = links[key]
  })
}

// active and done are passed from Steps.js. They are removed here because of warning
// eslint-disable-next-line
const LinkComponent = ({ active, done, isNavLink, external, ...props }) =>
  external
    ? <a {...transformProps(props)} /> // eslint-disable-line
    : isNavLink
      ? <RouterNavLink {...transformProps(props)} />
      : <RouterLink {...transformProps(props)} />

LinkComponent.defaultProps = {
  external: false,
}

LinkComponent.propTypes = {
  external: PropTypes.bool,
}

assignStaticsLinks(LinkComponent)

const linkStyles = css`
  text-decoration: none;
  color: ${({ theme, blue, primary, white }) =>
    blue || primary ? theme.colors.primary : white ? 'white' : theme.text.color};
  font-size: 1.6rem;
  font-size: ${({ size }) => size};
`

export const Link = styled(LinkComponent)`
  ${linkStyles}
`
export const NavLink = styled(LinkComponent).attrs(() => ({ isNavLink: true }))`
  ${linkStyles}
`
assignStaticsLinks(Link)

const styles = {
  linkButton: {
    textDecoration: 'none',
  },
}
const LinkButtonComponent = ({ large, primary, icon, leftIcon, iconSize, ...props }) => (
  <LinkComponent {...props} style={styles.linkButton}>
    <Button large={large} primary={primary} leftIcon={leftIcon} iconSize={iconSize} {...props} />
  </LinkComponent>
)

export const LinkButton = styled(LinkButtonComponent)``

assignStaticsLinks(LinkButton)
