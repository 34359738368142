import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import MaterialTooltip from '@material-ui/core/Tooltip'
import { withStyles } from '@material-ui/core/styles'
import { format } from 'date-fns'
import { sortOrderStatusHistory } from '../../common/orders/utils'
import T from './T'
import { orderStatuses } from '../../common/config'

const tooltipStyles = {
  tooltip: {
    backgroundColor: 'white',
    boxShadow: '0px 2px 32px 11px rgb(0 0 0 / 26%)',
    padding: 0,
    borderRadius: 8,
    border: 'none',
    fontSize: '1.4rem',
    maxWidth: '330px',
  },
  arrow: {
    color: 'white',
    marginBottom: '-9.1px !important',
    marginTop: '-8.6px !important',
  },
}

const StyledTooltipWhiteArrow = withStyles(() => tooltipStyles)(MaterialTooltip)
// const StyledTooltipDarkArrow = withStyles(() => ({
//   ...tooltipStyles,
//   arrow: { ...tooltipStyles.arrow, color: '#f1f1f1' },
// }))(MaterialTooltip)

const ChildrenWrapper = styled.span`
  cursor: pointer;
`

const PopupContainer = styled.div`
  min-width: 330px;
  /* padding-bottom: 10px; */
  overflow: hidden;
  border-radius: 8px;
`

const Title = styled.div`
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 18px;
  color: black;
  text-align: center;
  padding: 14px;
`

const StatusRow = styled.div`
  font-weight: 400;
  font-size: 1.4rem;
  color: black;
  text-align: left;
  padding: 5px 16px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;

  &:nth-child(odd) {
    background-color: #f1f1f1;
  }
`
const StatusRowsContainer = styled.div`
  max-height: 400px;
  overflow-y: auto;
`

const DatetimeStamp = styled.div`
  font-weight: 600;
  width: 130px;
`
const StatusText = styled.div`
  ${({ isSubStatus, isFutureStatus, theme: { colors } }) => css`
    padding-left: ${isSubStatus ? '14px' : '4px'};
    font-style: ${isSubStatus && 'italic'};
    color: ${isFutureStatus && colors.orange};
  `}
`

const StatusHistoryTooltip = ({ children, order }) => {
  const sortedStatusHistory = sortOrderStatusHistory(order)
  // console.log('sortedStatusHistory', sortedStatusHistory)

  if (!sortedStatusHistory?.length) {
    return children
  }

  const TooltipContent = () => (
    <PopupContainer>
      <Title>
        <T>Status history</T>
      </Title>
      <StatusRowsContainer>
        {sortedStatusHistory.map((history, index) => (
          <StatusRow key={index}>
            <DatetimeStamp>{format(new Date(history.statusAt), 'd. M. yyyy HH:mm')}</DatetimeStamp>
            <StatusText isSubStatus={history.isSubStatus}>
              <T>{history.statusName}</T>
            </StatusText>
          </StatusRow>
        ))}
        {order.estimatedDepartureAt && order.status === orderStatuses.AT_MANUFACTURER && (
          <StatusRow>
            <DatetimeStamp>
              {format(new Date(order.estimatedDepartureAt), 'd. M. yyyy')}
            </DatetimeStamp>
            <StatusText isFutureStatus>
              <T>Estimated departure time</T>
            </StatusText>
          </StatusRow>
        )}
      </StatusRowsContainer>
    </PopupContainer>
  )

  return (
    <StyledTooltipWhiteArrow
      title={<TooltipContent />}
      arrow
      // placement="bottom"
      // leaveDelay={10000000}
      interactive
      // enterDelay={300}
      // open
    >
      <ChildrenWrapper>{children}</ChildrenWrapper>
    </StyledTooltipWhiteArrow>
  )
}

export default StatusHistoryTooltip

const ContentContainer = styled.div`
  /* padding: 2rem; */
`

const TooltipContent = ({ children }) => (
  <PopupContainer>
    <ContentContainer>{children}</ContentContainer>
  </PopupContainer>
)

export const HistoryLikeTooltip = ({ children, content }) => (
  <StyledTooltipWhiteArrow
    // open
    title={<TooltipContent>{content}</TooltipContent>}
    arrow
    interactive
  >
    <ChildrenWrapper>{children}</ChildrenWrapper>
  </StyledTooltipWhiteArrow>
)

export const TooltipRow = styled.div`
  font-weight: 400;
  font-size: 1.4rem;
  color: black;
  text-align: left;
  padding: 0.8rem 1.6rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;

  &:nth-child(even) {
    background-color: #f1f1f1;
  }
`
