import React, { Component, useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { withTranslation, useTranslation } from 'react-i18next'
import styled, { css, withTheme } from 'styled-components'
import { DndProvider, DragPreviewImage, useDrag, DndContext, useDrop } from 'react-dnd'

import MouseBackend from 'react-dnd-mouse-backend'

import { HTML5Backend } from 'react-dnd-html5-backend'
import Deformator from '../vca/Deformator'
import Visualiser from '../vca/Visualiser'
import PopupPresentational from './PopupPresentational'
import Popup from './Popup'
import { CheckboxField, TextField } from '../fields'
import { Row, Col, Text, Gap, Icon, Button } from '..'
import { createFusionOrder } from '../../../common/orders/actions'
import { removeDiacritics } from '../../../common/lib/text'
import FlatList from '../FlatList'
import { textStyle } from '../Text'
import { Container, Step, StepNumber, Wrapper as StepsWrapper } from '../Steps'
import MilensVisualiser from '../MilensVisualiser'
import SliderRange from '../SliderRange'
import { withNotifs } from '../hoc'
import { Link } from '../Link'
import T from '../T'
import { appTypeConfig } from '../../../common/config'

/**
 * TODOs:
 *  - api komplet - napul hotovo
 *  - otestovat
 */

const types = { ACTIVITY: 'ACTIVITY' }
const activities = ['driving', 'outdoor', 'pc', 'phone', 'reading']
export const bgImages = ['outdoor', 'driving', 'golf', 'pc']

const BgImageThumb = styled.img`
  ${({ active, theme: { colors } }) => css`
    border-radius: 8px;
    opacity: ${active ? 1 : 0.3};
    transition: 0.3s;
    cursor: pointer;

    &:hover {
      opacity: 1;
    }
  `};
`

const ActivityImg = styled.img`
  ${({ offset, hovered, isDragging, isUsed, withoutHover }) => css`
    width: 180px;
    min-height: 156px;
    top: ${offset && '-78px'};
    z-index: ${hovered ? 1 : 0};
    pointer-events: ${hovered ? 'all' : 'none'};
    transition: transform 0.2s;
    cursor: move;

    &:hover {
      pointer-events: all;
      z-index: 1;

      ${!withoutHover &&
      css`
        transform: scale(0.95);
      `}
    }

    opacity: ${isUsed || isDragging ? 0 : 1};

    ${isUsed &&
    css`
      display: none;
    `}
  `};
`

const HexaSvg = styled.svg`
  z-index: 1;
  &:hover {
    & path {
      /* fill: orange; */
    }
  }
`
const HexaPlaceholderSvg = styled.svg`
  ${({ theme: { colors } }) => css`
    position: absolute;
    z-index: 0;
    & path {
      fill: ${colors.gray};
    }
  `}
`

const HexaPath = styled.path`
  z-index: 1;
  pointer-events: all;

  &:hover {
    /* fill: orange; */
  }
`

const HexaMask = ({ innerRef, onHover }) => (
  <HexaSvg
    // ref={innerRef}
    width="180"
    height="156"
    viewBox="0 0 180 156"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <HexaPath
      d="M2.25169 77.9658L46.097 2.02347L133.788 2.02347L177.633 77.9657L133.788 153.908L46.097 153.908L2.25169 77.9658Z"
      // fill="red"
      onMouseEnter={() => {
        onHover(true)
      }}
      onMouseLeave={() => {
        onHover(false)
      }}
    />
  </HexaSvg>
)
const HexaPlaceholder = () => (
  <HexaPlaceholderSvg
    width="180"
    height="156"
    viewBox="0 0 180 156"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <HexaPath d="M2.25169 77.9658L46.097 2.02347L133.788 2.02347L177.633 77.9657L133.788 153.908L46.097 153.908L2.25169 77.9658Z" />
  </HexaPlaceholderSvg>
)

const steps = [
  {
    text: 'omega; Current lenses',
  },
  {
    text: 'omega; Activity priorities',
  },
  {
    text: 'omega; Visualisation',
  },
]

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: ${({ top }) => (top ? 'flex-start' : 'center')};
  height: 100%;
`

const CurrentLensImg = styled.img`
  width: 240px;
  min-height: 196px;
  cursor: pointer;
  transition: 0.4s;

  &:hover {
    transform: scale(1.1);
  }
`

const ActivitiesWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  margin-top: 78px;
`

const ActivityWrapper = styled.div`
  position: relative;
  margin: 0 -20px;
  ${({ offset }) => css`
    top: ${offset && '-78px'};
  `};
`

const ActivityImageWrapper = styled.div`
  ${({ absolute }) => css`
    position: relative;
    ${absolute &&
    css`
      position: absolute;
      left: 0;
      top: 0;
      z-index: 2;
    `};
    width: 180px;
    height: 156px;

    & img,
    & svg {
      position: absolute;
    }
  `}
`

const PrioritiesWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 280px;
`

const PriorityWrapper = styled.div`
  ${({ offset, isOver, theme: { colors } }) => css`
    position: relative;
    margin: -2px -24px;
    top: ${offset && '76px'};
    background: ${isOver && 'red'};
    user-select: none;
  `};
`

const PriorityDrop = ({ children, offset, text }) => {
  const [{ isOver, canDrop }, drop] = useDrop({
    accept: types.ACTIVITY,
    drop: (item, monitor) => ({
      ...item,
      priority: text,
    }),
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  })
  return (
    <PriorityWrapper ref={drop} offset={offset}>
      <Priority isOver={isOver}>{text}</Priority>
      {children}
    </PriorityWrapper>
  )
}

const Priority = styled.div`
  ${({ offset, isOver, theme: { colors } }) => css`
    display: flex;
    user-select: none;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 700;
    color: ${isOver ? 'white' : colors.primary};
    width: 180px;
    min-height: 156px;
    background-image: url('data:image/svg+xml;utf8,<svg width="180" height="156" viewBox="0 0 180 156" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.42006 78.1341L45.4954 1.79346L133.646 1.79347L177.721 78.1341L133.646 154.475L45.4954 154.475L1.42006 78.1341Z"  fill="%23${isOver
      ? colors.primary.substring(1)
      : 'none'}"  stroke="%23${colors.primary.substring(1)}" stroke-width="4"/></svg>');
    position: relative;

    /* top: ${offset && '76px'}; */
  `};
`

const CurrentLens = ({ index, text, onClick }) => (
  <Col alignItems="center" onClick={onClick}>
    <CurrentLensImg src={`/public/images/milens/1-${index}.png`} alt={text} />
    <Gap gap="1rem" />
    <Row justifyContent="center" alignItems="flex-start">
      <Gap gap="4rem" />
      <Text>
        <T>{text}</T>
      </Text>
    </Row>
  </Col>
)

// const PrioritiesSorting = DndContext(MouseBackend)(() => (
//   <Row>
//     <ActivitiesWrapper>
//       {activities.map((key, index) =>
//         <Activity key={key} activityKey={key} offset={index % 2 === 0} />)}
//     </ActivitiesWrapper>
//     <Gap gap="3rem" />
//   </Row>
// ))

const DraggableActivityImg = ({
  activityKey,
  text,
  absolute,
  onDropSuccess,
  onDropFail,
  isUsed,
  withoutHover,
}) => {
  const [hovered, setHovered] = useState(false)

  const [{ isDragging }, drag, preview] = useDrag(
    () => ({
      type: types.ACTIVITY,
      item: { activityKey },
      collect: monitor => ({
        isDragging: monitor.isDragging(),
      }),
      end: (item, monitor) => {
        // const { id: droppedId, originalIndex } = item
        // console.log('item', item, monitor.getDropResult())
        const didDrop = monitor.didDrop()
        if (!didDrop) {
          // console.log('no drop')
          onDropFail(activityKey)
        } else {
          // console.log('dropped')
          onDropSuccess(monitor.getDropResult())
        }
      },
    }),
    [activityKey]
  )

  return (
    <>
      <ActivityImageWrapper absolute={absolute}>
        <DragPreviewImage
          connect={preview}
          src={`/public/images/milens/2-${activityKey}-small.png`}
        />

        <HexaMask
          onHover={entered => {
            setHovered(entered)
          }}
        />

        <ActivityImg
          hovered={hovered}
          withoutHover={withoutHover}
          ref={drag}
          isDragging={isDragging}
          src={`/public/images/milens/2-${activityKey}.png`}
          alt={text}
          isUsed={isUsed}
        />
      </ActivityImageWrapper>
    </>
  )
}

const Activity = ({ activityKey, text, offset, onDropSuccess, onDropFail, isUsed }) => {
  const { t } = useTranslation()

  return (
    <ActivityWrapper offset={offset}>
      <Col alignItems="center" position="relative">
        <HexaPlaceholder />

        <DraggableActivityImg
          activityKey={activityKey}
          text={`${activityKey}`}
          onDropSuccess={onDropSuccess}
          onDropFail={onDropFail}
          isUsed={isUsed}
        />

        <Gap gap="1rem" />
        <Row justifyContent="center" alignItems="flex-start">
          <Text>{t(`omega; milens-activity-${activityKey}`)}</Text>
        </Row>
      </Col>
    </ActivityWrapper>
  )
}

class FusionPopup extends Component {
  state = {
    activeStep: 1,
    defaultSoftWide: undefined,
    softWide: undefined,
    farNear: undefined,
    showLines: true,
    priorities: {
      1: null,
      2: null,
      3: null,
      4: null,
      5: null,
    },
    activeBgImage: 'outdoor',
    patientName: '',
    agreement: false,
  }

  close = ({ ok } = {}) => {
    // finally hide the popup itself
    this.props.close()
  }

  triggerSaveOrder = async openOrderAfterSave => {
    const { createFusionOrder, args, notifs, close, t } = this.props
    const { patientName, farNear, softWide, defaultSoftWide } = this.state
    try {
      const newOrderResponse = await createFusionOrder({
        patientName,
        ratio: farNear,
        hardness: softWide ?? defaultSoftWide,
      }).meta.action.payload

      close()
      args?.onLoad?.(openOrderAfterSave && newOrderResponse.data.order._id)
    } catch (error) {
      notifs.error({ title: t('omega; Order was not saved') })
      // console.log('error', error)
    }
  }

  renderFooter = () => {
    const { t, miLensCreateOrderInProgress, i18n } = this.props
    const { activeStep, patientName, agreement } = this.state

    const lang = i18n.language.split('-')[0]
    const useCustomPrivacyPolicy = appTypeConfig.milensPrivacyPolicyCustomLanguages?.includes(lang)

    console.log('i18n', i18n)
    if (activeStep !== 3) return null
    return (
      <Col>
        <Row justifyContent="center" alignItems="center">
          <Popup
            minWidth="50rem"
            title={t('omega; Save to orders')}
            text={
              <Col>
                <Text>{t('omega; Fill customer name so you can find the order later.')}</Text>
                <Gap gap="2rem" />
                <Col maxWidth="30rem">
                  <TextField
                    name="patientName"
                    label={t('omega; Patient name')}
                    value={patientName}
                    onChange={e => {
                      this.setState({ patientName: e.value })
                    }}
                  />
                </Col>

                <Gap gap="1.6rem" />

                <CheckboxField
                  name="agreement"
                  label={t('omega; The patient agrees with using their personal information')}
                  tooltip={
                    <Text color="white">
                      <T>omega; You can read all terms and conditions</T>{' '}
                      <Link
                        external
                        white
                        // href={`/public/milens/privacy_policy_${
                        //   i18n.language === 'cs' ? 'cs' : 'en'
                        // }.pdf`}
                        href={`/public/${
                          useCustomPrivacyPolicy ? `${appTypeConfig.APP_TYPE}/` : ''
                        }privacy_policy_${
                          useCustomPrivacyPolicy ? lang : lang === 'cs' ? lang : 'en'
                        }.pdf`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <T>omega; here</T>
                      </Link>
                      .
                    </Text>
                  }
                  value={agreement}
                  onChange={e => {
                    this.setState({ agreement: !agreement })
                  }}
                />

                <Gap gap="4rem" />

                <Row justifyContent="center">
                  <Button
                    disabled={!agreement || miLensCreateOrderInProgress}
                    onClick={() => this.triggerSaveOrder(false)}
                  >
                    <T>omega; Save and close</T>
                  </Button>
                  <Gap />

                  <Button
                    onClick={() => this.triggerSaveOrder(true)}
                    disabled={!agreement || miLensCreateOrderInProgress}
                  >
                    <T>omega; Save and open the order</T>
                  </Button>
                </Row>
              </Col>
            }
            closeOnEsc
            showControlButtons={false}
            // displayTextInMessage={false}
          >
            {({ open }) => (
              <>
                <Button primary onClick={open} large rightIcon="CustomDoubleArrow">
                  <T>omega; Save to orders</T>
                </Button>
              </>
            )}
          </Popup>
        </Row>
        <Gap />
      </Col>
    )
  }

  onCurrentLensClick = kind => {
    const kindValues = {
      1: 0,
      2: 0,
      3: 20,
      4: 100,
    }

    this.setState({ defaultSoftWide: kindValues[kind], activeStep: 2 })
  }

  renderStep1 = () => {
    const { t } = this.props

    return (
      <Col alignItems="center">
        <Gap gap="5rem" />
        <Text big>
          <T>omega; What is your current lens type?</T>
        </Text>
        <Gap gap="5rem" />
        <Row justifyContent="center" alignItems="center">
          <CurrentLens index="1" text="omega; No lens" onClick={() => this.onCurrentLensClick(1)} />
          <CurrentLens
            index="2"
            text="omega; Single vision"
            onClick={() => this.onCurrentLensClick(2)}
          />
          <CurrentLens
            index="3"
            text="omega; Progressive"
            onClick={() => this.onCurrentLensClick(3)}
          />
          <CurrentLens
            index="4"
            text="omega; Bi-Focal"
            onClick={() => this.onCurrentLensClick(4)}
          />
        </Row>
        <Gap gap="3rem" />
      </Col>
    )
  }

  getActivityPriority = key => {
    const { priorities } = this.state
    return Object.keys(priorities).find(index => {
      if (priorities[index] === key) {
        return true
      }
      return false
    })
  }

  haveActivitiesPriority = () => {
    const { priorities } = this.state

    return Object.keys(priorities).every(index => priorities[index])
  }

  onActivityDropSuccess = ({ activityKey, priority }) => {
    this.setState(state => {
      const currentPriority = this.getActivityPriority(activityKey)

      // console.log('currentPriority', currentPriority)
      const update = {
        [priority]: activityKey,
      }

      if (currentPriority) {
        update[currentPriority] = null
      }

      return {
        priorities: {
          ...state.priorities,
          ...update,
        },
      }
    })
  }

  onActivityDropFail = activityKey => {
    // this.setState(state => {
    //   const currentPriority = this.getActivityPriority(activityKey)
    //   const update = {}
    //   if (currentPriority) {
    //     update[currentPriority] = null
    //   }
    //   return {
    //     priorities: {
    //       ...state.priorities,
    //       ...update,
    //     },
    //   }
    // })
  }

  goToStep2 = () => {
    this.setState(state => ({
      activeStep: 2,
    }))
  }

  goToStep3 = () => {
    this.setState(state => ({
      activeStep: 3,
      farNear: undefined,
      softWide: state.defaultSoftWide,
    }))
  }

  renderStep2 = () => {
    const { t } = this.props
    const { priorities } = this.state

    return (
      <Col alignItems="center">
        <Gap gap="5rem" />
        <Text big>
          <T>omega; Rate these activities as the most important to you.</T>
        </Text>
        <Gap gap="2rem" />
        <Text>
          <T>omega; Drag and drop, 1 is the most important, 5 the least.</T>
        </Text>
        <Gap gap="5rem" />
        <DndProvider backend={HTML5Backend}>
          <Row alignItems="center">
            <PrioritiesWrapper>
              {Object.keys(priorities).map((key, index) => (
                <PriorityDrop text={key} key={key} offset={index % 2 === 1}>
                  {priorities[key] && (
                    <DraggableActivityImg
                      activityKey={priorities[key]}
                      absolute
                      onDropSuccess={this.onActivityDropSuccess}
                      onDropFail={this.onActivityDropFail}
                      withoutHover
                    />
                  )}
                </PriorityDrop>
              ))}
            </PrioritiesWrapper>
            <Gap gap="6rem" />
            <ActivitiesWrapper>
              {activities.map((key, index) => (
                <Activity
                  key={key}
                  activityKey={key}
                  offset={index % 2 === 0}
                  onDropSuccess={this.onActivityDropSuccess}
                  onDropFail={this.onActivityDropFail}
                  isUsed={this.getActivityPriority(key)}
                />
              ))}
            </ActivitiesWrapper>
          </Row>
        </DndProvider>

        <Button
          large
          rightIcon="IosArrowForward"
          primary
          disabled={!this.haveActivitiesPriority()}
          onClick={this.goToStep3}
        >
          <T>omega; Next</T>
        </Button>
      </Col>
    )
  }

  renderStep3 = () => {
    const { t } = this.props
    const { defaultSoftWide, farNear, softWide, showLines, activeBgImage } = this.state

    const weights = {}

    const priorityWeights = {
      1: 1,
      2: 0.75,
      3: 0.5,
      4: 0.25,
      5: 0,
    }
    activities.forEach(key => {
      weights[key] = priorityWeights[this.getActivityPriority(key)]
    })

    const x = 1 * weights.outdoor + 0.9 * weights.driving + 0.5 * weights.pc + 0.2 * weights.phone

    let defaultFarNear = 0
    for (let i = 0; i < 21; i += 1) {
      if (x > 1.9075 - i * 0.0675) {
        defaultFarNear = 0 + i * 5
      }
    }

    const farNearMin = Math.max(-20, defaultFarNear - 40)
    const farNearMax = Math.min(120, defaultFarNear + 40)

    const softWideMin = Math.max(-20, defaultSoftWide - 30)
    const softWideMax = Math.min(120, defaultSoftWide + 30)

    const farNearValue = farNear ?? defaultFarNear ?? 0 // fallback to default

    if (typeof farNear === 'undefined') {
      // console.log('UPDATE state farnear')
      this.setState({ farNear: farNearValue })
    }

    // console.log('farNearValue = farNear ?? defaultFarNear', farNearValue, farNear, defaultFarNear)

    return (
      <Col marginRight="0" padding="0 1rem">
        <Gap gap="3rem" />

        <Row justifyContent="center" alignItems="center">
          <Col marginRight="0" justifyContent="center">
            {bgImages.map(imageKey => (
              <>
                <BgImageThumb
                  src={`/public/images/milens/3-${imageKey}-thumb.png`}
                  onClick={() => {
                    this.setState({ activeBgImage: imageKey })
                  }}
                  active={activeBgImage === imageKey}
                />
                <Gap gap="0.8rem" />
              </>
            ))}
          </Col>
          <Gap gap="0.8rem" />

          <MilensVisualiser
            defaultFarNear={defaultFarNear}
            defaultSoftWide={defaultSoftWide}
            farNear={farNearValue}
            softWide={softWide}
            showLines={showLines}
            activeBgImage={activeBgImage}
          />

          <Col alignItems="center" alignSelf="center" width="100%">
            <Text big bold>
              <T>omega; Visual field balance</T>
            </Text>
            <Gap gap="1.6rem" />
            <Row justifyContent="center">
              <SliderRange
                minLabel={t('omega; Far')}
                maxLabel={t('omega; Near')}
                min={farNearMin}
                max={farNearMax}
                step={10}
                value={farNearValue}
                onChange={value => {
                  this.setState({ farNear: value })
                }}
                orientation="vertical"
              />
              <Gap gap="4rem" />
              <SliderRange
                minLabel={t('omega; Soft')}
                maxLabel={t('omega; Wide')}
                min={softWideMin}
                max={softWideMax}
                // min={-20}
                // max={120}
                step={5}
                value={softWide ?? defaultSoftWide}
                orientation="vertical"
                onChange={value => {
                  this.setState({ softWide: value })
                }}
              />
            </Row>
            <Col />
            <Gap gap="1.6rem" />

            <Button
              onClick={() => {
                this.setState(state => ({
                  softWide: state.defaultSoftWide,
                  farNear: defaultFarNear,
                }))
              }}
            >
              <T>omega; Restore recommendation</T>
            </Button>
            <Gap gap="1.6rem" />

            <Button
              onClick={() => {
                this.setState(state => ({ showLines: !state.showLines }))
              }}
            >
              {showLines ? t('omega; Hide dashed lines') : t('omega; Show dashed lines')}
            </Button>
          </Col>
        </Row>
      </Col>
    )
  }

  render() {
    const { t, notifs } = this.props

    const { activeStep } = this.state

    return (
      <>
        <PopupPresentational
          title={t('omega; fusion popup title')}
          close={this.close}
          type="big"
          displayChildrenInMessage={false}
          showControlButtons={false}
          FooterComponent={this.renderFooter}
        >
          <Gap />

          <ContentWrapper top>
            <StepsWrapper>
              <Container mildShadow>
                {steps.map((l, index) => {
                  const renderStep = index + 1

                  return (
                    <Step
                      key={index}
                      onClick={() => {
                        if (renderStep === 2) {
                          if (typeof this.state.defaultSoftWide !== 'undefined') {
                            this.goToStep2()
                          } else {
                            notifs.error({ title: t('omega; Choose current lens first') })
                          }
                          return
                        }
                        if (renderStep === 3) {
                          console.log(
                            'this.haveActivitiesPriority()',
                            this.haveActivitiesPriority()
                          )
                          if (this.haveActivitiesPriority()) {
                            this.goToStep3()
                          } else {
                            notifs.error({ title: t('omega; Assign priorities to all activities') })
                          }
                          return
                        }
                        this.setState({ activeStep: renderStep })
                      }}
                      disabled={renderStep > activeStep}
                      active={renderStep === activeStep}
                      done={renderStep < activeStep}
                    >
                      <StepNumber
                        disabled={renderStep > activeStep}
                        active={renderStep === activeStep}
                        done={renderStep < activeStep}
                      >
                        {renderStep < activeStep ? (
                          <Icon name="MdCheckmark" size="18px" />
                        ) : (
                          renderStep
                        )}
                      </StepNumber>
                      <div>{t(l.text)}</div>
                    </Step>
                  )
                })}
              </Container>
            </StepsWrapper>

            {activeStep === 1 && this.renderStep1()}
            {activeStep === 2 && this.renderStep2()}
            {activeStep === 3 && this.renderStep3()}

            <Gap />
          </ContentWrapper>
        </PopupPresentational>
      </>
    )
  }
}

FusionPopup.defaultProps = {}

FusionPopup.propTypes = {
  close: PropTypes.func.isRequired, // is injected by PopupsGate
  args: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  createFusionOrder: PropTypes.func.isRequired,
}

const enhance = compose(
  connect(
    state => ({
      miLensCreateOrderInProgress: state.orders.miLensCreateOrderInProgress,
    }),
    { createFusionOrder }
  ),
  withTranslation(),
  withTheme,
  withNotifs
)

export default enhance(FusionPopup)
