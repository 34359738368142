import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { saveAs } from 'file-saver'
import { withTranslation } from 'react-i18next'
import { compose } from 'recompose'
import moment from 'moment'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { isBefore } from 'date-fns'
import {
  Icon,
  Text,
  Page,
  LinkButton,
  Row,
  Col,
  Button,
  Pagination,
  Link,
  Box,
  Gap,
  SelectFileButton,
} from '../components'
import { OrdersTable, ImportOrders, SummaryTooltip } from '../components/order'
import { PopoverMenu } from '../components/popoverMenus'
import {
  fetchOrders,
  sendOrder,
  updateOrderStatus,
  setCurrentOrder,
  selectToSend,
  deleteOrder,
  cancelOrder,
  importOrders,
} from '../../common/orders/actions'
import { generateDeliveryNote } from '../../common/deliveryNotes/actions'
import { translateOrder, orderDateTranslate, totalResetOrder } from '../../common/orders/utils'
import { setOrdersTableFullWidth } from '../../common/app/actions'
import { isOrderMatureEnoughToArchive } from '../../common/orders/validations'
import { lists } from '../../common/lib/redux-lists'
import { CheckboxField, MultiCheckboxField, SwitchField, TextField } from '../components/fields'
import {
  orderStatuses,
  orderListTimeTypes,
  orderImportTypes,
  appTypeConfig,
} from '../../common/config'
import { getTheme } from '../app/App'
import { showPopup } from '../../common/popups/actions'
import { withNotifs } from '../components/hoc'
import { addIoListener } from '../io'
import StatusHistoryTooltip from '../components/StatusHistoryTooltip'
import { WrappedLoading } from '../components/Loading'
import { OrdersLoading } from '../components/Skeleton'
import { apiLight } from '../../common/lib/api'
import withRouter from '../components/hoc/withRouter'
import PuppetUserSelect from '../components/PuppetUserSelect'

const OptionButton = styled(Button)`
  display: inline-block;
`
const OptionsWrapper = styled.div`
  display: flex;
  direction: row;
  justify-content: flex-end;
`
const MoreOptionsButton = styled(OptionButton)`
  float: right;
`

const Dot = styled.div`
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.primary};
  margin-bottom: 3px;
  &:last-child {
    margin-bottom: 0;
  }
`

const ORDERS_ORIGIN_OPTIONS = [
  { label: 'Web', value: 'web' },
  { label: 'Other', value: 'external' },
]

class OrdersListPage extends Component {
  state = {
    orderIdForEyestationImport: '',
  }

  ioOrdersUpdateDebounce = null

  importEyestationRef = React.createRef()

  componentDidMount() {
    this.removeShouldUpdateOrdersListener = addIoListener('shouldUpdateOrders', () => {
      console.log('incoming order update')

      clearInterval(this.ioOrdersUpdateDebounce)

      this.ioOrdersUpdateDebounce = setTimeout(() => {
        this.props.list.$reload()
      }, 1500)
    })
    // add fallback interval for orders update (e.g. if connection was lost )
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.shouldUpdate && !prevProps.shouldUpdate) {
      this.props.list.$reload()
    }
  }

  componentWillUnmount() {
    this.removeShouldUpdateOrdersListener?.()
  }

  onEyestationImportToOrder = async e => {
    const { importOrders, notifs, t, list } = this.props
    const { orderIdForEyestationImport } = this.state
    this.setState({ orderIdForEyestationImport: '' })
    try {
      await importOrders({
        files: e.target.files,
        _id: orderIdForEyestationImport,
      }).meta.action.payload
      notifs.success({ title: t('saving success') })
      list.$reload()
    } catch (error) {
      notifs.error({ title: t('saving failed') })
    }
  }

  onPopoverSelect = async ({ key, order, t }) => {
    const {
      history,
      deleteOrder,
      cancelOrder,
      setCurrentOrder,
      sendOrder,
      updateOrderStatus,
      showPopup,
      sendingOrdersIds,
      generateDeliveryNote,
      viewer,
      notifs,
    } = this.props

    if (key === 'send') {
      if (!sendingOrdersIds[order._id]) {
        sendOrder({ _id: order._id })
      } else {
        console.log('already sending')
      }
    }
    if (key === 'edit') {
      // setCurrentOrder(order._id)
      totalResetOrder()
      history.push(`${Link.CREATE_ORDER_STEP_1}?_id=${order._id}`)
    }
    if (key === 'print') {
      console.log('TODO: Print')
    }
    if (key === 'copy') {
      // otevřít formulář, nakopírovat hodnoty, smazat nepotřebné
      totalResetOrder()
      history.push(`${Link.CREATE_ORDER_STEP_1}?_id=${order._id}&copy=true`)
    }
    if (key === 'secondPair') {
      // otevřít formulář, nakopírovat hodnoty, smazat nepotřebné a pridat link na puvodni objednavku
      totalResetOrder()
      history.push(`${Link.CREATE_ORDER_STEP_1}?_id=${order._id}&copy=true&secondPair=true`)
    }
    if (key === 'detail') {
      totalResetOrder()
      history.push({
        pathname: Link.ORDER_DETAIL.replace(':id', order._id),
      })
    }
    if (key === 'archive') {
      updateOrderStatus({ _id: order._id, action: 'archive' })
    }
    if (key === 'unarchive') {
      updateOrderStatus({ _id: order._id, action: 'unarchive' })
    }
    if (key === 'deliveryNote') {
      // generateDeliveryNote(order._id)
      try {
        const res = await apiLight({ viewer, url: `delivery-notes/${order._id}`, blob: true })
        saveAs(res, `${order._id}.pdf`)
      } catch (e) {
        notifs.error({ title: t('error') })
      }
    }
    if (key === 'cancel') {
      showPopup('confirm', {
        title: t('cancel order'),
        text: `${t('Do you really want to cancel order')} ${order.reference}?`,
        okText: t('cancel'),
        onConfirm: () => cancelOrder(order),
      })
    }
    if (key === 'delete') {
      showPopup('confirm', {
        title: t('delete order'),
        text: `${t('Do you really want to delete order')} ${order.reference}?`,
        okText: t('delete'),
        onConfirm: () => deleteOrder(order),
      })
    }
    if (key === 'eyestation') {
      this.setState({ orderIdForEyestationImport: order._id })
      this.importEyestationRef.current.value = null
      this.importEyestationRef.current.click()
    }
  }

  getItemKey = order => `order-item-${order._id}`

  isOrderEligibleForCancelButton = order => {
    const { viewer } = this.props

    return (
      [orderStatuses.WAIT_FOR_SEND].includes(order.status) &&
      order.cancellableTill &&
      isBefore(new Date(), new Date(order.cancellableTill)) &&
      appTypeConfig.isOrderCancellationEnabled &&
      !viewer.group.disableOrderCancellation
    )
  }

  getPopoverMenu = order => {
    const { t, sendingOrdersIds, viewer, hostConf } = this.props

    const options = []
    if (
      [
        orderStatuses.AT_MANUFACTURER,
        orderStatuses.SENDING,
        orderStatuses.SENT,
        orderStatuses.DONE,
        orderStatuses.CANCELLED,
        orderStatuses.ARCHIVED,
      ].includes(order.status)
    ) {
      options.push({ title: t('detail'), key: 'detail' })
    } else if (appTypeConfig.isEyestationImportEnabled) {
      options.push({ title: t('import eyestation'), key: 'eyestation' })
    }

    if ([orderStatuses.CONCEPT, orderStatuses.IMPORTED].includes(order.status)) {
      options.push({ title: t('edit'), key: 'edit' })
    }
    if (order.status === orderStatuses.NOT_SENT) {
      options.push({ title: t('detail'), key: 'detail' })
      options.push({ title: t('edit'), key: 'edit' })
      if (viewer.isActive) options.push({ title: t('send'), key: 'send' })
    }

    options.push({ title: t('copy'), key: 'copy' })

    if (order.isSecondPairCapable) {
      options.push({ title: t('second pair'), key: 'secondPair' })
    }

    if (isOrderMatureEnoughToArchive(order)) {
      options.push({ title: t('archive'), key: 'archive' })
    }
    if ([orderStatuses.ARCHIVED].includes(order.status)) {
      options.push({ title: t('unarchive'), key: 'unarchive' })
    }

    if ([orderStatuses.DONE].includes(order.status)) {
      if (hostConf.areDeliveryNotesEnabled && order.deliveryNote) {
        options.push({ title: t('omega; Delivery note'), key: 'deliveryNote' })
      }
    }

    if (
      [orderStatuses.IMPORTED, orderStatuses.CONCEPT, orderStatuses.NOT_SENT].includes(order.status)
    ) {
      options.push({ title: t('delete'), key: 'delete' })
    }

    if (this.isOrderEligibleForCancelButton(order)) {
      // console.log('order', order)
      options.push({ title: t('cancel'), key: 'cancel' })
    }

    // TODO: uncomment one day
    // options.push(
    //   { title: t('print'), key: 'print' },
    // )

    return (
      <PopoverMenu
        closeOnOutsideClick
        closeOnEsc
        options={options}
        onSelect={({ key }) => this.onPopoverSelect({ key, order, t })}
        nodeId={this.getItemKey(order)}
      >
        {({ open }) => (
          <MoreOptionsButton name="options-button" onClick={open}>
            <Dot />
            <Dot />
            <Dot />
          </MoreOptionsButton>
        )}
      </PopoverMenu>
    )
  }

  createOrderLink = order => () => {
    const { history } = this.props
    if (
      [orderStatuses.CONCEPT, orderStatuses.NOT_SENT, orderStatuses.IMPORTED].includes(order.status)
    ) {
      totalResetOrder()
      // setCurrentOrder(order._id)
      history.push(`${Link.CREATE_ORDER_STEP_1}?_id=${order._id}`)
    } else {
      totalResetOrder()
      history.push({
        pathname: Link.ORDER_DETAIL.replace(':id', order._id),
      })
    }
  }

  render() {
    const {
      t,
      orders,
      loading,
      list,
      meta,
      history,
      sendOrder,
      setCurrentOrder,
      notSent,
      selectedToSend,
      selectToSend,
      sendingOrdersIds,
      isMasterUser,
      viewer,
      orderListTimeType,
      hostConf,
      showPopup,
      setOrdersTableFullWidth,
      ordersTableFullWidth,
      cancelOrder,
    } = this.props

    const listHeader = (headerText, column, renderCol, sortByColumn, colProps) => ({
      headerText,
      column,
      onClick: list.headings[sortByColumn || column].onClick,
      sortState: list.headings[sortByColumn || column].direction,
      renderCol,
      colProps,
    })

    const paging = list.$paging(meta.count)

    const areWebOrders = list.filters.ordersOrigin?.value === ORDERS_ORIGIN_OPTIONS[0].value

    const orderOriginOptions = ORDERS_ORIGIN_OPTIONS.map(({ value, label }) => ({
      value,
      label: t(label),
    }))

    const defaultFilters = areWebOrders
      ? initialStatusFilters
      : initialStatusFiltersForExternalOrders

    const hasDefaultFilters =
      defaultFilters.every(f => meta.filter?.status?.in?.includes(f)) || !meta.filter?.status?.in

    // const areFiltersSet =
    //   meta &&
    //   meta.filter &&
    //   (meta.filter.searchText || meta.filter.status.in.length !== initialStatusFilters.length)

    const areFiltersSet = meta?.filter?.searchText || !hasDefaultFilters

    const noOrdersWithFilter = orders && orders.length === 0 && areFiltersSet
    const noOrdersWithoutFilter = orders && orders.length === 0 && !areFiltersSet

    return (
      <Page metaTitle="Home">
        {/* INPUT FOR EYESTATION IMPORT INTO EXISTING ORDER */}
        <input
          ref={this.importEyestationRef}
          type="file"
          name="eyestation-file"
          style={{ display: 'none' }}
          onChange={this.onEyestationImportToOrder}
        />

        <Page.Content
          style={{
            paddingTop: '2rem',
            maxWidth: ordersTableFullWidth ? '100%' : undefined,
            transition: '0.3s',
          }}
        >
          <PuppetUserSelect
            onChange={() => {
              list.$setPage(0)
              setTimeout(() => {
                list.$reload()
              }, 10)
            }}
          />
          <Box>
            <Box.Header>
              <Box.Title>{t('my orders')}</Box.Title>
            </Box.Header>
            <Box.HomepageBoxContent>
              <Row large justifyContent="space-between">
                <Row.Modern>
                  <Col>
                    <Button
                      leftIcon="MdAdd"
                      iconSize={23}
                      large
                      primary
                      onClick={() => {
                        totalResetOrder()
                        history.push(LinkButton.CREATE_ORDER_STEP_1)
                      }}
                    >
                      {t('create order')}
                    </Button>
                  </Col>

                  {appTypeConfig.isPmsImportEnabled && !hostConf.disablePmsImport && (
                    <Col>
                      <ImportOrders onLoad={list.$reload} importType={orderImportTypes.B2B} />
                    </Col>
                  )}
                  {appTypeConfig.isEyestationImportEnabled && (
                    <Col>
                      <ImportOrders
                        onLoad={list.$reload}
                        importType={orderImportTypes.EYESTATION}
                      />
                    </Col>
                  )}
                  {appTypeConfig.isMiLensEnabled &&
                    hostConf.isMiLensEnabled &&
                    viewer.group.isMiLensEnabled && (
                      <Col>
                        <Button
                          large
                          onClick={() => {
                            totalResetOrder()
                            showPopup('miLensImport', {
                              onLoad: _id => {
                                // console.log('!!!_id', _id)
                                list.$reload()
                                if (_id) {
                                  setTimeout(() => {
                                    history.push(`${Link.CREATE_ORDER_STEP_1}?_id=${_id}`)
                                  }, 200)
                                }
                              },
                              onFusionLoad: _id => {
                                // console.log('_id', _id)
                                list.$reload()
                                if (_id) {
                                  setTimeout(() => {
                                    history.push(`${Link.CREATE_ORDER_STEP_1}?_id=${_id}`)
                                  }, 200)
                                }
                              },
                            })
                          }}
                        >
                          {t('omega; import milens')}
                        </Button>
                      </Col>
                    )}
                  {appTypeConfig.isMiLensEnabled &&
                    hostConf.isMiLensEnabled &&
                    viewer.group.isMiLensEnabled && (
                      <Col>
                        <Button
                          large
                          onClick={() => {
                            totalResetOrder()
                            showPopup('fusion', {
                              onLoad: _id => {
                                // console.log('_id', _id)
                                list.$reload()
                                if (_id) {
                                  setTimeout(() => {
                                    history.push(`${Link.CREATE_ORDER_STEP_1}?_id=${_id}`)
                                  }, 200)
                                }
                              },
                            })
                          }}
                        >
                          {t('omega; fusion - homepage button')}
                        </Button>
                      </Col>
                    )}
                </Row.Modern>
                <Row.Modern>
                  <Col>
                    <Button
                      large
                      onClick={() => {
                        // this.setState(state => ({ tableFullWidth: !state.tableFullWidth }))
                        // this is for table header recalc
                        // setTimeout(() => {
                        //   window.scrollBy(0, 1)
                        // }, 10)
                        // setTimeout(() => {
                        //   window.scrollBy(0, -1)
                        // }, 100)
                        setOrdersTableFullWidth(!ordersTableFullWidth)
                      }}
                    >
                      <Icon size={23} name="CustomOppositeArrows" />
                    </Button>
                  </Col>
                </Row.Modern>
              </Row>

              {/* {(loading || !noOrdersWithoutFilter) && ( */}
              <>
                <Row alignItems="flex-end">
                  <Col width="30%" marginRight="0.8rem">
                    <TextField
                      label={t('search')}
                      name="search"
                      value={list.filters['searchText.like'].value}
                      onChange={({ value }) => list.filters['searchText.like'].onChange({ value })}
                      icon="IosClose"
                      onKeyPress={event =>
                        event.key === 'Enter' &&
                        !loading &&
                        (() => {
                          list.$setPage(0)
                          setTimeout(() => {
                            list.$reload()
                          }, 10)
                        })()
                      }
                      onIconClick={() => {
                        list.$setPage(0)
                        list.filters['searchText.like'].onChange({ value: '' })
                        setTimeout(() => {
                          list.$reload()
                        }, 10)
                      }}
                    />
                  </Col>
                  <Col marginRight="2.4rem">
                    <Button
                      disabled={loading}
                      onClick={() => {
                        list.$setPage(0)
                        setTimeout(() => {
                          list.$reload()
                        }, 10)
                      }}
                    >
                      {t('search')}
                    </Button>
                  </Col>

                  {!appTypeConfig.hideOrdersSourceSwitch && (
                    <Col marginRight="2.4rem">
                      <SwitchField
                        required
                        hideRequiredStar
                        disabled={loading}
                        label={t('Order origin')}
                        options={orderOriginOptions}
                        value={list.filters.ordersOrigin.value}
                        onChange={opt => {
                          list.$setPage(0)
                          list.filters.ordersOrigin.onChange({ value: opt.value })
                        }}
                      />
                    </Col>
                  )}
                </Row>
                <Row>
                  <Col grow="0" style={{ paddingBottom: '0.3rem' }}>
                    <MultiCheckboxField
                      disabled={loading}
                      label={`${t('filter by state')}:`}
                      fields={[
                        ...areWebOrders
                          ? [
                              { label: t(orderStatuses.IMPORTED), name: orderStatuses.IMPORTED },
                              { label: t(orderStatuses.CONCEPT), name: orderStatuses.CONCEPT },
                              { label: t(orderStatuses.NOT_SENT), name: orderStatuses.NOT_SENT },
                            ]
                          : [],
                        {
                          label: t(orderStatuses.AT_MANUFACTURER),
                          name: orderStatuses.AT_MANUFACTURER,
                        },
                        { label: t(orderStatuses.DONE), name: orderStatuses.DONE },

                        ...areWebOrders
                          ? [
                              {
                                label: t(orderStatuses.CANCELLED),
                                name: orderStatuses.CANCELLED,
                              },
                              { label: t(orderStatuses.ARCHIVED), name: orderStatuses.ARCHIVED },
                            ]
                          : [],
                      ]}
                      value={list.filters['status.in'].value}
                      onChange={value => {
                        list.$setPage(0)
                        list.filters['status.in'].onChange({ value })
                      }}
                    />
                  </Col>
                </Row>
              </>
              {/* <OrdersLoading /> */}
              {loading && (!orders || orders.length === 0) && <OrdersLoading />}
              {/* {loading && (!orders || orders.length === 0) &&
                <WrappedLoading type="deformedGrid" padding="10rem 0 10rem" />} */}
              {!loading && noOrdersWithoutFilter && (
                <Row>
                  <Text big>
                    {t(
                      areWebOrders
                        ? 'orders list - no orders - welcome message'
                        : 'orders list - no external orders - welcome message',
                    )}
                  </Text>
                </Row>
              )}
              {!loading && noOrdersWithFilter && (
                <Row>
                  <Text big>{t('orders list - no orders - when filters set')}</Text>
                </Row>
              )}
              <Row marginBottom="0">
                {orders && orders.length > 0 && (
                  <OrdersTable
                    isLoading={loading}
                    recalcHeaderWidth={!!ordersTableFullWidth}
                    data={orders}
                    // createRowClickHandler={this.createOrderLink}
                    columns={[
                      // TODO: doresit checkboxy
                      // {
                      //   headerText: 'Checkbox',
                      //   renderHeader: () => (Boolean(notSent.length) &&
                      //   <CheckboxField
                      //     name="select-all"
                      //     value={selectedToSend.length === notSent.length}
                      //     onChange={() => {
                      //       if (selectedToSend.length === notSent.length) {
                      //         selectToSend([])
                      //       } else {
                      //         selectToSend(notSent)
                      //       }
                      //     }}
                      //   />
                      //   ),
                      //   renderCol: row => (row.status === orderStatuses.NOT_SENT &&
                      //   <CheckboxField
                      //     name={row._id}
                      //     value={selectedToSend.includes(row._id)}
                      //     onChange={() => {
                      //       if (selectedToSend.includes(row._id)) {
                      //         selectToSend(selectedToSend.filter(selected => selected !== row._id))
                      //       } else {
                      //         selectToSend([...selectedToSend, row._id])
                      //       }
                      //     }}
                      //   />),
                      // },

                      appTypeConfig.showOrdersTableIdColumn || !areWebOrders
                        ? listHeader(t('order id'), 'manufacturerId', row => (
                            <>
                              <div
                                style={{ cursor: areWebOrders && 'pointer' }}
                                onClick={areWebOrders ? this.createOrderLink(row) : null}
                              >
                                <Text>{row.manufacturerId || <>&nbsp;</>}</Text>
                              </div>
                            </>
                          ))
                        : undefined,
                      listHeader(t('reference'), 'reference', row => (
                        <>
                          <SummaryTooltip data={row}>
                            <div
                              style={{ cursor: areWebOrders && 'pointer' }}
                              onClick={areWebOrders ? this.createOrderLink(row) : null}
                            >
                              <Text>
                                {row.reference && row.reference.trim() !== ''
                                  ? row.reference
                                  : t('(no reference)')}
                              </Text>
                            </div>
                          </SummaryTooltip>
                        </>
                      )),
                      listHeader(t('client name'), 'clientName'),
                      areWebOrders
                        ? listHeader(t('order type'), 'orderType', row => (
                            <>
                              <Text>{t(row.orderType)}</Text>
                            </>
                          ))
                        : undefined,
                      areWebOrders
                        ? {
                            headerText: t('lens type'),
                            renderCol: row => (
                              <Icon
                                style={{ marginRight: '.5rem' }}
                                size={24}
                                name={row.stockLensOrder ? 'CustomStock' : 'CustomManufactured'}
                              />
                            ),
                            colProps: {
                              // style: { textAlign: 'center' },
                              // style: { width: '5rem' },
                            },
                          }
                        : undefined,
                      listHeader(
                        orderListTimeType === orderListTimeTypes.SENT_ONLY
                          ? t('sent at')
                          : t('time'),
                        'dateText',
                        row => {
                          const data = orderDateTranslate({ order: row, t, orderListTimeType })
                          return (
                            <StatusHistoryTooltip order={row}>
                              <Text>{data}</Text>
                            </StatusHistoryTooltip>
                          )
                        },
                        orderListTimeType === orderListTimeTypes.SENT_ONLY
                          ? 'ftpSentAt'
                          : 'sortDate',
                      ),
                      listHeader(t('status'), 'status', row => {
                        let color = null
                        if (
                          row.status === orderStatuses.AT_MANUFACTURER ||
                          row.status === orderStatuses.SENT
                        ) {
                          color = getTheme().colors.green
                        }
                        if (row.status === orderStatuses.CONCEPT) color = getTheme().colors.red
                        return (
                          <StatusHistoryTooltip order={row}>
                            <Text color={color}>
                              {t(row.status)}
                              {row.status === orderStatuses.AT_MANUFACTURER &&
                                ` / ${t(row.subStatus)}`}
                            </Text>
                          </StatusHistoryTooltip>
                        )
                      }),
                      isMasterUser
                        ? {
                            headerText: t('Branch'),
                            colProps: {
                              style: {
                                // width: '8rem',
                                wordWrap: 'break-word',
                              },
                            },
                            renderCol: row => (
                              <Text
                                style={{
                                  opacity: row.mine ? 0.5 : 1,
                                }}
                              >
                                {row.user && row.user.username}
                                {row.mine && ` (${t('orders list - me')})`}
                              </Text>
                            ),
                          }
                        : undefined,

                      areWebOrders
                        ? {
                            headerText: t('actions'),
                            colProps: {
                              style: { width: '1%' },
                            },
                            renderCol: row => (
                              <OptionsWrapper id={this.getItemKey(row)}>
                                {row.status === orderStatuses.NOT_SENT && (
                                  <OptionButton
                                    onClick={e => {
                                      e.stopPropagation()
                                      sendOrder({ _id: row._id })
                                    }}
                                    disabled={sendingOrdersIds[row._id] || !viewer.isActive}
                                  >
                                    {t('send')}
                                  </OptionButton>
                                )}
                                {[orderStatuses.CONCEPT, orderStatuses.IMPORTED].includes(
                                  row.status,
                                ) && (
                                  <OptionButton
                                    onClick={e => {
                                      e.stopPropagation()
                                      totalResetOrder()
                                      history.push(`${Link.CREATE_ORDER_STEP_1}?_id=${row._id}`)
                                    }}
                                  >
                                    {t('edit')}
                                  </OptionButton>
                                )}
                                {this.isOrderEligibleForCancelButton(row) && (
                                  <OptionButton
                                    onClick={e => {
                                      e.stopPropagation()
                                      showPopup('confirm', {
                                        title: t('cancel order'),
                                        text: `${t('Do you really want to cancel order')} ${
                                          row.reference
                                        }?`,
                                        okText: t('cancel'),
                                        onConfirm: () => cancelOrder(row),
                                      })
                                    }}
                                  >
                                    {t('cancel')}
                                  </OptionButton>
                                )}
                                <Gap />
                                {this.getPopoverMenu(row)}
                              </OptionsWrapper>
                            ),
                          }
                        : undefined,
                    ]}
                  />
                )}
              </Row>

              {orders.length > 0 && <Pagination paging={paging} />}
            </Box.HomepageBoxContent>
          </Box>
        </Page.Content>
      </Page>
    )
  }
}

OrdersListPage.propTypes = {
  t: PropTypes.func.isRequired,
  fetchOrders: PropTypes.func.isRequired,
  sendOrder: PropTypes.func.isRequired,
  updateOrderStatus: PropTypes.func.isRequired,
  setCurrentOrder: PropTypes.func.isRequired,
  orders: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  list: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  sendingOrdersIds: PropTypes.object.isRequired,
  notSent: PropTypes.array.isRequired,
  selectedToSend: PropTypes.array.isRequired,
  selectToSend: PropTypes.func.isRequired,
  deleteOrder: PropTypes.func.isRequired,
  cancelOrder: PropTypes.func.isRequired,
  showPopup: PropTypes.func.isRequired,
  isMasterUser: PropTypes.bool.isRequired,
  viewer: PropTypes.object.isRequired,
  orderListTimeType: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  hostConf: PropTypes.object.isRequired,
  importOrders: PropTypes.func.isRequired,
  notifs: PropTypes.object.isRequired,
}

const initialStatusFilters = [
  orderStatuses.IMPORTED,
  orderStatuses.CONCEPT,
  orderStatuses.NOT_SENT,
  // orderStatuses.SENT,
  orderStatuses.AT_MANUFACTURER,
  orderStatuses.DONE,
  orderStatuses.CANCELLED,
  orderStatuses.SENDING,
  // orderStatuses.ARCHIVED,
]

const initialStatusFiltersForExternalOrders = [orderStatuses.AT_MANUFACTURER, orderStatuses.DONE]

const enhance = compose(
  withTranslation(),
  withRouter,
  connect(
    ({ orders, app, config }) => ({
      orders: orders.all,
      shouldUpdate: orders.shouldUpdate,
      notSent: orders.all.filter(o => o.status === orderStatuses.NOT_SENT).map(ord => ord._id),
      selectedToSend: orders.selectedToSend,
      meta: orders.meta,
      loading: orders.loading,
      sendingOrdersIds: orders.sendingOrdersIds,
      isMasterUser: orders.isMasterUser,
      viewer: app.viewer,
      hostConf: app.hostConf,
      orderListTimeType: config.orderListTimeType,
      ordersTableFullWidth: app.ordersTableFullWidth,
    }),
    {
      fetchOrders,
      sendOrder,
      updateOrderStatus,
      setCurrentOrder,
      selectToSend,
      deleteOrder,
      cancelOrder,
      showPopup,
      importOrders,
      generateDeliveryNote,
      setOrdersTableFullWidth,
    },
  ),
  lists({
    path: 'OrdersListPage',
    filters: ['status.in', 'searchText.like', 'ordersOrigin'],
    noAutoRefreshFields: ['searchText.like'],
    headings: [
      'reference',
      'manufacturerId',
      'clientName',
      'orderType',
      'sortDate',
      'ftpSentAt',
      'status',
    ],
    debounceTime: 1,
    loadDataAction: props => query => {
      // loads SENT when AT_MANUFACTURER is selected
      const copyQuery = JSON.parse(JSON.stringify(query))
      if (
        copyQuery?.filters?.status?.in?.includes?.(orderStatuses.AT_MANUFACTURER) &&
        !copyQuery.filters.status.in.includes(orderStatuses.SENT)
      ) {
        copyQuery.filters.status.in.push(orderStatuses.SENT)
      }

      // if (
      //   !copyQuery?.filters?.status?.in?.includes?.(orderStatuses.ARCHIVED) &&
      //   copyQuery?.filters?.status?.in?.length !== 1
      // ) {
      //   copyQuery.filters.status.in.push(orderStatuses.IMPORTED)
      // }
      copyQuery.filters.status.in.push(orderStatuses.SENDING)
      copyQuery.filters.status.in.push(orderStatuses.WAIT_FOR_SEND)

      return props.fetchOrders(copyQuery)
    },
    getInitialState: ({ orderListTimeType }) => {
      const sort = orderListTimeType === orderListTimeTypes.SENT_ONLY ? '-ftpSentAt' : '-sortDate'
      return {
        'status.in': initialStatusFilters,
        ordersOrigin: ORDERS_ORIGIN_OPTIONS[0].value,
        sort,
      }
    },
  }),
  withNotifs,
)

export default enhance(OrdersListPage)
