const config = {
  // url base
  publicPath: '/public/locales',
  // persistent file for translations used by locize
  // this folder is source of translations
  // for local development you can enable locize temporary to load files
  // or copy files from localFolder
  // persistentFolder: `${process.cwd()}/public-files/languages`,
  persistentFolder: `${process.cwd()}/public-files/locales`,
  // missing keys are added here. It is stored in git.
  // We need this for posting new keys to locize on release
  // and prevent issues with empty files, etc.
  localFolder: `${process.cwd()}/src/common/i18n/languages`,
}

export const languagesTexts = {
  cs: 'Česky',
  en: 'English',
  de: 'Deutsch',
  fr: 'Français',
  es: 'Español',
  it: 'Italiano',
  hr: 'Hrvatski',
  ru: 'русский',
  pl: 'Polski',
  pt: 'Português',
}

// app type nofig určije, jaké jazyky jsou použity
// export const availableLanguages = Object.keys(languagesTexts)

export default config
