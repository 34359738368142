import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { withTranslation } from 'react-i18next'
import styled, { css, withTheme } from 'styled-components'
import Deformator from '../vca/Deformator'
import Visualiser from '../vca/Visualiser'
import PopupPresentational from './PopupPresentational'
import Popup from './Popup'
import { NumberField, TextField } from '../fields'
import { Row, Col, Text, Gap, Icon } from '..'

import {
  deformThumbsVertical,
  deformThumbsHorizontal,
  selectVca,
  deleteMyShape,
} from '../../../common/vca/actions'

const ThumbsWrapper = styled.div`
  overflow: auto;
  max-height: 40rem;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 2rem;
`

const ThumbVisualiser = styled(Visualiser)`
  cursor: pointer;
`

const IconsWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  opacity: 0;
  transition: 0.3s;
`

const ThumbVisualiserWrapper = styled.div`
  cursor: pointer;

  position: relative;
  &:hover {
    ${IconsWrapper} {
      opacity: 1;
    }
  }
`

const OneIconWrapper = styled.div`
  ${({ theme: { colors } }) => css`
    cursor: pointer;
    color: ${colors.red};
    transition: 0.3s;

    &:hover {
      color: ${colors.redLight};
    }
  `}
`

class StandardShapeLibPopup extends Component {
  state = {
    pickedShapeId: null,
    searchInputValue: '',
  }

  getShapeStyle = isPicked => {
    const { theme } = this.props
    if (isPicked) {
      return {
        shape: {
          fillStyle: 'none',
          lineWidth: 0.7,
          color: theme.colors.primary,
        },
        title: {
          fillStyle: theme.colors.primary,
        },
      }
    }
    return {
      shape: {
        fillStyle: theme.colors.gray2,
        lineWidth: 0.5,
        color: theme.colors.grayDark,
      },
      title: {
        fillStyle: theme.colors.grayDark,
      },
    }
  }

  onThumbClick = _id => {
    this.setState({ pickedShapeId: _id })
  }

  close = ({ ok } = {}) => {
    // do anything you want on close
    const {
      deformThumbsVertical,
      deformThumbsHorizontal,
      selectVca,
      args,
      deformThumbsHBox,
      deformThumbsVBox,
      importedDbl,
      importedHBox,
      importedVBox,
    } = this.props

    const { pickedShapeId } = this.state

    if (ok) {
      if (pickedShapeId) {
        if (args.type === 'standardShapes') {
          selectVca({
            _id: pickedShapeId,
            fromStandardLib: true,
            hbox: deformThumbsHBox || importedHBox,
            vbox: deformThumbsVBox || importedVBox,
            dbl: importedDbl,
          })
        }

        if (args.type === 'myShapes') {
          selectVca({
            _id: pickedShapeId,
            fromMyLib: true,
            hbox: deformThumbsHBox || importedHBox,
            vbox: deformThumbsVBox || importedVBox,
            dbl: importedDbl,
          })
        }

        if (args.type === 'collection') {
          selectVca({
            _id: pickedShapeId,
            fromCollection: true,
          })
        }
      }
    }
    // reset deform values - we should not need it anymore
    deformThumbsVertical()
    deformThumbsHorizontal()

    // finally hide the popup itself
    this.props.close()
  }

  render() {
    const {
      standardLibDeformed,
      myLibDeformed,
      deformThumbsHBox,
      deformThumbsVBox,
      deformThumbsVertical,
      deformThumbsHorizontal,
      args,
      deleteMyShape,
      shapesCollections,
      t,
    } = this.props

    const { pickedShapeId, searchInputValue } = this.state

    let title
    let lib
    switch (args.type) {
      case 'standardShapes': {
        title = t('standart shape selection')
        lib = standardLibDeformed
        break
      }
      case 'myShapes': {
        title = t('custom shape selection')
        lib = myLibDeformed
        break
      }
      case 'collection': {
        const col = shapesCollections.find(col => col.typeKey === args.collectionKey)
        title = col.name
        lib = col.shapes
        break
      }
      // no default
    }

    // filter shapes
    const searchText = searchInputValue.trim().toLowerCase()
    const filteredLib = lib.filter(({ name = '' }) => {
      const nameFilterString = name.trim().toLowerCase()
      return nameFilterString.includes(searchText)
    })

    return (
      <PopupPresentational
        title={title}
        close={this.close}
        type="big"
        okText={t('choose shape')}
        cancelText={t('cancel')}
        okDisabled={!pickedShapeId}
        displayChildrenInMessage={false}
      >
        {lib && lib.length > 0 && (
          <>
            {args.type !== 'collection' && (
              <Row>
                <Text>{t('CUSTOM_SHAPE_SELECT_DESCRIPTION')}</Text>
              </Row>
            )}

            <Row>
              {args.type !== 'collection' && (
                <>
                  <NumberField
                    label={t('HBOX')}
                    name="hbox"
                    onChange={e => {
                      deformThumbsHorizontal(e.value)
                    }}
                    value={deformThumbsHBox}
                  />
                  <Gap />
                  <NumberField
                    label={t('VBOX')}
                    name="vbox"
                    onChange={e => {
                      deformThumbsVertical(e.value)
                    }}
                    value={deformThumbsVBox}
                  />
                  <Gap gap="5rem" />
                </>
              )}
              <TextField
                label={t('search')}
                name="search"
                onChange={e => {
                  this.setState({ searchInputValue: e.value })
                }}
                value={searchInputValue}
              />
            </Row>
          </>
        )}
        <ThumbsWrapper>
          {filteredLib &&
            filteredLib.map((vca, index) => (
              <ThumbVisualiserWrapper key={index}>
                <ThumbVisualiser
                  onClick={() => this.onThumbClick(vca._id)}
                  key={vca._id}
                  {...vca}
                  rightOnly
                  width={238}
                  height={230}
                  hideToggleButton
                  forceRealSize // TODO: tlacitko na to do  reduxu
                  title={vca.name}
                  drawStyles={
                    vca._id === pickedShapeId ? this.getShapeStyle(true) : this.getShapeStyle(false)
                  }
                />
                {args.type !== 'standardShapes' && args.type !== 'collection' && (
                  <IconsWrapper>
                    <Popup
                      title={t('Delete shape from favorites?')}
                      text={t('Do you really want to delete selected shape?')}
                      closeOnOutsideClick
                      closeOnEsc
                      onClose={opts => {
                        if (opts.ok) {
                          deleteMyShape(vca._id)
                        }
                      }}
                      okText={t('delete')}
                      closeText={t('cancel')}
                    >
                      {({ open }) => (
                        <>
                          <OneIconWrapper onClick={open}>
                            <Icon name="IosTrash" size={24} />
                          </OneIconWrapper>
                        </>
                      )}
                    </Popup>
                  </IconsWrapper>
                )}
              </ThumbVisualiserWrapper>
            ))}
        </ThumbsWrapper>
        {(!lib || lib.length === 0) && <Text center>{t('You do not have any shapes yet')}</Text>}
      </PopupPresentational>
    )
  }
}

StandardShapeLibPopup.defaultProps = {
  shapesCollections: [],
}

StandardShapeLibPopup.propTypes = {
  close: PropTypes.func.isRequired, // is injected by PopupsGate
  deformThumbsVertical: PropTypes.func.isRequired,
  deformThumbsHorizontal: PropTypes.func.isRequired,
  deleteMyShape: PropTypes.func.isRequired,
  selectVca: PropTypes.func.isRequired,
  standardLibDeformed: PropTypes.array.isRequired,
  shapesCollections: PropTypes.array,
  myLibDeformed: PropTypes.array.isRequired,
  deformThumbsHBox: PropTypes.number.isRequired,
  deformThumbsVBox: PropTypes.number.isRequired,
  args: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
}

const enhance = compose(
  connect(
    state => ({
      standardLibDeformed: state.vca.standardLibDeformed,
      myLibDeformed: state.vca.myLibDeformed,
      deformThumbsVBox: state.vca.deformThumbsVBox,
      deformThumbsHBox: state.vca.deformThumbsHBox,
      shapesCollections: state.vca.shapesCollections,
      importedDbl: state.orders.currentOrder?.importedDbl,
      importedHBox: state.orders.currentOrder?.importedHBox,
      importedVBox: state.orders.currentOrder?.importedVBox,
    }),
    {
      deformThumbsVertical,
      deformThumbsHorizontal,
      selectVca,
      deleteMyShape,
    },
  ),
  withTranslation(),
  withTheme,
)

export default enhance(StandardShapeLibPopup)
