/* eslint-disable import/prefer-default-export */

import { useCallback, useEffect } from 'react'
import { useSelector } from 'react-redux'
import io from 'socket.io-client'
import { selectViewerId } from '../common/app/reducer'

let socket = null

// create io connection
export function getIo() {
  if (socket) {
    return socket
  }

  socket = io()

  socket.on('connect', () => {
    console.log('socket connected')
  })
  socket.on('disconnect', () => {
    console.log('socket disconnected')
  })

  return socket
}

export function addIoListener(eventName, func) {
  const socket = getIo()
  socket.on(eventName, func)

  return () => {
    socket.removeListener(eventName, func)
  }
}

// get server stats
export function emitStats(cb) {
  socket.emit('stats', cb)
}

// used when we want to trigger auth event manually - e.g. on reconnect
export function useEmitAuth() {
  const viewerId = useSelector(selectViewerId)

  const emitAuth = useCallback(() => {
    const socket = getIo()
    socket.emit('auth', viewerId)
  }, [viewerId])

  return emitAuth
}

// used for automatic emitting auth event on login / logout
export function useAuthSocketIo() {
  const viewerId = useSelector(selectViewerId)
  // console.log('viewer', viewerId)

  useEffect(() => {
    // console.log('user is authed', viewerId)
    const socket = getIo()

    socket.emit('auth', viewerId)

    return () => {
      socket.emit('auth', null)
      // console.log('user is NOT authed')
    }
  }, [viewerId])

  return {}
}
