import React from 'react'
import Icon from 'react-icon-base'
import { useTheme } from 'styled-components'

const CheckboxChecked = props => {
  const { primaryCheckbox } = useTheme().colors

  return (
    <Icon viewBox="0 0 16 16" {...props}>
      <rect width="16" height="16" rx="4" fill={primaryCheckbox} />
      <path
        d="M11.7634 4.26717L11.7232 4.2249C11.5814 4.07638 11.3894 3.99572 11.1899 4.00017C10.9904 4.00407 10.8015 4.09418 10.6661 4.24881L6.5756 8.93958L6.49517 8.97573L6.41687 8.9368L5.40206 7.69137C5.25496 7.51227 5.04438 7.40491 4.81899 7.39545C4.59359 7.386 4.37454 7.47667 4.21528 7.64354C3.96132 7.91109 3.92798 8.33217 4.13698 8.63921L6.23063 11.7213C6.34968 11.8971 6.54174 12 6.74649 12H6.92904C7.27348 12 7.5957 11.8231 7.79094 11.5261L11.8565 5.35294C12.0788 5.01531 12.0391 4.55808 11.7634 4.26717Z"
        fill="white"
      />
    </Icon>
  )
}

export default CheckboxChecked
