import { apiGet } from '../lib/api'

export const FETCH_USERS = 'FETCH_USERS'
export const FETCH_ORDERS_STATE = 'FETCH_ORDERS_STATE'

const fetchConfig = {
  credentials: 'same-origin',
}

export const fetchUsers = () => ({ getState, fetch }) => ({
  type: FETCH_USERS,
  payload: apiGet({
    fetch: (data) => fetch(data, fetchConfig),
    endpoint: 'admin/fetch-users',
    getState,
  }),
})

export const fetchOrdersState = () => ({ getState, fetch }) => ({
  type: FETCH_ORDERS_STATE,
  payload: apiGet({
    fetch: (data) => fetch(data, fetchConfig),
    endpoint: 'admin/fetch-orders-state',
    getState,
  }),
})

