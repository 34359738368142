/**
 * READ README FIRST!
 */

import pino from 'pino'
import express from 'express-pino-logger'
import { serializeProbableError } from '../serializeError'

// example
// const log = console.getLogger('MY_MODULE')

// all modules have to be listed here!
const loggerNames = [
  'MAIN',
  'ENV',
  'API',
  'BUGSNAG',
  'EXPRESS-API',
  'EXPRESS-SOAP',
  'SOAP', // Michal's SOAP server
  'SOAPER', // Peter's soaper parser for calc, milens
  'FTP',
  'MILENS',
  'DASH',
  'CRON',
  'VCA',
  'B2B',
  'CATALOG',
  'LOCALE', // for locize, i18n...
  'STATUSER', // server / lib / statuser
  'NOTER', // server / lib / delivery Noter
  'MONGO',
  'IO',
  'NOW',
  'MEMO',
  'PERF',
  'API ORDER IMPORT',
  'WORKER-CUSTOMERS',
  'WORKER-STATUSER',
  'EXCHANGER',
  'WORKERS',
  'WORKER-DELIVERY_NOTES',
  'EXCHANGER-ORDERS',
  'AUTH',
  'VALIDATIONS',
  // aways add to the end of the list
]

// { trace: 10, debug: 20, info: 30, warn: 40, error: 50, fatal: 60 }
const levels = ['trace', 'debug', 'info', 'warn', 'error', 'fatal']

const loggers = {}

let logLevel = process.env.LOG_LEVEL
let logLevels = process.env.LOG_LEVELS
if (!process.env.IS_BROWSER) {
  const config = require('../../../server/localConfig')
  // use localConfig setup for local development
  if (!['prod', 'dev'].includes(config.SERVER_ENV)) {
    logLevel = process.env.LOG_LEVEL || config.LOG_LEVEL
    logLevels = process.env.LOG_LEVELS || config.LOG_LEVELS
  }
} else {
  // only error logs for browser in production
  if (process.env.NODE_ENV === 'production') {
    logLevel = 'error'
    logLevels = undefined
  }

  const urlParams = new URLSearchParams(window.location.search)
  if (urlParams.get('LOG_LEVEL')) {
    logLevel = urlParams.get('LOG_LEVEL')
    console.log('setting custom LOG_LEVEL', logLevel)
  }
  if (urlParams.get('LOG_LEVELS')) {
    logLevels = urlParams.get('LOG_LEVELS')
    console.log('setting custom LOG_LEVELS', logLevels)
  }
}

if (logLevels) {
  logLevels = logLevels.split(',')
}

loggerNames.forEach((moduleName, index) => {
  loggers[moduleName] = createLogger({
    name: moduleName,
    level: (logLevels && logLevels[index]) || logLevel,
  })
})

const browserPrettyConfig = {
  trace: { icon: '🔍' },
  debug: { icon: '🐛' },
  info: { icon: '✨' },
  warn: { icon: '⚠️' },
  error: { icon: '🚨' },
  fatal: { icon: '💀', method: 'error' },
}

function createLogger({ name, level = 'info' }) {
  if (level === 'debug' || level === 'trace') {
    console.log('creating logger', name, level)
  }
  // const dest = pino.destination('./logs/out.txt')
  const dest = !process.env.IS_BROWSER ? pino.destination(1) : null

  const options = {
    name,
    // redact: ['key', 'path.to.key', 'stuff.thats[*].secret']
    redact: ['password', 'token', 'headers["x-access-token"]'],
    level,
    hooks: {
      logMethod(args, method) {
        // SERVER ONLY
        // let's patch log methods args to what we used to
        // args = merged obj, msg, ...interpolated
        let mergedObj = {}
        let message = ''
        args.forEach((arg, index) => {
          if (['string', 'number', 'boolean'].includes(typeof arg)) {
            message = !message ? arg : `${message} ${arg}`
          } else {
            // if not mergedObj, stringify it
            if (index !== 0) {
              // JSON.stringify is slow! use it only when debug, not in normal production
              message = `${message} ${JSON.stringify(serializeProbableError(arg))}`
              // message = `${message} ${JSON.stringify(
              //   arg,
              //   arg instanceof Error ? Object.getOwnPropertyNames(arg) : undefined,
              // )}`
            }

            // TODO: rozhodnout jestli v produkci vynechat stringify a nebo takove veci budou jen v debug a niz

            // only first obj is merged
            if (index === 0) {
              mergedObj = {
                ...mergedObj,
                ...arg,
              }
            }

            // auto-merge errors
            if (arg instanceof Error) {
              mergedObj = {
                ...mergedObj,
                mergedErrors: [...(mergedObj.mergedErrors || []), serializeProbableError(arg)],
              }
            }
          }
        })

        const updatedArgs = [mergedObj, message]
        // merged obj, msg, ...interpolated
        return method.apply(this, updatedArgs)
      },
    },
  }
  if (process.env.IS_BROWSER) {
    options.browser = {
      asObject: true,
      write: o => {
        o.levelName = o.levelName || 'info'
        const consoleMethod =
          console[browserPrettyConfig[o.levelName].method || o.levelName] || console.log

        consoleMethod(
          `${browserPrettyConfig[o.levelName].icon} %c${o.name}`,
          'color: rgb(73,148,201);',
          ...o.args
        )
      },
    }
  }

  const logger = pino(options, dest)
  logger.on('level-change', (lvl, val, prevLvl, prevVal) => {
    if (prevVal !== val) {
      console.log('%s (%d) was changed to %s (%d)', prevLvl, prevVal, lvl, val)
      logger.info('%s (%d) was changed to %s (%d)', prevLvl, prevVal, lvl, val)
    }
  })

  patchBrowserLogger({ logger, name })

  return logger
}

function patchBrowserLogger({ logger, name }) {
  if (process.env.IS_BROWSER) {
    // monkey patch methods to args we are used to
    levels.forEach(level => {
      logger[`${level}Original`] = logger[level].bind(logger)
      logger[level] = (...args) => {
        // just add args in merged object, then prettify this in write config above
        logger[`${level}Original`]({ args, levelName: level, name })
      }
    })
  }

  logger.changeLevel = level => {
    logger.level = level
    patchBrowserLogger({ logger, name })
  }
}

export function getLogger(name = 'MAIN') {
  if (!loggers[name]) {
    throw new Error('You have to list module name in config!')
  }
  return loggers[name]
}

// enhance console, so we dont have to import everywhere
const mainLogger = getLogger('MAIN')
console.getLogger = getLogger
console.l = {
  getLogger,
}
levels.forEach(level => {
  // console.l[level] = (...args) => mainLogger[level].call(mainLogger, ...args)
  console.l[level] = mainLogger[level].bind(mainLogger)
})

export function getLoggerMiddleware(loggerName) {
  return express({
    logger: getLogger(loggerName),
  })
}

// SERVER ONLY
if (!process.env.IS_BROWSER) {
  // asynchronously flush every 10 seconds to keep the buffer empty
  // in periods of low activity
  setInterval(function () {
    Object.keys(loggers).forEach(loggerKey => {
      loggers[loggerKey].flush()
    })
  }, 10000).unref()

  // const handler = final(mainLogger, (err, finalLogger, evt) => {
  //   finalLogger.info(`${evt} caught`)
  //   if (err) finalLogger.error(err, 'error caused exit')
  //   process.exit(err ? 1 : 0)
  // })
  // process.on('beforeExit', () => handler(null, 'beforeExit'))
  // process.on('exit', () => handler(null, 'exit'))
  // process.on('uncaughtException', err => handler(err, 'uncaughtException'))
  // process.on('SIGINT', () => handler(null, 'SIGINT'))
  // process.on('SIGQUIT', () => handler(null, 'SIGQUIT'))
  // process.on('SIGTERM', () => handler(null, 'SIGTERM'))

  // enable changing log levels on the fly
  const Arborsculpt = require('./reloader')
  const arbor = new Arborsculpt({
    path: './logs/adjustments.json',
    loggers: loggerNames.map(key => getLogger(key)),
    interval: 60000,
  })

  arbor.on('error', function (err) {
    // there was a problem reading the file or setting the level
    mainLogger.error(err, 'there was a problem reading the file or setting the level')
    console.log('there was a problem reading the file or setting the level')
  })

  mainLogger.debug('logs bootstrapped')
}

// BROWSER ONLY
if (process.env.IS_BROWSER) {
  // expose change level api to window
  window.changeLogLevel = function (level) {
    loggerNames.forEach(name => {
      getLogger(name).changeLevel(level)
      console.log('level changed to', level, name)
    })
  }
  window.changeLoggerLevel = function (name, level) {
    getLogger(name).changeLevel(level)
    console.log('level changed to', level, name)
  }
}

export default function () {}
