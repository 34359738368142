const base = '/fonts/'

const f = filename => `${base}${filename}`

// TODO: tohle se muze refactorovat jeste vic a tahat nejak automaticky ze slozky dle filename
export const fonts = [
  {
    familyName: 'Roboto',
    formats: ['woff2', 'woff'],
    files: [
      {
        weight: 400,
        filenames: [f('400.woff2'), f('400.woff')],
        style: 'normal', // custom
      },
      {
        weight: 500,
        filenames: [f('500.woff2'), f('500.woff')],
        style: 'normal', // custom
      },
      {
        weight: 700,
        filenames: [f('700.woff2'), f('700.woff')],
        style: 'normal', // custom
      },
    ],
  },
]

// TODO: add into app head pre fetch links

/**
 *
 *       <Head>
          <link
            rel="preload"
            href="/fonts/EBGaramond/EBGaramond-Regular.ttf"
            as="font"
            crossOrigin=""
          />
          <link
            rel="preload"
            href="/fonts/EBGaramond/EBGaramond-Medium.ttf"
            as="font"
            crossOrigin=""
          />
        </Head>
 *
 */
