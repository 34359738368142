import moment from 'moment'
import {
  translate,
  orderTypes,
  bevelPositions,
  frameTypes,
  appTypeConfig,
  orderStatuses,
  orderListTimeTypes,
  R,
  L,
} from '../config'
import { getFinalRadiiByShapeAndLenses } from '../lib/vca'
import { resetCatalog } from '../catalog/actions'
import { getOrderSchema } from '../schemaConfig'
import { checkAreBaseCurvesValid } from '../basecurve'
import { getLensValuesBySide } from '../helpers'

const { individualMenus, allIndividualMenuFieldKeys } = appTypeConfig

const orderSchema = getOrderSchema()
const orderRelationsKeys = Object.keys(orderSchema)
  .map(k => orderSchema[k].ref ? k : undefined)
  .filter(a => a)
const orderBooleanKeys = Object.keys(orderSchema)
  .map(k => orderSchema[k].type && orderSchema[k].type.name === 'Boolean' ? k : undefined)
  .filter(a => a)

export const getIsPolishAvailable = (order, user) => {}

export const deleteIndividualMenuValuesIfNotUsed = order => {
  if (appTypeConfig.APP_TYPE === 'omega') {
    if (!order.individualMenu) {
      order.bvdR = null
      order.bvdL = null
      order.frameBowAngle = null
      order.panto = null
    } else {
      const menuConf = individualMenus[order.individualMenu]
      if (menuConf.bvd === false) {
        order.bvdR = null
        order.bvdL = null
      }
      if (menuConf.panto === false) {
        order.panto = null
      }
      if (menuConf.frameBowAngle === false) {
        order.frameBowAngle = null
      }
    }
  } else {
    const individualMenuConfig = individualMenus[order.individualMenu] || {}
    // delete all unused keys
    allIndividualMenuFieldKeys.forEach(individualMenuKey => {
      if (typeof individualMenuConfig[individualMenuKey] === 'undefined') {
        order[individualMenuKey] = null
      }
    })
    // reize uses cvt instead of bvd. Bvd should not be stored
    // but it was stored before and this makes sure it is not in new orders
    if (appTypeConfig.APP_TYPE === 'reize') {
      order.bvdR = null
      order.bvdL = null
    }
  }
  return order
}

export const getIsPolishDisabledForFrameType = frameType => {
  if (appTypeConfig?.getPolishDisabledWithFrameTypes) {
    const disabledFrameType = appTypeConfig.getPolishDisabledWithFrameTypes()
    return disabledFrameType.includes(frameType)
  }
  return false
}

// eslint-disable-next-line
export const cleanOrder = order => {
  order = { ...order }

  if (!order.isExpress) {
    // make sure express code is not stored, if it is not express order
    delete order.expressCode
  }

  if (![frameTypes.NYLOR, frameTypes.GROOVEDDRILLED].includes(order.frameType)) {
    order.grooveWidth = null
    order.grooveDepth = null
  }
  if (![frameTypes.DRILLED, frameTypes.GROOVEDDRILLED].includes(order.frameType)) {
    order.holes = null
    order.minHoleThickness = null
  }

  if ([bevelPositions.AUTO, bevelPositions.HALF].includes(order.bevelPosition)) {
    order.bevelModifier = null
  }

  if (order.polish) {
    if ([orderTypes.DIAMETER_ONLY, orderTypes.WITH_SHAPE].includes(order.orderType)){
      delete order.polish
    }
    // polish may not available for some frame types
    if (getIsPolishDisabledForFrameType(order.frameType)) order.polish = false
  }

  // if (!order.hasUvR) { // TODO hasUv už neexistuje ve formuláři, takže toto asi může být smazáno
  //   delete order.hasUvR
  // }
  // if (!order.hasUvL) {
  //   delete order.hasUvL
  // }

  if ([orderTypes.DIAMETER_ONLY, orderTypes.WITH_SHAPE].includes(order.orderType)) {
    delete order.bevelPosition
  }

  if (orderTypes.WITH_FRAME !== order.orderType) {
    delete order.frame
  }

  if (!order.rightLensEnabled) {
    order = deleteOneSide(order, 'R')
  }
  if (!order.leftLensEnabled) {
    order = deleteOneSide(order, 'L')
  }

  order = deleteIndividualMenuValuesIfNotUsed(order)

  if (order.orderType === orderTypes.DIAMETER_ONLY) {
    order.shapeData = null
    order.pdR = null
    order.pdL = null
    order.heightR = null
    order.heightL = null
    order.heightL = null
    order.holes = null
    order.minHoleThickness = null
    order.frameType = null
    // order.isCtoCapableR = null
    // order.isCtoCapableL = null
    order.ctoR = null
    order.ctoL = null
  }

  // TODO - smazat po zvalidování
  // na reize může být non eliptical i když není DIAMETER ONLY
  // Pokud to je nevalidní, tak se to stejně smaže na následujících řádcích
  // is always -10 smaller
  if (order.isEllipticalR) {
    order.diameterEllipticalR = order.diameterPhysicalR - 10
  }
  if (order.isEllipticalL) {
    order.diameterEllipticalL = order.diameterPhysicalL - 10
  }

  if (order.orderType !== orderTypes.DIAMETER_ONLY) {
    if (!isRxDesignCustomDiameter(order.isCtoCapableR, order.ctoR)) {
      order.diameterEllipticalR = null
      order.diameterOpticalR = null
      order.isEllipticalR = false
    }

    if (!isRxDesignCustomDiameter(order.isCtoCapableL, order.ctoL)) {
      order.diameterEllipticalL = null
      order.diameterOpticalL = null
      order.isEllipticalL = false
    }
    // TODO: calculate this????
  }

  // clean cto if is not capable
  if (!order.isCtoCapableR) order.ctoR = null
  if (!order.isCtoCapableL) order.ctoL = null

  // delete all empty values / strings
  // UPDATE: this deleted empty changes... use $cleanValues instead
  // ANOTHER UPDATE: we have to change empty strings for relations to null
  Object.keys(order).forEach(k => {
    if (order[k] === '' && orderRelationsKeys.includes(k)) {
      order[k] = null
    }
    if (order[k] === '' && orderBooleanKeys.includes(k)) {
      order[k] = false
    }
  })

  return order
}

const deleteOneSide = (order, side) => {
  order = { ...order }
  Object.keys(order).forEach(k => {
    if (k.endsWith(side)) {
      delete order[k]
    }
  })
  return order
}

export const isRxDesignCustomDiameter = (isCtoCapable, cto) => isCtoCapable && !cto

// currentLens is not mandatory -- is for validation now! (radii)
export const prepareOrderToSend = ({ orderValues, selectedVca, currentLensR, currentLensL }) => {
  let order = {
    ...orderValues,
  }

  console.log('orderValues', orderValues, selectedVca)

  if (appTypeConfig.isBaseCurveSelectEnabled) {
    const areBaseCurvesValid = checkAreBaseCurvesValid({
      values: orderValues,
      currentLensR,
      currentLensL,
      selectedVca,
    })

    if (!areBaseCurvesValid.R) order.baseCurveR = null
    if (!areBaseCurvesValid.L) order.baseCurveL = null
    if (order.isLeftLensSameAsRight && !areBaseCurvesValid.R) order.baseCurveL = null
  }

  // merge vca and order form data...
  if (order.orderType !== orderTypes.DIAMETER_ONLY) {
    if (selectedVca) {
      // ! currentLens is not provided when saving draft! therefore validation of diameters is not performed!!

      const radii = getFinalRadiiByShapeAndLenses({
        vca: selectedVca,
        lensR: currentLensR,
        lensL: currentLensL,
        order: orderValues,
      })

      let diameterPhysicalR = radii.r * 2

      // if rx design and custom diameter - use it (this is duplicated below for not selected vca)
      if (isRxDesignCustomDiameter(orderValues.isCtoCapableR, orderValues.ctoR)) {
        diameterPhysicalR = orderValues.diameterPhysicalR
      }

      let diameterPhysicalL = radii.l * 2
      // if rx design and custom diameter - use it
      if (isRxDesignCustomDiameter(orderValues.isCtoCapableL, orderValues.ctoL)) {
        diameterPhysicalL = orderValues.diameterPhysicalL
      }

      order = {
        ...order,
        frameCurveR: selectedVca.frameCurveR,
        frameCurveL: selectedVca.frameCurveL,
        shapeData: selectedVca.shapeData, // if order type one -- no shape
        holes: selectedVca.holes,
        dbl: selectedVca.dbl,
        diameterPhysicalR,
        diameterPhysicalL,
      }
      if (selectedVca.box) {
        order = {
          ...order,
          hboxR: selectedVca.box.r.hbox,
          vboxR: selectedVca.box.r.vbox,
          hboxL: selectedVca.box.l.hbox,
          vboxL: selectedVca.box.l.vbox,
        }
      }
    } else {
      // we have to reset shape related data if shape is not set!
      order = {
        ...order,
        hboxR: null,
        vboxR: null,
        hboxL: null,
        vboxL: null,
        frameCurveR: null,
        frameCurveL: null,
        shapeData: null,
        holes: null,
        dbl: null,
        diameterPhysicalR: null,
        diameterPhysicalL: null,
      }

      // reize speciality (it is duplicated, cuz someone does not have to select vca in step1 and want to save custom diam.)
      if (isRxDesignCustomDiameter(orderValues.isCtoCapableR, orderValues.ctoR)) {
        order = {
          ...order,
          diameterPhysicalR: orderValues.diameterPhysicalR,
        }
      }
      if (isRxDesignCustomDiameter(orderValues.isCtoCapableL, orderValues.ctoL)) {
        order = {
          ...order,
          diameterPhysicalL: orderValues.diameterPhysicalL,
        }
      }
    }
  }

  // console.log('order before clean', order)
  // now clean it - leftovers from different order types...
  order = cleanOrder(order)
  // console.log('order after clean', order)

  return order
}

export const orderDateTranslate = ({ order, t, orderListTimeType }) => {
  let text = ''
  let time = ''

  if (orderListTimeType === orderListTimeTypes.SENT_ONLY) {
    if (!order.ftpSentAt) return ''
    time = moment(order.ftpSentAt)
    text = t('sent at')
  } else {
    switch (order.status) {
      case orderStatuses.AT_MANUFACTURER: {
        // text = t('sent at')
        // time = moment(order.statusSentToManufacturerAt)
        // console.log('order.lastStatusUpdateAt', order.lastStatusUpdateAt)
        time = moment(order.lastStatusUpdateAt)
        break
      }
      case orderStatuses.SENDING: {
        if (order.ftpSentAt) {
          text = t('sent at')
          time = moment(order.ftpSentAt)
        } else {
          text = t('created at')
          time = moment(order.createdAt)
        }
        break
      }
      case orderStatuses.SENT: {
        text = t('sent at')
        time = moment(order.ftpSentAt)
        break
      }
      case orderStatuses.DONE: {
        text = t('done at')
        time = moment(order.statusDoneAt)
        break
      }
      case orderStatuses.CANCELLED: {
        text = t('Cancelled at')
        time = moment(order.statusCancelledAt)
        break
      }
      case orderStatuses.ARCHIVED: {
        text = t('Archived at')
        time = moment(order.statusArchivedAt)
        break
      }
      default: {
        text = t('created at')
        time = moment(order.createdAt)
      }
    }
  }

  const isToday = moment().isSame(time, 'day')
  const wasYesterday = moment().subtract(1, 'days').isSame(time, 'd')

  if (isToday || wasYesterday) {
    time = time.format('HH:mm')
    if (wasYesterday) text = text.concat(` ${t('yesterday')}`)
  } else {
    const isThisYear = moment().isSame(time, 'year')
    time = isThisYear ? time.format('D. M.  HH:mm') : time.format('D. M. YYYY')
  }

  return `${text} ${time}`
}

/*
const orderDateText = (lang, order) => {
  let text = translate(lang, `TIME_${order.status}`)
  let time = moment(order.createdAt)
  // TODO: remove the '|| time' later on the following lines, which is there for debugging purposes
  if (order.status === orderStatuses.AT_MANUFACTURER) time = moment(order.statusSentToManufacturerAt) || time
  if (order.status === orderStatuses.DONE) time = moment(order.statusDoneAt) || time
  if (order.status === orderStatuses.CANCELLED) time = moment(order.statusCancelledAt) || time

  const isToday = moment().isSame(time, 'day')
  const wasYesterday = moment().subtract(1, 'days').isSame(time, 'd')

  if (isToday || wasYesterday) {
    time = time.format('kk:mm')
    if (wasYesterday) text = text.concat(` ${translate(lang, 'YESTERDAY')}`)
  } else {
    const isThisYear = moment().isSame(time, 'year')
    time = isThisYear ? time.format('D. M.') : time.format('D. M. YYYY')
  }

  return `${text} ${time}`
}

export const translateOrder = lang => order => ({
  ...order,
  // statusText: translate(lang, order.status),
  // orderTypeText: translate(lang, order.orderType),
  dateText: orderDateText(lang, order),
}) */

export const sortOrderStatusHistory = order => {
  const subStatusHistory = order.subStatusHistoryNames.map((statusName, index) => ({
    statusName,
    statusAt: order.subStatusHistoryTimes[index],
    isSubStatus: true,
  }))
  const statusHistory = order.mainStatusHistoryNames.map((statusName, index) => ({
    statusName,
    statusAt: order.mainStatusHistoryTimes[index],
    isSubStatus: false,
  }))

  const sortedStatusHistory = [...subStatusHistory, ...statusHistory].sort(
    (a, b) => new Date(a.statusAt) - new Date(b.statusAt),
  )

  // fix AT_MANUFACTURER lag (is set after substatus, we need to switch first two)
  for (let index = 0; index < sortedStatusHistory.length; index += 1) {
    const history = sortedStatusHistory[index]
    if (history.statusName === orderStatuses.AT_MANUFACTURER) {
      if (sortedStatusHistory[index - 1]?.isSubStatus) {
        const subStatus = sortedStatusHistory[index - 1]
        sortedStatusHistory[index - 1] = history
        sortedStatusHistory[index] = subStatus
      }
    }
  }

  return sortedStatusHistory
}

export const totalResetOrder = () => {
  if (process.env.IS_BROWSER) {
    const storeWrapper = require('../../browser/main')
    const store = storeWrapper.getStore()
    const { resetOrder } = require('./actions')
    const { resetFields } = require('../lib/redux-fields/actions')
    store.dispatch(resetOrder())
    store.dispatch(resetFields(['OrderForm']))
    store.dispatch(resetFields(['CatalogForm']))
    store.dispatch(resetFields(['FrameCatalogForm']))
    store.dispatch(resetCatalog())
  }
}

// user.isGetOrderPriceEnable is STRING, because it can be empty (it has three possible values)
// if it is empty, value from group is applied
export const getIsGetOrderPriceEnabled = (user, config) =>
  appTypeConfig.isGetPricesFromLWSoapEnabled && // it must be enabled in appTypeConfig
  config.isGetOrderPriceEnabled && // set in pageVersion / hostConf
  (user.isGetOrderPriceEnabled === 'true' || // user.isGetOrderPriceEnabled is string !!!
    (user.group.isGetOrderPriceEnabled && user.isGetOrderPriceEnabled !== 'false'))
