import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import styled, { css } from 'styled-components'
import { withTranslation } from 'react-i18next'
import { FullScreen } from 'react-full-screen'

import {
  deformSelected,
  saveSelectedVcaDeformed,
  resetSelectedVcaDeformed,
  setDrawingVca,
  updateVcaDrawPoint,
  normalizeDrawingVca,
  smoothDrawingVca,
  centerDrawingVca,
  flipDrawingVcaHorizontally,
} from '../../../common/vca/actions'
import { hideAllPopups } from '../../../common/popups/actions'
import { setTabletConfig } from '../../../common/config/actions'
import { withNotifs } from '../hoc'

import { FieldsGroup, Row, Col, InfoMessage, Gap, Button, Link, Text, WarningMessage } from '..'
import Visualiser from './Visualiser'
import { NumberField } from '../fields'
import Popup from '../popups/Popup'
import withFullScreen from './withFullScreen'

const StyledVisualiser = styled(Visualiser)`
  /* border: red solid thin; */
`

const VisualiserWrapper = styled.div`
  ${({ isFullscreen }) => css`
    width: 100%;
    height: 100%;
    position: ${!isFullscreen && 'relative'};
  `}
`

const EmphasizedWrapper = styled.div`
  padding: 1rem 1.5rem;
  border-radius: 3px;
  color: white;
  ${({ transparent, theme: { colors } }) => css`
    background: ${colors.primary};
    background: ${transparent && 'transparent'};
  `}
`
const VisualiserOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  ${({ hidden }) => css`
    display: ${hidden && 'none'};
  `}
`

const calcAngle = point => {
  let angle = (Math.atan2(point.y, point.x) * 180) / Math.PI
  angle = angle < 0 ? -angle : 360 - angle
  return angle
}

class Drawer extends Component {
  state = {
    emphasizePoint: 0,
    isFullscreen: false,
    fullscreenWidth: 600,
    fullscreenHeight: 350,
    isCalibrating: false,
    calibrationFirstPoint: null,
    mouseDown: false,
    vcaCopy: null,
  }

  calibrateStep = mouseData => {
    const { setTabletConfig } = this.props
    const { calibrationFirstPoint } = this.state

    // first point
    if (!calibrationFirstPoint) {
      this.setState({ calibrationFirstPoint: mouseData })
      return
    }

    // second point
    const dx = mouseData.xPx - calibrationFirstPoint.xPx
    const dy = mouseData.yPx - calibrationFirstPoint.yPx

    const quadrantDim = 38.5 // mm
    const xRatio = Math.abs(dx / quadrantDim) // how much is one real mm screen pixels
    const yRatio = Math.abs(dy / quadrantDim) // how much is one real mm screen pixels

    setTabletConfig({ xRatio, yRatio })

    setTimeout(() => {
      this.setState({
        calibrationFirstPoint: null,
        isCalibrating: false,
      })
    }, 100) // cuz user may slightly move mouse and we dont want to trigger mouseMove
  }

  onMouseDown = data => {
    // console.log('onMouseDown')
    const mousePoint = {
      x: data.lenseMouseX,
      y: data.lenseMouseY,
      xPx: data.xPx,
      yPx: data.yPx,
    }

    const { vca } = this.props

    const vcaCopy = {
      ...vca,
      shapeDataXY: {
        ...vca.shapeDataXY,
        r: vca.shapeDataXY.r.map(p => ({ ...p })),
      },
    }

    this.setState({ mouseDown: true, vcaCopy })

    if (this.state.isCalibrating) {
      return this.calibrateStep(mousePoint)
    }
  }

  onMouseUp = data => {
    // console.log('!!!!!onMouseUp')
    this.props.setDrawingVca(this.state.vcaCopy)
    this.props.normalizeDrawingVca({ justCoords: true })
    this.setState({ mouseDown: false })
  }

  onMouseDownMove = data => {
    // console.log('onMouseDownMove', )

    const { isFullscreen, isCalibrating, fullscreenWidth, fullscreenHeight } = this.state
    const { tablet } = this.props

    let vca = this.state.vcaCopy

    // this fn is for drawing only
    if (isCalibrating) {
      return
    }
    // disable drawing when not in fullscreen
    if (!isFullscreen) {
      return
    }
    // block if is full and not yet calibrated
    if (isFullscreen && !tablet.calibrationDone) {
      return
    }
    if (!vca || !vca.shapeData.r || !vca.shapeData.r.length) {
      console.log('[error] basic vca or shape not loaded, exit')
      return
    }

    let { axisDeformation } = tablet

    if (!isFullscreen) {
      axisDeformation = 1
    }

    const mousePoint = {
      x: data.lenseMouseX * axisDeformation,
      y: data.lenseMouseY,
    }
    const angleStep = 360 / (vca.shapeData.r.length + 1)

    const shapePoint = {
      ...mousePoint,
      r: Math.sqrt(mousePoint.x ** 2 + mousePoint.y ** 2),
    }

    let index = Math.floor(calcAngle(mousePoint) / angleStep)
    // console.log('index', index)
    if (index > vca.shapeData.r.length - 1) {
      // we have no data for 360 deg -- wut?
      index = vca.shapeData.r.length - 1
      // console.log('Updated index', index)
    }

    // check if there was lag and interpolate between last and current position
    let diff = Math.abs(this.lastIndex - index)
    let direction = this.lastIndex < index ? -1 : 1

    // console.log('diffdiff', diff, direction, this.lastIndex, index)
    // used when we draw over zero index
    // let interpolateShift = 0
    if (diff > vca.shapeData.r.length * 0.7) {
      // probably diff around 0 - 360 deg
      diff = Math.abs(diff - vca.shapeData.r.length)
      direction = this.lastIndex > index ? -1 : 1
      // console.log('!!! over zero', )
    }

    vca = this.updatePointInVca({ index, point: shapePoint, vca })

    // console.log('this.lastIndex, index', this.lastIndex, index)
    let extrapolatedPoint = shapePoint

    if (
      typeof this.lastIndex !== 'undefined' &&
      this.lastIndex !== index &&
      new Date() - this.lastIndexTime < 1500 &&
      diff <= 400 && // interpolate only ..
      diff > 0 // but more then 2 points
    ) {
      let stepX = (this.lastPoint.x - shapePoint.x) / diff
      let stepY = (this.lastPoint.y - shapePoint.y) / diff
      if (direction < 0) {
        stepX = (shapePoint.x - this.lastPoint.x) / diff
        stepY = (shapePoint.y - this.lastPoint.y) / diff
      }
      // console.log('interpolate', direction, diff, stepX, stepY)

      // if (diff > 10) {
      //   console.log('!!!!! more than 10')
      // }

      for (let i = 0; Math.abs(i) <= diff + 1; i -= direction) {
        // const x = shapePoint.x + i * stepX
        // const y = shapePoint.y + i * stepY
        const x = this.lastPoint.x + i * stepX
        const y = this.lastPoint.y + i * stepY
        const interpolatedPoint = {
          x,
          y,
          r: Math.sqrt(x ** 2 + y ** 2),
        }
        const interpolatedPointIndex = this.ensureIndexIsInCycle(this.lastIndex + i, vca)

        vca = this.updatePointInVca({
          index: interpolatedPointIndex,
          point: interpolatedPoint,
          vca,
        })

        // save last point - we will set it as final point later
        if (Math.abs(i) === diff) {
          extrapolatedPoint = interpolatedPoint
        }

        // console.log(
        //   'interpolatedPointIndex',
        //   interpolatedPointIndex,
        //   index,
        //   interpolatedPoint.x,
        //   interpolatedPoint.y,
        //   interpolatedPoint.r,
        //   'from', this.lastPoint.x,
        //   'to', shapePoint.x
        // )
      }
    }

    // update to current
    // console.log('CURR index', index, this.ensureIndexIsInCycle(index - direction * 1, vca))
    // mark final index the one before final
    this.lastIndex = this.ensureIndexIsInCycle(index - direction * 1, vca)
    this.lastIndexTime = Date.now()
    this.lastPoint = extrapolatedPoint
    this.setState({
      emphasizePoint: index,
      vcaCopy: vca,
    })
  }

  ensureIndexIsInCycle = (index, vca) => {
    if (index > vca.shapeData.r.length - 1) {
      index -= vca.shapeData.r.length
    }
    if (index < 0) {
      index += vca.shapeData.r.length
    }
    return index
  }

  // this wont rerender!
  updatePointInVca = ({ index, point, vca }) => {
    const newShapeXY = [...vca.shapeDataXY.r]
    newShapeXY[index] = point

    return {
      ...vca,
      invalidRadii: true,
      shapeDataXY: {
        ...vca.shapeDataXY,
        r: newShapeXY, // right side only
      },
    }
  }

  goFull = () => {
    this.setState({ isFullscreen: true })
  }

  render() {
    const {
      t,
      vca,
      normalizeDrawingVca,
      smoothDrawingVca,
      centerDrawingVca,
      tablet = {},
      flipDrawingVcaHorizontally,
      setDrawingVca,
      fullScreenHandle,
    } = this.props

    const {
      emphasizePoint,
      isFullscreen,
      fullscreenWidth,
      fullscreenHeight,
      isCalibrating,
      calibrationFirstPoint,
      mouseDown,
      vcaCopy,
    } = this.state

    const visualiserWidth = isFullscreen ? fullscreenWidth : 600
    const visualiserHeight = isFullscreen ? fullscreenHeight : 500

    const shownVca = mouseDown ? vcaCopy : vca

    const showInitialCalibrationMessage = isFullscreen && !tablet.calibrationDone && !isCalibrating

    const closeMessage = (
      <Row>
        <InfoMessage>{t('draw - bottom fullscreen instructions')}</InfoMessage>
        <Gap />
        <Button
          primary
          onClick={() => {
            normalizeDrawingVca()
            this.setState({ isFullscreen: false })
            fullScreenHandle.exit()
          }}
        >
          {t('Done')}
        </Button>
      </Row>
    )

    const notCalibratedMessage = (
      <Row>
        <WarningMessage>{t('draw - tablet calibration info')}</WarningMessage>
        <Gap />
        <Button
          onClick={() => {
            this.setState({ isCalibrating: true })
          }}
        >
          {t('Calibrate')}
        </Button>
      </Row>
    )

    let calibrationMessage = (
      <Row>
        <InfoMessage>{t('draw - tablet calibration done')}</InfoMessage>
        <Gap />
        <Button
          onClick={() => {
            this.setState({ isCalibrating: true })
          }}
        >
          {t('Calibrate again')}
        </Button>
      </Row>
    )
    if (isCalibrating) {
      calibrationMessage = (
        <EmphasizedWrapper>
          <Text color="white" big>
            {!calibrationFirstPoint && t('draw - tablet calibration - step 1')}
            {calibrationFirstPoint && t('draw - tablet calibration - step 2')}
          </Text>
        </EmphasizedWrapper>
      )
    }

    // console.log('renders')
    return (
      <div>
        <Row>
          <Col>
            {shownVca && (
              <div>
                <FullScreen
                  handle={fullScreenHandle}
                  enabled={this.state.isFullscreen}
                  onChange={(isFullscreen, ...rest) => {
                    console.log('isFullscreenisFullscreen', isFullscreen, rest)
                    const rect = this.visualiserRef.getBoundingClientRect()
                    this.setState({
                      isFullscreen,
                      fullscreenWidth: rect.width,
                      fullscreenHeight: rect.height,
                    })
                    if (isFullscreen) {
                      setTimeout(() => {
                        const rect = this.visualiserRef.getBoundingClientRect()
                        this.setState({
                          fullscreenWidth: rect.width,
                          fullscreenHeight: rect.height,
                        })
                      }, 500)
                    }
                  }}
                >
                  <VisualiserWrapper isFullscreen={isFullscreen}>
                    <StyledVisualiser
                      {...shownVca}
                      width={visualiserWidth}
                      height={visualiserHeight}
                      rightOnly
                      onMouseDownMove={this.onMouseDownMove}
                      onMouseDown={this.onMouseDown}
                      onMouseUp={this.onMouseUp}
                      emphasizePoint={emphasizePoint}
                      showShapeGeometryCenter
                      debug
                      fullscreenRef={r => {
                        this.visualiserRef = r
                      }}
                      isFullscreen={isFullscreen}
                      topMessage={
                        isFullscreen && !showInitialCalibrationMessage && calibrationMessage
                      }
                      bottomMessage={isFullscreen && !isCalibrating && closeMessage}
                      forceScale={isFullscreen && tablet.visualiserScale}
                      overlayMessage={showInitialCalibrationMessage && notCalibratedMessage}
                      hideToggleButton
                      hidden={isCalibrating || showInitialCalibrationMessage}
                    />
                    <Popup
                      title={t('How to draw?')}
                      text={t(
                        'Drawing is now enabled only in the fullscreen mode. Click on the Start drawing button.',
                      )}
                      closeOnOutsideClick
                      closeOnEsc
                      hideCancel
                    >
                      {({ open }) => (
                        <>
                          <VisualiserOverlay onClick={open} hidden={isFullscreen} />
                        </>
                      )}
                    </Popup>
                  </VisualiserWrapper>
                </FullScreen>
              </div>
            )}
          </Col>

          <Col grow={1}>
            <Button
              primary
              onClick={() => {
                this.goFull()
                fullScreenHandle.enter()
              }}
            >
              {t('Start drawing')}
            </Button>
            <Gap />
            <Button onClick={smoothDrawingVca}>{t('Smooth it')}</Button>
            <Gap />
            <Button onClick={centerDrawingVca}>{t('Center shape')}</Button>
            <Gap />
            <Button onClick={flipDrawingVcaHorizontally}>{t('Mirror shape')}</Button>
            <Gap />
            <Button
              onClick={() => {
                setDrawingVca()
              }}
            >
              {t('Reset')}
            </Button>
            <Gap />
            <Gap />
            <WarningMessage>{t('Do not forget to check BOX values in step 2!')}</WarningMessage>
          </Col>
        </Row>
      </div>
    )
  }
}

Drawer.defaultProps = {
  deformSelectedRight: 0,
  deformSelectedTop: 0,
  deformSelectedLeft: 0,
  deformSelectedBottom: 0,
  order: {},
  currentLensR: null,
  tablet: {},
}

Drawer.propTypes = {
  selectedVca: PropTypes.object.isRequired,
  selectedVcaDeformed: PropTypes.object.isRequired,
  deformSelectedRight: PropTypes.number,
  deformSelectedTop: PropTypes.number,
  deformSelectedLeft: PropTypes.number,
  deformSelectedBottom: PropTypes.number,
  deformSelected: PropTypes.func.isRequired,
  saveSelectedVcaDeformed: PropTypes.func.isRequired,
  resetSelectedVcaDeformed: PropTypes.func.isRequired,
  order: PropTypes.object,
  currentLensR: PropTypes.object,
  vca: PropTypes.object.isRequired,
  normalizeDrawingVca: PropTypes.func.isRequired,
  updateVcaDrawPoint: PropTypes.func.isRequired,
  smoothDrawingVca: PropTypes.func.isRequired,
  centerDrawingVca: PropTypes.func.isRequired,
  tablet: PropTypes.object.isRequired,
  setTabletConfig: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
}

const enhance = compose(
  connect(
    state => ({
      selectedVca: state.vca.selectedVca,
      selectedVcaDeformed: state.vca.selectedVcaDeformed,
      deformSelectedRight: state.vca.deformSelectedRight,
      deformSelectedTop: state.vca.deformSelectedTop,
      deformSelectedLeft: state.vca.deformSelectedLeft,
      deformSelectedBottom: state.vca.deformSelectedBottom,
      order: state.fields.OrderForm,
      currentLensR: state.catalog.currentLensR,
      vca: state.vca.drawingVca,
      tablet: state.config.tablet,
    }),
    {
      deformSelected,
      saveSelectedVcaDeformed,
      resetSelectedVcaDeformed,
      setDrawingVca,
      updateVcaDrawPoint,
      normalizeDrawingVca,
      smoothDrawingVca,
      centerDrawingVca,
      hideAllPopups,
      setTabletConfig,
      flipDrawingVcaHorizontally,
    },
  ),
  withNotifs,
  withTranslation(),
)

export default withFullScreen(enhance(Drawer))
