// forked PopupWithState
// https://github.com/tajo/react-portal

import React from 'react'
import PropTypes from 'prop-types'
import { Portal } from 'react-portal'

import PopupPresentational from './PopupPresentational'

const KEYCODES = {
  ESCAPE: 27,
}

class Popup extends React.Component {
  constructor(props) {
    super(props)
    this.portalNode = null
    this.state = { active: !!props.defaultOpen }
    this.openPortal = this.openPortal.bind(this)
    this.closePortal = this.closePortal.bind(this)
    this.wrapWithPortal = this.wrapWithPortal.bind(this)
    this.handleOutsideMouseClick = this.handleOutsideMouseClick.bind(this)
    this.handleKeydown = this.handleKeydown.bind(this)

    // expose close and open to ref
    this.close = this.forcedClose.bind(this)
    this.open = this.openPortal.bind(this)
  }

  componentDidMount() {
    if (this.props.closeOnEsc) {
      document.addEventListener('keydown', this.handleKeydown)
    }
    if (this.props.closeOnOutsideClick) {
      document.addEventListener('click', this.handleOutsideMouseClick)
    }
  }

  componentWillUnmount() {
    if (this.props.closeOnEsc) {
      document.removeEventListener('keydown', this.handleKeydown)
    }
    if (this.props.closeOnOutsideClick) {
      document.removeEventListener('click', this.handleOutsideMouseClick)
    }
  }

  openPortal(e) {
    if (this.state.active) {
      return
    }
    if (e && e.nativeEvent) {
      e.nativeEvent.stopImmediatePropagation()
    }
    this.setState({ active: true }, this.props.onOpen)
    this.props.disableBodyScroll?.()
  }

  closePortal(value = {}) {
    if (!this.state.active) {
      return
    }
    if (this.props.disableAutoClose) {
      this.props.onClose(value)
      return
    }
    this.setState({ active: false }, () => this.props.onClose(value))
    this.props.enableBodyScroll?.()
  }

  // use from outside, no callbacks
  forcedClose(value = {}) {
    if (!this.state.active) {
      return
    }
    this.setState({ active: false })
    this.props.enableBodyScroll?.()
  }

  wrapWithPortal(children) {
    if (!this.state.active) {
      return null
    }
    return (
      <Portal
        node={this.props.node}
        key="react-portal"
        ref={portalNode => this.portalNode = portalNode}
      >
        <PopupPresentational {...this.props} />
      </Portal>
    )
  }

  handleOutsideMouseClick(e) {
    if (!this.state.active) {
      return
    }
    const root = this.portalNode.props.node || this.portalNode.defaultNode
    if (!root || root.contains(e.target) || (e.button && e.button !== 0)) {
      return
    }
    this.closePortal()
  }

  handleKeydown(e) {
    if (e.keyCode === KEYCODES.ESCAPE && this.state.active) {
      this.closePortal()
    }
  }

  render() {
    const { active } = this.state
    const { children, ...rest } = this.props
    return (
      <>
        {this.props.children?.({
          open: this.openPortal,
          close: this.closePortal,
          // portal: this.wrapWithPortal,
          isOpen: this.state.active,
        })}
        {active && (
          <Portal
            node={this.props.node}
            key="react-portal"
            ref={portalNode => {
              this.portalNode = portalNode
            }}
          >
            <PopupPresentational {...rest} close={this.closePortal} />
          </Portal>
        )}
      </>
    )
  }
}

Popup.propTypes = {
  children: PropTypes.func.isRequired,
  defaultOpen: PropTypes.bool,
  node: PropTypes.any,
  openByClickOn: PropTypes.element,
  closeOnEsc: PropTypes.bool,
  closeOnOutsideClick: PropTypes.bool,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  disableAutoClose: PropTypes.bool,
}

Popup.defaultProps = {
  onOpen: () => {},
  onClose: () => {},
  disableAutoClose: false,
}

export default Popup
