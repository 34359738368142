import * as actions from './actions'
import * as authActions from '../auth/actions'

export const State = {
  all: [],
  loading: false,
  error: false,
}

const reducer = (state = State, action) => {
  switch (action.type) {
    case actions.FETCH_PROMOTIONS_START: {
      return {
        ...state,
        loading: true,
        error: false,
      }
    }
    case actions.FETCH_PROMOTIONS_SUCCESS: {
      const { data } = action.payload

      return {
        ...state,
        all: data,
        loading: false,
        error: false,
      }
    }
    case actions.FETCH_PROMOTIONS_ERROR: {
      return {
        ...state,
        all: [],
        loading: false,
        error: true,
      }
    }

    default:
      return state
  }
}

export default reducer
