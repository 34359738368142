import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Provider as Redux } from 'react-redux'
import Bugsnag from '@bugsnag/js'
import Router from './Router'
import ErrorFallback from '../components/ErrorFallback'
import '../../common/i18n/i18n'
import App from './App'
import AppGate from './AppGate'
import withSuspendedTranslation from '../components/hoc/withSuspendedTranslation'
import { useEmojis } from '../hooks/useEmojis'

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React)

// wrap all in Suspended translation so app is shown after translation files are already loaded
const SuspendedTranslation = withSuspendedTranslation(({ children }) => <>{children}</>)

// We needs such Root for vanilla hot reloading.
const Root = ({ store }) => {
  // Enable store in cypress automated tests
  if (!!window && window.Cypress) {
    window.__store__ = store
  }

  useEmojis()

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Redux store={store}>
        <AppGate>
          <SuspendedTranslation>
            <Router />
          </SuspendedTranslation>
        </AppGate>
      </Redux>
    </ErrorBoundary>
  )
}
Root.propTypes = {
  store: PropTypes.object.isRequired,
}

export default Root
