import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const WarningContainer = styled.div``

const WarningText = styled.div`
  font-size: 1.6rem;
  color: red;
  position: absolute;
`

const Warning = ({ text }) => (
  <WarningContainer>
    <WarningText>{text}</WarningText>
  </WarningContainer>
)

Warning.propTypes = {
  text: PropTypes.string,
}

Warning.defaultProps = {
  text: '',
}

export default Warning
