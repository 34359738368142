import { fullNormalizeForSearch } from '../lib/text'

// TODO - tohle vlastne není struktura lens. Asi by se to mělo jmenovat jinak
const defaultValues = {
  _id: null,
  names: [],
  diameters: [],
}

export default values => {
  const lens = { ...defaultValues, ...values }
  lens.names = lens.names.map(name => ({
    ...name,
    search: name.long ? fullNormalizeForSearch(name.long) : fullNormalizeForSearch(name.label),
  }))
  return lens
}
