import React from 'react'
import Omega from './Manufactured.omega'
import Reize from './Manufactured.reize'
import { SwitchByAppType } from '../../../../browser/components'

const variants = {
  Omega,
  Reize,
}

export default (props) => <SwitchByAppType variants={variants} props={props} />
