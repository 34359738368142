import React, { useState, useRef, useCallback } from 'react'
import styled, { css } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import CorrectionsSummary from './Summary/CorrectionsSummary'
import { ProductSummary, FrameSummary } from './Summary/Summary'
import { FixedGap } from '../Gap'
import { Row } from '..'
import { appTypeConfig } from '../../../common/config'

const Wrapper = styled.div`
  /* position: relative; */
`
const HoverContainer = styled.div`
  position: relative;
`

const tooltipOffset = 30
const getPosition = isOpenedDown =>
  isOpenedDown
    ? css`
        /* top: ${tooltipOffset}px; */
        bottom: 0;
        transform: translateY(100%);
      `
    : css`
        /* bottom: ${tooltipOffset}px; */
        top: 0;
        transform: translateY(-100%);
      `

const TooltipWrapper = styled.div`
  ${({ theme: { colors }, active, isOpenedDown }) => css`
    font-size: 14px;
    position: absolute;
    ${getPosition(isOpenedDown)}
    z-index: 1000;
    width: max-content;
    display: none;

    @keyframes tooltip {
      0% {
        opacity: 0;
      }
      66% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }

    ${active &&
    css`
      display: block;
      opacity: 0;
      animation: tooltip 0.6s ease-out forwards;
    `}

    display: ${active ? 'block' : 'none'};
  `}
`

const Bg = styled.div`
  background: white;
  display: inline-block;
  border-radius: 6px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
`

const SummaryTooltip = ({ data, children }) => {
  const { t } = useTranslation()
  const [active, setActive] = useState(false)
  const [isOpenedDown, setIsOpenedDown] = useState(false)
  const wrapperRef = useRef(null)

  const onMouseEnter = useCallback(() => {
    const fromTop = wrapperRef?.current?.getBoundingClientRect()?.top
    // open tooltip down if there is not enough space above
    setIsOpenedDown(fromTop < 230)
    setActive(true)
  }, [wrapperRef, setIsOpenedDown, setActive])

  const onMouseLeave = useCallback(() => {
    setActive(false)
  }, [setActive])

  return (
    <Wrapper ref={wrapperRef}>
      <TooltipWrapper active={active} isOpenedDown={isOpenedDown}>
        <Bg>
          <CorrectionsSummary values={data} t={t} title="" />
        </Bg>
        <FixedGap gap="8px" />
        <Row>
          <Bg>
            <ProductSummary values={data} t={t} hasNoTitle />
          </Bg>
          {data?.frame && (
            <>
              <FixedGap gap="8px" />
              <Bg>
                <FrameSummary vca={data} t={t} hasNoTitle />
              </Bg>
            </>
          )}
        </Row>
      </TooltipWrapper>
      <HoverContainer onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
        {children}
      </HoverContainer>
    </Wrapper>
  )
}

// copyReferenceOnDuplication: config.copyReferenceOnDuplication,

const SummaryTooltipComponent = ({ isSummaryTooltipDisabled, ...props }) => {
  if (appTypeConfig.isSummaryTooltipEnabled && !isSummaryTooltipDisabled) {
    return <SummaryTooltip {...props} />
  }
  return props.children
}

const enhance = compose(
  connect(({ config, lists }) => ({
    isSummaryTooltipDisabled:
      config.isSummaryTooltipDisabled ||
      lists?.OrdersListPage?.filters?.ordersOrigin === 'external',
  })),
)

export default enhance(SummaryTooltipComponent)
