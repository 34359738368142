import React from 'react'
import styled, { css } from 'styled-components'

const Title = styled.h1`
  ${({ theme: { colors }, align }) => css`
    color: ${colors.primary};
    text-align: ${align};
  `}
`

Title.PageTitle = props => <Title {...props} as="h1" />

export default Title
