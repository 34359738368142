export const SHOW_POPUP = 'SHOW_POPUP'
export const HIDE_POPUP = 'HIDE_POPUP'
export const HIDE_ALL_POPUPS = 'HIDE_ALL_POPUPS'

export const showPopup = (name, args) => ({
  type: SHOW_POPUP,
  payload: {
    name,
    args,
  },
})

export const hidePopup = name => ({
  type: HIDE_POPUP,
  payload: {
    name,
  },
})

export const hideAllPopups = () => ({
  type: HIDE_ALL_POPUPS,
})
