import React, { Component, useEffect } from 'react'
import styled, { css } from 'styled-components'
import { useTranslation, withTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { format } from 'date-fns'
import { Col, Gap, Link, LinkButton, Page, Row, Text, T } from '../components'
import Title from '../components/Title'
import Button from '../components/Button'
import { fetchPromotions } from '../../common/promotions/actions'
import { WrappedLoading } from '../components/Loading'
import ErrorTryAgain from '../components/ErrorTryAgain'
import Box from '../components/Box'
// import 'emoji-mart/css/emoji-mart.css'
import { appTypeConfig } from '../../common/config'
import Emoji from '../components/Emoji'

const Status = styled.div`
  ${({ theme: { colors }, active }) => css`
    color: ${active ? colors.green : colors.darkRed};
  `}
`

const PromotionsPage = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation()
  const dispatch = useDispatch()

  const data = useSelector(state => state.promotions.all)
  const loading = useSelector(state => state.promotions.loading)
  const error = useSelector(state => state.promotions.error)

  useEffect(() => {
    dispatch(fetchPromotions())
  }, [dispatch])

  const promotions = data
    .filter(n => n.body && n.title)
    .map(n => ({
      ...n,
      body: n.body[language] || n.body.default,
      title: n.title[language] || n.title.default,
      // link: n.link[language] || n.link.default,
    }))
    .filter(n => n.title)

  if (!appTypeConfig.arePromotionsEnabled) {
    return null
  }

  return (
    <Page metaTitle={t('Promotions')}>
      <Page.Content minWidth="600px">
        <Col>
          <Col maxWidth="120rem" alignSelf="center">
            {/* <div
              onClick={() => {
                dispatch(fetchPromotions())
              }}
            >
              DEBUG: Click this to refresh
            </div> */}

            <Gap gap="3rem" />
            <Title.PageTitle align="center">
              <T>Promotions</T>
            </Title.PageTitle>

            {loading && <WrappedLoading type="bar" padding="0" />}
            <Gap gap="3rem" />

            {error && (
              <ErrorTryAgain
                onClick={() => {
                  dispatch(fetchPromotions())
                }}
              />
            )}

            {!loading && !error && promotions?.length === 0 && (
              <Col alignItems="center" margin="0">
                <Emoji id="see_no_evil" size="50" set="apple" />
                <Gap gap="1.6rem" />
                <Text big>
                  <T>No promotions right now. Come back later!</T>
                </Text>
                <Gap gap="1.6rem" />
              </Col>
            )}

            <Row.Modern wrap="wrap" margin="0 0 0 -1.6rem" justifyContent="center">
              {promotions.map(promotion => (
                <>
                  <Box minWidth="38rem" maxWidth="38rem" margin="0 0 1.6rem 1.6rem">
                    <Box.Header>
                      <Box.Title>{promotion.title}</Box.Title>
                    </Box.Header>
                    <Box.Content>
                      <Col margin="0">
                        <Gap gap="1rem" />
                        <Text bold size="1.5rem">
                          {t('Valid from')} {format(new Date(promotion.validFrom), 'dd.MM.yyyy')}{' '}
                          {t('to (as date to/till)')}{' '}
                          {format(new Date(promotion.validTo), 'dd.MM.yyyy')}
                        </Text>
                        <Gap gap="1.6rem" />

                        <Row alignItems="baseline" margin="0">
                          <Text big bold>
                            <Row alignItems="baseline">
                              {promotion.isActive && 
                                <Status active={promotion.isActive}>{t('Active')}</Status>
                              }
                              {!promotion.isActive && (
                                <>
                                  <Status active={promotion.isActive}>{t('Inactive')}</Status>
                                  <Gap gap="0.8rem" />
                                  {promotion.inactiveLink && (
                                    <LinkButton
                                      external
                                      href={promotion.inactiveLink}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      {t('Click to sign up')}
                                    </LinkButton>
                                  )}
                                </>
                              )}
                            </Row>
                          </Text>
                          <Gap gap="0.4rem" />

                          {promotion.isActive && (
                            <Text>
                              {t('since')} {format(new Date(promotion.activeFrom), 'dd.MM.yyyy')}
                            </Text>
                          )}
                        </Row>
                        {promotion.body && (
                          <>
                            <Gap gap="1.6rem" />
                            <Text>{promotion.body}</Text>
                          </>
                        )}
                        {promotion.link && (
                          <>
                            <Gap gap="1.6rem" />
                            <LinkButton external href={promotion.link} target="_blank">
                              {t('Find out more!')}
                            </LinkButton>
                          </>
                        )}
                        <Gap gap="0.8rem" />
                      </Col>
                    </Box.Content>
                  </Box>
                </>
              ))}
            </Row.Modern>
          </Col>
        </Col>
      </Page.Content>
    </Page>
  )
}

export default PromotionsPage
