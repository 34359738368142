// import config from '../../server/config'
import axios from 'axios'
import i18n from '../i18n/i18n'

export class APIException {
  constructor(errors) {
    this._errors = errors
  }

  get errors() {
    return this._errors
  }
}

const processFetch = response => {
  if (!response) return {}
  if (response.status === 'Ok') {
    return response
  }

  throw new APIException(response.errors ? response.errors : [response])
}

const request = async ({ method, contentType, fetch, endpoint, getState, body }) => {
  let response = null
  const appLang = i18n?.language?.split('-')[0]

  const options = {
    method,
    headers: {
      pragma: 'no-cache',
      'cache-control': 'no-cache',
      'x-application-language': appLang,
      'x-application-version': process.env.APP_VERSION,
    },
  }

  const state = getState()
  const viewer = (state.app && state.app.viewer) || null

  if (contentType) options.headers['Content-Type'] = contentType
  if (viewer) {
    if (viewer.tokens) {
      options.headers['x-access-token'] = viewer.tokens.access
    }
    options.headers['x-user-id'] = viewer._id
  }

  options.headers['x-browser-id'] = state?.app?.browserId
  
  const puppetUserId = state?.app?.puppetUser?._id
  if (puppetUserId) options.headers['x-puppet-user'] = puppetUserId

  if (body) options.body = body

  try {
    response = await fetch(`/api/v1/${endpoint}`, options)
    const responseBody = processFetch(response)

    return responseBody
  } catch (e) {
    response = {}
    response.errors = e.errors
    console.log('API error', e)
    throw response
  }
}

// for library redux-fields
const createGetQuery = (endpoint, query) => {
  if (!query) return endpoint
  const { sort, page, perPage, filters, fields } = query
  endpoint += '?'
  if (sort) endpoint += `sort=${sort}&`
  if (page) endpoint += `page=${page}&`
  if (perPage) endpoint += `perPage=${perPage}&`
  if (filters && Object.keys(filters).length) endpoint += `filter=${JSON.stringify(filters)}&`
  if (fields) endpoint += `fields=${JSON.stringify(fields)}&`
  return endpoint.slice(0, -1)
}

// transform data from json to get query
const createCustomGetQuery = (endpoint, data) => {
  if (!data) return endpoint
  return Object.keys(data).reduce(
    (response, key) => `${response}${key}=${data[key]}&`,
    `${endpoint}?`
  )
}

export const apiGet = async ({ fetch, endpoint, getState, query, customQuery }) => {
  endpoint = createGetQuery(endpoint, query)
  if (customQuery) endpoint = createCustomGetQuery(endpoint, customQuery)
  try {
    const body = await request({
      fetch,
      endpoint,
      getState,
      method: 'GET',
      contentType: 'application/json',
    })
    return body
  } catch (e) {
    console.log('e', e)
    throw e
  }
}

export const apiPost = async ({ fetch, endpoint, getState, body }) => {
  try {
    const responseBody = await request({
      fetch,
      endpoint,
      getState,
      method: 'POST',
      contentType: 'application/json',
      body: JSON.stringify(body),
    })
    return responseBody
  } catch (e) {
    throw e
  }
}

export const apiPostFile = async ({ fetch, endpoint, getState, body }) => {
  try {
    const formData = new FormData()
    const keys = Object.keys(body)
    keys.forEach(key => {
      if (body[key]) {
        if (key === 'files') {
          const files = body[key]
          for (let i = 0; i < files.length; i += 1) {
            formData.append('files', files[i])
          }
        } else {
          formData.append(key, Array.isArray(body[key]) ? JSON.stringify(body[key]) : body[key])
        }
      }
    })

    const responseBody = await request({
      fetch,
      endpoint,
      getState,
      method: 'POST',
      body: formData,
    })
    return responseBody
  } catch (e) {
    throw e
  }
}

export const apiPutFile = async ({ fetch, endpoint, getState, body }) => {
  try {
    const formData = new FormData()
    const keys = Object.keys(body)
    keys.forEach(key => {
      if (body[key]) {
        formData.append(key, Array.isArray(body[key]) ? JSON.stringify(body[key]) : body[key])
      }
    })

    const responseBody = await request({
      fetch,
      endpoint,
      getState,
      method: 'PUT',
      body: formData,
    })
    return responseBody
  } catch (e) {
    throw e
  }
}

export const apiPut = async ({ fetch, endpoint, getState, body }) => {
  try {
    const responseBody = await request({
      fetch,
      endpoint,
      getState,
      method: 'PUT',
      contentType: 'application/json',
      body: JSON.stringify(body),
    })
    return responseBody
  } catch (e) {
    throw e
  }
}

export const apiPatch = async ({ fetch, endpoint, getState, body }) => {
  try {
    const responseBody = await request({
      fetch,
      endpoint,
      getState,
      method: 'PATCH',
      contentType: 'application/json',
      body: JSON.stringify(body),
    })
    return responseBody
  } catch (e) {
    throw e
  }
}

export const apiDelete = async ({ fetch, endpoint, getState, query }) => {
  endpoint = createGetQuery(endpoint, query)
  try {
    const body = await request({
      fetch,
      endpoint,
      getState,
      method: 'DELETE',
      contentType: 'application/json',
    })
    return body
  } catch (e) {
    throw e
  }
}

// direct call outside of the redux
export const apiLight = async ({ viewer, method = 'GET', url, data, blob }) => {
  const appLang = i18n?.language?.split('-')[0]

  const resp = await axios({
    method,
    baseURL: '/api/v1/',
    url,
    data,
    headers: {
      'cache-control': 'no-cache',
      'x-application-language': appLang,
      'x-access-token': viewer?.tokens?.access,
    },
    responseType: blob ? 'blob' : undefined, // for files download
  })

  // console.log('respresprespresp', resp)

  return resp.data
}
