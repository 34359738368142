import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { SelectField } from '.'
import { appTypeConfig } from '../../../common/config'
import { languagesTexts } from '../../../common/i18n/config'

const SelectLanguageField = ({ t, i18n, name, ...props }) => {
  const hostConf = useSelector(state => state.app.hostConf)

  const languagesOptions = appTypeConfig.languages
    .filter(lang => !hostConf?.disabledLanguages?.[lang])
    .map(lang => ({
      value: lang,
      label: languagesTexts[lang],
    }))

  const value = i18n.language && i18n.language.split('-')[0]

  if (!value || !languagesOptions.find(opt => opt.value === value)) {
    const fallbackToValue = hostConf.fallbackLanguage || 'en'
    setTimeout(() => {
      props?.onChange?.({ value: fallbackToValue })
      i18n.changeLanguage(fallbackToValue)
    }, 200)
    return null
  }

  return (
    <SelectField
      name={name}
      label={t('language')}
      value={value}
      options={languagesOptions}
      {...props}
      onChange={e => {
        // react select return [] when backspace is pressed
        // this is workaround
        if (typeof e.value === 'object' && e.value.length === 0) return
        props?.onChange?.(e)
        i18n.changeLanguage(e.value)
      }}
    />
  )
}

SelectLanguageField.propTypes = {
  t: PropTypes.func.isRequired,
  i18n: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  name: PropTypes.string,
}

SelectLanguageField.defaultProps = {
  onChange: null,
  name: 'SelectLanguage',
}

export default withTranslation()(SelectLanguageField)
