import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { props } from 'bluebird'
import { Input, Label, Errors, Warning } from './components'
import { Icon } from '..'
import { withMemoize } from '.'
import Gap from '../Gap'

const Container = styled.div`
  display: inline-block;
`

const CheckBoxHiddenField = styled(Input).attrs({
  type: 'checkbox',
})`
  display: none;
`

const CheckBoxLabel = styled(Label)`
  cursor: pointer;
  font-size: 1.4rem;
  /* padding: 0.5rem 0; */
  /* letter-spacing: 0.1rem; */
  font-weight: 400;
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
    `}
  flex-direction: ${props => getFlexDirection(props.labelPosition)};
  justify-content: ${props => getJustifyContent(props.labelPosition)};
  display: flex;
  align-items: ${({ labelPosition }) =>
    labelPosition === 'left' || labelPosition === 'right' ? 'center' : 'flex-start'};
  padding: ${({ noPadding }) => noPadding ? '0' : ''};
`

const getFlexDirection = labelPosition => {
  switch (labelPosition) {
    case 'left':
      return 'row'
    case 'right':
      return 'row-reverse'
    case 'top':
      return 'column'
    case 'bottom':
      return 'column-reverse'
    default:
      return 'column'
  }
}

const getJustifyContent = labelPosition => labelPosition === 'right' ? 'flex-end' : 'flex-start'

const CheckBoxIcon = styled(Icon)`
  /* margin: 0 0.5rem 0 0; */
`

const CheckboxField = ({
  disabled,
  errors,
  label,
  onBlur,
  onChange,
  required,
  name,
  value,
  tooltip,
  warning,
  labelPosition,
  className,
  noPadding,
  ...props
}) => (
  <Container className={className} {...props}>
    <CheckBoxLabel
      labelPosition={labelPosition}
      htmlFor={name}
      required={required}
      disabled={disabled}
      tooltip={tooltip}
      noPadding={noPadding}
    >
      {label}
      <Gap gap="0.5rem" />
      <CheckBoxIcon size={16} name={value ? 'CustomCheckboxChecked' : 'CustomCheckboxUnchecked'} />
    </CheckBoxLabel>
    <CheckBoxHiddenField
      id={name}
      disabled={!!disabled}
      name={name}
      onChange={e => onChange(e.target)}
      required={required || undefined}
      checked={!!value}
      onBlur={onBlur}
    />
    {!!errors && <Errors errors={errors} />}
    {!!warning && <Warning text={warning} />}
  </Container>
)

CheckboxField.defaultProps = {
  after: null,
  before: null,
  disabled: false,
  errors: null,
  warning: '',
  onBlur: () => {},
  required: false,
  type: 'text',
  value: false,
  tooltip: '',
  labelPosition: 'right',
  label: '',
  className: '',
}

CheckboxField.propTypes = {
  after: PropTypes.any,
  before: PropTypes.any,
  disabled: PropTypes.bool,
  errors: PropTypes.array,
  warning: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  tooltip: PropTypes.string,
  labelPosition: PropTypes.string,
  className: PropTypes.string,
}

export default withMemoize(CheckboxField)
