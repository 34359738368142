import React from 'react'
import styled, { css } from 'styled-components'

const CrossItem = styled.div`
  ${({ size }) => css`
    position: absolute;
    left: 50%;
    top: 50%;
    width: ${size}rem;
    height: ${size / 6.25}rem;
    background: #c4c4c4;
    border-radius: 10rem;
    /* transform-origin: center center; */
    transform: translate(-50%, -50%) rotate(${({ rotation }) => rotation || 45}deg);
    transition: 0.3s;
  `}
`

const Cross = styled.div`
  ${({ justIcon, size, theme: { colors }}) => css`
    display: block;
    min-width: ${size}rem;
    min-height: ${size}rem;
    cursor: pointer;

    ${!justIcon &&
    css`
      position: absolute;
      right: 0;
      top: 50%;
      transform: translate(0, -50%);
    `}

    &:hover {
      ${CrossItem} {
        background: #9c9c9c;
      }
    }
  `}
`

const CloseIcon = ({ onClick, justIcon, size = 2.5, wrapperSize = 4 }) => (
  <Cross onClick={onClick} justIcon={justIcon} size={wrapperSize}>
    <CrossItem size={size} />
    <CrossItem rotation={135} size={size} />
  </Cross>
)
export default CloseIcon
