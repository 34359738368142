import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { Box, Row, Col } from '../..'
import { NumberField, SwitchField, SelectField, LensParamsField } from '../../fields'
import { appTypeConfig } from '../../../../common/config'
import i18n from '../../../../common/i18n/i18n'

const { individualMenus } = appTypeConfig
const SWITCH_BUTTON_MIN_WIDTH = '10rem'
const readdistApproxOptions = [
  { value: '< 160', label: '< 160', exactValue: 38 },
  { value: '160 - 180', label: '160 - 180', exactValue: 41 },
  { value: '> 180', label: '> 180', exactValue: 44 },
]

const pantoApproxSpec = [
  { value: 8, translationKey: 'Normal' },
  { value: 13, translationKey: 'Strong' },
]

const bvdApproxSpec = [
  { value: 9, translationKey: 'Short' },
  { value: 12, translationKey: 'Normal' },
  { value: 15, translationKey: 'Long' },
]

const frameBowAngleApproxSpec = [
  { value: 2, translationKey: 'Normal' },
  { value: 11, translationKey: 'Curved' },
]

const createApproxOptions = (spec, t) =>
  spec.map(o => ({
    value: o.translationKey,
    label: t(o.translationKey),
    exactValue: o.value,
  }))

const FaceFormAngle = props => (
  <NumberField
    label={i18n.t('face form angle', { context: 'individual' })}
    errorsStyle={{ position: 'relative' }}
    noMaxWidth
    {...props}
  />
)

const Bvd = ({ fields, ...props }) => (
  <NumberField
    label={i18n.t('bvd')}
    errorsStyle={{ position: 'relative' }}
    noMaxWidth
    {...props}
    onChange={e => {
      fields.bvdR.onChange(e)
      fields.bvdL.onChange(e)
    }}
  />
)

const Panto = props => (
  <NumberField
    label={i18n.t('pantoscopic tilt')}
    errorsStyle={{ position: 'relative' }}
    noMaxWidth
    {...props}
  />
)

const Readdist = props => (
  <NumberField
    label={i18n.t('reading distance')}
    errorsStyle={{ position: 'relative' }}
    noMaxWidth
    {...props}
  />
)

const isFilled = field => !!field.value || field.value === 0

const Or = ({ t, ...props }) => (
  <Col alignSelf="flex-start" style={{ textAlign: 'center' }} {...props}>
    <div style={{ paddingTop: '2.7rem', fontSize: '1.4rem' }}>{t('or')}</div>
  </Col>
)

const LineWithApprox = (
  { children, t } //eslint-disable-line
) => (
  <Row>
    <Col width="15rem">{children && children[0]}</Col>
    <Or width="3rem" t={t} />
    <Col grow={1}>{children && children[1]}</Col>
  </Row>
)

const enhanceFields = (fields, menuConf) => {
  Object.keys(menuConf).forEach(key => {
    const fieldConf = menuConf[key]
    const isRequired = typeof fieldConf === 'object' ? fieldConf.isRequired : fieldConf === true

    let field = fields[key]
    if (key === 'bvd') field = fields.bvdR
    if (field) {
      field.required = isRequired
    }
  })
}

const isFieldVisible = fieldConf => typeof fieldConf !== 'undefined'

const cleanErrors = field => {
  if (field.errors && field.errors.length > 1) {
    field.onBlur()
  }
}

class IndividualSection extends React.Component {
  constructor(props) {
    super(props)
    const { fields } = props
    this.state = {
      // used only for validation after individual menu change
      inMenuKey: fields.individualMenu && fields.individualMenu.value,
    }
  }

  componentDidUpdate() {
    const { inMenuKey } = this.state
    const { fields } = this.props
    const newInMenuKey = fields.individualMenu && fields.individualMenu.value
    if (inMenuKey !== newInMenuKey) {
      this.setState({ inMenuKey: newInMenuKey }) // eslint-disable-line
      if (newInMenuKey) {
        const menuConf = individualMenus[newInMenuKey]
        Object.keys(menuConf).forEach(fieldKey => {
          let field = fields[fieldKey]
          if (fieldKey === 'bvd') field = fields.bvdR
          if (fieldKey === 'inset') {
            cleanErrors(fields.insetR)
            cleanErrors(fields.insetL)
            return
          }

          cleanErrors(field)
        })
      }
    }
  }

  render() {
    const { fields, lensL, lensR, t } = this.props
    if (
      !(
        (lensR && fields.lensR.value && lensR.individualMenu) ||
        (lensL && fields.lensL.value && lensL.individualMenu)
      )
    ) {
      return null
    }
    const menuConf = individualMenus[fields.individualMenu && fields.individualMenu.value]

    if (!menuConf) return null
    enhanceFields(fields, menuConf)

    let proglenOptions = []
    if (menuConf.proglen && menuConf.proglen.options) {
      const configOptions = menuConf.proglen.options
      proglenOptions = configOptions.map(o => {
        if (!o) return { label: t('AUTO'), value: '' }
        return { label: o, value: o }
      })
      const proglenValue = fields.proglen && fields.proglen.value
      if (proglenValue && !configOptions.includes(proglenValue) && configOptions.includes('')) {
        fields.proglen.onChange({ value: '' })
      }
    }

    const itemsCount = Object.keys(menuConf || {}).length
    const columnWidth = [4, 5].includes(itemsCount) ? '25%' : '33%'

    const pantoApproxOptions = createApproxOptions(pantoApproxSpec, t)
    const bvdApproxOptions = createApproxOptions(bvdApproxSpec, t)
    const frameBowAngleApproxOptions = createApproxOptions(frameBowAngleApproxSpec, t)

    return (
      <Box>
        <Box.Header>
          <Box.Title>{t('individual data')}</Box.Title>
        </Box.Header>
        <Box.Content>
          {/* FACEFORMANGLE WITH APPROX */}
          {isFieldVisible(menuConf.frameBowAngle) &&
            isFieldVisible(menuConf.frameBowAngleApprox) && (
              <LineWithApprox t={t}>
                <FaceFormAngle
                  disabled={isFilled(fields.frameBowAngleApprox)}
                  noMaxWidth
                  {...fields.frameBowAngle}
                  value={!isFilled(fields.frameBowAngleApprox) && fields.frameBowAngle.value}
                />
                <SwitchField
                  label={t('Frame bow angle approximated')}
                  options={frameBowAngleApproxOptions}
                  tooltip={t('Frame bow angle approximated DESCRIPTION')}
                  tooltipDown
                  // disabled={isFilled(fields.frameBowAngle)}
                  buttonMinWidth={SWITCH_BUTTON_MIN_WIDTH}
                  {...fields.frameBowAngleApprox}
                  onChange={e => {
                    fields.frameBowAngleApprox.onChange(e)
                    fields.frameBowAngle.onChange({
                      value: e.value && e.exactValue,
                    })
                    setTimeout(() => fields.frameBowAngle.onBlur(), 1)
                  }}
                />
              </LineWithApprox>
            )}
          {/* CVD WITH APPROX */}
          {isFieldVisible(menuConf.bvd) && isFieldVisible(menuConf.bvdApprox) && (
            <LineWithApprox t={t}>
              <Bvd
                disabled={isFilled(fields.bvdApprox)}
                noMaxWidth
                fields={fields}
                {...fields.bvdR}
                value={!isFilled(fields.bvdApprox) && fields?.bvdR?.value}
              />
              <SwitchField
                label={t('Bvd approximated')}
                options={bvdApproxOptions}
                tooltip={t('Bvd approximated DESCRIPTION')}
                tooltipDown
                // disabled={isFilled(fields.cvd)}
                buttonMinWidth={SWITCH_BUTTON_MIN_WIDTH}
                {...fields.bvdApprox}
                onChange={e => {
                  fields.bvdApprox.onChange(e)
                  fields.bvdR.onChange({ value: e.value && e.exactValue })
                  fields.bvdL.onChange({ value: e.value && e.exactValue })
                  setTimeout(() => fields.bvdR.onBlur(), 1)
                }}
              />
            </LineWithApprox>
          )}
          {/* PANTO WITH APPROX */}
          {isFieldVisible(menuConf.panto) && isFieldVisible(menuConf.pantoApprox) && (
            <LineWithApprox t={t}>
              <Panto
                disabled={isFilled(fields.pantoApprox)}
                noMaxWidth
                {...fields.panto}
                value={!isFilled(fields.pantoApprox) && fields.panto.value}
              />
              <SwitchField
                label={t('Panto approximated')}
                options={pantoApproxOptions}
                tooltip={t('Panto approximated DESCRIPTION')}
                tooltipDown
                buttonMinWidth={SWITCH_BUTTON_MIN_WIDTH}
                {...fields.pantoApprox}
                // disabled={isFilled(fields.panto)}
                onChange={e => {
                  fields.pantoApprox.onChange(e)
                  fields.panto.onChange({ value: e.value && e.exactValue })
                  setTimeout(() => fields.panto.onBlur(), 1)
                }}
              />
            </LineWithApprox>
          )}
          {/* READDIST WITH APPROX */}
          {isFieldVisible(menuConf.readdist) && isFieldVisible(menuConf.readdistApprox) && (
            <LineWithApprox t={t}>
              <Readdist
                disabled={isFilled(fields.readdistApprox)}
                noMaxWidth
                {...fields.readdist}
                value={!isFilled(fields.readdistApprox) && fields.readdist.value}
              />
              <SwitchField
                label={t('Body height of wearer')}
                options={readdistApproxOptions}
                tooltip={t('Body height of wearer DESCRIPTION')}
                tooltipDown
                buttonMinWidth={SWITCH_BUTTON_MIN_WIDTH}
                {...fields.readdistApprox}
                // disabled={isFilled(fields.readdist)}
                onChange={e => {
                  fields.readdistApprox.onChange(e)
                  fields.readdist.onChange({ value: e.value && e.exactValue })
                  setTimeout(() => fields.readdist.onBlur(), 1)
                }}
              />
            </LineWithApprox>
          )}
          <Row alignItems="flex-end">
            {/* FACEFORMANGLE */}
            {isFieldVisible(menuConf.frameBowAngle) &&
              !isFieldVisible(menuConf.frameBowAngleApprox) && (
                <Col width={columnWidth}>
                  <FaceFormAngle {...fields.frameBowAngle} />
                </Col>
              )}

            {/* BVD */}
            {isFieldVisible(menuConf.bvd) && !isFieldVisible(menuConf.bvdApprox) && (
              <Col width={columnWidth}>
                <Bvd fields={fields} {...fields.bvdR} />
              </Col>
            )}

            {/* PANTO */}
            {isFieldVisible(menuConf.panto) && !isFieldVisible(menuConf.pantoApprox) && (
              <Col width={columnWidth}>
                <Panto {...fields.panto} />
              </Col>
            )}

            {/* READDIST */}
            {isFieldVisible(menuConf.readdist) && !isFieldVisible(menuConf.readdistApprox) && (
              <Col width={columnWidth}>
                <Readdist {...fields.readdist} />
              </Col>
            )}
          </Row>

          {/* PROGLEN */}
          {isFieldVisible(menuConf.proglen) && (
            <Row>
              <Col width="23%">
                <SelectField
                  label={t('Progression length')}
                  options={proglenOptions}
                  {...fields.proglen}
                />
              </Col>
            </Row>
          )}

          <Row justifyContent="flex-start">
            {isFieldVisible(menuConf.inset) && (
              <LensParamsField
                showSideLabels
                noBorder
                isDefaultOpen
                rightDisabled={!fields.rightLensEnabled.value}
                leftDisabled={!fields.leftLensEnabled.value}
                options={[
                  {
                    label: t('inset'),
                    r: fields.insetR,
                    l: fields.insetL,
                  },
                ]}
                style={{
                  paddingLeft: '0',
                  paddingTop: '0',
                  marginLeft: '-14px',
                  width: '100%',
                }}
              />
            )}
          </Row>
        </Box.Content>
      </Box>
    )
  }
}

IndividualSection.defaultProps = {
  lensR: {},
  lensL: {},
}

IndividualSection.propTypes = {
  fields: PropTypes.object.isRequired,
  lensR: PropTypes.object,
  lensL: PropTypes.object,
  t: PropTypes.func.isRequired,
}

const enhance = compose(
  connect(
    ({ catalog }) => ({
      lensR: catalog.currentLensR,
      lensL: catalog.currentLensL,
    }),
    {},
  ),
  withTranslation(),
)

export default enhance(IndividualSection)
