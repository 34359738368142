import React from 'react'
import Omega from './CatalogPopup.omega'
import Reize from './CatalogPopup.reize'
import Jzo from './CatalogPopup.jzo'
import Jaikudo from './CatalogPopup.jaikudo'
import { SwitchByAppType } from '../..'

const variants = {
  Omega,
  Reize,
  Jzo,
  Jaikudo,
}

/* TODO zatím rozděleno jen kvůli focus type filteru, pokud nebude více změn, tak se zamyslet
    zda zase nespojit dohromadi a nevyřešit filter zvlášť
*/
export default props => <SwitchByAppType variants={variants} props={props} />
