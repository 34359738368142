import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { compose } from 'recompose'

const T = ({ t, children }) => <>{t(children)}</>

T.defaultProps = {
  children: '',
}

T.propTypes = {
  children: PropTypes.node,
  t: PropTypes.func.isRequired,
}

const enhance = compose(withTranslation())
export default enhance(T)
