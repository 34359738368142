import React, { Fragment } from 'react'
import styled from 'styled-components'

const VisibleInPrint = styled.div`
  display: none;
  flex-grow: 1;
  width: 100%;
  @media print {
    display: flex;
  }
`

export default VisibleInPrint
