import React from 'react'
import PropTypes from 'prop-types'
import memoize from 'memoize-one'
import Select, { createFilter } from 'react-select'
import { withTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'
import { Label, Errors, Warning, Wrapper } from './components'
import { getTheme } from '../../app/App'
import { withMemoize } from '.'

const transformOptions = options =>
  options.map(o => {
    if (o.options) {
      return {
        ...o,
        options: transformOptions(o.options),
      }
    }
    return {
      ...o,
      value: o.value,
      label: o.label || o.text || o.name || o.value,
    }
  })

const findElement = (value, options) => {
  const isGrouped = options.some(o => !!o.options)
  let transformedOptions = options
  if (isGrouped) {
    transformedOptions = options.reduce((result, o) => [...result, ...o.options], [])
  }
  return Array.isArray(value)
    ? transformedOptions.filter(o => value.includes(o.value))
    : transformedOptions.find(o => o.value === value)
}

const topLevelOptionStyle = isShown =>
  isShown
    ? {
      fontWeight: 700,
      marginLeft: '-0.5rem',
    }
    : {}

const getCustomStyles = ({ isValid, hasCustomMenu, isGrouped, disabled, isValueHidden }) => {
  const theme = getTheme()
  return {
    control: (base, state) => ({
      ...base,
      minHeight: '3rem',
      borderRadius: theme.field.borderRadius,
      backgroundColor: isValueHidden && disabled ? theme.colors.gray2 : 'white',
      fontSize: '1.4rem',
      borderColor: !isValid
        ? theme.colors.error
        : state.isFocused
          ? theme.colors.primaryColor
          : theme.field.borderColor,
      borderWidth: !isValueHidden ? '1.1px' : 0,
      boxShadow: 'none',
    }),
    option: (base, { data, isSelected, isFocused, ...rest }) => ({
      // aa: (() => { console.log(rest) })(),
      ...base,
      fontSize: '1.4rem',
      paddingTop: '0.5rem',
      paddingBottom: '0.5rem',
      paddingLeft: isGrouped ? '3.5rem' : undefined,
      backgroundColor: isSelected
        ? theme.colors.primary
        : isFocused
          ? theme.colors.primaryVeryLight
          : undefined,
      ...topLevelOptionStyle(data.isTopLevel),
    }),
    indicatorsContainer: base => ({
      ...base,
      height: '3rem',
    }),
    menuList: base => ({
      ...base,
      // boxShadow: !hasCustomMenu && '0px 4px 12px rgba(0, 0, 0, 0.2)',
      boxShadow: !hasCustomMenu && '0px 2px 20px rgba(0, 0, 0, 0.2)',
      borderRadius: !hasCustomMenu && '0.8rem',
    }),
    menu: base => ({
      ...base,
      borderRadius: '0.8rem',
      // marginTop: '0.8rem',
      marginTop: '0.5rem',
      // boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.2)',
      // border: 'solid thin red',
      overflow: 'hidden',
    }),
    groupHeading: base => ({
      ...base,
      fontSize: '1.2rem',
      textTransform: 'none',
      fontStyle: 'italic',
      paddingLeft: '1.8rem',
      paddingBottom: '0.5rem',
    }),
  }
}

class SelectField extends React.PureComponent {
  transformOptions = memoize(options => transformOptions(options))

  render() {
    const {
      disabled,
      errors,
      label,
      options,
      onChange,
      onBlur,
      required,
      name,
      value,
      className,
      placeholder,
      warning,
      tooltip,
      t,
      styles,
      grow,
      maxMenuHeight,
      flexBasis,
      isValueHidden,
      ...props
    } = this.props

    const transformedOptions = this.transformOptions(options)
    let selectedValue = findElement(value, transformedOptions)
    // clear field when undefined workaround
    if (!selectedValue) selectedValue = null
    const hasCustomMenu = props.components && !!props.components.Menu
    return (
      <Wrapper className={className} grow={grow} flexBasis={flexBasis}>
        {label && (
          <Label htmlFor={name} required={required} tooltip={tooltip}>
            {label}
          </Label>
        )}
        <Select
          name={name}
          id={name}
          onChange={selectedItem => {
            const changeAndBlur = value => {
              onChange({ name, value })
              if (props.onBlur) props.onBlur({ name })
            }
            if (!selectedItem) return changeAndBlur(null)
            if (typeof selectedItem.value !== 'undefined') return changeAndBlur(selectedItem.value)
            return changeAndBlur(selectedItem.map(item => item.value))
          }}
          onBlur={() => {
            onBlur?.({ name })
          }}
          required={required || undefined}
          value={!isValueHidden ? selectedValue : null}
          isDisabled={disabled}
          placeholder={placeholder}
          options={transformedOptions}
          styles={getCustomStyles({
            isValid: !errors || errors.length === 0,
            hasCustomMenu,
            isGrouped: true,
            isValueHidden,
            disabled,
          })}
          loadingMessage={() => t('Loading')}
          noOptionsMessage={() => t('No options')}
          maxMenuHeight={maxMenuHeight}
          // defaultMenuIsOpen // debug purposes
          {...props}
        />
        {!!errors && <Errors errors={errors} />}
        {!!warning && <Warning text={warning} />}
      </Wrapper>
    )
  }
}

SelectField.defaultProps = {
  disabled: false,
  errors: null,
  defaultText: '',
  className: '',
  options: [],
  required: false,
  type: 'text',
  value: '',
  placeholder: '',
  warning: '',
  tooltip: '',
  components: undefined,
  onBlur: null,
  isValueHidden: false,
}

SelectField.propTypes = {
  disabled: PropTypes.bool,
  errors: PropTypes.array,
  defaultText: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
  warning: PropTypes.string,
  tooltip: PropTypes.string,
  components: PropTypes.object,
  t: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  isValueHidden: PropTypes.bool,
}

export default withTranslation()(withMemoize(SelectField))
