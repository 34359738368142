import { fieldsReducer as fields } from '../lib/redux-fields'
import { listsReducer as lists } from '../lib/redux-lists'
import { updateStateOnStorageLoad } from './storage'

import app from '../app/reducer'
import config from '../config/reducer'
import vca from '../vca/reducer'
import popups from '../popups/reducer'
import orders from '../orders/reducer'
import catalog from '../catalog/reducer'
import promotions from '../promotions/reducer'

const configureReducer = () => {
  let reducer = combineReducers({
    app,
    config,
    lists,
    fields,
    vca,
    popups,
    orders,
    catalog,
    promotions,
  })

  reducer = updateStateOnStorageLoad(reducer)

  return reducer
}

// implement our own combineReducer, so we can pass rootState
function combineReducers(reducers) {
  return (state, action) => {
    let hasChanged = false
    const keys = Object.keys(reducers)
    const nextState = {}
    for (let i = 0; i < keys.length; i += 1) {
      const key = keys[i]

      const reducer = reducers[key]
      const previousStateForKey = state[key]

      // append rootState as third argument
      const nextStateForKey = reducer(previousStateForKey, action, state)

      if (typeof nextStateForKey === 'undefined') {
        throw new Error(`CUSTOM REDUX: next state is undefined for key: ${key}`)
      }

      nextState[key] = nextStateForKey
      hasChanged = hasChanged || nextStateForKey !== previousStateForKey
    }
    return hasChanged ? nextState : state
  }
}

export default configureReducer
