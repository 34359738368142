import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { Label, Errors, Warning } from './components'
import { Button } from '..'
import { withMemoize } from '.'

const SwitchButton = styled(Button)`
  margin: 0;
  border-right-width: 0;
  border-radius: 0;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);

  ${({ isSingleChild }) =>
    isSingleChild &&
    css`
      border-radius: 25px 25px 25px 25px;
    `}

  ${({ isSingleChild }) =>
    !isSingleChild &&
    css`
      &:first-child {
        border-radius: 25px 0 0 25px;
      }
      &:last-child {
        border-radius: 0 25px 25px 0;
        border-right-width: 1px;
      }
    `}

  &:hover {
    ${({ active, theme }) => active && `background-color: ${theme.button.activeBackgroundColor};`}
    ${({ active, theme }) => active && `color: ${theme.button.activeTextColor};`}
  }
`

const Switch = styled.div`
  display: flex;
  flex-wrap: nowrap;
`

const getValue = (pressedButton, currentValue, multiple, required) => {
  if (!multiple) {
    if (!required && pressedButton === currentValue) return ''
    return pressedButton
  }
  const index = currentValue.indexOf(pressedButton)
  if (index === -1) {
    currentValue.push(pressedButton)
    return currentValue
  }
  currentValue.splice(index, 1)
  return currentValue
}

// eslint-disable-next-line react/prefer-stateless-function
class SwitchField extends React.Component {
  render() {
    const {
      className,
      disabled,
      errors,
      label,
      name,
      multiple,
      onChange,
      options,
      required,
      tooltip,
      tooltipDown,
      warning,
      value,
      buttonMinWidth,
      hideRequiredStar,
    } = this.props
    return (
      <div className={className}>
        {label && (
          <Label
            htmlFor={name}
            required={required}
            tooltip={tooltip}
            tooltipDown={tooltipDown}
            hideRequiredStar={hideRequiredStar}
          >
            {label}
          </Label>
        )}
        <Switch>
          {options.map(option => (
            <SwitchButton
              disabled={disabled}
              key={`${name}_option_${option.value}`}
              onClick={() =>
                onChange({
                  ...option,
                  name,
                  value: getValue(option.value, value, multiple, required),
                })}
              toggle
              active={option.value === value}
              style={buttonMinWidth && { minWidth: buttonMinWidth }}
              isSingleChild={options.length === 1}
            >
              {option.text || option.label}
            </SwitchButton>
          ))}
        </Switch>
        {!!errors && <Errors errors={errors} />}
        {!!warning && <Warning text={warning} />}
      </div>
    )
  }
}

SwitchField.defaultProps = {
  className: '',
  disabled: false,
  errors: null,
  multiple: false,
  required: false,
  tooltip: '',
  tooltipDown: false,
  validated: false,
  warning: '',
  buttonMinWidth: null,
}

SwitchField.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.any,
  errors: PropTypes.array,
  label: PropTypes.string.isRequired,
  multiple: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  required: PropTypes.bool,
  tooltip: PropTypes.node,
  tooltipDown: PropTypes.bool,
  validated: PropTypes.bool,
  value: PropTypes.any.isRequired,
  warning: PropTypes.string,
  buttonMinWidth: PropTypes.string,
}

export default withMemoize(SwitchField)
