import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import ReactDOM from 'react-dom'
import { Text, Row, Col } from '.'
import { PopTooltip, TinyPopTooltip } from './Tooltip'

const rowWrapperColor = ({ theme, isActive, isOdd }) => {
  if (isActive) return theme.colors.tableActiveRow
  if (isOdd) return theme.colors.tableOddRowBackground
  return 'inherit'
}

const RowWrapper = styled.div`
  ${({ theme, isActive, isDisabled }) => css`
    min-height: 4rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    &:nth-child(odd) {
      background-color: ${rowWrapperColor({ theme, isActive, isDisabled, isOdd: true })};
    }
    &:hover {
      /* background-color: ${({ theme }) => theme.colors.primaryVeryLight}; */
      background-color: #f0f0f0;
    }
    ${({ isActive, theme }) => css`
      background-color: ${rowWrapperColor({ theme, isActive, isDisabled, isOdd: false })};
    `}
    opacity: ${isDisabled ? 0.3 : 1};
  `}
`

const TableInnerRow = styled(Row)`
  padding: 0.5rem 2rem;
  align-items: center;
`

const TableCell = styled(Col)`
  ${({ compact }) => css`
    margin-right: ${compact ? '1rem' : '2rem'};
  `}
`

class TableRow extends React.Component {
  componentDidMount() {
    const { shouldScrollIntoView } = this.props
    if (shouldScrollIntoView) {
      ReactDOM.findDOMNode(this).scrollIntoView({ block: 'center' })
    }
  }

  shouldComponentUpdate(nextProps) {
    // console.log('nextProps', this.props.row !== nextProps.row, nextProps.row)
    return this.props.row._id !== nextProps.row._id || this.props.isActive !== nextProps.isActive
  }

  render() {
    const {
      row,
      columns, //eslint-disable-line
      isActive,
      compact,
      ...props
    } = this.props

    const { isDisabled, disabledReasonText } = row

    const PopWrapper = ({ children }) =>
      isDisabled && disabledReasonText
        ? <TinyPopTooltip text={disabledReasonText}>{children}</TinyPopTooltip>
        : children

    return (
      // TODO - id nemusí být v datech vyplněné - dořešit
      // TODO: rozdelil jsem to na pure comonentu, nasledkem toho se duplikuji nejpise nektere styled componenty
      <RowWrapper isActive={isActive} isDisabled={isDisabled} {...props}>
        <PopWrapper>
          <TableInnerRow>
            {columns.map(({ colProps, item, renderCol }, key) => (
              <TableCell key={key} {...colProps} compact={compact}>
                {renderCol ? renderCol(row[item], row) : <Text color="black">{row[item]}</Text>}
              </TableCell>
            ))}
          </TableInnerRow>
        </PopWrapper>
      </RowWrapper>
    )
  }
}

TableRow.defaultProps = {
  isActive: false,
  compact: false,
  shouldScrollIntoView: false,
}

TableRow.propTypes = {
  isActive: PropTypes.bool,
  row: PropTypes.object.isRequired,
  compact: PropTypes.bool,
  shouldScrollIntoView: PropTypes.bool,
}

export default TableRow
