import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { Portal } from 'react-portal'
import { hidePopup } from '../../../common/popups/actions'
import {
  DeformatorPopup,
  ShapeLibPopup,
  CatalogPopup,
  FrameCatalogPopup,
  TracerPopup,
  VcaUploadPopup,
  DrillerPopup,
  ConfirmPopup,
  DrawingPopup,
  MiLensImportPopup,
  FusionPopup,
  NewsPopup,
} from '.'
import SliderRange from '../SliderRange'

const popups = {
  deformator: DeformatorPopup,
  shapeLib: ShapeLibPopup,
  catalog: CatalogPopup,
  frameCatalog: FrameCatalogPopup,
  tracer: TracerPopup,
  vcaUpload: VcaUploadPopup,
  driller: DrillerPopup,
  confirm: ConfirmPopup,
  drawing: DrawingPopup,
  miLensImport: MiLensImportPopup,
  fusion: FusionPopup,
  news: NewsPopup,
}

// eslint-disable-next-line
class PopupsGate extends Component {
  render() {
    const { openPopups, hidePopup } = this.props

    return (
      <Portal>
        {Object.keys(openPopups).map(p => {
          const Popup = popups[p]
          return <Popup key={p} close={() => hidePopup(p)} args={openPopups[p].args} />
        })}
      </Portal>
    )
  }
}

const enhance = compose(
  connect(
    state => ({
      openPopups: state.popups.openPopups,
    }),
    {
      hidePopup,
    },
  ),
)

export default enhance(PopupsGate)

PopupsGate.propTypes = {
  openPopups: PropTypes.object.isRequired,
  hidePopup: PropTypes.func.isRequired,
}
