import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import axios from 'axios'
import { appTypeConfig } from '../config'
import translationsConfig from './config'

const fallbackLng = 'en'

const fetchFile = url =>
  axios.request({
    url,
    method: 'GET',
    headers: {
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '0',
    },
  })

const backendPlugin = {
  type: 'backend',
  init(services, backendOptions, i18nextOptions) {
    /* use services and options */
  },
  read(language, namespace, callback) {
    try {
      if (!process.env.IS_BROWSER) return callback(null, null)

      language = language.split('-')[0]

      if (
        !appTypeConfig ||
        !appTypeConfig.languages ||
        !appTypeConfig.languages.includes(language)
      ) {
        language = fallbackLng
      }

      let translation = null
      let fallbackTranslation = null

      // todo fetch asi nefunguje
      const p1 = fetchFile(
        `${translationsConfig.publicPath}/${fallbackLng}/${namespace}.json`,
      )
        .then(response => {
          fallbackTranslation = response.data
        })
        .catch(err => callback(err))

      const p2 = fetchFile(
        `${translationsConfig.publicPath}/${language}/${namespace}.json`,
      )
        .then(response => {
          translation = response.data
        })
        .catch((err, rest) => {
          callback(err)
        })
      Promise.all([p1, p2]).then(() => {
        if (language === fallbackLng) return callback(null, translation)
        // bugnsang does not work here
        // this is debbuging log
        try {
          Object.keys(translation)
        } catch (error) {
          console.log('error', error)
          console.log('language', language)
          console.log('translation', translation)
        }

        let final = fallbackTranslation

        if (translation) {
          final = Object.keys(translation).reduce((acc, key) => {
            acc[key] = translation[key] || fallbackTranslation?.[key]
            return acc
          }, {})
        }

        return callback(null, final)
      })
    } catch (error) {
      console.log('BACKEND PLUGIN ERROR', error)
    }
  },

  create(languages, namespace, key, fallbackValue) {
    axios.request({
      url: '/api/v1/translations/add-missing-key',
      method: 'POST',
      data: {
        missingKey: key,
      },
    })
  },
}

// test only
// const resources = Object.keys(languages).reduce(
//   (result, key) => ({
//     ...result,
//     [key]: {
//       translation: {
//         ...languages[key],
//       },
//     },
//   }),
//   {},
// )
// test only end

i18n
  .use(backendPlugin)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // resources,
    // lng: 'en',
    // fallbackLng,
    // debug: true,
    debug: false,
    // saveMissing: true, // send not translated keys to endpoint -- nefunguje, neni backend asi..

    // have a common namespace used around the full app
    ns: ['translation'],
    defaultNS: 'translation',

    keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false, // not needed for react!!
      // formatSeparator: ',',
    },
    saveMissing: process.env.SERVER_ENV === 'local', // only on localhost

    react: {
      wait: true,
      // useSuspense: false, // frontend is crashing without this -> not anymore
    },
  })

export default i18n
