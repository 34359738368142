import React from 'react'
import styled, { css, keyframes, useTheme } from 'styled-components'
import PacmanLoader from 'react-spinners/PacmanLoader'
import PuffLoader from 'react-spinners/PuffLoader'
import PropagateLoader from 'react-spinners/PropagateLoader'
import BarLoader from 'react-spinners/BarLoader'
import GridLoader from 'react-spinners/GridLoader'
import CircleLoader from 'react-spinners/CircleLoader'
import MoonLoader from 'react-spinners/MoonLoader'
import { Gap, Text } from '.'

const rainbowKeyframes = keyframes`
  from {
    background-position: 0% 0%;
  }
  to {
    background-position: -200% 0%;
  }
`
const RainbowLoading = styled.div`
  height: 5px;
  width: 100%;
  border-radius: 3px;
  background: linear-gradient(
    90deg,
    red 0%,
    yellow 15%,
    lime 30%,
    cyan 50%,
    blue 65%,
    magenta 80%,
    red 100%
  );
  background-size: 200%;
  animation: ${rainbowKeyframes} 2s linear infinite;
  opacity: 0.4;
`

const types = {
  pacman: {
    Component: PacmanLoader,
    size: 20,
    margin: 2,
    css: css`
      width: 40px;
      height: 40px;
    `,
  },
  circle: {
    Component: CircleLoader,
    size: 60,
  },
  smallMoon: {
    Component: MoonLoader,
    size: 20,
  },
  smallPuff: {
    Component: PuffLoader,
    size: 20,
  },
  puff: {
    Component: PuffLoader,
    size: 60,
    css: css`
      width: 60px;
      height: 60px;
    `,
  },
  rainbow: {
    Component: RainbowLoading,
    css: css`
      width: 100%;
      height: 5px;
    `,
  },
  deformedGrid: {
    Component: GridLoader,
    size: 20,
    margin: 2,
    css: css`
      /* width: 60px;
      height: 60px; */
    `,
  },
  bar: {
    Component: BarLoader,
    width: 100,
    height: 4,

    css: css`
      width: 100px;
      height: 4px;
      & > span {
        border-radius: 35px;
        display: block;
      }
    `,
  },
}

const CorrectionWrapper = styled.div`
  ${({ injectedCss }) => injectedCss}
`

const Wrapper = styled.div`
  ${({ padding = '2rem' }) => css`
    padding: ${padding};
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  `}
`

function Loading({ type = 'pacman' }) {
  const theme = useTheme()

  const { Component, size, margin, css, width, height } = types[type]

  if (!Component) {
    if (process.env.NODE_ENV !== 'production') {
      throw new Error(`Loading component has non-existent type! - ${type}`)
    }
    return null
  }

  return (
    <CorrectionWrapper injectedCss={css}>
      <Component
        size={size}
        margin={margin}
        width={width}
        height={height}
        color={theme.colors.primary}
      />
    </CorrectionWrapper>
  )
}

export default Loading

export const WrappedLoading = ({ text, padding, type }) => (
  <Wrapper padding={padding}>
    <Loading type={type} />
    {text && (
      <>
        <Gap gap="2rem" />
        <Text center big>
          {text}
        </Text>
      </>
    )}
  </Wrapper>
)
