import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { compose } from 'recompose'
import propTypes from 'prop-types'
import styled, { keyframes } from 'styled-components'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import { Row, Col, Link, Button } from '../components'
import { AuthForm } from '../components/forms'
import { authRedirects } from '../app/links'
import meta from '../app/meta'
import withRouter from '../components/hoc/withRouter'
import { selectHostConf, selectViewerId } from '../../common/app/reducer'
import { redirectToOAuthSignIn } from '../../common/lib/oauth/client'
import { appTypeConfig } from '../../common/config'

const bgKeyframes = keyframes`
  from {
    /* background: linear-gradient(black, transparent),
      linear-gradient(to top left, black, transparent),
      linear-gradient(to top right, black, transparent); */
      /* opacity: 0; */
      background-color: #8e8e8e;
      background-color: #2a2744;
      background-position: 100% 100%;

      background-size: 150% 150%;


    }
    to {
      background-position: 0% 0%;
      background-size: 100% 100%;

      /* opacity: 1; */
      /* background: linear-gradient(#82cfff, transparent),
      linear-gradient(to top left, #af1f68, transparent),
      linear-gradient(to top right, #0665a8, transparent); */
  }
`

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.backgroundDark};
  width: 100%;
  height: 100%;
  padding-bottom: 200px;

  background-color: #4158d0;
  background-image: linear-gradient(43deg, #4158d0 0%, #c850c0 46%, #ffcc70 100%);

  background-color: #0093e9;
  background-image: linear-gradient(151deg, #0093e9 0%, #80d0c7 100%);

  background-image: linear-gradient(135deg, #ad1e67 0%, #035da0 100%);

  background: linear-gradient(red, transparent), linear-gradient(to top left, lime, transparent),
    linear-gradient(to top right, blue, transparent);
  background-blend-mode: screen;

  background: linear-gradient(#82cfff, transparent),
    linear-gradient(to top left, #af1f68, transparent),
    linear-gradient(to top right, #0665a8, transparent);
  background-blend-mode: color-burn;

  animation-name: ${bgKeyframes};
  animation-duration: 2s;
  animation-timing-function: ease-out;
  transition-timing-function: cubic-bezier(0.495, 0.46, 0.395, 0.93);
  transition-timing-function: cubic-bezier(0.14, 0.745, 0.5, 0.99);
  animation-fill-mode: forwards;

  background: ${({ theme }) => theme.colors.authBackground};
`

const Box = styled.div`
  width: 50rem;
  background-color: white;
  padding: 5rem;
  border-radius: 8px;
  box-shadow: 0 9px 25px rgba(0, 0, 0, 0.25);
`

const LogoWrapper = styled.div`
  padding: 1rem 0 2rem;
  max-width: 100%;
`

const Title = styled.h2`
  font-size: 2rem;
  font-weight: 400;
  margin: 0 0 3rem;
`

const AuthPage = ({ t }) => {
  const viewerId = useSelector(selectViewerId)
  const hostConf = useSelector(selectHostConf)
  const navigate = useNavigate()
  const location = useLocation()
  const [isLoading, setIsLoading] = useState(false)

  const from = location.state?.from?.pathname || Link.HOME
  const search = location.state?.from?.search || ''

  let to = from + search

  // exception for some pages, where redirect is not wanted
  const redirection = authRedirects.find(r => r.from.find(route => route === to))
  // console.log('tooo', to, query, statePathname, queryPathname)
  if (redirection) {
    if (process.env.NODE_ENV === 'production') {
      to = redirection.to
    } else {
      console.log('[DEV] Auto redirect after login is disabled in development')
    }
  }

  useEffect(() => {
    if (viewerId) {
      navigate(to, { replace: true })
    }
  }, [viewerId, navigate, to])

  const logoPath = hostConf.logoSquared?.publicPath || hostConf.logo?.publicPath
  const logoStyle = {
    maxWidth: '100%',
    maxHeight: '12rem',
  }
  const logoWrapperStyle = {}

  Object.assign(logoStyle, hostConf.logoSquared?.styles?.image || {})
  Object.assign(logoWrapperStyle, hostConf.logoSquared?.styles?.wrapper || {})

  const handleOauthSignIn = () => {
    setIsLoading(true)
    redirectToOAuthSignIn(appTypeConfig.APP_TYPE)
  }

  let content
  if (appTypeConfig.APP_TYPE === 'jaikudo') {
    content = (
      <Button primary large onClick={handleOauthSignIn} disabled={isLoading}>
        {!isLoading ? 'SIGN IN WITH JAIKUDO' : 'Loading...'}
      </Button>
    )
  } else {
    content = <AuthForm />
  }

  return (
    <Container>
      <Helmet
        titleTemplate={`%s | ${hostConf.htmlTitle}`}
        meta={meta.metas}
        link={meta.links}
        title={t('sign in')}
      />

      <Box>
        <Row>
          <Col width="100%" alignItems="center">
            <LogoWrapper style={logoWrapperStyle}>
              <img
                src={logoPath}
                alt="logo"
                style={logoStyle}
              />
            </LogoWrapper>
          </Col>
        </Row>
        <Title>{t('sign in')}</Title>
        {content}
      </Box>
    </Container>
  )
}

AuthPage.propTypes = {
  t: propTypes.func.isRequired,
  hostConf: propTypes.object.isRequired,
}

const enhance = compose(withTranslation(), withRouter)

export default enhance(AuthPage)
