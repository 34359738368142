import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router'
import { Link } from '../components'

const OpenImportedOrderPage = () => {
  const navigate = useNavigate()
  const { id } = useParams()

  useEffect(() => {
    const orderId = id
    if (!orderId) {
      navigate('/', { replace: true })
    }

    const redirectToUrl = `${Link.CREATE_ORDER_STEP_1}?_id=${orderId}`
    navigate(redirectToUrl, { replace: true })
  }, [navigate, id])

  return null
}

export default OpenImportedOrderPage
