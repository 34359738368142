import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect, useSelector } from 'react-redux'
import { compose, branch, renderComponent } from 'recompose'
import { Navigate, Outlet, useLocation, useParams, useSearchParams } from 'react-router-dom'
import links from '../app/links'
import { getIo, useAuthSocketIo } from '../io'
import DataUpdateService from '../components/DataUpdateService'
import DataMigrationService from '../components/DataMigrationService'
import { selectViewerId } from '../../common/app/reducer'
import { Link } from '../components'

const AuthorizedPage = ({}) => {
  const viewerId = useSelector(selectViewerId)

  const location = useLocation()

  if (!viewerId) {
    const state = window.location.hash !== '#logout' ? { from: location } : null
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to={Link.AUTH} state={state} replace />
  }

  return <AuthorizedContent />
}

const AuthorizedContent = () => {
  useAuthSocketIo()
  return (
    <>
      <DataUpdateService />
      <DataMigrationService />
      <Outlet />
    </>
  )
}

export default AuthorizedPage
