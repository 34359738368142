import styled, { css } from 'styled-components'
import { appTypeConfig } from '../../common/config'

const BoxHeader = styled.div`
  background: ${({ theme }) => theme.colors.boxHeader};
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.boxTitle};
  min-height: 5rem;
  padding: 1.4rem 5rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.boxHeaderBorder};
  border-radius: 0.8rem 0.8rem 0 0;
`

const BoxTitle = styled.h3`
  font-size: 1.6rem;
  letter-spacing: 0.3rem;
  font-weight: 700;
  text-transform: uppercase;
  margin: 0;
  word-break: break-word;

  ${appTypeConfig?.colors?.titleColor && `color: ${appTypeConfig.colors.titleColor};`}
`

const BoxContent = styled.div`
  padding: 1.5rem 5rem 3rem;
  background-color: white;
  border-radius: 0 0 0.8rem 0.8rem;
  @media print {
    padding: 0;
  }
`

const HomepageBoxContent = styled(BoxContent)`
  padding-bottom: 0;
  padding-top: 2rem;
`

const Box = styled.section`
  ${({ minWidth, maxWidth, margin }) => css`
    /* position: relative; */
    background-color: white;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.07);
    box-shadow: 0px 1px 7px 1px rgb(0 0 0 / 15%);
    margin-bottom: 2rem;
    border-radius: 0.8rem;
    /* overflow: hidden; */
    min-width: ${minWidth};
    margin: ${margin};
    max-width: ${maxWidth};

    transition: 0.45s;
    &:hover {
      box-shadow: 0px 4px 14px 1px rgba(0, 0, 0, 0.08);
      box-shadow: 0px 1px 10px 1px rgb(0 0 0 / 15%);

    }

    @media print {
      box-shadow: none;
    }
  `}
`
Box.Header = BoxHeader
Box.Content = BoxContent
Box.HomepageBoxContent = HomepageBoxContent
Box.Title = BoxTitle

Box.displayName = 'Box'
BoxHeader.displayName = 'BoxHeader'
BoxContent.displayName = 'BoxContent'
BoxTitle.displayName = 'BoxTitle'

export default Box
