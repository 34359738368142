import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import styled from 'styled-components'
import { withTranslation } from 'react-i18next'
import { Driller } from '../vca'
import PopupPresentational from './PopupPresentational'
import {
  saveSelectedVcaDeformed,
  resetSelectedVcaDeformed,
  saveHolesAfterEdit,
} from '../../../common/vca/actions'

// eslint-disable-next-line
class DrillerPopup extends Component {
  close = ({ ok } = {}) => {
    // console.log('was ok', ok)
    if (ok) {
      this.props.saveHolesAfterEdit()
    }

    // finally hide the popup itself
    this.props.close()
  }

  render() {
    const { t } = this.props
    return (
      <PopupPresentational
        title={t('drill data')}
        close={this.close}
        type="big"
        okText={t('save')}
        // hideCancel
        // cancelText="Zahodit změny"
        // showControlButtons={false}
        width="120"
      >
        <Driller />
      </PopupPresentational>
    )
  }
}

const enhance = compose(
  connect(state => ({}), {
    saveSelectedVcaDeformed,
    resetSelectedVcaDeformed,
    saveHolesAfterEdit,
  }),
  withTranslation(),
)

export default enhance(DrillerPopup)

DrillerPopup.defaultProps = {}

DrillerPopup.propTypes = {
  close: PropTypes.func.isRequired, // is injected by PopupsGate
  saveSelectedVcaDeformed: PropTypes.func.isRequired,
  resetSelectedVcaDeformed: PropTypes.func.isRequired,
  saveHolesAfterEdit: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
}
