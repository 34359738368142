import React from 'react'
import ReactDOM from 'react-dom'
import { createRoot } from 'react-dom/client'
import bootstrapLogs from '../common/lib/logs'
import { consoleEnhance } from '../common/lib/utils'
import './bugsnag' // start bugsnag
import Root from './app/Root'
import LoadingWatcher from './app/LoadingWatcher'
import configureStore from '../common/configure/store'
import { isBrowserSupported } from '../common/lib/supportedBrowsers'

consoleEnhance()

const envLog = console.getLogger('ENV')

envLog.debug('[APP_TYPE]', process.env.APP_TYPE)
envLog.debug('[IS_BROWSER]', process.env.IS_BROWSER)
envLog.debug('[SERVER_ENV]', process.env.SERVER_ENV)
envLog.debug('[LOG_LEVEL]', process.env.LOG_LEVEL)
envLog.debug('[LOG_LEVELS]', process.env.LOG_LEVELS)
envLog.debug('[APP_VERSION]', process.env.APP_VERSION)
envLog.debug('[NODE_ENV]', process.env.NODE_ENV)

export const store = configureStore()

export const getStore = () => {
  if (process.env.IS_BROWSER) {
    return store
  }
  return {}
}

// for testing purposes - move to Console lib, once we have this
// trace console logs
// const { log } = console
// console.log = (...args) => {
//   log(...args)
//   console.trace()
// }
// setInterval(() => {
//   throw new Error('testik')
// }, 1500);

// used for bugsnag reporting
let stateCopy = {}
export const getState = () => {
  if (process.env.IS_BROWSER) {
    return stateCopy
  }
  return {}
}
function makeStateCopy() {
  stateCopy = store.getState()
}
const unsubscribe = store.subscribe(makeStateCopy)

const appElement = document.getElementById('root')

const root = createRoot(appElement)

console.log('isBrowserSupported', isBrowserSupported())

// Initial render.
// ReactDOM.hydrate(<Root store={store} />, appElement)

// // Hot reload render.
// // gist.github.com/gaearon/06bd9e2223556cb0d841#file-naive-js
// if (module.hot && typeof module.hot.accept === 'function') {
//   module.hot.accept('./app/Root', () => {
//     const NextRoot = require('./app/Root').default

//     ReactDOM.hydrate(<NextRoot store={store} />, appElement)
//   })
// }

// root.render(
//   <React.StrictMode>
//     <Root store={store} />
//   </React.StrictMode>,
// )
root.render(<Root store={store} />)
