import { useEffect } from 'react'
import { init } from 'emoji-mart'

// downloads emojis data

let emojiData = null
export const useEmojis = async () => {
  useEffect(() => {
    const fetchDataAndInit = async () => {
      const response = await fetch('https://cdn.jsdelivr.net/npm/@emoji-mart/data')
      const data = await response.json()
      emojiData = data
      init({ data })
    }
    if (emojiData) {
      init({ data: emojiData })
    } else {
      fetchDataAndInit()
    }
  }, [])
}
