/* @flow weak */
import * as actions from './actions'

const State = {
  openPopups: {},
}

const popupsReducer = (state = State, action) => {
  switch (action.type) {
    case actions.SHOW_POPUP: {
      const { name, args } = action.payload
      return {
        ...state,
        openPopups: {
          ...state.openPopups,
          [name]: { args },
        },
      }
    }

    case actions.HIDE_POPUP: {
      const { name } = action.payload

      const openPopups = {
        ...state.openPopups,
      }

      delete openPopups[name]

      return {
        ...state,
        openPopups,
      }
    }

    case actions.HIDE_ALL_POPUPS: {
      const openPopups = []

      return {
        ...state,
        openPopups,
      }
    }

    default:
      return state
  }
}

export default popupsReducer
