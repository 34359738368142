import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { withTranslation } from 'react-i18next'
import { SelectFileButton } from '..'
import { importOrders } from '../../../common/orders/actions'
import { orderImportTypes } from '../../../common/config'
import { withNotifs } from '../hoc'

const ImportOrders = ({ t, importOrders, onLoad, notifs, importType }) => (
  <SelectFileButton
    large
    onSelect={async e => {
      try {
        await importOrders({ files: e.target.files, importType }).meta.action.payload
        // TODO - separátní error hlášky
        notifs.success({ title: t('saving success') })
        onLoad()
      } catch (error) {
        notifs.error({ title: t('saving failed') })
      }
    }}
  >
    {importType === orderImportTypes.EYESTATION ? t('import eyestation') : t('import orders')}
  </SelectFileButton>
)

ImportOrders.defaultProps = {
  onLoad: () => null,
  importType: orderImportTypes.B2B,
}

ImportOrders.propTypes = {
  t: PropTypes.func.isRequired,
  importOrders: PropTypes.func.isRequired,
  notifs: PropTypes.object.isRequired,
  onLoad: PropTypes.func,
  importType: PropTypes.string,
}

const enhance = compose(
  withTranslation(),
  withNotifs,
  connect(() => ({}), {
    importOrders,
  }),
)

export default enhance(ImportOrders)
