import Validation from '../lib/validation'
import { validation } from '../validationHelpers'

const validationConfig = {
  username: {
    isRequired: true,
  },
  password: {
    isRequired: true,
  },
}

export const validateSignIn = ({ dataToValidate, allData }) => {
  const validator = new Validation(dataToValidate)

  validation({
    validator,
    specification: validationConfig,
    data: allData || dataToValidate,
  })

  return validator.validate()
}

export default validateSignIn
