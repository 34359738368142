import React, { useState, useEffect, useRef } from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import { Button } from '.'
import Gap, { FixedGap } from './Gap'
import useIsSticky from './useIsSticky'

const ButtonsWrapper = styled.div`
  ${({ isSticky, theme: { colors } }) => css`
    transition: 0.4s;
    min-height: 4rem;
    display: flex;
    border-radius: 25px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
    position: relative;

    &::after {
      border-radius: 25px;
      position: absolute;
      content: '';
      top: 50%;
      left: 50%;
      width: 100%;
      height: 100%;
      transform: translate(-50%, -50%);
    }

    ${isSticky &&
    css`
      box-shadow: 0px 7px 30px rgba(0, 0, 0, 0.3);
    `}
  `}
`

const StickyContainer = styled.div`
  ${({ isSticky }) => css`
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: sticky;
    bottom: 0;
    padding: 3rem 0 2.5rem;
    transition: 0.5s;
    /* transform: translateY(10px); */

    &::before {
      position: absolute;
      background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 0.7469362745098039) 59%,
        rgba(255, 255, 255, 0) 100%
      );
      /* background: red; */
      content: '';
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transition: 0.5s;
      opacity: 0.9;
    }

    ${isSticky &&
    css`
      &:hover {
        &::before {
          opacity: 1;
        }
        ${ButtonsWrapper} {
          box-shadow: 0px 7px 30px rgba(0, 0, 0, 0.4);
        }
      }
    `}
  `}
`

const PageButton = styled(Button)`
  margin: 0;
  border-right-width: 0;
  border-radius: 0;
  position: relative;
  box-shadow: none;
  z-index: 1;

  &:hover {
    z-index: 2;
  }

  &:first-child {
    border-radius: 25px 0 0 25px;
    &::before {
      border-radius: 25px 0 0 25px;
    }
  }
  &:last-child {
    border-radius: 0 25px 25px 0;
    border-right-width: 1px;
    &::before {
      border-right-width: 1px;
      border-radius: 0 25px 25px 0;
    }
  }
`

const Pagination = ({ paging, disabled, stickyBottomOffset }) => {
  const { isSticky, ref } = useIsSticky(true, stickyBottomOffset)

  if (!paging) return <FixedGap gap="5rem" />

  return (
    <>
      <StickyContainer ref={ref} isSticky={isSticky}>
        <ButtonsWrapper isSticky={isSticky}>
          {paging.map((item, i) => {
            if (item.first) {
              return (
                <PageButton
                  key={i}
                  onClick={!item.disabled ? item.onClick : undefined}
                  icon
                  isArrow
                  disabled={disabled || item.disabled}
                >
                  {/* &lsaquo; */}
                  &lt;
                </PageButton>
              )
            }
            if (item.last) {
              return (
                <PageButton
                  key={i}
                  onClick={!item.disabled ? item.onClick : undefined}
                  icon
                  isArrow
                  disabled={disabled || item.disabled}
                >
                  {/* &rsaquo; */}
                  &gt;
                </PageButton>
              )
            }
            return (
              <PageButton
                key={i}
                active={item.active}
                onClick={!item.disabled ? item.onClick : undefined}
                disabled={disabled || item.disabled}
              >
                {item.text}
              </PageButton>
            )
          })}
        </ButtonsWrapper>
      </StickyContainer>
      <FixedGap gap="1rem" />
    </>
  )
}

Pagination.propTypes = {
  paging: PropTypes.array,
}

export default Pagination
