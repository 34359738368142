import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { scrollToElement, scrollTop } from '../../common/lib/scroll'

const jumpTo = (event, to) => {
  event.preventDefault()
  const cleanedUpTo = to.charAt(0) === '/' ? to.substr(1) : to
  if (cleanedUpTo.charAt(0) === '#') {
    scrollToElement(cleanedUpTo)
  } else {
    scrollTop()
  }
}

const AnchorLink = ({ to, children, className }) => (
  <div
    onClick={e => {
      jumpTo(e, to)
    }}
  >
    <a href={to} className={className}>
      {children}
    </a>
  </div>
)

AnchorLink.defaultProps = {
  children: null,
  className: '',
}

AnchorLink.propTypes = {
  children: PropTypes.any,
  to: PropTypes.string.isRequired,
  className: PropTypes.string,
}

export default AnchorLink
