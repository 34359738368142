import { apiGet, apiDelete, apiPost, apiPut } from '../lib/api'

export const GENERATE_DELIVERY_NOTE = 'GENERATE_DELIVERY_NOTE'
export const GENERATE_DELIVERY_NOTE_START = 'GENERATE_DELIVERY_NOTE_START'
export const GENERATE_DELIVERY_NOTE_SUCCESS = 'GENERATE_DELIVERY_NOTE_SUCCESS'
export const GENERATE_DELIVERY_NOTE_ERROR = 'GENERATE_DELIVERY_NOTE_ERROR'

export const generateDeliveryNote = orderId => ({ getState, fetch }) => ({
  type: GENERATE_DELIVERY_NOTE,
  payload: apiGet({
    fetch,
    endpoint: `/delivery-notes/${orderId}`,
    getState,
  }),
})
