import { apiGet, apiPost, apiPut, apiDelete, apiPostFile } from '../lib/api'

export const CREATE_OR_UPDATE_ORDER_START = 'CREATE_OR_UPDATE_ORDER_START'
export const CREATE_OR_UPDATE_ORDER_SUCCESS = 'CREATE_OR_UPDATE_ORDER_SUCCESS'
export const CREATE_OR_UPDATE_ORDER_ERROR = 'CREATE_OR_UPDATE_ORDER_ERROR'

export const FETCH_ORDERS = 'FETCH_ORDERS'
export const FETCH_ORDERS_START = 'FETCH_ORDERS_START'
export const FETCH_ORDERS_SUCCESS = 'FETCH_ORDERS_SUCCESS'
export const FETCH_ORDERS_ERROR = 'FETCH_ORDERS_ERROR'

export const FETCH_ORDER = 'FETCH_ORDER'
export const FETCH_ORDER_START = 'FETCH_ORDER_START'
export const FETCH_ORDER_SUCCESS = 'FETCH_ORDER_SUCCESS'
export const FETCH_ORDER_ERROR = 'FETCH_ORDER_ERROR'

export const FETCH_MILENS = 'FETCH_MILENS'
export const FETCH_MILENS_START = 'FETCH_MILENS_START'
export const FETCH_MILENS_SUCCESS = 'FETCH_MILENS_SUCCESS'
export const FETCH_MILENS_ERROR = 'FETCH_MILENS_ERROR'

export const FETCH_ONE_MILENS = 'FETCH_ONE_MILENS'
export const FETCH_ONE_MILENS_START = 'FETCH_ONE_MILENS_START'
export const FETCH_ONE_MILENS_SUCCESS = 'FETCH_ONE_MILENS_SUCCESS'
export const FETCH_ONE_MILENS_ERROR = 'FETCH_ONE_MILENS_ERROR'

export const CREATE_MILENS = 'CREATE_MILENS'
export const CREATE_MILENS_START = 'CREATE_MILENS_START'
export const CREATE_MILENS_SUCCESS = 'CREATE_MILENS_SUCCESS'
export const CREATE_MILENS_ERROR = 'CREATE_MILENS_ERROR'

export const IMPORT_MILENS = 'IMPORT_MILENS'
export const IMPORT_MILENS_START = 'IMPORT_MILENS_START'
export const IMPORT_MILENS_SUCCESS = 'IMPORT_MILENS_SUCCESS'
export const IMPORT_MILENS_ERROR = 'IMPORT_MILENS_ERROR'

export const SEND_ORDER = 'SEND_ORDER'
export const SEND_ORDER_START = 'SEND_ORDER_START'
export const SEND_ORDER_SUCCESS = 'SEND_ORDER_SUCCESS'
export const SEND_ORDER_ERROR = 'SEND_ORDER_ERROR'

export const UPDATE_ORDER_STATUS = 'UPDATE_ORDER_STATUS'
export const UPDATE_ORDER_STATUS_START = 'UPDATE_ORDER_STATUS_START'
export const UPDATE_ORDER_STATUS_SUCCESS = 'UPDATE_ORDER_STATUS_SUCCESS'
export const UPDATE_ORDER_STATUS_ERROR = 'UPDATE_ORDER_STATUS_ERROR'

export const DELETE_ORDER = 'DELETE_ORDER'
export const DELETE_ORDER_START = 'DELETE_ORDER_START'
export const DELETE_ORDER_SUCCESS = 'DELETE_ORDER_SUCCESS'
export const DELETE_ORDER_ERROR = 'DELETE_ORDER_ERROR'

export const CANCEL_ORDER = 'CANCEL_ORDER'
export const CANCEL_ORDER_START = 'CANCEL_ORDER_START'
export const CANCEL_ORDER_SUCCESS = 'CANCEL_ORDER_SUCCESS'
export const CANCEL_ORDER_ERROR = 'CANCEL_ORDER_ERROR'

export const IMPORT_ORDERS = 'IMPORT_ORDERS'
export const IMPORT_ORDERS_START = 'IMPORT_ORDERS_START'
export const IMPORT_ORDERS_SUCCESS = 'IMPORT_ORDERS_SUCCESS'
export const IMPORT_ORDERS_ERROR = 'IMPORT_ORDERS_ERROR'

export const IS_REFERENCE_UNIQUE_CHECK = 'IS_REFERENCE_UNIQUE_CHECK'
export const IS_REFERENCE_UNIQUE_CHECK_START = 'IS_REFERENCE_UNIQUE_CHECK_START'
export const IS_REFERENCE_UNIQUE_CHECK_SUCCESS = 'IS_REFERENCE_UNIQUE_CHECK_SUCCESS'
export const IS_REFERENCE_UNIQUE_CHECK_ERROR = 'IS_REFERENCE_UNIQUE_CHECK_ERROR'

export const ENABLE_ORDER_STEP = 'ENABLE_ORDER_STEP'
export const SET_ACTIVE_ORDER_STEP = 'SET_ACTIVE_ORDER_STEP'
export const SET_CURRENT_ORDER = 'SET_CURRENT_ORDER'
export const SET_SECOND_PAIR = 'SET_SECOND_PAIR'
export const SELECT_TO_SEND = 'SELECT_TO_SEND'
export const RESET_ORDER = 'RESET_ORDER'
export const SET_SHOULD_UPDATE_ORDERS = 'SET_SHOULD_UPDATE_ORDERS'

export const FETCH_ORDER_PRICES = 'FETCH_ORDER_PRICES'
export const FETCH_ORDER_PRICES_START = 'FETCH_ORDER_PRICES_START'
export const FETCH_ORDER_PRICES_SUCCESS = 'FETCH_ORDER_PRICES_SUCCESS'
export const FETCH_ORDER_PRICES_ERROR = 'FETCH_ORDER_PRICES_ERROR'

export const fetchOrderPrices = ({ order }) => ({ getState, fetch }) => ({
  type: FETCH_ORDER_PRICES,
  payload: apiPost({
    fetch,
    endpoint: 'orders/get-order-prices',
    getState,
    body: { order },
  }),
})

export const createOrUpdateOrder = ({ order, sendImmediately, isConcept, currentId }) => ({
  getState,
  fetch,
}) => {
  // editing or creating?
  const apiCall = currentId
    ? apiPut({
      fetch,
      endpoint: `orders/${currentId}`,
      getState,
      body: { order, sendImmediately, isConcept },
    })
    : apiPost({
      fetch,
      endpoint: 'orders',
      getState,
      body: { order, sendImmediately, isConcept },
    })
  return {
    type: 'CREATE_OR_UPDATE_ORDER',
    isConcept,
    sendImmediately,
    _id: currentId,
    payload: apiCall,
  }
}

export const fetchOrders = query => ({ getState, fetch }) => ({
  type: FETCH_ORDERS,
  payload: apiGet({
    fetch,
    endpoint: 'orders',
    getState,
    query,
  }),
})

export const fetchOrder = ({ _id, shouldFindProductsInCurrentCatalog = false }) => ({
  getState,
  fetch,
}) => ({
  type: FETCH_ORDER,
  _id,
  payload: apiGet({
    fetch,
    endpoint: `orders/${_id}?shouldFindProductsInCurrentCatalog=${shouldFindProductsInCurrentCatalog}`,
    getState,
  }),
})

export const sendOrder = ({ _id }) => ({ getState, fetch }) => ({
  type: SEND_ORDER,
  _id,
  payload: apiPost({
    fetch,
    endpoint: `orders/send/${_id}`,
    getState,
  }),
})

export const updateOrderStatus = ({ _id, action }) => ({ getState, fetch }) => ({
  type: UPDATE_ORDER_STATUS,
  _id,
  payload: apiPost({
    fetch,
    endpoint: `orders/status/${_id}`,
    getState,
    body: { action },
  }),
})

export const deleteOrder = ({ _id }) => ({ getState, fetch }) => ({
  type: DELETE_ORDER,
  _id,
  payload: apiDelete({
    fetch,
    endpoint: `orders/${_id}`,
    getState,
  }),
})

export const cancelOrder = ({ _id }) => ({ getState, fetch }) => ({
  type: CANCEL_ORDER,
  _id,
  payload: apiPost({
    fetch,
    endpoint: `orders/unsend/${_id}`,
    getState,
  }),
})

export const importOrders = ({ _id, files, importType }) => ({ getState, fetch }) => {
  const endpoint = _id ? `orders/${_id}/import` : `${'orders/import?importType='}${importType}`
  return {
    type: IMPORT_ORDERS,
    payload: apiPostFile({
      fetch,
      endpoint,
      getState,
      body: { files },
    }),
  }
}

export const isReferenceUnique = reference => ({ getState, fetch }) => ({
  type: IS_REFERENCE_UNIQUE_CHECK,
  payload: apiGet({
    endpoint: `orders/is-reference-unique/${reference}`,
    fetch,
    getState,
  }),
})

// export const fetchMiLensesForImport = () => ({ getState, fetch }) => ({
//   type: FETCH_MILENS,
//   payload: apiGet({
//     fetch,
//     endpoint: 'orders/milenses',
//     getState,
//   }),
// })

export const fetchMiLenses = ({ page, origin, search, type }) => ({ getState, fetch }) => ({
  type: FETCH_MILENS,
  origin,
  payload: apiGet({
    fetch,
    endpoint: `orders/milenses?page=${page}&origin=${origin}&search=${search}&type=${type}`,
    getState,
  }),
})

export const fetchOneMiLens = ({ id }) => ({ getState, fetch }) => ({
  type: FETCH_ONE_MILENS,
  origin,
  payload: apiGet({
    fetch,
    endpoint: `orders/milenses/${id}`,
    getState,
  }),
})

// old soap api
// export const importMiLenses = ids => ({ getState, fetch }) => ({
//   type: IMPORT_MILENS,
//   payload: apiPost({
//     fetch,
//     endpoint: 'orders/milenses',
//     getState,
//     body: { ids },
//   }),
// })

export const importMiLens = ({ id, alsoImportMeasurement, alsoImportFusion }) => ({
  getState,
  fetch,
}) => ({
  type: IMPORT_MILENS,
  payload: apiPost({
    fetch,
    endpoint: 'orders/milenses',
    getState,
    body: { id, alsoImportMeasurement, alsoImportFusion },
  }),
})

export const createFusionOrder = body => ({ getState, fetch }) => ({
  type: CREATE_MILENS,
  payload: apiPost({
    fetch,
    endpoint: 'orders/create-fusion',
    getState,
    body,
  }),
})

export const selectToSend = selected => ({
  type: SELECT_TO_SEND,
  payload: { selected },
})

export const enableOrderStep = ({ summary, shape }) => ({
  type: ENABLE_ORDER_STEP,
  payload: { summary, shape },
})

export const setActiveOrderStep = value => ({
  type: SET_ACTIVE_ORDER_STEP,
  payload: { value },
})

export const setCurrentOrder = _id => ({
  type: SET_CURRENT_ORDER,
  payload: { _id },
})

export const setSecondPair = _id => ({
  type: SET_SECOND_PAIR,
  payload: { _id },
})

export const resetOrder = () => ({
  type: RESET_ORDER,
})

export const setShouldUpdateOrders = () => ({
  type: SET_SHOULD_UPDATE_ORDERS,
})
