import React from 'react'
import { withTranslation as withTranslationOriginal } from 'react-i18next'
import LoadingWatcher from '../../app/LoadingWatcher'

// suspense component render until translations are loaded
const withSuspendedTranslation = Component => props => {
  const TranslatedComponent = withTranslationOriginal()(Component)
  return (
    <React.Suspense fallback={<LoadingWatcher />}>
      <TranslatedComponent {...props} />
    </React.Suspense>
  )
}
export default withSuspendedTranslation
