import React from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import { Label } from './fields/components'
import Text from './Text'

const Grid = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: max-content 1fr;
    width: 100%;
    border-style: solid;
    border-width: ${theme.field.borderWidth};
    border-color: ${theme.colors.groupBorder};
    border-radius: ${theme.field.borderRadius};
  `}
`
const Border = styled.div`
  ${({ theme, disabled, isLabel }) => css`
    border-bottom: ${theme.field.borderWidth} solid ${theme.colors.groupBorder};
    padding: 0.25rem 1rem;
    vertical-align: bottom;
    display: block;
    ${isLabel &&
    css`
      border-right: ${theme.field.borderWidth} solid ${theme.colors.groupBorder};
      background: ${({ theme }) => theme.field.disabledInputColor};
    `}

    ${disabled &&
    css`
      border-bottom: none;
    `}
  `}
`

const StyledLabel = styled.label`
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.6rem;
  margin: 0;
  color: ${({ theme }) => theme.field.labelColor};
`

const List = ({ data }) => (
  <Grid>
    {data.map(({ label, value }, key) => (
      <>
        <Border disabled={key === data.length - 1} isLabel>
          <StyledLabel>{label}</StyledLabel>
        </Border>
        <Border disabled={key === data.length - 1}>
          <Text>{value}</Text>
        </Border>
      </>
    ))}
  </Grid>
)

List.propTypes = {
  data: PropTypes.object.isRequired,
  grid: PropTypes.string.isRequired,
}

export default List
