import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { Page, Row, Col, Button, Text, Link, PrintOrder } from '../components'
import { Summary } from '../components/order'
import { fetchOrder } from '../../common/orders/actions'
import NotFoundPage from './NotFoundPage'
import withRouter from '../components/hoc/withRouter'

const LoaderWrapper = styled.div`
  text-align: center;
  padding: 4rem 0;
`
class OrderDetailPage extends React.Component {
  state = {
    isLoading: true,
  }

  async componentDidMount() {
    const { fetchOrder, router } = this.props
    try {
      await fetchOrder({ _id: router.params.id }).meta.action.payload
    } catch (error) {
      // nothing
    }
    this.setState({ isLoading: false })
  }

  render() {
    const { order, currentOrderPlaceholder, t, ...all } = this.props
    const { isLoading } = this.state

    if (!order && !isLoading) return <NotFoundPage />

    return (
      <PrintOrder
        orderValues={order}
        activeStep={4}
        fields={{ $values: () => order }}
        showCalculation={false}
        disable={isLoading}
      >
        <Page metaTitle={t('order detail')}>
          <Page.Content style={{ paddingTop: '2rem' }}>
            {!order && !currentOrderPlaceholder && (
              <LoaderWrapper>
                <Text big>{t('Loading')}</Text>
              </LoaderWrapper>
            )}
            {(order || currentOrderPlaceholder) && (
              <Summary
                values={{ ...currentOrderPlaceholder, ...order }}
                vca={order}
                // TODO: current lens + populated vca (use order and call process vca in redux or sth...)
              />
            )}
            <Row style={{ marginTop: '5rem' }}>
              <Col>
                <Button.StepBack to={Link.HOME}>{t('back')}</Button.StepBack>
              </Col>
              <Col alignItems="flex-end" grow="1">
                <Button.PrintOrder />
              </Col>
            </Row>
          </Page.Content>
        </Page>
      </PrintOrder>
    )
  }
}

OrderDetailPage.defaultProps = {
  currentOrderPlaceholder: null,
  order: null,
}

OrderDetailPage.propTypes = {
  match: PropTypes.object.isRequired,
  fetchOrder: PropTypes.func.isRequired,
  currentOrderPlaceholder: PropTypes.object,
  order: PropTypes.object,
  history: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
}

const enhance = compose(
  withRouter,
  connect(
    ({ orders }) => ({
      order: orders.currentOrder,
      currentOrderPlaceholder: orders.currentOrderPlaceholder,
    }),
    {
      fetchOrder,
    },
  ),
  withTranslation(),
)

export default enhance(OrderDetailPage)
