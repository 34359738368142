import React, { useEffect, useMemo, useRef } from 'react'
import { useDebounce } from 'react-use'

/**
 * Observer component, use it in class components to observe state update
 * - values: object, can mutate
 * - callback: has to be immutable function
 *
 * is triggered on first render
 */

export default function Observer({ values, callback }) {
  const valuesArr = Object.keys(values).map(key => values[key])

  useEffect(() => {
    callback(values)
  }, [...valuesArr, callback])
  return null
}

/**
 * same as above but
 * - debounce: num of Ms
 * - onlyDebounce: array of keys. if omitted, everything is debounced
 * - disableFirstRender: you can skip first render
 */

export function DebouncedObserver({ values, callback, debounce = 500, onlyDebounce }) {
  let debounceDeps = Object.keys(values).map(key => values[key])
  let immediateDeps = []

  if (onlyDebounce) {
    debounceDeps = Object.keys(values)
      .filter(key => onlyDebounce.includes(key))
      .map(key => values[key])
    immediateDeps = Object.keys(values)
      .filter(key => !onlyDebounce.includes(key))
      .map(key => values[key])
  }

  const firstRun = useRef(true)

  const [ready, cancel] = useDebounce(
    () => {
      if (firstRun.current) {
        firstRun.current = false
        return
      }
      console.log('debounce update')
      callback(values)
    },
    debounce,
    [...debounceDeps, callback],
  )

  useEffect(() => {
    console.log('immediate update')
    callback(values)
  }, [...immediateDeps, callback])

  // TODO: first render
  return null
}
