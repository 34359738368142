import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { withTranslation } from 'react-i18next'
import { R, L, appTypeConfig, currenciesTexts } from '../../../../common/config'
import { getIsGetOrderPriceEnabled, prepareOrderToSend } from '../../../../common/orders/utils'
import { fetchLensPrice } from '../../../../common/catalog/actions'
import { fetchOrderPrices } from '../../../../common/orders/actions'
import HiddenInPrint from '../../HiddenInPrint'
import Box from '../../Box'
import Row from '../../Row'
import Col from '../../Col'
import Gap from '../../Gap'
import Button from '../../Button'
import SummaryTable from '../SummaryTable'
import SummaryTitle from '../SummaryTitle'
import withNotifs from '../../hoc/withNotifs'

// todo dependency cycle
// HiddenInPrint importuje

const dataColumnConfig = {
  width: '16rem',
  textAlign: 'right',
}

const getPriceString = (value, currency) =>
  `${value.toFixed(2)} ${currenciesTexts[currency] || currency}`

const getPriceSum = (priceValues = []) => {
  const sum = priceValues.reduce((result, item) => {
    const { price } = item
    if (typeof price === 'number') {
      result += price
    }
    return result
  }, 0)

  const withCurrencyItem = priceValues.find(item => !!item.currency)
  const { currency = '' } = withCurrencyItem || {}

  return getPriceString(sum, currency)
}

const renderSummaryTable = ({ t, side, pricesValues }) => {
  const data = pricesValues.map(prices => ({
    renderValue: () => `${prices.price} ${prices.currency}`,
    header: prices.catalogName || prices.name,
    ...prices,
  }))

  return (
    <>
      <SummaryTable
        values={{}}
        isDisplayedInColumn
        columnConfig={{
          header: {
            // grow: 1,
            width: 'auto',
          },
          data: dataColumnConfig,
        }}
        data={data}
      />
      <Gap />
      <SummaryTable
        values={{ sideSum: getPriceSum(pricesValues) }}
        isDisplayedInColumn
        columnConfig={{
          header: {
            width: 'auto',
            style: {
              fontWeight: 700,
            },
          },
          data: dataColumnConfig,
        }}
        data={[
          {
            header: side === L ? t('Price sum left') : t('Price sum right'),
            fieldName: 'sideSum',
          },
        ]}
      />
    </>
  )
}

const createCodeNamesMap = currentLens => {
  const codeNamesMap = {}
  codeNamesMap[currentLens.code] = currentLens.names[0].long
  currentLens.optionsArray.forEach(({ options }) => {
    options.forEach(o => {
      codeNamesMap[o.originalId] = o.names[0].long
    })
  })

  return codeNamesMap
}

const addProductNamesFromCatalog = (pricesValues = [], currentLens) => {
  const codeNamesMap = createCodeNamesMap(currentLens)

  pricesValues?.forEach(priceValue => {
    priceValue.catalogName = codeNamesMap[priceValue.code]
  })
}

const PricesSection = ({
  t,
  values,
  currentLensL,
  currentLensR,
  isCreatingOrder,
  fetchOrderPrices,
  currentOrderPrices,
  selectedVca,
  user,
  notifs,
  hostConf,
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [arePricesShown, setArePricesShown] = useState(false)

  const onLoadPricesClick = useCallback(() => {
    const load = async () => {
      setIsLoading(true)
      try {
        const preparedOrderData = prepareOrderToSend({
          orderValues: values,
          selectedVca,
        })

        // toto by mělo mít stejné věci jako při odesílání objednávky
        // je to v summary, takže objednávka stejně musí být kompletní
        // await fetchOrderPrices({ order: values }).meta.action.payload
        await fetchOrderPrices({ order: preparedOrderData }).meta.action.payload
        setArePricesShown(true)
      } catch (error) {
        notifs.error({ title: t('Prices are not available.') })
      }
      setIsLoading(false)
    }

    load()
  }, [values, fetchOrderPrices, notifs, t, selectedVca])

  if (!appTypeConfig.isGetPricesFromLWSoapEnabled) return null

  if (!getIsGetOrderPriceEnabled(user, hostConf)) return null

  // do not show this section in order detail
  if (!isCreatingOrder) return null

  const renderPrices = () => {
    const pricesValuesR = currentOrderPrices?.R || []
    const pricesValuesL = currentOrderPrices?.L || []

    addProductNamesFromCatalog(pricesValuesR, currentLensR)
    addProductNamesFromCatalog(pricesValuesL, currentLensL)

    const totalSum = getPriceSum([...pricesValuesR, ...pricesValuesL])
    return (
      <>
        <Row>
          <Col width="50%">
            {values.rightLensEnabled && (
              <>
                <SummaryTitle>{t('right lens')}</SummaryTitle>
                {renderSummaryTable({ t, side: R, pricesValues: pricesValuesR })}
              </>
            )}
          </Col>
          <Col width="50%">
            {values.leftLensEnabled && (
              <>
                <SummaryTitle>{t('left lens')}</SummaryTitle>
                {renderSummaryTable({ t, side: L, pricesValues: pricesValuesL })}
              </>
            )}
          </Col>
        </Row>
        <Row>
          <Col width="100%">
            <SummaryTable
              values={{ totalSum }}
              isDisplayedInColumn
              columnConfig={{
                header: {
                  // grow: 1,
                  width: 'auto',
                  style: {
                    fontWeight: 700,
                  },
                },
                data: dataColumnConfig,
              }}
              data={[
                {
                  header: t('Total price'),
                  fieldName: 'totalSum',
                },
              ]}
            />
          </Col>
        </Row>
      </>
    )
  }
  const getLoaderButton = () => (
    <Row>
      <Col alignItems="left" justifyContent="left">
        <Gap gap="8px" />
        <Button onClick={onLoadPricesClick}>{t('Load prices')}</Button>
      </Col>
    </Row>
  )

  const content =
    !arePricesShown && !isLoading
      ? getLoaderButton()
      : isLoading ? (
        <Row>
          <Col>
            <Gap gap="8px" />
            <div style={{ height: '30px' }}>{t('Loading')}</div>
          </Col>
        </Row>
      )
        : renderPrices()

  return (
    <HiddenInPrint>
      <Box>
        <Box.Header>
          <Box.Title>{t('Prices')}</Box.Title>
        </Box.Header>
        <Box.Content>{content}</Box.Content>
      </Box>
    </HiddenInPrint>
  )
}

PricesSection.defaultProps = {
  currentLensR: null,
  currentLensL: null,
  isCreatingOrder: false,
  selectedVca: null,
  currentOrderPrices: null,
}

PricesSection.propTypes = {
  t: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  currentLensR: PropTypes.object,
  currentLensL: PropTypes.object,
  fetchLensPrice: PropTypes.func.isRequired,
  isPriceShown: PropTypes.bool.isRequired,
  priceType: PropTypes.string.isRequired,
  isCreatingOrder: PropTypes.bool,
  selectedVca: PropTypes.object,
  fetchOrderPrices: PropTypes.func.isRequired,
  currentOrderPrices: PropTypes.object,
}

const enhance = compose(
  connect(
    ({ catalog = {}, vca = {}, config = {}, orders = {}, app = {} }) => ({
      selectedVca: vca.selectedVca,
      currentLensR: catalog.currentLensR,
      currentLensL: catalog.currentLensL,
      isPriceShown: config.isPriceShown,
      priceType: config.priceType,
      currentOrderPrices: orders.currentOrderPrices,
      user: app.viewer,
      hostConf: app.hostConf,
    }),
    {
      fetchLensPrice,
      fetchOrderPrices,
    },
  ),
  withTranslation(),
  withNotifs,
)

export default enhance(PricesSection)
