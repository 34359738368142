import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { SwitchField, CheckboxField } from '../fields'
import { fields } from '../../../common/lib/redux-fields'
import { Row } from '..'
import { setOrdersConfig } from '../../../common/config/actions'
import { appTypeConfig, orderListTimeTypes } from '../../../common/config'
import { setSort } from '../../../common/lib/redux-lists/actions'

const getOptions = t => [
  { text: t('all states'), value: orderListTimeTypes.ALL_STATES },
  { text: t('sent only'), value: orderListTimeTypes.SENT_ONLY },
]

const OrdersSettingForm = ({ t, fields }) => (
  <>
    <Row>
      <CheckboxField
        label={t('Check reference duplicity')}
        {...fields.isReferenceDuplicityCheckEnabled}
        onChange={(e, event) => {
          fields.isReferenceDuplicityCheckEnabled.onChange(e, event)
          fields.$submit()
        }}
      />
    </Row>
    <Row>
      <CheckboxField
        // label={t('Check reference duplicity')}
        label={t('Copy reference when duplicating order')}
        {...fields.copyReferenceOnDuplication}
        onChange={(e, event) => {
          fields.copyReferenceOnDuplication.onChange(e, event)
          fields.$submit()
        }}
      />
    </Row>
    <Row>
      <CheckboxField
        label={t('Disable order preview on hover')}
        {...fields.isSummaryTooltipDisabled}
        onChange={(e, event) => {
          fields.isSummaryTooltipDisabled.onChange(e, event)
          fields.$submit()
        }}
      />
    </Row>
    <Row>
      <SwitchField
        label={t('Show time of order state change')}
        options={getOptions(t)}
        name="type"
        required
        hideRequiredStar
        {...fields.orderListTimeType}
        onChange={(e, event) => {
          fields.orderListTimeType.onChange(e, event)
          fields.$submit()
        }}
      />
    </Row>
  </>
)

OrdersSettingForm.propTypes = {
  t: PropTypes.func.isRequired,
  fields: PropTypes.object.isRequired,
  setOrdersConfig: PropTypes.func.isRequired,
  orderListTimeType: PropTypes.string.isRequired,
}

const enhance = compose(
  connect(
    ({ config }) => ({
      orderListTimeType: config.orderListTimeType,
      isReferenceDuplicityCheckEnabled: config.isReferenceDuplicityCheckEnabled,
      copyReferenceOnDuplication: config.copyReferenceOnDuplication,
    }),
    {
      setOrdersConfig,
      setSort,
    },
  ),
  fields({
    path: 'OrdersSettingForm',
    fields: [
      'orderListTimeType',
      'isReferenceDuplicityCheckEnabled',
      'copyReferenceOnDuplication',
      'isSummaryTooltipDisabled',
    ],
    getInitialState: ({
      orderListTimeType,
      isReferenceDuplicityCheckEnabled,
      copyReferenceOnDuplication,
      isSummaryTooltipDisabled,
    }) => ({
      orderListTimeType: orderListTimeType || orderListTimeTypes.ALL_STATES,
      isReferenceDuplicityCheckEnabled,
      copyReferenceOnDuplication,
      isSummaryTooltipDisabled,
    }),
    submitFunc: (data, { setOrdersConfig, setSort }) => {
      switch (data.orderListTimeType) {
        case orderListTimeTypes.ALL_STATES:
          setSort(['OrdersListPage', 'sort'], '-sortDate')
          break
        case orderListTimeTypes.SENT_ONLY:
          setSort(['OrdersListPage', 'sort'], '-ftpSentAt')
          break
        // no default
      }

      setOrdersConfig(data)
    },
  }),
  withTranslation(),
)

export default enhance(OrdersSettingForm)
