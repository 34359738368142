import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import ReactDOM from 'react-dom'
import { IoIosArrowUp, IoIosArrowDown } from 'react-icons/io'
import { Text, Row, Col } from '.'
import TableRowPureComponent from './TableRow'

const StyledTable = styled.div`
  text-align: left;
  border-style: solid;
  border-width: ${({ theme }) => theme.field.borderWidth};
  border-color: ${({ theme }) => theme.colors.groupBorder};
  border-radius: ${({ theme }) => theme.field.borderRadius};
`

const Header = styled.div`
  border-bottom: solid 1px #c4c4c4;
  min-height: 4rem;
  padding-right: 2rem; /* because of scrollBar in body*/
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const Body = styled.div`
  max-height: 60vh;
  overflow-y: scroll;
`

const RowWrapper = styled.div`
  min-height: 4rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  &:nth-child(odd) {
    background-color: ${({ theme }) => theme.colors.tableOddRowBackground};
  }
`
const TableRow = styled(Row)`
  padding: 0.5rem 2rem;
  align-items: center;
`

const TableHeading = styled(Col)`
  ${({ compact }) => css`
    position: relative;
    margin-right: ${compact ? '1rem' : '2rem'};
    cursor: pointer;
    user-select: none;
    flex-direction: row;
    align-items: center;
  `}
`

const TableCell = styled(Col)`
  margin-right: 2rem;
`

const SortIconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 0.5rem;
  & * {
    display: block;
    font-weight: 700;
    &:first-child {
      margin-bottom: -0.8rem;
    }
  }
`

class Table extends Component {
  state = {
    // if we want to scroll to active row, we want to do it only on first render.
    // and it cannot be solved in row component because it can be unmounted and mounted
    // again because of filtering
    isFirstRender: true,
  }

  componentDidUpdate() {
    const { isFirstRender } = this.state
    if (isFirstRender) {
      this.setState({ isFirstRender: false })
    }
  }

  render() {
    const {
      data,
      columns,
      sortBy,
      sortAsc,
      name,
      onRowClick,
      activeItemId,
      compact,
      bodyRef,
      onBodyScroll,
      ...rest
    } = this.props
    const { isFirstRender } = this.state
    const newData = [...data]
    if (sortBy) {
      newData.sort((a, b) => {
        if (a[sortBy] < b[sortBy]) return sortAsc ? -1 : 1
        return sortAsc ? 1 : -1
      })
    }
    return (
      <StyledTable name={name} {...rest}>
        <Header>
          <TableRow>
            {columns.map(
              (
                {
                  colProps,
                  headerColProps,
                  header,
                  renderHeader,
                  item,
                  sortable,
                  sortState,
                  sortHandler,
                },
                key,
              ) => (
                <TableHeading
                  key={key}
                  onClick={sortable ? () => sortHandler(item) : null}
                  {...colProps}
                  {...headerColProps}
                  compact={compact}
                >
                  {renderHeader ? renderHeader() : <Text color="black">{header}</Text>}
                  {sortable && (
                    <SortIconWrapper>
                      <IoIosArrowUp size={16} color={sortState === 1 ? '#0265ab' : '#BDBDBD'} />
                      <IoIosArrowDown size={16} color={sortState === -1 ? '#0265ab' : '#BDBDBD'} />
                    </SortIconWrapper>
                  )}
                </TableHeading>
              ),
            )}
          </TableRow>
        </Header>
        <Body name={`${name}-body`} ref={bodyRef} onScroll={onBodyScroll}>
          {newData.map(row => (
            <TableRowPureComponent
              row={row}
              key={`${row._id}`}
              columns={columns}
              onClick={() => onRowClick && onRowClick(row)}
              isActive={activeItemId === row._id}
              shouldScrollIntoView={activeItemId === row._id && isFirstRender}
              style={onRowClick && { cursor: 'pointer' }}
              compact={compact}
              // todo toto neexistuje? Smazat?
              // scrollIntoView={this.scrollRowIntoView}
            />
            // TODO - id nemusí být v datech vyplněné - dořešit
          ))}
        </Body>
      </StyledTable>
    )
  }
}

// const Table = ({
//   data,
//   columns,
//   sortBy,
//   sortAsc,
//   name,
//   onRowClick,
//   activeItemId,
//   compact,
//   ...rest
// }) => {
//   const newData = [...data]
//   if (sortBy) {
//     newData.sort((a, b) => {
//       if (a[sortBy] < b[sortBy]) return sortAsc ? -1 : 1
//       return sortAsc ? 1 : -1
//     })
//   }
//   return (
//     <StyledTable name={name} {...rest}>
//       <Header>
//         <TableRow>
//           {columns.map(({
//             colProps, headerColProps, header, renderHeader, item, sortable, sortState, sortHandler,
//           }, key) => (
//             <TableHeading
//               key={key}
//               onClick={sortable ? () => sortHandler(item) : null}
//               {...colProps}
//               {...headerColProps}
//               compact={compact}
//             >
//               {renderHeader ? renderHeader() : <Text color={'black'}>{header}</Text>}
//               {sortable && (
//                 <SortIconWrapper>
//                   <IoIosArrowUp size={16} color={sortState === 1 ? '#5891EF' : '#BDBDBD'} />
//                   <IoIosArrowDown size={16} color={sortState === -1 ? '#5891EF' : '#BDBDBD'} />
//                 </SortIconWrapper>)}
//             </TableHeading>
//           ))}
//         </TableRow>
//       </Header>
//       <Body name={`${name}-body`}>
//         {newData.map((row) => (
//           <TableRowPureComponent
//             row={row}
//             key={`${row._id}`}
//             columns={columns}
//             onClick={() => onRowClick && onRowClick(row)}
//             isActive={activeItemId === row._id}
//             style={onRowClick && { cursor: 'pointer' }}
//             compact={compact}
//             scrollIntoView={(node) => { console.log('SCROLL INTO VIEW CALLED', this, node) }}
//           />
//           // TODO - id nemusí být v datech vyplněné - dořešit
//         ))}
//       </Body>
//     </StyledTable>
//   )
// }

Table.defaultProps = {
  data: [],
  sortBy: '',
  sortAsc: true,
  sortHandler: () => {},
  name: '',
  onRowClick: null,
  activeItemId: null,
  compact: false,
}

Table.propTypes = {
  data: PropTypes.array,
  columns: PropTypes.array.isRequired,
  sortBy: PropTypes.string,
  sortAsc: PropTypes.bool,
  sortHandler: PropTypes.func,
  name: PropTypes.string,
  onRowClick: PropTypes.func,
  activeItemId: PropTypes.string,
  compact: PropTypes.bool,
}

export default Table
