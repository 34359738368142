// when user enter-click address bar and he is logged
// in some cases we want to change the after login route
export const authRedirects = [
  {
    from: ['/create-order/2', '/create-order/3', '/create-order/4'],
    to: '/create-order/1',
  },
]

const links = {
  AUTH: '/auth',
  HOME: '/',
  SETTINGS: '/settings',
  ORDER_DETAIL: '/order-detail/:id',
  // this only redirects to classic order edit.
  // it is created only to simplify url and remove characters like ? and =
  // which were causing issues to some PMSs
  EDIT_IMPORTED_ORDER: '/edit-order/:id',
  CREATE_ORDER: '/create-order',
  CREATE_ORDER_STEP_1: '/create-order/1',
  CREATE_ORDER_STEP_2: '/create-order/2',
  CREATE_ORDER_STEP_3: '/create-order/3',
  CREATE_ORDER_STEP_4: '/create-order/4',
  OWNER: '/impressum',
  PROMOTIONS: '/promotions',
  // TEST:'/test-page',
  // VCA:'/vca',
}

export default links

export const routes = {
  AUTH: {
    path: '/auth',
    end: true,
    page: 'AuthPage',
  },
  AUTHORIZED: {
    path: '',
    page: 'AuthorizedPage',
    children: {
      HOME: {
        path: '/',
        end: true,
        page: 'OrdersListPage',
      },
      ORDER_DETAIL: {
        path: '/order-detail/:id',
        page: 'OrderDetailPage',
        end: true,
      },
      // this only redirects to classic order edit.
      // it is created only to simplify url and remove characters like ? and =
      // which were causing issues to some PMSs
      EDIT_IMPORTED_ORDER: {
        path: '/edit-order/:id',
        page: 'OpenImportedOrderPage',
        end: true,
      },
      CREATE_ORDER: {
        path: '/create-order',
        page: 'CreateOrderPage',
        end: true,
      },
      CREATE_ORDER_STEP_1: {
        path: '/create-order/1',
        page: 'CreateOrderPage',
        end: true,
      },
      CREATE_ORDER_STEP_2: {
        path: '/create-order/2',
        page: 'CreateOrderPage',
        end: true,
      },
      CREATE_ORDER_STEP_3: {
        path: '/create-order/3',
        page: 'CreateOrderPage',
        end: true,
      },
      CREATE_ORDER_STEP_4: {
        path: '/create-order/4',
        page: 'CreateOrderPage',
        end: true,
      },
      SETTINGS: {
        path: '/settings',
        end: true,
        page: 'SettingsPage',
      },
      OWNER: {
        path: '/impressum',
        end: true,
        page: 'OwnerPage',
      },
      PROMOTIONS: {
        path: '/promotions',
        end: true,
        page: 'PromotionsPage',
      },
      // TEST: {
      //   path: '/test-page',
      //   end: true,
      //   page: 'TestPage',
      // },
      // vca: {
      //   path: '/vca',
      //   end: true,
      //   page: 'VcaPage',
      // },
    },
  },
}
