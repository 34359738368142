import React from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { Trans, withTranslation, useTranslation } from 'react-i18next'
import { createOrUpdateOrder } from '../../common/orders/actions'
import { prepareOrderToSend, totalResetOrder } from '../../common/orders/utils'

import Icon from './Icon'
import { Link } from '.'
import Popup from './popups/Popup'
import { orderStatuses } from '../../common/config'

const CONTROL_BUTTONS_MAX_WIDTH = '20rem'

const IconWrapper = styled.span`
  padding: 0 0.5rem 0 0;
  flex-grow: 0;
  flex-shrink: 0;
  vertical-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: ${({ size }) => size}px;
  max-height: ${({ size }) => size}px;
`

// TODO create helper function like this???
const _if = (property, first, second) => props => props[property] ? first : second

const transformBooleanValue = value => value ? 1 : 0

export const Wrapper = styled.button`
  cursor: ${p => p.disabled ? '' : 'pointer'};
  /* display: inline-block; */
  display: flex;
  border-radius: 2.5rem;
  /* font-size: ${({ large }) => large ? '1.8rem' : '1.4rem'}; */
  font-size: 1.4rem;
  font-weight: ${_if('large', '700', '400')};
  /* line-height: ${_if('large', '3.8rem', '2.8rem')}; */
  line-height: '1.6rem';
  min-height: ${_if('large', '4rem', '3rem')};
  text-transform: ${_if('large', 'uppercase', 'none')};
  padding: 0.5rem 1.5rem;
  vertical-align: center;
  border: 1px solid;
  margin: 0;
  transition: 0.2s;
  text-align: center;
  flex-shrink: 0;
  flex-grow: 0;
  flex-direction: row;
  align-items: center;
  /* align-self: center; */
  justify-content: center;
  max-width: ${({ maxWidth }) => maxWidth};
  ${({ noShadow }) => css`
    ${!noShadow &&
    css`
      box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
    `}
  `};

  &:focus {
    outline: none;
  }
  ${({ primary, theme: { button }, active, disabled, secondary, alignSelf }) => {
    // standard type
    let style = { ...button }

    if (primary) {
      style = {
        ...style,
        ...button.primary,
      }
    }
    if (secondary) {
      style = {
        ...style,
        ...button.secondary,
      }
    }

    if (disabled) {
      style = {
        ...style,
        ...button.disabled,
      }

      if (primary) {
        style = {
          ...style,
          ...button.disabledPrimary,
        }
      }
      if (secondary) {
        style = {
          ...style,
          ...button.disabledSecondary,
        }
      }
    }

    // console.log('btn style', style);

    return css`
      background-color: ${!active ? style.backgroundColor : style.activeBackgroundColor};
      /* font-weight: ${active && 600}; */
      color: ${!active ? style.textColor : style.activeTextColor};
      border-color: ${style.borderColor};
      opacity: ${style.opacity};
      box-shadow: ${active && '0px 5px 12px rgba(0, 0, 0, 0.4)'};
      align-self: ${alignSelf};
      /* TODO - refactor, simplify. Style is broken with such deep indent */
      ${!disabled &&
      css`
        &:hover {
          border-color: ${style.hoverBorderColor};
          background-color: ${style.hoverBackgroundColor};
          color: ${style.hoverTextColor};

          ${({ noHoverShadow }) => css`
            ${!noHoverShadow &&
            css`
              box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.2);
            `}
          `};
        }
      `}

      ${disabled &&
      `
        box-shadow: none;
      `}
    `
  }}
`

const ButtonComponent = ({ leftIcon, rightIcon, iconSize, ...props }) => {
  const size = iconSize || (props.large ? 26 : 24)

  return (
    <Wrapper {...props} onClick={!props.disabled ? props.onClick : () => null}>
      {leftIcon && (
        <IconWrapper size={size}>
          <Icon size={size} name={leftIcon} />
        </IconWrapper>
      )}
      <span>{props.children}</span>
      {rightIcon && <Icon size={size} name={rightIcon} />}
    </Wrapper>
  )
}

const Button = styled(ButtonComponent)``

export default Button

ButtonComponent.defaultProps = {
  primary: false,
  secondary: false,
  large: false,
  disabled: false,
  leftIcon: null,
  rightIcon: null,
  iconSize: null,
  onClick: null,
}

ButtonComponent.propTypes = {
  primary: PropTypes.bool,
  large: PropTypes.bool,
  disabled: PropTypes.bool,
  leftIcon: PropTypes.string,
  rightIcon: PropTypes.string,
  children: PropTypes.node.isRequired,
  iconSize: PropTypes.number,
  onClick: PropTypes.func,
}

Button.Step2 = props => (
  <Button large primary rightIcon="IosArrowForward" {...props}>
    {props.children || <Trans i18nKey="shape info" />}
  </Button>
)

Button.Step3 = props => (
  <Button
    large
    secondary={props.orange}
    primary={!props.orange}
    rightIcon={!props.orange ? 'IosArrowForward' : ''}
    leftIcon={props.orange ? 'CustomCalculator' : ''}
    iconSize={props.orange ? 20 : undefined}
    {...props}
  >
    {props.children || <Trans i18nKey="calculation" />}
  </Button>
)

Button.Step4 = props => (
  <Button
    large
    primary
    rightIcon={props.double ? 'CustomDoubleArrow' : 'IosArrowForward'}
    {...props}
  >
    {props.children || <Trans i18nKey="summary" />}
  </Button>
)

Button.StepBack = props => {
  if (props.to) {
    return (
      <Link to={props.to}>
        <Button large leftIcon="IosArrowBack" maxWidth={CONTROL_BUTTONS_MAX_WIDTH} {...props}>
          {props.children || <Trans i18nKey="back" />}
        </Button>
      </Link>
    )
  }
  return (
    <Button large primary leftIcon="IosArrowBack" maxWidth={CONTROL_BUTTONS_MAX_WIDTH} {...props}>
      {props.children || <Trans i18nKey="back" />}
    </Button>
  )
}

const TrashOrder = props => {
  const { t } = useTranslation()
  return (
    <Popup
      title={t('Cancel order?')}
      text={t('RESET_ORDER_WARNING_MESSAGE')}
      closeOnOutsideClick
      closeOnEsc
      onClose={opts => {
        if (opts.ok) {
          totalResetOrder()
          // this btn is in opened createOrderPage, where fields exists -->
          // we have to also reset fields
          if (props.fields) {
            // props.fields.$reset()
            // if we had edited order, we have to refresh initial state too
            // totalResetOrder()
            props.fields.$refreshInitialState()
          }
          if (process.env.IS_BROWSER && window) {
            window.scrollTo(0, 0)
          }
        }
      }}
      okText={t('delete')}
      closeText={t('cancel')}
    >
      {({ open }) => (
        <>
          <Button
            large
            leftIcon="IosTrash"
            iconSize={28}
            onClick={open}
            maxWidth={CONTROL_BUTTONS_MAX_WIDTH}
            {...props}
          >
            {props.children || <Trans i18nKey="cancel changes" />}
          </Button>
        </>
      )}
    </Popup>
  )
}
Button.TrashOrder = TrashOrder

Button.PrintOrder = props => (
  <Button
    large
    leftIcon="MdPrint"
    onClick={() => window.print()}
    maxWidth={CONTROL_BUTTONS_MAX_WIDTH}
    {...props}
  >
    {props.children || <Trans i18nKey="print" />}
  </Button>
)

// takova specialitka
const SaveConcept = ({ getInnerOnClick, ...props }) => {
  const { t } = useTranslation()
  // const ref =
  const onClick = () => {
    const {
      createOrUpdateOrder,
      orderValues,
      selectedVca,
      holes,
      currentId,
      fields,
      currentLensR,
      currentLensL,
    } = props
    createOrUpdateOrder({
      order: prepareOrderToSend({
        orderValues: fields.$cleanValues(),
        selectedVca,
        currentLensL,
        currentLensR,
      }),
      isConcept: true,
      currentId,
    })
  }
  getInnerOnClick(onClick)
  return (
    <Button
      large
      leftIcon="CustomSave"
      disabled={!!props.sendingOrder}
      maxWidth={CONTROL_BUTTONS_MAX_WIDTH}
      onClick={onClick}
      {...props}
    >
      {props.children ||
        t(
          props.currentOrder &&
            props.currentOrder.status &&
            props.currentOrder.status !== orderStatuses.CONCEPT
            ? 'change to concept'
            : 'save concept',
        )}
    </Button>
  )
}
SaveConcept.defaultProps = {
  getInnerOnClick: () => null,
}
SaveConcept.propTypes = {
  getInnerOnClick: PropTypes.func,
}

const enhance = compose(
  connect(
    state => ({
      selectedVca: state.vca.selectedVca,
      holes: state.vca.holes,
      orderValues: state.fields.OrderForm,
      sendingOrder: state.orders.sendingOrder,
      currentLensL: state.catalog.currentLensL,
      currentLensR: state.catalog.currentLensR,
      currentId: state.orders.currentId,
      currentOrder: state.orders.currentOrder,
    }),
    {
      createOrUpdateOrder,
    },
  ),
  withTranslation(),
)

Button.SaveConcept = enhance(SaveConcept)
