import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import styled from 'styled-components'
import { withTranslation } from 'react-i18next'
import PopupPresentational from './PopupPresentational'
import { NumberField } from '../fields'
import { Row, Col } from '..'

import {} from '../../../common/vca/actions'

// const iframe

class TracerPopup extends Component {
  close = ({ ok } = {}) => {
    // do anything you want on close
    const { selectVca } = this.props

    if (ok) {
      // TODO: nastavit BOX a DBL do objednavky --- ve fields??? ne do vca reduceru. BOX nebude ve FIELDS ale v selected vca!!! a odtud se bude davat do orders XML a dbl...
      // if (pickedShapeId) {
      //   selectVca({ _id: pickedShapeId, fromStandardLib: true })
      // }
    }
    // reset deform values - we should not need it anymore

    // finally hide the popup itself
    this.props.close()
  }

  render() {
    const url =
      'http://192.168.0.94:33333/getTraceData?tracer=BRIVCA&port=COM3&baud=19200&flow=1&parity=N&data=8&stop=1&url=http://192.168.0.213:3000&vca=0&trctp=&trcser=&custNumb=999999&custPW=4F546B354F546B35&ip=localhost:33333&proxyHost=&proxyPort=&proxyUser=&proxyPass=&supplier=LWR&ipLS=http://192.168.0.213:3000&supplier=LWR'
    const { t } = this.props

    return (
      <PopupPresentational
        title={t('loading by tracer')}
        close={this.close}
        type="big"
        okText={t('choose shape')}
        cancelText={t('cancel')}
        showControlButtons={false}
      >
        <Row>
          <Col>{t('Waiting for tracer Press the button below when appears')}</Col>
        </Row>
        <Row>
          <Col>
            <iframe src={`/tracerIframe.html?url=${url}`} />
          </Col>
        </Row>
      </PopupPresentational>
    )
  }
}

const enhance = compose(
  connect(
    state => ({
      standardLibDeformed: state.vca.standardLibDeformed,
      deformThumbsVBox: state.vca.deformThumbsVBox,
      deformThumbsHBox: state.vca.deformThumbsHBox,
    }),
    {},
  ),
  withTranslation(),
)

export default enhance(TracerPopup)

TracerPopup.defaultProps = {}

TracerPopup.propTypes = {
  close: PropTypes.func.isRequired, // is injected by PopupsGate
  deformThumbsVertical: PropTypes.func.isRequired,
  deformThumbsHorizontal: PropTypes.func.isRequired,
  selectVca: PropTypes.func.isRequired,
  standardLibDeformed: PropTypes.array.isRequired,
  deformThumbsHBox: PropTypes.number.isRequired,
  deformThumbsVBox: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired,
}
