/* eslint-disable import/prefer-default-export */
import React from 'react'
import ContentLoader from 'react-content-loader'
import Col from './Col'
import Gap from './Gap'
import BasicCol from './BasicCol'

const ROW_HEIGHT = 45
const ORDERS_HEIGHT = ROW_HEIGHT * 10

const random = (from, to) => Math.random() * (to - from) + from

export const OrdersLoading = React.memo(props => (
  <Col marginRight="0">
    <Gap gap="1rem" />

    <ContentLoader
      width="100%"
      height={ORDERS_HEIGHT}
      viewBox={`0 0 1100 ${ORDERS_HEIGHT}`}
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
      {...props}
    >
      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((a, index) => (
        <React.Fragment key={index}>
          <rect
            x="1"
            y={4 + index * ROW_HEIGHT}
            rx="8"
            ry="8"
            width={random(200, 240)}
            height="22"
          />
          <rect
            x="300"
            y={4 + index * ROW_HEIGHT}
            rx="8"
            ry="8"
            width={random(80, 170)}
            height="22"
          />
          <rect x="480" y={4 + index * ROW_HEIGHT} rx="8" ry="8" width="100" height="22" />
          <rect x="600" y={index * ROW_HEIGHT} rx="15" ry="15" width="30" height="30" />
          <rect x="670" y={4 + index * ROW_HEIGHT} rx="8" ry="8" width="100" height="22" />
          <rect
            x="800"
            y={4 + index * ROW_HEIGHT}
            rx="8"
            ry="8"
            width={random(70, 140)}
            height="22"
          />
          <rect x="980" y={index * ROW_HEIGHT} rx="15" ry="15" width="65" height="30" />
          <rect x="1055" y={index * ROW_HEIGHT} rx="15" ry="15" width="35" height="30" />
        </React.Fragment>
      ))}

      {/* <circle cx="37" cy="97" r="11" />
    <rect x="26" y="23" rx="5" ry="5" width="153" height="30" />
    <circle cx="1316" cy="88" r="11" />
    <rect x="1337" y="94" rx="0" ry="0" width="134" height="3" />
    <circle cx="77" cy="96" r="11" /> */}
    </ContentLoader>
    <Gap gap="5rem" />
  </Col>
))

export const BasicValueLoading = React.memo(props => {
  const { width = 40, height = 18, widthMin = 10, widthMax = 30, randomize = true, ...rest } = props

  const finalWidth = randomize ? random(widthMin, widthMax) : width

  return (
    <ContentLoader
      width={finalWidth}
      height={height}
      viewBox={`0 0 ${finalWidth} ${height}`}
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
      {...rest}
    >
      <rect x="0" y={0} rx="6" ry="6" width={finalWidth} height={height} />
    </ContentLoader>
  )
})
