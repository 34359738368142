import React, { Component, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { connect, useDispatch, useSelector } from 'react-redux'
import { compose } from 'recompose'
import { useTranslation, withTranslation } from 'react-i18next'
import styled, { css, withTheme } from 'styled-components'
import { format } from 'date-fns'
import { useToggle } from 'react-use'
import Deformator from '../vca/Deformator'
import Visualiser from '../vca/Visualiser'
import PopupPresentational from './PopupPresentational'
import Popup from './Popup'
import { CheckboxField, SwitchField, TextField } from '../fields'
import { Row, Col, Text, Gap, Icon, Button, InfoMessage, T } from '..'
import Pagination from '../Pagination'
import { importMiLens, fetchMiLenses, fetchOneMiLens } from '../../../common/orders/actions'
import { removeDiacritics } from '../../../common/lib/text'
import FlatList from '../FlatList'
import { TextSetter, textStyle } from '../Text'
import { Label } from '../fields/components'
import { fields } from '../../../common/lib/redux-fields'
import Observer, { DebouncedObserver } from '../../hooks/Observer'
import { showPopup } from '../../../common/popups/actions'
import { HistoryLikeTooltip, TooltipRow } from '../StatusHistoryTooltip'
import { TableLikeRow, TableLikeRowsWrapper } from '../TableLikeRows'
import Loading, { WrappedLoading } from '../Loading'
import { SummaryTable, SummaryTitle } from '../order'
import { constants } from '../../../common/schemaConfig'
import { renderFormattedDate, renderFormattedNumber, renderWithSign } from '../order/Summary/utils'
import { mapMeasurementsToValues } from '../../../common/lib/miLens'
import ErrorTryAgain from '../ErrorTryAgain'

/**
 *
 * TODO:
 * preklady komplet
 * chybove stavy notifky
 * box / dbl
 *
 */

const ORIGIN_OPTIONS = [
  { label: 'Web', value: 'web' },
  { label: 'Tablet', value: 'tablet' },
]

const formatMiLensDate = dateISOString => format(new Date(dateISOString), 'dd.MM.yyyy HH:mm')

const Td = styled.td`
  ${textStyle}
  padding: 1rem 1rem;
  ${({ textAlign }) => css`
    text-align: ${textAlign};
  `}
`

const EmptyRow = styled.tr`
  height: 0.3rem;
`

const ItemRow = styled.tr`
  ${({ isSelected, theme: { colors } }) => css`
    &:nth-child(odd) {
      td {
        background: ${isSelected ? colors.primaryLight : colors.gray2};
      }
    }
    &:hover {
      td {
        background-color: #f0f0f0;
      }
    }

    &:last-child {
      td {
        &:first-child {
          border-radius: 0 0 0 8px;
        }
        &:last-child {
          border-radius: 0 0 8px;
        }
      }
    }

    td {
      background: ${isSelected ? colors.primaryLight : 'white'};
    }

    cursor: pointer;
  `}
`
const HeaderRow = styled.tr`
  ${({ theme: { colors } }) => css`
    /* box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4); */
  `}
`

const SearchWrapper = styled.div`
  /* padding: 2rem; */
  max-width: 40rem;
  flex-grow: 10;
`

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: ${({ top }) => top ? 'flex-start' : 'center'};
  height: 100%;
  padding: 0 3rem 1rem;
`

const Th = styled.th`
  ${({
    theme: {
      colors: { tableBorderColor },
    },
  }) => css`
    ${textStyle}
    background: white;
    position: sticky;
    top: 2px;
    font-size: 1.5rem;
    padding: 1rem 1rem;
    font-weight: 500;
    /* box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.2); */

    background: white;
    /* border-top: 2px solid ${({ theme }) => theme.colors.tableBorderColor}; */

    /* this is for hiding scrolled items that vystrkovaly ruzky behind border radius */
     &::before {
      display: block;
      position: absolute;
      background: white;
      top: -3px;
      left: -2px;
      width: 100%;
      height: 100%;
      content: '';
      z-index: -1;
    }
    &::after {
      display: block;
      position: absolute; */
      background: red;
      top: -2px;
      left: -2px;
      width: 100%;
      height: 100%;
      content: '';
      border-top: 2px solid ${tableBorderColor};
    }

    &:first-child {
      &::after {
        border-radius: 8px 0 0;
        border-left: 2px solid ${tableBorderColor};
      }
    }
    &:last-child {
      &::after {
        border-radius: 0 8px 0 0;
        border-right: 2px solid ${tableBorderColor};
        width: calc(100% + 2px);
      }
      &::before {
        width: calc(100% + 4px);
      }
    }
/*
    @supports (backdrop-filter: none) or (-webkit-backdrop-filter: none) {
      background: rgba(255, 255, 255, 0.6);
      backdrop-filter: blur(9px);
    } */
  `}
`

const TableItem = React.memo(({ item, origin, closeParentPopup }) => {
  const { t, i18n } = useTranslation()

  // const data = null
  // const loading = true
  const dispatch = useDispatch()
  const data = useSelector(state => state.orders.miLensDetail)
  const loading = useSelector(state => state.orders.miLensDetailLoading)
  const error = useSelector(state => state.orders.miLensDetailError)

  const importedMiLens = useSelector(state => state.orders.importedMiLens)
  const importedMiLensLoading = useSelector(state => state.orders.importedMiLensLoading)
  const importedMiLensError = useSelector(state => state.orders.importedMiLensError)

  const [alsoImportFusion, toggleAlsoImportFusion] = useToggle(true)
  const [alsoImportMeasurement, setAlsoImportMeasurement] = useState(undefined)
  const [showFinalScreen, setShowFinalScreen] = useState(false)

  // const ref = useRef(null)
  // if (ref.current !== item) {
  //   console.log('UNEVEN')
  // }
  // ref.current = item

  // console.log('renders this import pop')

  const measurementValues = data?.measurements

  const values = { ...data, ...measurementValues }

  let measurement = measurementValues
  let finalMessage

  if (data) {
    if (data.type === 'fusion' && alsoImportMeasurement) {
      measurement = data?.measurementOrders.find(o => o.id === alsoImportMeasurement)?.measurements
    }

    // console.log('datadatadatadata', data, measurementValues, measurement)

    if (data.type === 'fusion') {
      if (alsoImportMeasurement) {
        finalMessage = t('omega; Fusion data with measurements will be imported.')
      } else if (data.measurementOrders?.length > 0) {
        finalMessage = t('omega; Only fusion data will be imported (without measurements).')
      }
    } else if (data.type === 'mimeasure' && data.fusionOrder) {
      if (alsoImportFusion) {
        finalMessage = t('omega; Measurements with fusion data will be imported.')
      } else {
        finalMessage = t('omega; Only measurements will be imported (without fusion).')
      }
    }
  }

  const triggerImport = async () => {
    try {
      setShowFinalScreen(true)

      const importResult = await dispatch(
        importMiLens({ id: item.id, alsoImportMeasurement, alsoImportFusion }),
      ).meta.action.payload
    } catch (error) {
      console.log('import error', error)
      console.notifyError(error, { customMessage: 'importing milens via new api failed' })
      setShowFinalScreen(false)
      // TODO: notifka --> redux
    }
  }

  return (
    <Popup
      showControlButtons={false}
      minWidth="60rem"
      maxWidth="60rem"
      title={t('omega; Import as an order')}
      displayTextInMessage={false}
      closeOnOutsideClick
      closeOnEsc
      injectPropsToContent
      contentStyle={{
        padding: '3.2rem 4rem',
        margin: 0,
      }}
      onClose={opts => {
        if (opts.ok) {
        }
      }}
      text={({ cancelClose }) => (
        <>
          {!showFinalScreen && (
            <>
              {loading && <WrappedLoading type="puff" />}

              {!loading && error && (
                <ErrorTryAgain
                  onClick={() => {
                    dispatch(fetchOneMiLens({ id: item.id }))
                  }}
                />
              )}

              {!loading && data && !error && (
                <TextSetter>
                  <Col>
                    <SummaryTitle>{t('order specification')}</SummaryTitle>
                    <SummaryTable
                      maxWidth="fit-content"
                      values={values}
                      compactColumns
                      data={[
                        {
                          header: t('omega; Reference / Patient'),
                          fieldName: constants.reference,
                        },
                        {
                          header: t('omega; miLens import type'),
                          fieldName: 'type',
                          renderValue: ({ value }) => t(`omega; type ${value}`),
                        },
                        {
                          header: t('created at'),
                          fieldName: 'createdAt',
                          renderValue: renderFormattedDate,
                        },
                      ]}
                    />

                    {data.type === 'fusion' && data.measurementOrders?.length > 0 && (
                      <>
                        <Gap gap="2rem" />
                        <SummaryTitle>{t('omega; Linked measurements')}</SummaryTitle>
                        <Text bold>{t('omega; Select for import')}:</Text>
                        <Gap gap=".4rem" />
                        <TableLikeRowsWrapper>
                          {data.measurementOrders.map((m, index) => (
                            <TableLikeRow
                              clickable
                              selected={alsoImportMeasurement === m.id}
                              key={m.id}
                              justifyContent="space-between"
                              padding="1.2rem 0.8rem"
                              onClick={() => {
                                if (alsoImportMeasurement === m.id) {
                                  setAlsoImportMeasurement(null)
                                } else {
                                  setAlsoImportMeasurement(m.id)
                                }
                              }}
                            >
                              <Text size="1.6rem">{m.reference}</Text>
                              <Text size="1.6rem">{formatMiLensDate(m.createdAt)}</Text>
                            </TableLikeRow>
                          ))}
                        </TableLikeRowsWrapper>
                      </>
                    )}

                    {measurement && (
                      <>
                        <Gap gap="1rem" />
                        {data.type === 'mimeasure' && <Gap gap="1rem" />}
                        {data.type === 'mimeasure' &&
                          <SummaryTitle>{t('omega; measurement details')}</SummaryTitle>}
                        <SummaryTable
                          values={measurement}
                          compactColumns
                          maxWidth="fit-content"
                          data={[
                            {
                              header: t('HBOX'),
                              fieldName: 'importedHBox',
                              renderValue: renderFormattedNumber,
                            },
                            {
                              header: t('VBOX'),
                              fieldName: 'importedVBox',
                              renderValue: renderFormattedNumber,
                            },
                            {
                              header: t('DBL'),
                              fieldName: 'importedDbl',
                              renderValue: renderFormattedNumber,
                            },
                            {
                              header: t('panto'),
                              fieldName: constants.panto,
                              renderValue: renderFormattedNumber,
                            },
                            {
                              header: t('frameBowAngle'),
                              fieldName: constants.frameBowAngle,
                              renderValue: renderFormattedNumber,
                            },
                            {
                              header: t('bvd'),
                              fieldName: 'bvdR',
                              renderValue: renderFormattedNumber,
                            },
                          ]}
                        />
                        <Gap gap="1rem" />

                        <SummaryTable
                          values={measurement}
                          isPair
                          compactColumns
                          maxWidth="fit-content"
                          data={[
                            {
                              header: t('pd'),
                              fieldName: constants.pd,
                              // renderValue: renderWithSign,
                              renderValue: renderFormattedNumber,
                              colProps: {
                                // minWidth: numberColumnWidth,
                              },
                            },
                            {
                              header: t('height'),
                              fieldName: constants.height,
                              renderValue: renderFormattedNumber,
                            },
                          ]}
                        />
                      </>
                    )}
                    {data.type === 'mimeasure' && data.fusionOrder && (
                      <>
                        <Gap gap="2rem" />
                        <CheckboxField
                          name="alsoImportFusion"
                          label={`${t('omega; Also import fusion data')} (${
                            data.fusionOrder.reference
                          })`}
                          tooltip={t(
                            'omega; Measurement has linked fusion data. You can import them together with measurement.',
                          )}
                          value={alsoImportFusion}
                          onChange={toggleAlsoImportFusion}
                        />
                      </>
                    )}

                    <Gap gap="4rem" />
                    {finalMessage && (
                      <>
                        <InfoMessage>{finalMessage}</InfoMessage>
                        <Gap gap="2.4rem" />
                      </>
                    )}
                    <Row justifyContent="center">
                      <Button primary onClick={triggerImport} disabled={loading}>
                        <T>Start import</T>
                      </Button>
                      <Gap />

                      <Button onClick={cancelClose} disabled={loading}>
                        <T>cancel</T>
                      </Button>
                    </Row>
                  </Col>
                </TextSetter>
              )}
            </>
          )}

          {showFinalScreen && (
            <>
              {importedMiLensLoading && <WrappedLoading type="puff" />}
              {!importedMiLensLoading && importedMiLens && (
                <div>
                  {showFinalScreen && (
                    <Col justifyContent="center">
                      {/* TODO: translations, ruzne cislovky */}
                      <Text center big>
                        🎉 {t('omega; Fusion data successfully imported!')}
                      </Text>

                      <Gap gap="2.4rem" />
                      <Row justifyContent="center">
                        <Button
                          primary
                          onClick={() => {
                            cancelClose()
                            closeParentPopup({ openImportedOrder: importedMiLens._id })
                          }}
                        >
                          {t('omega; Open the imported order')}
                        </Button>
                        <Gap />

                        <Button onClick={cancelClose}>{t('omega; Import next order')}</Button>
                      </Row>
                    </Col>
                  )}
                </div>
              )}
              {!importedMiLensLoading && importedMiLensError && <div>error - try again</div>}
            </>
          )}
        </>
      )}
    >
      {({ open: openPopup }) => (
        <ItemRow
          key={`${item.id}`}
          onClick={e => {
            dispatch(fetchOneMiLens({ id: item.id }))
            openPopup(e)
          }}
        >
          <Td>{item.reference}</Td>
          {origin === 'tablet' && (
            <>
              <Td>{t(`omega; type ${item.type}`)}</Td>
              <Td>
                {item.fusionOrder && 'Fusion'}
                {item.measurementOrders?.length && `${item.measurementOrders.length} měření`}
              </Td>
            </>
          )}
          <Td>{formatMiLensDate(item.createdAt)}</Td>
        </ItemRow>
      )}
    </Popup>
  )
})

const TableWrapper = styled.div`
  border: 2px solid ${({ theme }) => theme.colors.tableBorderColor};
  border-radius: 8px;
  /* overflow: hidden; */
  width: 100%;
`

const Table = styled.table`
  position: relative;
  border-collapse: collapse;
  border: none;
  width: 100%;
`

// TODO: move to separate file and apply to homepage
const NEGATIVE_OFFSET = 1
const POSITIVE_OFFSET = 2
const createPagination = ({
  currentPage,
  lastPage,
  negativeOffset = NEGATIVE_OFFSET,
  positiveOffset = POSITIVE_OFFSET,
  onPageChange,
}) => {
  if (lastPage === 1 || !currentPage || !lastPage) {
    return null
  }

  const pagination = [
    {
      first: true,
      disabled: currentPage === 1,
      onClick: () => {
        onPageChange(currentPage - 1)
      },
    },
    // show first page always
    {
      text: 1,
      active: currentPage === 1,
      onClick: () => {
        onPageChange(1)
      },
    },
  ]

  const pagesStart = Math.max(currentPage - negativeOffset, 2)
  const pagesStartDots = Math.max(pagesStart - 1, 1)

  const pagesEnd = Math.min(currentPage + positiveOffset, lastPage)
  const pagesEndDots = Math.min(pagesEnd + 1, lastPage)

  if (pagesStart > 2) {
    pagination.push({
      text: '...',
      onClick: () => {
        onPageChange(pagesStartDots)
      },
    })
  }

  for (let i = pagesStart; i <= pagesEnd; i += 1) {
    pagination.push({
      text: i,
      active: currentPage === i,
      onClick: () => {
        onPageChange(i)
      },
    })
  }

  if (pagesEnd < lastPage - 1) {
    pagination.push({
      text: '...',
      onClick: () => {
        onPageChange(pagesEndDots)
      },
    })
    pagination.push({
      text: lastPage,
      onClick: () => {
        onPageChange(lastPage)
      },
    })
  }

  pagination.push({
    last: true,
    disabled: currentPage === lastPage,
    onClick: () => {
      onPageChange(currentPage + 1)
    },
  })

  return pagination
}

class MiLensImportPopup extends Component {
  state = {
    searchInputValue: '',
    selectedIds: {},
    origin: 'web',
    // origin: 'tablet',
    typeFusion: true,
    typeMeasurement: true,
    desiredPage: 1,
    recentlySwitched: false,
  }

  popupContentRef = React.createRef()

  getPagination = () => {
    const { origin } = this.state
    const { webMiLensesMeta, tabletMiLensesMeta } = this.props

    const { currentPage, lastPage } = origin === 'web' ? webMiLensesMeta : tabletMiLensesMeta

    return createPagination({
      currentPage,
      lastPage,
      onPageChange: desiredPage => {
        this.setState({ desiredPage })
      },
    })
  }

  close = ({ showFusionPopup, openImportedOrder } = {}) => {
    // do anything you want on close
    const { importedMiLensLoading, args, showPopup } = this.props

    if (importedMiLensLoading) {
      alert('Wait until the import is finished!')
      return
    }

    // finally hide the popup itself
    this.props.close()

    args?.onLoad?.(openImportedOrder)

    if (showFusionPopup) {
      showPopup('fusion', { onLoad: args.onFusionLoad })
    }
  }

  renderTableHeader = () => {
    const { t } = this.props
    const { origin } = this.state
    return (
      <>
        <HeaderRow>
          <Th>{t('omega; Reference / Patient')}</Th>
          {origin === 'tablet' && <Th>{t('omega; Type')}</Th>}
          {origin === 'tablet' && <Th>{t('omega; Linked measurements / fusion')}</Th>}
          <Th>{t('created at')}</Th>
        </HeaderRow>
        <EmptyRow>
          <td />
        </EmptyRow>
      </>
    )
  }

  toggleId = id => {
    const { selectedIds } = this.state

    this.setState({
      selectedIds: {
        ...selectedIds,
        [id]: !selectedIds[id],
      },
    })
  }

  renderItem = (item, index) => {
    const { origin } = this.state

    return <TableItem item={item} origin={origin} closeParentPopup={this.close} />
  }

  renderHeader = () => {
    const { searchInputValue, origin, typeFusion, typeMeasurement } = this.state
    const { t } = this.props

    return (
      <Col>
        <Row padding="3rem 3rem 1rem 3rem" marginBottom="0">
          <SwitchField
            label={t('omega; origin')}
            options={ORIGIN_OPTIONS}
            value={origin}
            onChange={async opt => {
              this.setState({ origin: opt.value, desiredPage: 1, recentlySwitched: true })
            }}
          />
          <Gap gap="4rem" />
          <SearchWrapper>
            <TextField
              label={t('search')}
              // TODO: tooltip kde se hleda
              name="search-milens"
              onChange={e => {
                this.setState({ searchInputValue: e.value })
              }}
              value={searchInputValue}
            />
          </SearchWrapper>
          <Gap gap="3.2rem" />

          {origin === 'tablet' && (
            <Col>
              <Label>{t('omega; Type')}</Label>
              <Gap gap="0.6rem" />
              <Row>
                <CheckboxField
                  label={t('omega; type fusion')}
                  name="typeFusion"
                  value={typeFusion}
                  disabled={false}
                  onChange={() => {
                    this.setState(state => ({ typeFusion: !state.typeFusion }))
                  }}
                />
                <Gap />
                <CheckboxField
                  label={t('omega; type mimeasure')}
                  name="typeMeasurement"
                  value={typeMeasurement}
                  disabled={false}
                  onChange={() => {
                    this.setState(state => ({ typeMeasurement: !state.typeMeasurement }))
                  }}
                />
              </Row>
            </Col>
          )}
        </Row>
        <Gap gap="2.4rem" />
      </Col>
    )
  }

  // getSelectedIds = () => {
  //   const { selectedIds } = this.state
  //   return Object.keys(selectedIds).filter(id => selectedIds[id])
  // }

  // triggerImport = async () => {
  //   try {
  //     const { importMiLens, args } = this.props
  //     const ids = this.getSelectedIds()
  //     // await importMiLens({ id, alsoImportMeasurement, alsoImportFusion }).meta.action.payload

  //     if (args && args.onLoad) {
  //       args.onLoad()
  //     }
  //   } catch (error) {
  //     // console.log('error', error)
  //   }
  // }

  // renderFooter = () => {
  //   const ids = this.getSelectedIds()
  //   const count = ids.length
  //   const { importedMiLensLoading, t } = this.props

  //   return (
  //     <Col>
  //       <Row justifyContent="center" alignItems="center">
  //         <Text>
  //           {/* TODO: translate ruzne cislovky.... */}
  //           {!count
  //             ? t('omega; Pick measurements for import')
  //             : `${t('omega; You have picked ')} ${count} ${t('omega; measurements for import')}`}
  //           .
  //         </Text>
  //         <Gap />
  //         <Button primary disabled={!count || importedMiLensLoading} onClick={this.triggerImport}>
  //           {importedMiLensLoading ? t('Importing') : t('Start import')}
  //         </Button>
  //       </Row>
  //       <Gap />
  //     </Col>
  //   )
  // }

  filterStateUpdated = values => {
    console.log('updated!', values)

    this.fetchData(values)

    this.setState({ recentlySwitched: false })
  }

  fetchData = ({
    desiredPage = this.state.page,
    origin = this.state.origin,
    searchInputValue = this.state.searchInputValue,
    typeMeasurement = this.state.typeMeasurement,
    typeFusion = this.state.typeFusion,
  } = {}) => {
    const { fetchMiLenses } = this.props

    let type = ''
    if (origin === 'tablet') {
      if (typeMeasurement && !typeFusion) {
        type = 'measurement'
      }
      if (!typeMeasurement && typeFusion) {
        type = 'fusion'
      }
    }

    const search = removeDiacritics(searchInputValue.toLowerCase())
      .split('.')
      .join('')
      .split(':')
      .join('')

    fetchMiLenses({ page: desiredPage, origin, search, type })
  }

  onDataUpdate = values => {
    // on new data, scroll content to the top
    if (this.popupContentRef?.current?.scrollTop) {
      this.popupContentRef.current.scrollTop = 0
    }
  }

  renderWrapper = ({ ...props }) => (
    <TableWrapper>
      <Table {...props} />
    </TableWrapper>
  )

  renderEmptyComponent = () => {
    const { t } = this.props
    const { origin, searchInputValue } = this.state

    return (
      <Col>
        <Gap gap="7rem" />
        <Row justifyContent="center">
          <Text center big>
            {(() => {
              if (origin === 'web' && !searchInputValue) {
                return (
                  <Row alignItems="center" justifyContent="center">
                    No Fusion data found. Start with <Gap />
                    <Button
                      onClick={() => {
                        this.close({ showFusionPopup: true })
                      }}
                    >
                      creating a new one
                    </Button>
                  </Row>
                )
              }

              // TODO: more cases

              return `🕸🕷${t('omega; milens-nothing-found')}`
            })()}
          </Text>
        </Row>
      </Col>
    )
  }

  render() {
    const {
      t,
      webMiLenses,
      webMiLensesLoading,
      webMiLensesError,

      tabletMiLenses,
      tabletMiLensesLoading,
      tabletMiLensesError,
    } = this.props

    const {
      searchInputValue,
      origin,
      typeFusion,
      typeMeasurement,
      desiredPage,
      recentlySwitched,
    } = this.state

    let displayedMiLenses = webMiLenses
    let displayedMiLensesLoading = webMiLensesLoading
    let displayedMiLensesError = webMiLensesError
    if (origin === 'tablet') {
      displayedMiLenses = tabletMiLenses
      displayedMiLensesLoading = tabletMiLensesLoading
      displayedMiLensesError = tabletMiLensesError
    }

    const showFlatList = !displayedMiLensesError

    // TODO: block selection when loading!!!

    return (
      <PopupPresentational
        title={t('import milens')}
        close={this.close}
        type="big"
        okText={t('choose shape')}
        cancelText={t('cancel')}
        displayChildrenInMessage={false}
        showControlButtons={false}
        HeaderComponent={this.renderHeader}
        // FooterComponent={showFlatList && this.renderFooter}
        height="100%"
        maxContent
        contentRef={this.popupContentRef}
        contentStyle={{ margin: '0' }}
      >
        <DebouncedObserver
          values={{ origin, typeFusion, typeMeasurement, desiredPage, searchInputValue }}
          callback={this.filterStateUpdated}
          debounce={400}
          onlyDebounce={['searchInputValue']}
        />
        <Observer values={{ displayedMiLenses }} callback={this.onDataUpdate} />
        <ContentWrapper top={showFlatList}>
          {displayedMiLensesError && (
            <ErrorTryAgain
              onClick={() => {
                this.fetchData()
              }}
            />
          )}

          {showFlatList && (
            <>
              <FlatList
                isLoading={recentlySwitched || displayedMiLensesLoading}
                data={displayedMiLenses}
                renderItem={this.renderItem}
                ListWrapperComponent={this.renderWrapper}
                ListHeaderComponent={this.renderTableHeader}
                ListEmptyComponent={this.renderEmptyComponent}
              />
              {displayedMiLenses?.length > 0 && (
                <Pagination
                  paging={this.getPagination()}
                  disabled={displayedMiLensesLoading}
                  stickyBottomOffset={-500}
                />
              )}
            </>
          )}

          <Gap />
        </ContentWrapper>
      </PopupPresentational>
    )
  }
}

MiLensImportPopup.defaultProps = {}

MiLensImportPopup.propTypes = {
  close: PropTypes.func.isRequired, // is injected by PopupsGate
  args: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
}

const enhance = compose(
  connect(
    state => ({
      importedMiLens: state.orders.importedMiLens,
      importedMiLensLoading: state.orders.importedMiLensLoading,
      importedMiLensError: state.orders.importedMiLensError,

      webMiLenses: state.orders.webMiLenses,
      webMiLensesMeta: state.orders.webMiLensesMeta,
      webMiLensesLoading: state.orders.webMiLensesLoading,
      webMiLensesError: state.orders.webMiLensesError,
      tabletMiLenses: state.orders.tabletMiLenses,
      tabletMiLensesMeta: state.orders.tabletMiLensesMeta,
      tabletMiLensesLoading: state.orders.tabletMiLensesLoading,
      tabletMiLensesError: state.orders.tabletMiLensesError,
      miLensDetail: state.orders.miLensDetail,
      miLensDetailLoading: state.orders.miLensDetailLoading,
      miLensDetailError: state.orders.miLensDetailError,
    }),
    { fetchMiLenses, fetchOneMiLens, importMiLens, showPopup },
  ),
  withTranslation(),
  withTheme,
)

export default enhance(MiLensImportPopup)
