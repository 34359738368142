import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Input } from './components'
import { Icon, Row } from '..'

const CheckBox = styled(Input).attrs({
  type: 'checkbox',
})`
  display: none;
`

const Container = styled.div`
  display: flex;
  flex-direction: ${({ horizontal }) => horizontal ? 'row' : 'column'};
  flex-direction: column;
  padding: 0.4rem;
  border-radius: 4px;
  align-items: stretch;
`

const Label = styled.label`
  cursor: pointer;
  font-size: 1.4rem;
  font-weight: ${({ bold }) => bold ? 700 : 400};
  flex-shrink: ${({ shrink }) => shrink};
  opacity: ${({ disabled }) => disabled ? 0.4 : 1};
  margin-right: 1.6rem;
  transition: 0.4s;
  display: flex;
  flex-direction: row;
  align-items: center;

  &:last-child {
    margin-right: 0;
  }
`

const GroupLabel = styled.label`
  font-size: 1.4rem;
  font-weight: 700;
  flex-shrink: 100;
  margin-bottom: 0.6em;
  transition: 0.4s;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
`

const LabelText = styled.div`
  /* word-break: keep-all; */
`

const MultiCheckboxField = ({ label, onChange, fields, value, disabled }) => (
  <Container>
    {label && <GroupLabel>{label}</GroupLabel>}

    <Row.Modern>
      {fields.map(({ name, label }) => (
        <Fragment key={name}>
          <CheckBox
            disabled={disabled}
            id={name}
            onChange={() =>
              onChange(
                value && value.includes(name) ? value.filter(v => v !== name) : [...value, name],
              )}
            checked={value && value.includes && value.includes(name)}
          />
          <Label htmlFor={name} disabled={disabled}>
            <Icon
              size={16}
              style={{ marginRight: '.5rem', flexShrink: 0 }}
              // TODO: co to je && value.includes a proc je undefined
              name={
                value && value.includes && value.includes(name)
                  ? 'CustomCheckboxChecked'
                  : 'CustomCheckboxUnchecked'
              }
            />
            <LabelText>{label}</LabelText>
          </Label>
        </Fragment>
      ))}
    </Row.Modern>
  </Container>
)

MultiCheckboxField.defaultProps = {
  label: '',
  className: '',
  horizontal: true,
}

MultiCheckboxField.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.array.isRequired,
  horizontal: PropTypes.bool,
  fields: PropTypes.array.isRequired,
}

export default MultiCheckboxField
