// based on the math here:
// http://math.stackexchange.com/a/1367732
// https://gist.github.com/jupdike/bfe5eb23d1c395d8a0a1a4ddd94882ac

// x1,y1 is the center of the first circle, with radius r1
// x2,y2 is the center of the second ricle, with radius r2
export function intersectTwoCircles(x1, y1, r1, x2, y2, r2) {
  const centerdx = x1 - x2
  const centerdy = y1 - y2
  const R = Math.sqrt(centerdx * centerdx + centerdy * centerdy)
  if (!(Math.abs(r1 - r2) <= R && R <= r1 + r2)) {
    // no intersection
    return [] // empty list of results
  }
  // intersection(s) should exist

  const R2 = R * R
  const R4 = R2 * R2
  const a = (r1 * r1 - r2 * r2) / (2 * R2)
  const r2r2 = r1 * r1 - r2 * r2
  const c = Math.sqrt((2 * (r1 * r1 + r2 * r2)) / R2 - (r2r2 * r2r2) / R4 - 1)

  const fx = (x1 + x2) / 2 + a * (x2 - x1)
  const gx = (c * (y2 - y1)) / 2
  const ix1 = fx + gx
  const ix2 = fx - gx

  const fy = (y1 + y2) / 2 + a * (y2 - y1)
  const gy = (c * (x1 - x2)) / 2
  const iy1 = fy + gy
  const iy2 = fy - gy

  // note if gy == 0 and gx == 0 then the circles are tangent and there is only one solution
  // but that one solution will just be duplicated as the code is currently written
  return [
    { x: ix1, y: iy1 },
    { x: ix2, y: iy2 },
  ]
}
