import React from 'react'
import IconBase from 'react-icon-base'
import * as Ionicons from 'react-icons/io'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import * as customIcons from '../../common/lib/icons'
import { PopTooltip } from './Tooltip'
// console.log('Ionicons', Ionicons)

// tReady is destructed because of causing warning when passed in ...rest to component
const Icon = ({ name, onClick, t, withoutTooltip, tReady, ...rest }) => {
  const Component = !name.startsWith('Custom')
    ? Ionicons[`Io${name}`]
    : customIcons[name.substr('Custom'.length)]
  if (!Component) return null

  let popoverText = ''
  switch (name) {
    case 'CustomStock': {
      popoverText = t('stock - singular')
      break
    }
    case 'CustomManufactured': {
      popoverText = t('manufactured - singular')
      break
    }
    // no default
  }

  if (popoverText && !withoutTooltip) {
    return (
      <PopTooltip text={popoverText}>
        <span>
          <Component name={name} {...rest} />
        </span>
      </PopTooltip>
    )
  }
  return <Component name={name} {...rest} />
}

Icon.propTypes = {
  name: PropTypes.string.isRequired,
}

export default withTranslation()(Icon)
