import { R, L, lensOptionsSelectTypes, appTypeConfig } from '../../common/config'
import { getLensFieldsValuesBySide } from '../../common/helpers'
import { validateLensParams } from '../../common/catalog/actions'
import {
  getConstants,
  getOrderSchema,
  getFrameSchema,
  getVcaSchema,
} from '../../common/schemaConfig'
import {
  isCombinationValid,
  isColorAvailableWithNewCoating,
} from '../../common/catalogExtensions'

const orderSchema = getOrderSchema()
const vcaSchema = getVcaSchema()
const frameSchema = getFrameSchema()

const { individualMenus } = appTypeConfig

const lensFields = getConstants()
const { COATING, COLOR } = lensOptionsSelectTypes

export function parseQuery(queryString) {
  const query = {}
  const pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&')
  for (let i = 0; i < pairs.length; i += 1) {
    const pair = pairs[i].split('=')
    query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '')
  }
  return query
}

export const getIndividualMenuKey = (lens1 = {}, lens2 = {}) =>
  Object.keys(individualMenus).find(
    key => (lens1 && key === lens1.individualMenu) || (lens2 && key === lens2.individualMenu),
  )

// TODO - sjednotit s funkci v order importu /:id/import
// TODO - zkontrolovat, jestli se nemá čistit i cto
export const clearLensFieldsValues = ({
  fields,
  side,
  cleanLens = true,
  disableCleanOptions = {},
}) => {
  const addSide = value => `${value}${side}`
  const fieldsToClear = []
  if (!disableCleanOptions.diameter) fieldsToClear.push(fields[addSide('diameterPhysical')])
  if (!disableCleanOptions.diameter) fieldsToClear.push(fields[addSide('diameterOptical')])
  if (!disableCleanOptions.diameter) fieldsToClear.push(fields[addSide('isElliptical')])
  if (!disableCleanOptions.diameter) fieldsToClear.push(fields[addSide('isDiameterCustom')])
  if (!disableCleanOptions.coating) fieldsToClear.push(fields[addSide('coating')])
  if (!disableCleanOptions.color) fieldsToClear.push(fields[addSide('color')])
  if (!disableCleanOptions.uv) fieldsToClear.push(fields[addSide('uv')])
  if (!disableCleanOptions.express) fieldsToClear.push(fields.isExpress)
  if (cleanLens) fieldsToClear.push(fields[addSide('lens')])
  fields.$setMultipleValues(fieldsToClear.map(field => ({ fieldName: field.name, value: '' })))
}

export const setLensToFields = async ({ fields, side, lens, dontClearLensOnUpdate = true, catalogExtensions }) => {
  const lensFieldsValues = getLensFieldsValuesBySide(fields)
  const values = side === R ? lensFieldsValues.r : lensFieldsValues.l
  const { store } = require('../main')
  const getLensField = fieldName => getLensFieldHelper({ fields, side, fieldName })

  const validationResult = lens._id
    ? await store.dispatch(
      validateLensParams({
        id: lens._id,
        customQuery: values,
        side,
        fieldValues: fields.$values(),
      }),
    ).meta.action.payload
    : {}

  const optionsAvailability = validationResult.data ? validationResult.data.optionsAvailability : {}
  if (fields.isLeftLensSameAsRight.value && side === R) {
    clearLensFields({ fields, side: L, disableCleanOptions: optionsAvailability })
  }
  if (!dontClearLensOnUpdate) {
    clearLensFields({ fields, side, disableCleanOptions: optionsAvailability })
  }
  const coatingOptions = getOptionsBySelectType(lens.optionsArray, COATING)
  const colorOptions = getOptionsBySelectType(lens.optionsArray, COLOR)
  if (coatingOptions.isMandatory && !getLensField(lensFields.coating).value) {
    const selectedColorId = getLensField(lensFields.color).value

    // do not set default coating, if it is forbidden with already selected color 
    // it can happen after import from SOAP
    const shouldSetDefaultCoating = !selectedColorId || isColorAvailableWithNewCoating({
      catalogExtensions,
      colorId: selectedColorId,
      colorOptions,
      coatingOptions,
      coatingId: coatingOptions.selectOptions[0].value,
    })

    if (shouldSetDefaultCoating) {
      getLensField(lensFields.coating).onChange({ value: coatingOptions.selectOptions[0].value })
    }

  }
  if (appTypeConfig.isMandatoryColorPreselectEnabled) {
    if (colorOptions.isMandatory && !getLensField(lensFields.color).value) {
      // todo - we should check catalog extensions here,
      // but catalog extensions are only for Reize now,
      // and this code is not reachable in Reize because of "isMandatoryColorPreselectEnabled"
      getLensField(lensFields.color).onChange({
        value: colorOptions.selectOptions[0].value,
      })
    }
  }

  fields.$setValidationOptions({
    [`mandatoryOptions${side}`]: {
      coating: coatingOptions.isMandatory,
      color: colorOptions.isMandatory,
    },
  })
}

export const getOptionsBySelectType = (optionsArray = [], selectType) => {
  const foundOptions = optionsArray.find(o => o.selectType === selectType) || { options: [] }
  return {
    ...foundOptions,
    isPossible: foundOptions.options.length > 0,
    selectOptions: foundOptions.options.map(o => ({
      label: o.names[0].long,
      value: o._id,
      code: o.originalId,
      groups: o.groups,
    })),
  }
}

const clearLensFields = ({ fields, side = R, disableCleanOptions }) => {
  clearLensFieldsValues({ fields, side, cleanLens: false, disableCleanOptions })
}

const getLensFieldHelper = ({ fields, side, fieldName }) => {
  const isLeftLensSameAsRight = fields.isLeftLensSameAsRight.value
  const field = fields[`${fieldName}${side}`]
  if (isLeftLensSameAsRight && side === R) {
    const onChangeL = fields[`${fieldName}${L}`]
    return {
      ...field,
      onChange: e => {
        field.onChange(e)
        // sets same value for left side, if isSameAsRight is checked
        onChangeL.onChange(e)
      },
    }
  }
  return {
    ...field,
    onChange: e => {
      field.onChange(e)
    },
  }
}

export const getEnhancedFieldForLensParameter = ({ fields, side, fieldName }) =>
  getLensFieldHelper({ fields, side, fieldName })

export const getOptionValue = ({ coatingType, lens, fieldValue }) => {
  if (!fieldValue) return '---'
  if (!lens) return '---'
  const foundOption = lens.optionsArray && lens.optionsArray.find(o => o.selectType === coatingType)
  if (!foundOption || !foundOption.options) {
    return '---'
  }
  const option = foundOption.options.find(({ _id, originalId }) => {
    const compareValue = coatingType === lensOptionsSelectTypes.CTO ? originalId : _id
    return compareValue === fieldValue
  })
  if (!option || !option.names || !option.names[0]) {
    return '---'
  }
  const name = option.names[0].long
  return name
}

const hasValuesDifferentSign = (first, second) => {
  const firstNumber = parseFloat(first)
  const secondNumber = parseFloat(second)
  return (firstNumber > 0 && secondNumber < 0) || (firstNumber < 0 && secondNumber > 0)
}

const spheresHaveDifferentSignsWarningMessageKey = 'WARNING MESSAGE - spheres have different signs'
const cylindersHaveDifferentSignsWarningMessageKey =
  'WARNING MESSAGE - cylinders have different signs'
const lensesAreDifferentWarningMessageKey = 'WARNING MESSAGE - lenses are different'
const differentAdditionWarningMessageKey =
  'WARNING MESSAGE - additions are different'

const orderWarningMessagesKeys = {
  sphereWarning: spheresHaveDifferentSignsWarningMessageKey,
  cylinderWarning: cylindersHaveDifferentSignsWarningMessageKey,
  lensWaning: lensesAreDifferentWarningMessageKey,
  differentAdditionWarning: differentAdditionWarningMessageKey,
}

const areValuesDifferent = (first, second) => {
  try {
    const firstNumber = parseFloat(first)
    const secondNumber = parseFloat(second)
    if (isNaN(firstNumber) || isNaN(secondNumber)) return false
    return firstNumber !== secondNumber
  } catch (error) {}
}

export const getOrderFormWarnings = (values = {}) => {
  const {
    sphereR,
    sphereL,
    cylinderR,
    cylinderL,
    lensR,
    lensL,
    additionR,
    additionL,
    rightLensEnabled,
    leftLensEnabled,
  } = values
  if (!rightLensEnabled || !leftLensEnabled) return {}
  const result = {
    shouldShowSphereWarning: hasValuesDifferentSign(sphereR, sphereL),
    shouldShowCylinderWarning: hasValuesDifferentSign(cylinderR, cylinderL),
    shouldShowDifferentLensesWarning: !!lensR && !!lensL && lensR !== lensL,
    shouldShowDifferentAdditionWarning: areValuesDifferent(
      rightLensEnabled && additionR,
      leftLensEnabled && additionL,
    ),
    ...orderWarningMessagesKeys,
  }
  result.shouldShowWarning = Object.keys(result).some(key => result[key] === true)
  const messagesKeysToShow = []
  if (result.shouldShowSphereWarning) {
    messagesKeysToShow.push(orderWarningMessagesKeys.sphereWarning)
  }
  if (result.shouldShowCylinderWarning) {
    messagesKeysToShow.push(orderWarningMessagesKeys.cylinderWarning)
  }
  if (result.shouldShowDifferentLensesWarning) {
    messagesKeysToShow.push(orderWarningMessagesKeys.lensWaning)
  }
  if (result.shouldShowDifferentAdditionWarning) {
    messagesKeysToShow.push(orderWarningMessagesKeys.differentAdditionWarning)
  }

  result.messagesKeysToShow = messagesKeysToShow
  return result
}

// this fields can cause false dirty blame, because they can change on form init
const keysToIgnoreInDirtTest = ['ctoR', 'ctoL', 'isCtoCapableR', 'isCtoCapableL', 'shapeData']
const getIsFieldDirty = (fieldKey, currentValue, savedValue) => {
  if (keysToIgnoreInDirtTest.includes(fieldKey)) return false
  if (savedValue && typeof savedValue === 'object') {
    // eslint-disable-next-line eqeqeq
    return savedValue._id != currentValue && (!!savedValue._id || !!currentValue)
  }
  // eslint-disable-next-line eqeqeq
  return currentValue != savedValue && (!!currentValue || !!savedValue)
}

export const getAreOrderFieldsDirty = ({ fieldsValues, currentOrder }) =>
  !currentOrder ||
  Object.keys(fieldsValues).some(fieldKey => {
    const fieldValue = fieldsValues[fieldKey]

    // if key is not in schema, then ignore
    if (!orderSchema[fieldKey] && !vcaSchema[fieldKey] && !frameSchema[fieldKey]) return false
    // ignore calculated diameters
    if (['diameterPhysicalR', 'diameterPhysicalL'].includes(fieldKey) && !fieldValue) return false

    const savedValue = currentOrder[fieldKey]
    return getIsFieldDirty(fieldKey, fieldValue, savedValue) // eslint-disable-line
  })

export const isSphOrCylFieldInHundredths = fields =>
  fields.sphereR.value % 0.25 !== 0 ||
  fields.cylinderR.value % 0.25 !== 0 ||
  fields.sphereL.value % 0.25 !== 0 ||
  fields.cylinderL.value % 0.25 !== 0

export const getBothLensFields = (fields, fieldName) => ({
  r: fields[`${fieldName}R`],
  l: fields[`${fieldName}L`],
})
