import { appTypeConfig } from './config'
import { fieldsConfig } from './schemaConfig'

const { individualMenus } = appTypeConfig

export const MUST_BE_BETWEEN = 'must be between'
export const AND = 'and'
export const IS_REQUIRED = 'is required'
export const FEMALE = 'female'
export const MALE = 'male'

export const separate = (strings, ...args) => args

export const isRequiredInIndividualMenu = (individualMenu, field) => {
  const individualConf = individualMenus[individualMenu]
  if (!individualConf) return false
  const fieldConfig = individualConf[field]
  if (typeof fieldConfig === 'object') {
    return fieldConfig.isRequired
  }
  return fieldConfig === true
}

export const isShownInIndividualMenu = (individualMenu, field) => {
  const individualConf = individualMenus[individualMenu]

  if (!individualConf) return false
  // TODO sjednotit
  if (appTypeConfig.APP_TYPE === 'reize') {
    return typeof individualConf[field] !== 'undefined'
  }
  return individualConf[field] !== false
}

export const checkCondition = (condition, data) => {
  if (!condition) return false
  if (typeof condition !== 'function') return true
  return condition(data)
}

export const bothSidesValidator = (validator, { leftLensEnabled, rightLensEnabled }) => (
  func,
  field,
  ...rest
) => {
  if (typeof rightLensEnabled === 'undefined' || rightLensEnabled) validator[func](`${field}R`, ...rest)
  if (typeof leftLensEnabled === 'undefined' || leftLensEnabled) validator[func](`${field}L`, ...rest)
}

const genericValidation = ({
  specification,
  data,
  requiredFunction,
  inRangeFunction,
  validateIndividualOptions,
  individualMenu,
}) => {
  Object.keys(specification).forEach(key => {
    const field = specification[key]
    const fieldName = key
    const translationKey = field.translationKey || fieldName

    const individualMenuConf = individualMenus[individualMenu] || {}
    const individualMenuFieldConf = individualMenuConf[fieldName] || {}

    if (checkCondition(field.isRequired, data)) {
      requiredFunction(fieldName, translationKey, field.context)
    }
    // check individual menu options
    if (individualMenuFieldConf.options) {
      validateIndividualOptions(
        fieldName,
        translationKey,
        individualMenuFieldConf.options.map(o => o.value || o),
        field.context,
      )
    }
    if (checkCondition(field.checkRange, data)) {
      const { min: defaultMin, max: defaultMax } = fieldsConfig[fieldName]

      // check min max in individual menu config
      const { min: individualMin, max: individualMax } = individualMenuFieldConf

      const min = typeof individualMin !== 'undefined' ? individualMin : defaultMin
      const max = typeof individualMax !== 'undefined' ? individualMax : defaultMax

      inRangeFunction(fieldName, translationKey, { min, max }, field.context)
    }
  })
}

export const pairValidation = ({
  validator,
  specification,
  data,
  leftLensEnabled,
  rightLensEnabled,
  individualMenu,
}) => {
  const bothSides = bothSidesValidator(validator, { leftLensEnabled, rightLensEnabled })
  const bothSidesRequired = (field, translationKey, context) =>
    bothSides('isRequired', field, {
      keys: separate`${translationKey || field}${IS_REQUIRED}`,
      args: { context },
    })

  genericValidation({
    specification,
    data,
    individualMenu,
    requiredFunction: (fieldName, translationKey, context) =>
      bothSidesRequired(fieldName, translationKey, context),
    inRangeFunction: (fieldName, translationKey, { min, max }, context) =>
      bothSides(
        'isFloatInRange',
        fieldName,
        { min, max },
        {
          keys: separate`${translationKey}${MUST_BE_BETWEEN}${min}${AND}${max}`,
          args: { context },
        },
      ),
  })
}

export const validation = ({ validator, specification, data, individualMenu }) => {
  genericValidation({
    specification,
    individualMenu,
    data,
    requiredFunction: (fieldName, translationKey, context) =>
      validator.isRequired(fieldName, {
        keys: separate`${translationKey}${IS_REQUIRED}`,
        args: { context },
      }),

    validateIndividualOptions: (fieldName, translationKey, options, context) =>
      validator.isInOptions(fieldName, options, {
        keys: separate`${translationKey}${IS_REQUIRED}`,
        args: { context },
      }),

    inRangeFunction: (fieldName, translationKey, { min, max }, context) =>
      validator.isFloatInRange(
        fieldName,
        { min, max },
        {
          keys: separate`${translationKey}${MUST_BE_BETWEEN}${min}${AND}${max}`,
          args: { context },
        },
      ),
  })
}

export const validateRange = ({ validator, fieldName, min, max, translationKey, context }) => {
  validator.isFloatInRange(
    fieldName,
    { min, max },
    {
      keys: separate`${translationKey}${MUST_BE_BETWEEN}${min}${AND}${max}`,
      args: { context },
    },
  )
}

export const validateRequired = ({ validator, fieldName, translationKey, context }) => {
  validator.isRequired(fieldName, {
    keys: separate`${translationKey}${IS_REQUIRED}`,
    args: { context },
  })
}

// workaround validation for firefox when  input type=number
export const checkNumbersValidity = ({ dataToValidate, validator }) => {
  try {
    if (document) {
      Object.keys(dataToValidate).forEach(key => {
        const e = document.getElementById(key)
        // if there is invalid number in input, value is always empty :(
        if (e && e.type === 'number' && e.checkValidity() === false) {
          const keyWithoutSide =
            key.endsWith('R') || key.endsWith('L') ? key.substring(0, key.length - 1) : key
          validator.isRequiredValidNumber(key, { keys: [keyWithoutSide, 'must be a number'] })
        }
      })
    }
  } catch (error) {
    // throws error on server, because document is undefined
  }
}
