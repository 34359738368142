import React from 'react'

import styled, { css } from 'styled-components'

const StyledWrapper = styled.div`
  ${({ grow, flexBasis }) => css`
    text-align: left;
    flex-grow: ${grow};
    flex-basis: ${flexBasis};
  `}
`

const Wrapper = props => <StyledWrapper {...props} />

export default Wrapper
