import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import styled from 'styled-components'
import { R, L, appTypeConfig, orderTypes } from '../../../common/config'
import { Col, Row } from '..'
import { SelectField } from '../fields'
import { getValidBaseCurvesOptions, getHasLensBaseCurve } from '../../../common/basecurve'
import { getLensFieldsValuesBySide } from '../../../common/helpers'
import { getConstants } from '../../../common/schemaConfig'
import { withNotifs } from '../hoc'
import { getEnhancedFieldForLensParameter } from '../../app/helpers'
import CheckboxField from '../fields/CheckboxField'
import { TinyPopTooltip } from '../Tooltip'

const lensFields = getConstants()

const SideLabel = styled(Col)`
  align-self: flex-end;
  margin-bottom: 0.7rem;
  margin-right: 1rem;
  width: 0.8rem;
`

const getBaseCurveOptions = ({
  forcedSide,
  side,
  fields,
  currentLensL,
  currentLensR,
  autoSelectedDiameterR,
  autoSelectedDiameterL,
  selectedVca,
  t,
}) => {
  if (forcedSide) side = forcedSide
  const currentLens = side === L ? currentLensL || {} : currentLensR || {}
  const autoSelectedDiameter = side === L ? autoSelectedDiameterL : autoSelectedDiameterR

  if (!currentLens.baseCurves || currentLens.baseCurves?.length === 0) return false

  const fieldsSideValues = getLensFieldsValuesBySide(fields)[side]
  // use auto selected diameter if available
  Object.assign(fieldsSideValues, autoSelectedDiameter)

  // todo - upravit věechny getValidBaseCurves volání
  const baseCurves = getValidBaseCurvesOptions({
    fieldValues: fields.$values(),
    selectedVca,
    side,
    lensR: currentLensR,
    lensL: currentLensL,
  })

  const baseCurveOptions =
    baseCurves?.map(baseCurve => ({ value: baseCurve, label: `${t('Base')} ${baseCurve}` })) || []

  return baseCurveOptions.length > 0
    ? [{ label: t('Standard'), value: null }, ...baseCurveOptions]
    : false
}

// todo - co když se změní čočka, nebo diameter, pořešit
const BaseCurveSelect = ({
  side,
  noLabel = false,
  fields,
  currentLensR,
  currentLensL,
  notifs,
  selectedVca,
  disabled,
}) => {
  const { t } = useTranslation()
  if (!appTypeConfig.isBaseCurveSelectEnabled) return null
  // todo - co když se změní čočka, nebo diameter, pořešit
  const baseCurvesParams = {
    side,
    fields,
    currentLensL,
    currentLensR,
    selectedVca,
    t,
  }

  const currentLens = side === L ? currentLensL || {} : currentLensR || {}
  if (!disabled && (!currentLens.baseCurves || currentLens.baseCurves?.length === 0)) return null

  const baseCurveOptions = getBaseCurveOptions(baseCurvesParams) || []

  const baseCurveField = getEnhancedFieldForLensParameter({
    fieldName: lensFields.baseCurve,
    fields,
    side,
  })

  const isShapeMissing = fields.orderType.value !== orderTypes.DIAMETER_ONLY && !selectedVca

  const TooltipWrapper = ({ children }) =>
    isShapeMissing
      ? <TinyPopTooltip text={t('basecurve can be selected at Step 2')}>{children}</TinyPopTooltip>
      : <>{children}</>

  const baseCurveOnChange = ({ value }) => {
    if (side === R && fields.isLeftLensSameAsRight.value) {
      const leftSideOptions = getBaseCurveOptions({ forcedSide: L, ...baseCurvesParams })
      if (leftSideOptions.some(({ value: optionValue }) => optionValue === value)) {
        baseCurveField.onChange({ value })
      } else {
        fields[lensFields.baseCurve + R].onChange({ value })
        notifs.warning({
          title: t('Selected basecurve is not available for left eye!'),
        })
        fields.isLeftLensSameAsRight.onChange({ value: false })
      }
    } else {
      baseCurveField.onChange({ value })
    }
  }

  return (
    <Col>
      <TooltipWrapper>
        <SelectField
          label={!noLabel ? t('basecurve') : ''}
          options={baseCurveOptions}
          {...baseCurveField}
          onChange={baseCurveOnChange}
          disabled={disabled || isShapeMissing}
          isValueHidden={disabled || isShapeMissing}
        />
      </TooltipWrapper>
    </Col>
  )
}

BaseCurveSelect.defaultProps = {
  currentLensR: {},
  currentLensL: {},
  selectedVca: undefined,
}

BaseCurveSelect.propTypes = {
  fields: PropTypes.object.isRequired,
  notifs: PropTypes.object.isRequired,
  side: PropTypes.oneOf([R, L]).isRequired,
  currentLensR: PropTypes.object,
  currentLensL: PropTypes.object,
  selectedVca: PropTypes.object,
}

const enhance = compose(
  connect(
    ({ catalog, vca }) => ({
      currentLensR: catalog.currentLensR,
      currentLensL: catalog.currentLensL,
      selectedVca: vca.selectedVca,
    }),
    {},
  ),
  withNotifs,
)

const EnhancedBaseCurveSelect = enhance(BaseCurveSelect)

const BothSides = ({ fields, currentLensR, currentLensL }) => {
  const { t } = useTranslation()
  const fieldValues = fields.$values()
  if (!getHasLensBaseCurve(currentLensR) && !getHasLensBaseCurve(currentLensL)) return null
  return (
    <Col width="17rem">
      <Row compact width="100%">
        <SideLabel>R</SideLabel>
        <Col width="100%">
          <EnhancedBaseCurveSelect
            fields={fields}
            side="R"
            disabled={!fieldValues.rightLensEnabled}
          />
        </Col>
      </Row>
      <Row compact>
        <SideLabel>L</SideLabel>
        {fieldValues.isLeftLensSameAsRight && fieldValues.leftLensEnabled ? (
          <Col>
            <CheckboxField label={t('same as right')} {...fields.isLeftLensSameAsRight} />
          </Col>
        ) : (
          <Col width="100%">
            <EnhancedBaseCurveSelect
              noLabel
              fields={fields}
              side="L"
              disabled={!fieldValues.leftLensEnabled}
            />
          </Col>
        )}
      </Row>
    </Col>
  )
}

EnhancedBaseCurveSelect.BothSides = BothSides

export default EnhancedBaseCurveSelect
