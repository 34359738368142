import React, { Component } from 'react'
import { ReactNotifications } from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import 'animate.css'

import styled, { css } from 'styled-components'
import { HiddenInPrint } from '.'
import { removeNotif } from '../../common/lib/notifs'
import CloseIcon from './CloseIcon'
import Text from './Text'
import Button from './Button'
import Gap, { FixedGap } from './Gap'
import BasicCol from './BasicCol'


const StyledReactNotifications = styled(ReactNotifications)`
  --animate-duration: 0.4s;

  .rnc__notification {
    transition-duration: 200ms !important;

    & > div {
      opacity: 0;
    }

    .animate__animated {
      opacity: 1;
    }
    .rnc__notification-item {
      cursor: initial;
      border-radius: 8px;
      transition: 0.5s;

      box-shadow: 0px 1px 9px 2px rgb(0 0 0 / 20%);
      &:hover {
        box-shadow: 0px 1px 11px 2px rgb(0 0 0 / 25%);
      }

      background-color: rgba(255, 255, 255, 1);
      @supports (backdrop-filter: none) or (-webkit-backdrop-filter: none) {
        background: rgba(255, 255, 255, 0.7);
        /* background: rgba(255, 255, 255, 1); */
        backdrop-filter: blur(9px);
      }
    }
  }
`

const CloseIconWrapper = styled.div`
  position: absolute;
  top: -0.9rem;
  left: -0.9rem;
  border-radius: 100px;
  background: white;
  box-shadow: 0px 0px 4px 1px rgb(0 0 0 / 20%);
  transition: 0.3s;

  &:hover {
    box-shadow: 0px 0px 5px 2px rgb(0 0 0 / 20%);
  }
  opacity: 0;
`

const NotifWrapper = styled.div`
  padding: 16px;
  width: 100%;

  &:hover {
    ${CloseIconWrapper} {
      opacity: 1;
    }
  }
`

const getLevelColor = (level, colors) => {
  switch (level) {
    case 'danger':
      return colors.darkRed
    case 'success':
      return colors.green
    case 'warning':
      return colors.orange
    case 'info':
      return colors.blue
    default:
      return 'black'
  }
}

const Title = styled.div`
  ${({ theme: { colors }, level }) => css`
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 1.8rem;
    color: black;
    color: ${getLevelColor(level, colors)};
  `}
`

function Notifications(props) {
  return (
    <HiddenInPrint id="notification-hidden">
      <StyledReactNotifications />
    </HiddenInPrint>
  )
}

export default Notifications

export const NotificationContent = ({ id, notificationConfig, ...props }) => {
  const { title, message, action = {}, level } = notificationConfig
  const { label, callback } = action

  return (
    <NotifWrapper>
      <CloseIconWrapper
        onClick={() => {
          removeNotif(id)
        }}
      >
        <CloseIcon justIcon wrapperSize={2.4} size={1.4} />
      </CloseIconWrapper>
      <Title level={level}>{title}</Title>
      <FixedGap gap="0.4rem" />
      <Text>{message}</Text>
      {callback && (
        <BasicCol alignItems="flex-start">
          <FixedGap gap="0.8rem" />
          <Button
            noShadow
            noHoverShadow
            onClick={() => {
              callback()
              removeNotif(id)
            }}
          >
            {label}
          </Button>
        </BasicCol>
      )}
    </NotifWrapper>
  )
}
