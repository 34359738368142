import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Tooltip from '../../Tooltip'

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  padding-bottom: 0.5rem;
`

const StyledLabel = styled.label`
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.6rem;
  /* letter-spacing: 0.0771429rem; */
  margin: 0;
  color: ${({ theme }) => theme.field.labelColor};
`

const RequiredSymbol = styled.span`
  margin-left: 0.1rem;
  color: red;
  align-self: flex-start;
  line-height: 1.6rem;
`

const TooltipWrapper = styled.div`
  margin-left: 0.2rem;
  transform: translate(0px, 2px);
`

const Label = ({ required, children, tooltip, tooltipDown, hideRequiredStar, ...props }) => (
  <Container>
    <StyledLabel {...props}>{children}</StyledLabel>
    {required && !hideRequiredStar && <RequiredSymbol>*</RequiredSymbol>}
    <TooltipWrapper>{tooltip && <Tooltip text={tooltip} down={tooltipDown} />}</TooltipWrapper>
  </Container>
)

Label.propTypes = {
  children: PropTypes.any.isRequired,
  required: PropTypes.bool,
  tooltip: PropTypes.node,
}

Label.defaultProps = {
  required: false,
  tooltip: null,
}

export default styled(Label)``
