import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { compose } from 'recompose'
import { components as selectComponents } from 'react-select'
import { withTranslation, useTranslation } from 'react-i18next'
import { fetchLens } from '../../../common/catalog/actions'
import { Button, Row, Col } from '..'
import { SelectField } from '.'
import { showPopup } from '../../../common/popups/actions'
import { R, L, BOTH, appTypeConfig } from '../../../common/config'
import { filterLensByNameFunction, fullNormalizeForSearch } from '../../../common/lib/text'
import { PopTooltip } from '../Tooltip'
import {
  getCompatibleFrameBrands,
  getIsLensDisabledWithoutEvo,
} from '../../../common/catalogExtensions'
import { BrandsIconsList } from '../order/FrameBrandIcons'
import { SelectValue } from './components/SelectComponents'

const DropDownMenu = styled.div`
  position: absolute;
  z-index: 10;
  width: 100%;
  border: ${({ theme }) => theme.field.borderWidth} solid ${({ theme }) => theme.field.borderColor};
  transform: translate(0, 0.5rem);
  background-color: white;
  border-radius: 0.8rem;
  /* box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2); */
  box-shadow: 0px 2px 20px rgb(0 0 0 / 26%);
  overflow: hidden;
`

const HelpText = styled.p`
  font-size: 1.2rem;
  color: ${({ theme }) => theme.colors.text3};
`

const Catalog = styled.div`
  min-height: 4rem;
  padding: 0 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.lensSelectCatalogBackground};
`

const showCatalogPopup = ({
  showPopup,
  side,
  onChange,
  value = null,
  disableShapeMandatoryLenses,
  miLensId,
}) =>
  showPopup('catalog', {
    side,
    onLensSelect: lensId => onChange({ value: lensId }),
    selectedId: value,
    disableShapeMandatoryLenses,
    miLensId,
  })

const OptionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`
const BrandIconWrapper = styled.div`
  font-size: 12px;
  padding-left: 6px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    max-height: 20px;
    width: auto;
  }
`

const getTooltipText = data => {
  if (data?.isAvailableOnlyWithShape && data?.isDisabled)
    return 'Available only with specified shape'
  if (data?.isDisabled && data?.disabledReasonText) return data?.disabledReasonText
  return ''
}

const CustomOptionComponent = ({ children, ...rest }) => {
  const { t } = useTranslation()

  const tooltipText = getTooltipText(rest.data)
  const item = tooltipText ? <PopTooltip text={t(tooltipText)}>{children}</PopTooltip> : children

  const compatibleFrameBrands = rest?.data?.compatibleFrameBrands

  return (
    <selectComponents.Option {...rest}>
      <OptionWrapper>
        <div>{item}</div>
        <BrandsIconsList brands={compatibleFrameBrands} />
      </OptionWrapper>
    </selectComponents.Option>
  )
}

const CustomMenuComponent = ({
  innerProps,
  children,
  showPopup,
  selectProps: { side, onChange, value, disableShapeMandatoryLenses, fieldValues },
  t,
}) => (
  <DropDownMenu {...innerProps}>
    {children}
    <Catalog>
      <Row alignItems="center">
        <Col marginRight="1rem" paddingRight="0">
          <HelpText>{t('OPEN_CATALOG_DESCRIPTION')}</HelpText>
        </Col>
        <Col marginRight="0" paddingRight="0">
          <Button
            primary
            onClick={() =>
              showCatalogPopup({
                showPopup,
                side,
                onChange,
                value,
                disableShapeMandatoryLenses,
                miLensId: fieldValues?.miLensId,
              })
            }
          >
            {t('catalog')}
          </Button>
        </Col>
      </Row>
    </Catalog>
  </DropDownMenu>
)
CustomMenuComponent.propTypes = {
  children: PropTypes.any.isRequired,
  innerProps: PropTypes.object.isRequired,
  showPopup: PropTypes.func.isRequired,
  selectProps: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
}
const CustomMenu = compose(
  connect(() => ({}), {
    showPopup,
  }),
  withTranslation(),
)(CustomMenuComponent)

const transformLens = ({ lens, disableShapeMandatoryLenses, catalogExtensions, fieldValues }) => {
  const compatibleFrameBrands = getCompatibleFrameBrands({ lens, catalogExtensions })

  const result = {
    value: lens._id,
    label: lens.names[0].long,
    search: lens.names[0].search,
    isAvailableOnlyWithShape: lens.isAvailableOnlyWithShape,
    isDisabled: lens.isAvailableOnlyWithShape && disableShapeMandatoryLenses,
  }

  if (!fieldValues?.miLensId && getIsLensDisabledWithoutEvo({ lens, catalogExtensions })) {
    result.isDisabled = true
    result.disabledReasonText = 'DISABLED_WITHOUT_EVO catalog message'
  }

  if (compatibleFrameBrands) result.compatibleFrameBrands = compatibleFrameBrands
  return result
}

const LensSelectField = ({
  lensesR,
  lensesL,
  isLoadingL,
  isLoadingR,
  onChange,
  fetchLens,
  side,
  isLeftLensSameAsRight,
  t,
  selectedLensName,
  showPopup,
  onFocus,
  disableShapeMandatoryLenses,
  catalogExtensions,
  fieldValues,
  ...props
}) => {
  const isLoading = isLeftLensSameAsRight
    ? isLoadingL || isLoadingR
    : side === L
    ? isLoadingL
    : isLoadingR
  const lenses = side === L ? lensesL : lensesR

  const transformLensConf = {
    disableShapeMandatoryLenses,
    catalogExtensions,
    fieldValues,
  }

  let options = []
  if (lenses.some(l => l.orderFrequency > 0)) {
    const frequentlyOrdered = []
    const other = []
    lenses.forEach((l, key) => {
      if (l.orderFrequency > 0 && key < 10) {
        frequentlyOrdered.push(transformLens({ lens: l, ...transformLensConf }))
      } else {
        other.push(transformLens({ lens: l, ...transformLensConf }))
      }
    })
    options = [
      {
        label: t('frequently ordered'),
        options: frequentlyOrdered,
      },
      {
        label: t('other'),
        options: other,
      },
    ]
  } else {
    options = lenses.map(l => transformLens({ lens: l, ...transformLensConf }))
  }
  if (options.length === 0 && props.value && selectedLensName) {
    options = [{ value: props.value, label: selectedLensName }]
  }

  const onLensSelectChange = ({ value }) => {
    if (!value || (value && value.length === 0)) return
    const fetchSide = isLeftLensSameAsRight && side === R ? BOTH : side
    fetchLens(value, fetchSide)
    onChange({ value })
  }

  const onCatalogLensSelected = response => {
    onLensSelectChange(response)
    props.onBlur()
  }

  return (
    <Row alignItems="flex-end">
      <Col grow="1" compact>
        <SelectField
          label={t('lens type')}
          options={!isLoading ? options : []}
          isLoading={isLoading}
          onChange={onLensSelectChange}
          side={side}
          components={{ Menu: CustomMenu, Option: CustomOptionComponent, SingleValue: SelectValue }}
          filterOption={filterLensByNameFunction}
          disableShapeMandatoryLenses={disableShapeMandatoryLenses}
          onFocus={onFocus}
          fieldValues={fieldValues}
          {...props}
        />
      </Col>
      <Col>
        <Button
          primary
          onClick={() => {
            onFocus()
            showCatalogPopup({
              showPopup,
              side,
              onChange: onCatalogLensSelected,
              value: props.value,
              disableShapeMandatoryLenses,
              miLensId: fieldValues?.miLensId,
            })
          }}
        >
          {t('catalog')}
        </Button>
      </Col>
    </Row>
  )
}

LensSelectField.defaultProps = {
  lensesR: [],
  lensesL: [],
  isLoadingR: false,
  isLoadingL: false,
  side: R,
  isLeftLensSameAsRight: false,
  onBlur: null,
  selectedLensName: '',
  value: '',
  disableShapeMandatoryLenses: false,
}

LensSelectField.propTypes = {
  fetchLens: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  lensesR: PropTypes.array,
  lensesL: PropTypes.array,
  isLoadingR: PropTypes.bool,
  isLoadingL: PropTypes.bool,
  side: PropTypes.string,
  isLeftLensSameAsRight: PropTypes.bool,
  t: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  value: PropTypes.string,
  selectedLensName: PropTypes.string,
  showPopup: PropTypes.func.isRequired,
  onFocus: PropTypes.func.isRequired,
  disableShapeMandatoryLenses: PropTypes.bool,
}

const enhance = compose(
  connect(
    ({ catalog }) => ({
      lensesR: catalog.lensesListR,
      lensesL: catalog.lensesListL,
      isLoadingR: catalog.lensesListIsLoadingR,
      isLoadingL: catalog.lensesListIsLoadingL,
      catalogExtensions: catalog.catalogExtensions,
    }),
    {
      fetchLens,
      showPopup,
    },
  ),
  withTranslation(),
)

export default enhance(LensSelectField)
