import React from 'react'

const formValidation = options => WrappedForm =>
  class Form extends React.PureComponent {
    constructor() {
      super()
      this.validate = this.validate.bind(this)
      this.submit = this.submit.bind(this)
      this.state = {
        errors: [],
        submitting: false,
        submitSuccessfull: false,
        submitFailed: false,
        validating: false,
        validationSuccessfull: false,
        validationFailed: false,
      }
    }

    setErrors(errors) {
      this.setState({
        errors,
      })
    }

    validate(data) {
      const { validationFunc } = options(this.props)
      const errors = validationFunc({ dataToValidate: data, allData: data, props: this.props })
      this.setErrors(errors)
      return errors.length === 0
    }

    async submit(data, submitFunction) {
      return new Promise(async (resolve, reject) => {
        const submitFunc = submitFunction || options(this.props).submitFunc
        this.setState({
          submitting: false,
          submitSuccessfull: false,
          submitFailed: false,
          validating: false,
          validationSuccessfull: false,
          validationFailed: false,
        })
        try {
          this.setState({ validating: true })
          if (this.validate(data)) {
            this.setState({ validating: false, validationSuccessfull: true, submitting: true })
            const submitData = submitFunc(data)
            // toto se může lišit v závislosti na struktuře akcí
            if (
              submitData &&
              submitData.meta &&
              submitData.meta.action &&
              submitData.meta.action.payload
            ) {
              const response = await submitData.meta.action.payload
              this.setState({ submitting: false, submitSuccessfull: true })
              resolve(true, response)
            }
            resolve(true)
          }
          this.setState({ validating: false, validationFailed: true })
          return resolve(false)
        } catch (error) {
          let { errors } = error
          if (!errors && error.message) errors = [error]
          else if (!errors && error.code === 404) errors = [{ error: 'Neexistuje' }]
          if (!Array.isArray(errors)) errors = [{ message: errors }]
          this.setErrors(errors)
          this.setState({ submitting: false, submitFailed: true })
          reject(errors)
        }
      })
    }

    render() {
      let errorMessage = ''
      const errors = this.state && this.state.errors
      if (errors && errors.length) {
        errorMessage = (
          <div className="message negative">
            {errors.length > 0 ? <h3>Errors</h3> : ''}
            <ul>
              {errors.map((error, i) =>
                <li key={i}>{error.message}</li>)}
            </ul>
          </div>
        )
      }
      return (
        <WrappedForm
          {...this.props}
          errorMessage={errorMessage}
          handleServerErrors={this.handleServerErrors}
          submit={this.submit}
          {...this.state}
        />
      )
    }
  }

export default formValidation
