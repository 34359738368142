import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import styled from 'styled-components'
import Drawer from '../vca/Drawer'
import PopupPresentational from './PopupPresentational'
import {
  saveSelectedVcaDeformed,
  resetSelectedVcaDeformed,
  saveDrawingVca,
  setDrawingVca,
} from '../../../common/vca/actions'

// eslint-disable-next-line
class DrawingPopup extends Component {
  componentDidMount() {
    const { vca, setDrawingVca } = this.props
    setDrawingVca()
    if (!vca) {
      setDrawingVca()
    }
  }

  close = ({ ok } = {}) => {
    // do anything you want on close
    const {
      saveSelectedVcaDeformed,
      resetSelectedVcaDeformed,
      saveDrawingVca,
      tablet,
      setDrawingVca,
    } = this.props

    if (ok) {
      saveDrawingVca({ ...tablet })
    } else {
      setDrawingVca(false)
    }

    // finally hide the popup itself
    this.props.close()
  }

  render() {
    const { t } = this.props
    return (
      <PopupPresentational
        title={t('draw shape')}
        close={this.close}
        type="big"
        okText={t('save shape')}
        cancelPopup
        disableAnimation
      >
        <Drawer />
      </PopupPresentational>
    )
  }
}

DrawingPopup.defaultProps = {}

DrawingPopup.propTypes = {
  close: PropTypes.func.isRequired, // is injected by PopupsGate
  saveDrawingVca: PropTypes.func.isRequired,
  // resetSelectedVcaDeformed: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  tablet: PropTypes.object.isRequired,
}
const enhance = compose(
  connect(
    state => ({
      tablet: state.config.tablet,
      vca: state.vca.drawingVca,
    }),
    {
      saveSelectedVcaDeformed,
      resetSelectedVcaDeformed,
      saveDrawingVca,
      setDrawingVca,
    },
  ),
  withTranslation(),
)

export default enhance(DrawingPopup)
