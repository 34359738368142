import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const Container = styled.div`
  position: relative;
  display: inline-block;
`

const Content = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -110%);
  visibility: hidden;

  padding: 0.5rem;
  border-radius: 0.8rem;
  text-align: center;
  font-size: 1.2rem;
  background-color: #333;
  color: #fff;

  ${Container}:hover & {
    visibility: visible;
  }
`

const Popover = ({ content, children }) => (
  <Container>
    {children}
    <Content>{content}</Content>
  </Container>
)

Popover.propTypes = {
  content: PropTypes.any.isRequired,
  children: PropTypes.any.isRequired,
}

export default Popover
