import React, { Fragment } from 'react'
import styled, { css } from 'styled-components'
import { nanoid } from 'nanoid'
import moment from 'moment'
import PropTypes from 'prop-types'
import { withTranslation, useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { compose } from 'recompose'

import { Wrapper as PopoverWrapper, Item as PopoverItem } from './PopoverPresentational'
import PopoverMenu from './PopoverMenu'
import { Link } from '../Link'

import { fetchNews, readNews } from '../../../common/app/actions'
import { T } from '..'
import { addIoListener } from '../../io'
import { showPopup } from '../../../common/popups/actions'
import { appTypeConfig } from '../../../common/config'

const Wrapper = styled(PopoverWrapper)`
  width: 40rem;
  max-width: 40rem;
  top: 1.3rem;
`

const ToRightWrapper = styled(Wrapper)`
  left: 0;
`

const Item = styled(PopoverItem)`
  text-align: 'left';
  font-size: 1.4rem;
  line-height: 2.4rem;
  padding: 2rem;
  color: ${p => p.placeholder && 'gray'};
  text-align: ${p => p.placeholder && 'center'};
  white-space: normal;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &:last-child {
    padding-bottom: 2rem;
  }
`
const ItemTitle = styled.div`
  ${({ theme: { colors } }) => css`
    color: ${colors.text2};
    text-align: 'left';
    font-size: 1.6rem;
    font-weight: 600;
    padding-bottom: 0.4rem;
  `}
`
const ItemLink = styled.a`
  ${({ theme: { colors } }) => css`
    display: block;
    text-align: 'left';
    font-size: 1.4rem;
    font-weight: 600;
    width: auto;
    padding-top: 0.8rem;
    color: ${colors.primary};
    text-decoration: none;

    &:hover,
    &:active {
      text-decoration: none;
      color: ${colors.primaryDark};
    }
  `}
`

class NewsPopoverMenu extends React.Component {
  nodeId = `news-popover-${nanoid()}`

  refreshTimeout = null

  componentDidMount() {
    const { fetchNews } = this.props
    fetchNews()
  }

  renderItem = item => {
    const { t } = this.props

    return (
      <Item placeholder={item.placeholder} unclickable key={item._id}>
        <ItemTitle>{item.title}</ItemTitle>
        {item.text.split('\n').map((chunk, index) => (
          <Fragment key={index}>
            {index !== 0 && <br />}
            {chunk}
          </Fragment>
        ))}
        {item.link && (
          <ItemLink rel="noreferrer" href={item.link} target="_blank">
            {t('Find out more!')}
          </ItemLink>
        )}
      </Item>
    )
  }

  handleNewsPopup= ({ news, showFlag }) => {
    if (!showFlag) return

    const { showPopup, readNews, t } = this.props
    showPopup('news', {
      title: t('NEWS POPUP TITLE'),
      okText: 'Ok',
      onConfirm: () => null,
      onClose: () => readNews(),
      news,
    })
  }

  render() {
    const {
      renderLinkComponent,
      fetchNews,
      readNews,
      isToRight,
      t,
      i18n: { language },
    } = this.props
    let { news = [] } = this.props

    if (!Array.isArray(news)) {
      news = []
    }

    news = news
      .filter(n => n.body && n.title)
      .map(n => ({
        ...n,
        text: n.body[language] || n.body.default,
        title: n.title[language] || n.title.default,
        link: n.link[language] || n.link.default,
      }))
      .filter(n => n.text && n.title)

    const showFlag = news.length > 0 && news.some(n => !n.read)

    if (appTypeConfig.isNewsPopupEnabled) {
      this.handleNewsPopup({ news, showFlag})
    }


    if (news.length === 0) {
      news = [
        {
          text: t('no news for now'),
          placeholder: true,
        },
      ]
    }

    return (
      <PopoverMenu
        closeOnOutsideClick
        closeOnEsc
        options={news}
        nodeId={this.nodeId}
        onClose={readNews}
        WrapperComponent={isToRight ? ToRightWrapper : Wrapper}
        renderItem={this.renderItem}
        animationVariant="big"
        animationOrigin={isToRight ? 'left top' : 'right top'}
      >
        {({ open, isOpen, close }) => (
          <div
            onClick={() => {
              if (isOpen) {
                close()
              } else {
                fetchNews()
                open()
              }
            }}
            id={this.nodeId}
          >
            {renderLinkComponent({ isOpen, showFlag })}
          </div>
        )}
      </PopoverMenu>
    )
  }
}
NewsPopoverMenu.defaultProps = {
  isToRight: false,
}

NewsPopoverMenu.propTypes = {
  t: PropTypes.func.isRequired,
  fetchNews: PropTypes.func.isRequired,
  news: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  hostConf: PropTypes.object.isRequired,
  renderLinkComponent: PropTypes.func.isRequired,
  isToRight: PropTypes.bool,
}

const enhance = compose(
  withTranslation(),
  connect(
    ({ app }) => ({
      hostConf: app.hostConf,
      news: app.news,
    }),
    {
      fetchNews,
      readNews,
      showPopup,
    },
  ),
)

export default enhance(NewsPopoverMenu)
