// this our more modern col from current (2021) projects, without responsivity
import React from 'react'
import styled, { css } from 'styled-components'

const BasicCol = styled.div`
  ${({
    justifyContent,
    mobileJustifyContent,
    alignItems,
    alignSelf,
    mobileAlignSelf,
    mobileAlignItems,
    position,
    maxWidth,
    mobileMaxWidth,
    minWidth,
    width,
    height,
    mobileWidth,
    flex,
    grow,
    basis,
    shrink,
    padding,
    margin,
    mobilePadding,
    mobileMargin,
    bp = 'mobile',
    ignoreMinContent,
  }) => css`
    display: flex;
    flex-direction: column;
    min-width: ${ignoreMinContent && 0};
    align-items: ${mobileAlignItems || alignItems};
    justify-content: ${mobileJustifyContent || justifyContent};
    max-width: ${mobileMaxWidth || maxWidth};
    min-width: ${minWidth};
    width: ${mobileWidth || width};
    height: ${height};
    position: ${position};
    flex: ${flex};
    align-self: ${mobileAlignSelf || alignSelf};
    flex-grow: ${grow};
    flex-basis: ${basis};
    flex-shrink: ${shrink};
    padding: ${mobilePadding || padding};
    margin: ${mobileMargin || margin};
  `}
`

export default BasicCol
