import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import Popup from '../popups/Popup'
import { Row, Col } from '..'
import { getOrderFormWarnings } from '../../app/helpers'

const ShowWarningPopupIfNeeded = ({ children, disabled, okText, onConfirm, values, t }) => {
  const { shouldShowWarning, messagesKeysToShow } = getOrderFormWarnings(values)
  // disable confirm function when disabled
  if (disabled) return children({ open: () => null })
  if (!shouldShowWarning) {
    return children({ open: onConfirm })
  }
  const rows = (
    <Row>
      <Col justifyContent="flex-start">
        <ul style={{ textAlign: 'left' }}>
          {messagesKeysToShow.map(key =>
            <li key={key}>{t(key)}</li>)}
        </ul>
      </Col>
    </Row>
  )
  return (
    <Popup
      title={t('Warning')}
      text={rows}
      closeOnOutsideClick
      closeOnEsc
      onClose={result => {
        if (result && result.ok) onConfirm()
      }}
      okText={okText}
      closeText={t('cancel')}
    >
      {children}
    </Popup>
  )
}

ShowWarningPopupIfNeeded.defaultProps = {
  okText: 'Ok',
  disabled: false,
}

ShowWarningPopupIfNeeded.propTypes = {
  children: PropTypes.node.isRequired,
  onConfirm: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  okText: PropTypes.string,
  disabled: PropTypes.bool,
}

export default withTranslation()(ShowWarningPopupIfNeeded)
