import { applyMiddleware, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import configureReducer from './reducer'
import configureMiddleware from './middleware'

const configureStore = () => {
  const reducer = configureReducer()
  let middleware = configureMiddleware()

  if (process.env.IS_BROWSER) {
    middleware = composeWithDevTools(applyMiddleware(...middleware))
  } else {
    middleware = applyMiddleware(...middleware)
  }

  const store = createStore(reducer, {}, middleware)

  // Enable hot reloading for reducers.
  if (module.hot && typeof module.hot.accept === 'function') {
    // Webpack for some reason needs accept with the explicit path.
    module.hot.accept('./reducer', () => {
      const configureReducer = require('./reducer').default

      store.replaceReducer(configureReducer({}))
    })
  }

  return store
}

export default configureStore
