import React from 'react'
import Icon from 'react-icon-base'

const OppositeArrows = props => (
  <Icon viewBox="0 0 32 16" {...props}>
    <path
      d="M1 8H30.5M1 8L8.5 1M1 8L8.5 15M30.5 8L23.5 1M30.5 8L23.5 15"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
)

export default OppositeArrows
