import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

export const textStyle = css`
  ${({ theme, color, bold, lineHeight = 'normal', margin, opacity }) => css`
    font-family: 'Roboto', sans-serif;
    font-weight: ${bold ? 700 : 400};
    color: ${color || theme.colors.text2};
    line-height: ${lineHeight};
    font-size: 1.4rem;
    font-size: ${({ size }) => size};
    font-size: ${({ big }) => big && '1.8rem'};
    height: ${({ height }) => height};
    /* letter-spacing: 0.0771429rem; */
    text-align: left;
    text-align: ${({ center }) => center && 'center'};
    text-align: ${({ left }) => left && 'left'};
    text-align: ${({ right }) => right && 'right'};
    margin: ${margin};
    opacity: ${opacity};
    transition: 0.4s;
  `};
`

const StyledText = styled.span`
  ${textStyle}
`

const Text = ({ children, ...props }) => <StyledText {...props}>{children}</StyledText>

Text.defaultProps = {
  children: '',
}

Text.propTypes = {
  children: PropTypes.node,
}

export default Text

export const TextSetter = styled.div`
  ${textStyle}
`
