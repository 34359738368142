import styled from 'styled-components'
import { GroupTitle } from '..'

const SummaryTitle = styled(GroupTitle)`
  margin-bottom: 0.6rem;
  max-width: unset;
  @media print {
    font-size: 80%;
  }
`

export default SummaryTitle
