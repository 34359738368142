import { format } from 'date-fns'
import { getFieldConfig } from '../../../../common/schemaConfig'

export const getTextValue = value => value || value === 0 ? value : '---'

export const renderWithSign = ({ value, fieldName }) => {
  value = renderFormattedNumber({ value, fieldName })
  const numberValue = parseFloat(value)
  if (isNaN(numberValue)) return '---'
  if (numberValue <= 0) return value
  return `+${value}`
}

export const renderFormattedNumber = ({ value, fieldName }) => {
  const fieldConfig = getFieldConfig(fieldName, false)
  const decimals = (fieldConfig && fieldConfig.decimals) || -1
  const numberValue = parseFloat(value)
  if (!isNaN(numberValue) && decimals >= 0) return numberValue.toFixed(decimals)
  if (isNaN(numberValue)) return '---'
  return value
}

export const isSomeFieldFilled = (fieldsArray, values) =>
  fieldsArray.some(f => {
    const valueR = values[`${f}R`]
    const valueL = values[`${f}L`]
    return !!valueR || valueR === 0 || !!valueL || valueL === 0
  })

export const renderFormattedDate = ({ value, fieldName }) => {
  if (!value) return '---'

  return format(new Date(value), 'dd.MM. yyy HH:mm')
}
