import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import styled from 'styled-components'
import { withTranslation } from 'react-i18next'

// import { fetchVca } from '../../common/config/actions'

import { useLocation } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import { NumberField, TextField, SelectLanguageField } from '../components/fields'
import { Page, Box, Row, Col, Button, Gap } from '../components'
import { signOut } from '../../common/auth/actions'
import {
  SettingsMeasurementForm,
  SettingsTabletForm,
  SettingsTracerForm,
  SettingsWorkersForm,
  OrdersSettingForm,
  PricesSettingForm,
} from '../components/forms'
import { appTypeConfig } from '../../common/config'
import Step2SettingForm from '../components/forms/Step2SettingForm'
import TracersSettings from '../components/TracersSettings'

const SettingsPage = ({ signOut, t }) => {
  const [searchParams, setSearchParams] = useSearchParams()

  return (
    <Page metaTitle={t('settings')}>
      <Page.Content>
        <Row alignItems="baseline" marginTop="2rem">
          {/* Left settings side */}
          <Col width="50%" grow={1}>
            <Box>
              <Box.Header>
                <Box.Title>{t('branch workers')}</Box.Title>
              </Box.Header>
              <Box.Content>
                <SettingsWorkersForm />
              </Box.Content>
            </Box>

            <Box>
              <Box.Header>
                <Box.Title>{t('calibration')}</Box.Title>
              </Box.Header>
              <Box.Content>
                <Row>
                  <Col>
                    <Row>{t('CALIBRATION_DESCRIPTION')}</Row>
                    <SettingsMeasurementForm />
                  </Col>
                </Row>
              </Box.Content>
            </Box>

            {/* <Box>
                <Box.Header>
                  <Box.Title>{t('graphics tablet')}</Box.Title>
                </Box.Header>
                <Box.Content>
                  <Row>
                    <Col>
                      <Row>
                        Pro správné fungování tabletu (kreslení) je potřeba změřit
                        aktivní část vašeho tabletu (v centimetrech). Aktivní část je ta část,
                        do které se dá kreslit. Čím přesněji tablet změříte, tím lépe bude fungovat
                        zakreslení tvaru a následný výpočet skutečné velikosti tvaru.
                      </Row>
                      <SettingsTabletForm />
                    </Col>
                  </Row>
                </Box.Content>
              </Box> */}

            <Box>
              <Box.Header>
                <Box.Title>{t('orders')}</Box.Title>
              </Box.Header>
              <Box.Content>
                <Row>
                  <Col>
                    <OrdersSettingForm />
                  </Col>
                </Row>
              </Box.Content>
            </Box>

            {appTypeConfig.hasPriceList && (
              <Box>
                <Box.Header>
                  <Box.Title>{t('reize; Prices')}</Box.Title>
                </Box.Header>
                <Box.Content>
                  <Row>
                    <Col>
                      <PricesSettingForm />
                    </Col>
                  </Row>
                </Box.Content>
              </Box>
            )}

            {appTypeConfig.isPinBevelEnabled && (
              <Box>
                <Box.Header>
                  <Box.Title>{t('Shape / Spectacle')}</Box.Title>
                </Box.Header>
                <Box.Content>
                  <Row>
                    <Col>
                      <Step2SettingForm />
                    </Col>
                  </Row>
                </Box.Content>
              </Box>
            )}

            <Box>
              <Box.Header>
                <Box.Title>{t('sign out_noun')}</Box.Title>
              </Box.Header>
              <Box.Content>
                <Row>
                  <Col>
                    <Button
                      onClick={() => {
                        window.location.hash = 'logout'
                        setTimeout(() => {
                          signOut()
                        }, 1)
                      }}
                    >
                      {t('sign out')}
                    </Button>
                  </Col>
                </Row>
              </Box.Content>
            </Box>
          </Col>
          {/* Right settings side */}
          <Col width="50%" grow={1}>
            <Box>
              <Box.Header>
                <Box.Title>{t('language settings')}</Box.Title>
              </Box.Header>
              <Box.Content>
                <Row>
                  <Col width="100%">
                    <SelectLanguageField />
                  </Col>
                </Row>
              </Box.Content>
            </Box>

            <Box>
              <Box.Header>
                <Box.Title>Tracer</Box.Title>
              </Box.Header>
              <Box.Content>
                <TracersSettings />
              </Box.Content>
            </Box>
          </Col>
        </Row>
      </Page.Content>
    </Page>
  )
}

SettingsPage.propTypes = {
  signOut: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
}

const enhance = compose(
  connect(
    state => ({
      vca: state.vca.selectedVca,
    }),
    {
      // fetchVca,
      signOut,
    },
  ),
  withTranslation(),
)

export default enhance(SettingsPage)
