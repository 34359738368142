import { getLensValuesBySide, getAutoSelectedDiameters } from './helpers'
import { R, L, orderTypes } from './config'

const isInRange = (value, range) => value >= range.from && value <= range.to
const isDefined = value => typeof value !== 'undefined'
const getParsedValues = (values, fieldKeys) =>
  fieldKeys.reduce(
    (result, key) => {
      result[key] = parseFloat(values[key]) || 0
      return result
    },
    { ...values },
  )

const floatFields = [
  'diameterPhysical',
  'diameterOptical',
  'sphere',
  'cylinder',
  'addition',
  'prism1',
  'prism2',
]

export const getValidBaseCurves = ({ lens, fieldsSideValues }) => {
  const { baseCurves } = lens
  if (!baseCurves) return []
  fieldsSideValues.isElliptical = !!fieldsSideValues.fieldsSideValues

  fieldsSideValues.isElliptical =
    fieldsSideValues.fieldsSideValues === 'false' ? false : !!fieldsSideValues.isElliptical

  const {
    diameterPhysical,
    diameterOptical,
    isElliptical,
    sphere,
    cylinder,
    addition,
    prism1,
    prism2,
  } = getParsedValues(fieldsSideValues, floatFields)

  const isMatching = matching => {
    if (matching.diameter.physical !== diameterPhysical) return false

    if (matching.diameter.optical) {
      if (matching.diameter.optical !== diameterOptical) return false
    }

    if (matching.diameter.elliptic && !isElliptical) return false

    const maxMeridianFrom = sphere + cylinder * matching.cylinderPartCoef.from
    const maxMeridianTo = sphere + cylinder * matching.cylinderPartCoef.to

    if (matching.maxMeridian.from > maxMeridianFrom) return false
    if (matching.maxMeridian.to < maxMeridianTo) return false
    if (isDefined(matching.cylinder) && !isInRange(cylinder, matching.cylinder)) return false
    if (isDefined(matching.addition) && !isInRange(addition, matching.addition)) return false
    if (isDefined(matching.prism) && !isInRange(prism1, matching.prism)) return false
    if (isDefined(matching.prism) && !isInRange(prism2, matching.prism)) return false

    return true
  }

  const availableBaseCurves = baseCurves.filter(({ matchings }) => matchings.some(isMatching))

  const uniqueBaseCurvesValues = availableBaseCurves
    .map(({ baseCurve }) => baseCurve)
    .reduce((result, baseCurve) => {
      if (result.includes(baseCurve)) return result
      result.push(baseCurve)
      return result
    }, [])
    .sort()

  return uniqueBaseCurvesValues
}

export const getHasLensBaseCurve = lens => !!lens?.baseCurves?.length > 0

export const getValidBaseCurvesOptions = ({ lensR, lensL, side, selectedVca, fieldValues }) => {
  const lens = side === R ? lensR : lensL
  if (!lens) return []
  if (!fieldValues) return []
  const fieldsSideValues = getLensValuesBySide(fieldValues)[side]
  if (!fieldsSideValues) return []

  //* ****  AUTO DIAMETER*/
  // if diameter is automatic
  if (
    fieldValues.orderType !== orderTypes.DIAMETER_ONLY &&
    // it is not possible to have cto just for one lens, so next condition is ok
    (!!fieldValues.ctoR || !!fieldValues.ctoL)
  ) {
    const autoSelectedDiameters = getAutoSelectedDiameters({
      selectedVca,
      fieldValues,
      fieldsSideValues,
      lensR,
      lensL,
    })
    Object.assign(fieldsSideValues, autoSelectedDiameters[side])
  }

  return getValidBaseCurves({ lens, fieldsSideValues })
}

export const checkAreBaseCurvesValid = ({ values, currentLensL, currentLensR, selectedVca }) => {
  const sides = [R, L]
  const result = { R: true, L: true }
  sides.forEach(side => {
    const baseCurve = side === R ? values.baseCurveR : values.baseCurveL
    if (!baseCurve && baseCurve !== 0) return

    const validBaseCurves = getValidBaseCurvesOptions({
      lensR: currentLensR,
      lensL: currentLensL,
      side,
      selectedVca,
      fieldValues: values,
    })
    if (!validBaseCurves.includes(baseCurve)) result[side] = false
  })
  return result
}
