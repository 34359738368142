import React, { useCallback } from 'react'
import { connect } from 'react-redux'
import AsyncSelect, { useAsync } from 'react-select/async'
import { fetchPuppetUsersList, setPuppetUser } from '../../common/app/actions'
import { Col, Row } from '.'

const PuppetUserSelect = ({
  fetchPuppetUsersList,
  puppetUsersList,
  puppetUser,
  setPuppetUser,
  onChange,
  viewer,
}) => {
  if (!viewer.canCreateOrdersForOthers) return null

  const promiseOptions = useCallback(
    inputValue =>
      new Promise(async resolve => {
        const response = await fetchPuppetUsersList({ searchText: inputValue }).meta.action.payload

        const options = response.data.map(user => ({
          value: user._id,
          label: user.username + ' | ' + user.name,
          username: user.username,
          _id: user._id,
        }))
        resolve(options)
      }),
    [fetchPuppetUsersList]
  )

  return (
    <Row maxWidth="100%">
      <Col width="400px" style={{ zIndex: 1000 }}>
        <AsyncSelect
          cacheOptions
          loadOptions={promiseOptions}
          value={puppetUser}
          placeholder="Select user"
          onChange={data => {
            setPuppetUser(data)
            onChange()
          }}
        />
      </Col>
    </Row>
  )
}

const enhance = connect(
  ({ app }) => ({
    puppetUsersList: app.puppetUsersList,
    puppetUser: app.puppetUser,
    viewer: app.viewer,
  }),
  {
    fetchPuppetUsersList,
    setPuppetUser,
  }
)

export default enhance(PuppetUserSelect)
