import React from 'react'

import i18n from '../common/i18n/i18n'
import * as notifs from '../common/lib/notifs'
import { createTranslatedErrorMessage } from '../common/helpers'

// TODO sjednotit s /app/helpers
// eslint-disable-next-line
export function processErrorsAndShowNotifs(errors, step) {
  // shape radius validation speciality
  const diameterExceeded = errors.filter(e => e.key === 'maxShapeDiameterExceeded')
  if (diameterExceeded.length > 0) {
    // filter out our speciality
    errors = errors.filter(e => e.key !== 'maxShapeDiameterExceeded')
    const e = diameterExceeded[0]
    notifs.error({
      title: i18n.t(e.title),
      message: i18n.t(e.message),
    })
  }

  const uniqueErrors = [...new Set(errors.map(e => createTranslatedErrorMessage(e.message)))]

  // cuz we filter out the specialities
  if (errors.length > 0) {
    notifs.error({
      title: `${i18n.t('form filled incorrectly')} - ${step ? `${i18n.t('step')} ${step}` : ''}`,
      message: (
        <div>
          {uniqueErrors.map((e, key) => (
            <div key={key}>{e}</div>
          ))}
        </div>
      ),
    })
  }
}

export function transformServerErrorsToMessages(serverResponseErrors) {
  if (!serverResponseErrors || serverResponseErrors?.length === 0) {
    return []
  }

  if (!Array.isArray(serverResponseErrors)) {
    serverResponseErrors = [serverResponseErrors]
  }

  const flattenedErrors = serverResponseErrors.reduce((result, error) => {
    if (error?.detail?.errors) {
      return [...result, ...error?.detail?.errors]
    }
    return [...result, error]
  }, [])

  const filteredErrors = flattenedErrors.filter(e => e?.showOnFrontend)

  const messages = filteredErrors.map(({ message }) => createTranslatedErrorMessage(message))

  return [...new Set(messages)]
}

export function transformServerErrorsToNotifMessageComponent(serverResponseErrors) {
  const messages = transformServerErrorsToMessages(serverResponseErrors)

  if (!messages || messages.length === 0) {
    return null
  }

  return (
    <div>
      {messages.map((e, key) => (
        <div key={key}>{e}</div>
      ))}
    </div>
  )
}
