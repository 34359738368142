import React from 'react'
import PropTypes from 'prop-types'
import invariant from 'invariant'
import { connect } from 'react-redux'
import { resetList, resetFilters, setFilter, setSort, setPage, setPageLimit } from './actions'
import { getIn } from '../../helpers'
// import { DeepDiffMapper, diffTypes } from '../deepDiffMapper'

// const diffMapper = new DeepDiffMapper()

const isReactNative = typeof navigator === 'object' && navigator.product === 'ReactNative' // eslint-disable-line no-undef

const isEmpty = value => {
  if (!value) return true
  if (value.length > 0) return false
  if (value.length === 0) return true
  if (typeof value === 'object' && Object.keys(value).length === 0) return true
  return false
}

// Higher order component for display of filtered, sorted and paginated data
const lists = providedOptions => WrappedComponent => {
  const options = {
    filters: [],
    headings: [],
    debounceTime: 500,
    maxPages: 5,
    limit: 100,
    getInitialState: () => ({}),
    noAutoRefreshFields: [], // trigger updated for some fields manually
    ...providedOptions,
  }

  const {
    path,
    filters,
    headings,
    debounceTime,
    noAutoRefreshFields,
    maxPages,
    limit,
    loadDataAction,
    getInitialState,
  } = options

  invariant(Array.isArray(filters), 'Fields must be an array.')
  invariant(
    typeof path === 'string' || typeof path === 'function' || Array.isArray(path),
    'Path must be a string, function, or an array.',
  )

  const getNormalizedPath = location => {
    let normalizedPath
    switch (typeof path) {
      case 'string':
        normalizedPath = [path]
        break
      default:
        normalizedPath = path
    }
    if (location) normalizedPath.push(location)
    return normalizedPath
  }

  class List extends React.Component {
    static lazyJsonValuesOf(model, props) {
      const initialState = getInitialState && getInitialState(props)
      const filters = options.filters.reduce(
        (filters, filter) => ({
          ...filters,
          [filter]: List.getFilterValue(filter, model, initialState),
        }),
        {},
      )
      const sort = List.getSortValue(model, initialState)
      const page = List.getPageValue(model, initialState)
      const limit = List.getLimitValue(model, initialState)
      return {
        filters,
        sort,
        page,
        limit,
      }
    }

    static getSortValue(model, initialState) {
      if (model && model.hasOwnProperty('sort')) {
        return getIn(model, 'sort')
      }
      if (initialState && {}.hasOwnProperty.call(initialState, 'sort')) {
        return initialState.sort
      }
      return ''
    }

    static getLimitValue(model, initialState) {
      if (model && model.hasOwnProperty('limit')) {
        return getIn(model, 'limit')
      }
      if (initialState && {}.hasOwnProperty.call(initialState, 'limit')) {
        return initialState.limit
      }
      return limit
    }

    static getPageValue(model, initialState) {
      if (model && model.hasOwnProperty('page')) {
        return getIn(model, 'page')
      }
      if (initialState && {}.hasOwnProperty.call(initialState, 'page')) {
        return initialState.page
      }
      return 1
    }

    static getFilterValue(filter, model, initialState) {
      if (
        model &&
        model.hasOwnProperty('filters') &&
        getIn(model, 'filters').hasOwnProperty(filter)
      ) {
        // return getIn(getIn(model, 'filters'), filter)
        return getIn(model, 'filters')[filter]
      }
      if (initialState && {}.hasOwnProperty.call(initialState, filter)) {
        return initialState[filter]
      }
      return ''
    }

    static prepareQuery(values) {
      const { filters, sort, page, limit } = values
      const parsedFilters = {}
      Object.keys(filters).forEach(key => {
        if (isEmpty(filters[key])) return

        const parts = key.split('.')
        const lastPart = parts.pop()
        if (['from', 'to', 'in', 'like'].indexOf(lastPart) !== -1) {
          const filter = { [lastPart]: filters[key] }
          const filterKey = parts.join('.')
          parsedFilters[filterKey] = { ...parsedFilters[filterKey], ...filter }
        } else {
          parsedFilters[key] = filters[key]
        }
      })
      return {
        filters: parsedFilters,
        sort,
        page,
        perPage: limit,
      }
    }

    static createFilterObject(filter, onChange) {
      return isReactNative
        ? {
          onChangeText: text => {
            onChange(filter, text)
          },
        }
        : {
          onChange: event => {
            const target = event.target || event
            const { type, checked, value } = target
            const isCheckbox = type && type.toLowerCase() === 'checkbox'
            onChange(filter, isCheckbox ? checked : value)
          },
        }
    }

    static createHeadingObject(heading, onClick) {
      return isReactNative
        ? {
          onPress: () => {
            onClick(heading)
          },
        }
        : {
          onClick: () => {
            onClick(heading)
          },
        }
    }

    onFilterChange = (filter, value) => {
      const normalizedPath = getNormalizedPath('filters').concat(filter)
      this.props.setFilter(normalizedPath, value)
    }

    onHeadingClick = heading => {
      const normalizedPath = getNormalizedPath('sort')
      this.props.setSort(normalizedPath, heading)

      const normalizedPagePath = getNormalizedPath('page')
      this.props.setPage(normalizedPagePath, 0) // reset pagination when sort is changed
    }

    onPagingClick = page => this.list.$setPage(page)

    createPaging = itemCount => {
      const { limit } = this.values
      const pages = Math.ceil(itemCount / limit)
      if (pages < 2) return null
      const currentPage = this.values.page || 1
      const items = []
      // move backwards
      items.push({
        first: true,
      })
      if (currentPage === 1) {
        items[0].disabled = true
      } else {
        items[0].onClick = () => {
          this.onPagingClick(currentPage - 1)
        }
      }
      let fixedItems = 0
      // first and second page are fixed when there is more pages then limit
      if (pages > maxPages) {
        if (currentPage > 1) {
          items.push({
            text: 1,
            onClick: () => {
              this.onPagingClick(1)
            },
          })
          fixedItems = 1
        }
        if (currentPage > 2) {
          items.push({
            disabled: true,
            text: '...',
          })
          fixedItems = 2
        }
        const remainingPages = pages + 1 - currentPage
        const remainingMenuItems = maxPages - fixedItems // remove page 1 and 2
        for (let i = 0; i < remainingMenuItems; i += 1) {
          let offset
          if (remainingPages >= remainingMenuItems) offset = i
          else offset = remainingPages - (remainingMenuItems - i)
          items.push({
            text: currentPage + offset,
            active: offset === 0,
            onClick: () => {
              this.onPagingClick(currentPage + offset)
            },
          })
        }
      } else {
        for (let i = 1; i <= pages; i += 1) {
          items.push({
            text: i,
            active: currentPage === i,
            onClick: () => {
              this.onPagingClick(i)
            },
          })
        }
      }
      if (!items.filter(item => item.active).length) {
        items[items.length - 1].active = true
      }
      // move forward
      items.push({
        last: true,
      })

      if (currentPage === pages) items[items.length - 1].disabled = true
      else {
        items[items.length - 1].onClick = () => {
          this.onPagingClick(currentPage + 1)
        }
      }
      return items
    }

    createList() {
      const filters = options.filters.reduce(
        (filters, filter) => ({
          ...filters,
          [filter]: List.createFilterObject(filter, this.onFilterChange),
        }),
        {},
      )

      const headings = options.headings.reduce(
        (headings, heading) => ({
          ...headings,
          [heading]: List.createHeadingObject(heading, this.onHeadingClick),
        }),
        {},
      )

      this.list = {
        filters: {
          ...filters,
        },
        headings: {
          ...headings,
        },
        $paging: this.createPaging,
        $sortBy: field => {
          const normalizedPath = getNormalizedPath('sort')
          this.props.setSort(normalizedPath, field)
        },
        $setPage: page => {
          const normalizedPath = getNormalizedPath('page')
          this.props.setPage(normalizedPath, page)
        },
        $setPageLimit: limit => {
          const normalizedPath = getNormalizedPath('limit')
          this.props.setPageLimit(normalizedPath, limit)
        },
        $resetFilters: () => {
          const normalizedPath = getNormalizedPath('filters')
          this.props.resetFilters(normalizedPath)
        },
        $reset: () => {
          const normalizedPath = getNormalizedPath()
          this.props.resetList(normalizedPath)
        },
        $reload: () => {
          this.loadData()
        },
        $heading: (heading, text, icons) => {
          let icon
          switch (this.list.headings[heading].direction) {
            case 1:
              icon = icons.descending
              break
            case -1:
              icon = icons.ascending
              break
            default:
              icon = icons.sort
              break
          }
          // eslint-disable-next-line jsx-a11y/no-static-element-interactions
          return (
            <a onClick={this.list.headings[heading].onClick}>
              {text} {icon}
            </a>
          )
        },
      }
    }

    async loadData() {
      const values = List.lazyJsonValuesOf(this.state.model, this.props)
      const query = List.prepareQuery(values)
      const action = loadDataAction(this.props)(query)

      await action.meta.action.payload
    }

    getModelFromState(props = this.props) {
      const normalizedPath = getNormalizedPath()
      return getIn(props.lists, normalizedPath)
    }

    setModel(model, withoutStateUpdate, props = this.props) {
      this.values = List.lazyJsonValuesOf(model, props)
      if (!this.list.filters) this.list.filters = {}
      filters.forEach(filter => {
        this.list.filters[filter].value = this.values.filters[filter]
      })

      if (!this.list.headings) this.list.headings = {}
      headings.forEach(heading => {
        let direction
        switch (this.values.sort) {
          case heading:
            direction = 1
            break
          case `-${heading}`:
            direction = -1
            break
          default:
            direction = 0
        }
        this.list.headings[heading].direction = direction
      })
      this.list.page = this.values.page
      this.list.limit = this.values.limit
      this.list.sort = this.values.sort
      this.list = { ...this.list } // Ensure rerender for pure components.
      if (!withoutStateUpdate) {
        this.setState({ model })
      }
      return model
    }

    constructor(props) {
      super()
      this.state = {
        model: null,
      }
      this.createList()
      this.state.model = this.setModel(this.getModelFromState(props), true, props)
    }

    componentDidMount() {
      this.setModel(this.getModelFromState())
      this.loadData()
    }

    componentDidUpdate(prevProps, prevState) {
      const newModel = this.getModelFromState()
      // json stringify je potencialne pomala vec
      if (JSON.stringify(newModel) === JSON.stringify(this.state.model)) return
      const fieldsToUpdate = filters.filter(f => !noAutoRefreshFields.includes(f))

      // console.log('newModel', newModel)
      let update = false
      fieldsToUpdate.forEach(f => {
        if (newModel.filters?.[f] !== this.state.model?.filters?.[f]) {
          update = true
        }
      })
      if (newModel.sort !== this.state.model.sort || newModel.page !== this.state.model.page) {
        update = true
      }

      this.setModel(newModel)

      // only fetch new data when allowed fields are changed
      if (update) {
        this.update()
        // console.log('trigger data update')
      } else {
        // console.log('skip data update')
      }
    }

    componentWillUnmount() {
      this.list = null
      clearTimeout(this.debounceTimer)
    }

    update = () => {
      clearTimeout(this.debounceTimer)
      this.debounceTimer = setTimeout(() => {
        this.loadData()
      }, debounceTime)
    }

    render() {
      return <WrappedComponent {...this.props} list={this.list} />
    }
  }

  return connect(
    state => ({
      // fields: state.fields,
      lists: state.lists,
    }),
    {
      resetList,
      resetFilters,
      setFilter,
      setSort,
      setPage,
      setPageLimit,
    },
  )(List)
}

export default lists
