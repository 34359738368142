import React from 'react'
import Icon from 'react-icon-base'

const HeartEmpty = props => (
  <Icon viewBox="0 0 19 18" {...props}>
    <path d="M9.79818 17.0414C9.62403 17.1771 9.47618 17.2899 9.36 17.3775C9.24382 17.2899 9.09597 17.1771 8.92182 17.0414C8.46328 16.6842 7.82327 16.1693 7.0995 15.5398C5.64755 14.277 3.87711 12.5694 2.55456 10.7613L2.1591 11.0506L2.55456 10.7613C1.18802 8.89313 0.5 6.63228 0.5 5.085C0.5 2.56663 2.53122 0.525608 5.04248 0.5H5.085C6.69148 0.5 8.11821 1.35014 8.94109 2.61297L9.36 3.25585L9.77891 2.61297C10.6018 1.35014 12.0285 0.5 13.635 0.5H13.6775C16.1888 0.525608 18.22 2.56663 18.22 5.085C18.22 6.63228 17.532 8.89313 16.1654 10.7613C14.8429 12.5694 13.0724 14.277 11.6205 15.5398C10.8967 16.1693 10.2567 16.6842 9.79818 17.0414Z" fill="#EB5757" stroke="#EB5757" />
    <path d="M9.79818 17.0414C9.62403 17.1771 9.47618 17.2899 9.36 17.3775C9.24382 17.2899 9.09597 17.1771 8.92182 17.0414C8.46328 16.6842 7.82327 16.1693 7.0995 15.5398C5.64755 14.277 3.87711 12.5694 2.55456 10.7613L2.1591 11.0506L2.55456 10.7613C1.18802 8.89313 0.5 6.63228 0.5 5.085C0.5 2.56663 2.53122 0.525608 5.04248 0.5H5.085C6.69148 0.5 8.11821 1.35014 8.94109 2.61297L9.36 3.25585L9.77891 2.61297C10.6018 1.35014 12.0285 0.5 13.635 0.5H13.6775C16.1888 0.525608 18.22 2.56663 18.22 5.085C18.22 6.63228 17.532 8.89313 16.1654 10.7613C14.8429 12.5694 13.0724 14.277 11.6205 15.5398C10.8967 16.1693 10.2567 16.6842 9.79818 17.0414Z" fill="white" stroke="#EB5757" />
    <path fillRule="evenodd" clipRule="evenodd" d="M0 0C0.207031 1.5498 0.90918 3.44629 2.08691 5.05664C4.7959 8.75977 9.2959 12 9.2959 12C9.2959 12 13.7959 8.75977 16.5049 5.05664C17.6826 3.44629 18.3838 1.5498 18.5908 0H0Z" transform="translate(0 6)" fill="#EB5757" />
    <path d="M9.79818 17.0414C9.62403 17.1771 9.47618 17.2899 9.36 17.3775C9.24382 17.2899 9.09597 17.1771 8.92182 17.0414C8.46328 16.6842 7.82327 16.1693 7.0995 15.5398C5.64755 14.277 3.87711 12.5694 2.55456 10.7613L2.1591 11.0506L2.55456 10.7613C1.18802 8.89313 0.5 6.63228 0.5 5.085C0.5 2.56663 2.53122 0.525608 5.04248 0.5H5.085C6.69148 0.5 8.11821 1.35014 8.94109 2.61297L9.36 3.25585L9.77891 2.61297C10.6018 1.35014 12.0285 0.5 13.635 0.5H13.6775C16.1888 0.525608 18.22 2.56663 18.22 5.085C18.22 6.63228 17.532 8.89313 16.1654 10.7613C14.8429 12.5694 13.0724 14.277 11.6205 15.5398C10.8967 16.1693 10.2567 16.6842 9.79818 17.0414Z" fill="white" stroke="#EB5757" />
    <path fillRule="evenodd" clipRule="evenodd" d="M0 0C2.71484 3.29297 6.47461 6 6.47461 6C6.47461 6 10.2344 3.29297 12.9492 0H0Z" transform="translate(2.88574 12)" fill="#EB5757" />
  </Icon>
)

export default HeartEmpty

