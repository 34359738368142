import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import styled from 'styled-components'
import Deformator from '../vca/Deformator'
import PopupPresentational from './PopupPresentational'
import {
  saveSelectedVcaDeformed,
  resetSelectedVcaDeformed,
  setOriginalVca,
} from '../../../common/vca/actions'

// eslint-disable-next-line
class DeformatorPopup extends Component {
  // set original vca. It is set only if original is empty
  componentDidMount() {
    const { setOriginalVca } = this.props
    setOriginalVca()
  }

  close = ({ ok } = {}) => {
    console.log('was ok', ok)
    // do anything you want on close
    const { saveSelectedVcaDeformed, resetSelectedVcaDeformed } = this.props

    if (ok) {
      saveSelectedVcaDeformed({
        isShapeModification: true,
      })
    } else {
      resetSelectedVcaDeformed()
    }

    // finally hide the popup itself
    this.props.close()
  }

  render() {
    const { t, args } = this.props
    return (
      <PopupPresentational
        title={t('modify shape')}
        close={this.close}
        type="big"
        okText={t('save shape')}
        cancelText={t('cancel changes')}
      >
        <Deformator {...args} />
      </PopupPresentational>
    )
  }
}

DeformatorPopup.defaultProps = {}

DeformatorPopup.propTypes = {
  close: PropTypes.func.isRequired, // is injected by PopupsGate
  saveSelectedVcaDeformed: PropTypes.func.isRequired,
  resetSelectedVcaDeformed: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
}
const enhance = compose(
  connect(state => ({}), {
    saveSelectedVcaDeformed,
    resetSelectedVcaDeformed,
    setOriginalVca,
  }),
  withTranslation(),
)

export default enhance(DeformatorPopup)
