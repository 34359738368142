import favicon from '../../common/app/favicon'

const navColor = '#4BC1B7'

export default {
  // title: '%s | Omega', -- superseed by app reducer + DB
  metas: [
    { charset: 'utf-8' },
    {
      name: 'viewport',
      content: 'width=device-width, initial-scale=1, shrink-to-fit=no',
    },
    {
      'http-equiv': 'x-ua-compatible',
      content: 'ie=edge',
    },
    {
      name: 'theme-color',
      content: navColor,
    },
    {
      name: 'msapplication-navbutton-color',
      content: navColor,
    },
    {
      name: 'apple-mobile-web-app-status-bar-style',
      content: navColor,
    },
    {
      name: 'description',
      content: '',
    },
    // ...favicon.meta,
  ],
  // links: favicon.link,
}
