import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { GroupTitle } from '.'

const Container = styled.div`
  border: ${({ theme, noBorder }) => !noBorder && `${theme.colors.groupBorder} .1rem solid`};
  border-radius: 6px;
  padding: 1.5rem 2rem;
  position: relative;
  text-align: left;
  flex-grow: ${({ grow }) => grow};
`

const Content = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  visibility: ${({ isContentHidden }) => isContentHidden ? 'hidden' : 'visible'};
`

const FieldsGroup = ({ title, children, isContentHidden, ...props }) => (
  <Container {...props}>
    <Content isContentHidden={isContentHidden}>
      {title && <GroupTitle>{title}</GroupTitle>}
      {children}
    </Content>
  </Container>
)

FieldsGroup.defaultProps = {
  children: null,
  title: '',
  direction: 'row',
  isContentHidden: false,
  noBorder: false,
}

FieldsGroup.propTypes = {
  title: PropTypes.string,
  children: PropTypes.any,
  direction: PropTypes.string,
  isContentHidden: PropTypes.bool,
  noBorder: PropTypes.bool,
}

export default FieldsGroup
