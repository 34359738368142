import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { IconContext } from 'react-icons'
// import { I18nextProvider } from 'react-i18next'
import styled, { ThemeProvider } from 'styled-components'
import { ReactNotifications } from 'react-notifications-component'
import { Outlet } from 'react-router'
import { start, toggleNavigation, setScrollLocation } from '../../common/app/actions'
import { ErrorBoundary, Header, Notifications } from '../components'
import { appTypeConfig } from '../../common/config'
import PopupsGate from '../components/popups/PopupsGate'
import generateTheme from './theme'
import meta from './meta'

import BrowserSupport from '../components/BrowserSupport'
import AppVersionReloader from '../components/AppVersionReloader'
import ServerConnectionWatcher from '../components/ServerConnectionWatcher'
import CookiesConsent from '../components/CookiesConsent'
import StatsUpdater from '../components/StatsUpdater'
import GlobalStyle from './GlobalStyle'

let currentTheme = generateTheme()
export const getTheme = () => currentTheme

class App extends React.Component {
  componentDidMount() {
    const { start } = this.props
    start()
    // setTimeout(() => {
    //   document.getElementById('app-loader').style.display = 'none'
    // }, 10000)
  }

  componentDidUpdate(prevProps) {
    const { storageLoaded, suspenseDone } = this.props

    const isEverythingLoaded =
      storageLoaded && suspenseDone && (!prevProps.storageLoaded || !prevProps.suspenseDone)

    if (isEverythingLoaded) {
      console.l.debug('ALL LOADED')
      // const appLoader = document.getElementById('app-loader')
      // appLoader.style.opacity = 0
      // setTimeout(() => {
      //   appLoader.style.display = 'none'
      // }, 1000)
    }
  }

  isAuthed = () => {
    const { viewer } = this.props

    return !!viewer?._id
  }

  render() {
    const { hostConf } = this.props

    // inject vendors colors
    currentTheme = generateTheme(hostConf)

    const iconPath = hostConf.favicon?.publicPath || ''
    const isIco = iconPath.indexOf('.ico') !== -1

    return (
      <ThemeProvider theme={currentTheme}>
        <GlobalStyle />
        <Notifications />

        <IconContext.Provider value={{ style: { verticalAlign: 'middle' } }}>
          <>
            <div className="app-container">
              <Helmet
                htmlAttributes={{ lang: 'cs' }}
                titleTemplate={`%s · ${hostConf.htmlTitle}`}
                meta={meta.metas}
                link={[
                  {
                    rel: 'shortcut icon',
                    type: !isIco ? 'image/png' : 'image/x-icon',
                    href: iconPath,
                  },
                  {
                    rel: 'icon',
                    type: !isIco ? 'image/png' : 'image/x-icon',
                    href: iconPath,
                  },
                ]}
              >
                {(process.env.APP_TYPE === 'reize' && [
                  <script
                    type="application/javascript"
                    src="https://app.usercentrics.eu/latest/main.js"
                    id="a91RY3yDI"
                    language="automatic"
                  />,
                  <script
                    type="application/javascript"
                    src="https://privacy-proxy.usercentrics.eu/latest/uc-block.bundle.js"
                  />,
                ]) ||
                  null}
              </Helmet>
              <ServerConnectionWatcher />
              <div>
                <BrowserSupport />
                <AppVersionReloader />
              </div>
              {this.isAuthed() && <Header />}

              <Outlet />

              <PopupsGate />
            </div>

            {appTypeConfig.useNativeCookieConsent && <CookiesConsent />}
            <StatsUpdater />
          </>
        </IconContext.Provider>
      </ThemeProvider>
    )
  }
}

App.propTypes = {
  errors: PropTypes.array.isRequired,
  navigationVisible: PropTypes.bool.isRequired,
  toggleNavigation: PropTypes.func.isRequired,
  setScrollLocation: PropTypes.func.isRequired,
  navigationOverlayVisible: PropTypes.bool.isRequired,
  start: PropTypes.func.isRequired,
  hostConf: PropTypes.object.isRequired,
}

const enhance = compose(
  connect(
    state => ({
      storageLoaded: state.app.storageLoaded,
      suspenseDone: state.app.suspenseDone,
      hostConf: state.app.hostConf,
      viewer: state.app.viewer,
      errors: state.app.errors,
      navigationVisible: state.app.navigationVisible,
      navigationOverlayVisible: state.app.navigationOverlayVisible,
    }),
    { toggleNavigation, setScrollLocation, start },
  ),
)

export default enhance(App)
