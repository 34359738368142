/* @flow weak */
import { createLogger } from 'redux-logger'
import configureStorage from './storage'
import fetch from '../lib/fetch'

// Like redux-thunk but with dependency injection.
const injectMiddleware = deps => ({ dispatch, getState }) => next => action =>
  next(typeof action === 'function' ? action({ ...deps, dispatch, getState }) : action)

// Like redux-promise-middleware but simpler.
const promiseMiddleware = () => ({ dispatch }) => next => action => {
  const { payload } = action
  const payloadIsPromise = payload && typeof payload.then === 'function'
  if (!payloadIsPromise) return next(action)
  const createAction = (suffix, payload) => ({
    type: `${action.type}_${suffix}`,
    meta: { action },
    payload,
  })
  const promise = payload
    .then(value => dispatch(createAction('SUCCESS', value)))
    .catch(error => {
      dispatch(createAction('ERROR', error.errors))
    })
  if (!process.env.IS_BROWSER) return promise
  return next(createAction('START'))
}

const configureMiddleware = () => {
  const { STORAGE_SAVE, storageEngine, storageMiddleware } = configureStorage()

  const middleware = [
    injectMiddleware({
      fetch,
      storageEngine,
    }),
    promiseMiddleware(),
  ]

  if (storageMiddleware) {
    middleware.push(storageMiddleware)
  }

  const enableLogger = process.env.NODE_ENV !== 'production' && process.env.IS_BROWSER

  // Logger must be the last middleware in chain.
  if (enableLogger) {
    const ignoredActions = [STORAGE_SAVE]
    // const ignoredActions = []
    const logger = createLogger({
      collapsed: true,
      predicate: (getState, action) => ignoredActions.indexOf(action.type) === -1,
      // Convert immutable to JSON.
      stateTransformer: state => JSON.parse(JSON.stringify(state)),
    })
    middleware.push(logger)
  }

  return middleware
}

export default configureMiddleware
