import React from 'react'
import styled from 'styled-components'

const StyledWrapper = styled.div`
  flex-direction: row;
  display: flex;
  align-items: center;
`

const InnerWrapper = props => <StyledWrapper {...props} />

export default InnerWrapper
