import React from 'react'
import Icon from 'react-icon-base'

const DoubleArrow = props => (
  <Icon viewBox="0 0 40 40" {...props}>
    <g><path d="m12.5 10l2.5-2.5 12.5 12.5-12.5 12.5-2.5-2.5 9.9-10z"/></g>
    <g transform="translate(10,0)"><path d="m12.5 10l2.5-2.5 12.5 12.5-12.5 12.5-2.5-2.5 9.9-10z"/></g>
  </Icon>
)

export default DoubleArrow
