import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { withTranslation } from 'react-i18next'
import { TextField } from '../fields'
import { isReferenceUnique } from '../../../common/orders/actions'
import { fieldsConfig } from '../../../common/schemaConfig'

class ReferenceField extends Component {
  componentDidMount() {
    // TODO
  }

  onBlur = e => {
    const { onBlur, value, isReferenceUnique, isReferenceDuplicityCheckEnabled } = this.props

    if (value !== '' && isReferenceDuplicityCheckEnabled) isReferenceUnique(value)
    onBlur(e)
  }

  render() {
    const {
      t,
      onBlur,
      value,
      isReferenceUnique,
      isReferenceDuplicityCheckEnabled,
      ...props
    } = this.props
    return (
      <TextField
        required
        tooltip={t('STEP_1_REFERENCE_DESCRIPTION')}
        label={t('reference')}
        maxLength={fieldsConfig.reference.maxLength}
        value={value}
        {...props}
        onBlur={this.onBlur}
      />
    )
  }
}

ReferenceField.defaultProps = {
  value: '',
  onBlur: () => {},
  isReferenceDuplicityCheckEnabled: true,
}

ReferenceField.propTypes = {
  isReferenceUnique: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  value: PropTypes.string,
  isReferenceDuplicityCheckEnabled: PropTypes.bool,
}

const enhance = compose(
  connect(
    ({ config: { isReferenceDuplicityCheckEnabled } = {} }) => ({
      isReferenceDuplicityCheckEnabled,
    }),
    {
      isReferenceUnique,
    },
  ),
  withTranslation(),
)

export default enhance(ReferenceField)
