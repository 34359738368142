import tinycolor from 'tinycolor2'
import { appTypeConfig } from '../../common/config'

function rgba2rgb(background, color) {
  const alpha = color.a

  // console.log('background, color', background, color)

  return {
    r: Math.floor((1 - alpha) * background.r + alpha * color.r + 0.5),
    g: Math.floor((1 - alpha) * background.g + alpha * color.g + 0.5),
    b: Math.floor((1 - alpha) * background.b + alpha * color.b + 0.5),
  }
}

const colors = {
  primary: '#5891EF', // old logo color
  primary: '#213e78', // new official color
  primary: '#0265ab', // a nice variant
  secondary: '#EB6239',

  // backgroundLight: '#FAFAFA', // be more specific! what bg?
  backgroundDark: '#F2F2F2',
  pageBackground: '#F4F4F4',

  gray: '#F2F2F2',
  gray2: '#FBFBFB',
  gray3: '#EAEAEA',
  grayDark: '#c4c4c4',

  stepDisabled: '#BDBDBD',

  text: '#000000',
  text2: '#333333',
  text3: '#757575', // takle fakt nepoznam co to je za barvu...
  text4: '#737D9F',
  error: 'red',

  green: '#219653',
  red: '#EB5757',
  darkRed: '#b30300',
  blue: '#0065eb',
  orange: '#eab000',

  borders: '#BDBDBD',
  ...appTypeConfig.colors,
}

let primaryVeryLight = tinycolor(colors.primary).desaturate(30).lighten(25)
if (primaryVeryLight.isDark()) {
  primaryVeryLight = primaryVeryLight.desaturate(10).lighten(30)
}

const primaryCheckbox = tinycolor(colors.primary)

// console.log('primaryCheckbox.isDark', primaryCheckbox.isDark())
// if (primaryCheckbox.isDark()) {
//   primaryCheckbox = primaryCheckbox.desaturate(20).lighten(30)
// }

const getAllColors = colors => ({
  ...colors,
  primaryCheckbox: primaryCheckbox.toString(),
  primaryLight: tinycolor(colors.primary).lighten(25).toString(),
  primaryVeryLight: primaryVeryLight.toString(),
  primaryDark: tinycolor(colors.primary).darken(5).toString(),
  redLight: tinycolor(colors.red).lighten(15).toString(),
  // secondaryDark: tinycolor(colors.secondary).darken(15).toString(),
  popupTitle: colors.text4,
  boxTitle: colors.text4,
  groupTitle: colors.text4,
  groupBorder: colors.gray,
  lensSelectCatalogBackground: colors.gray2,
  boxHeader: colors.gray2,
  popupHeader: colors.gray2,
  popupHeaderBorder: colors.gray3,
  boxHeaderBorder: colors.gray3,

  tableOddRowBackground: colors.gray2,
  tableBorderColor: colors.gray,
  tableHeaderBorderColor: colors.grayDark,
  tableActiveRow: '#deebff',

  comparingTableBackground: colors.gray2,

  navbarIcons: colors.grayDark,
  navbarDivider: colors.pageBackground,
})

const getTheme = colors => {
  const disabledPrimaryBtnColor = tinycolor(
    rgba2rgb(tinycolor('white').toRgb(), tinycolor(colors.primary).setAlpha(0.5).toRgb()),
  ).toRgbString()
  const disabledSecondaryBtnColor = tinycolor(
    rgba2rgb(tinycolor('white').toRgb(), tinycolor(colors.secondary).setAlpha(0.5).toRgb()),
  ).toRgbString()

  return {
    colors: {
      ...colors,
    },
    font: {
      fontFamily: "'Roboto', sans-serif",
    },
    page: {
      paddingTop: '1rem',
      paddingBottom: '8rem',
      paddingHorizontal: '1rem',
      maxWidth: '120rem',
      // minWidth: '1150px', // original value
      minWidth: '1140px',
      // backgroundColor: colors.backgroundLight,
    },
    grid: {
      spaceBetweenColumns: '2rem',
      spaceBetweenCompactColumns: '1rem',
      spaceBetweenRows: '1.5rem',
      spaceBetweenCompactRows: '1rem',
      spaceBetweenLargeRows: '2rem',
    },
    text: {
      color: colors.text2,
    },
    field: {
      labelColor: colors.text2,
      borderColor: colors.borders,
      disabledInputColor: colors.gray2,
      borderWidth: '1px',
      borderRadius: '6px',
      focusInputColor: 'rgba(0,0,0,0.2)',

      numberFieldMaxWidth: '7rem',
      numberFieldMinWidth: '4rem',
    },
    button: {
      backgroundColor: 'white',
      textColor: colors.primary,
      borderColor: colors.primary,

      activeBackgroundColor: colors.primaryDark,
      activeTextColor: 'white',

      hoverBackgroundColor: tinycolor('white').darken(2).toString(),
      hoverTextColor: colors.primary,
      hoverBorderColor: colors.primary,

      opacity: 1,
      primary: {
        backgroundColor: colors.primary,
        textColor: 'white',
        borderColor: colors.primary,

        hoverBackgroundColor: colors.primaryDark,
        hoverTextColor: 'white',
        hoverBorderColor: colors.primary,
      },

      // orange button
      secondary: {
        backgroundColor: 'white',
        textColor: colors.secondary,
        borderColor: colors.secondary,

        hoverBackgroundColor: colors.secondary,
        hoverTextColor: 'white',
        hoverBorderColor: colors.secondary,
      },

      disabled: {
        // opacity: 0.5,
        borderColor: disabledPrimaryBtnColor,
        textColor: disabledPrimaryBtnColor,
        // backgroundColor: 'white',
        activeBackgroundColor: disabledPrimaryBtnColor,
      },
      disabledPrimary: {
        // opacity: 0.2,
        textColor: 'white',
        backgroundColor: disabledPrimaryBtnColor,
      },
      disabledSecondary: {
        // opacity: 0.2,
        borderColor: disabledSecondaryBtnColor,
        // backgroundColor: disabledSecondaryBtnColor,
        textColor: disabledSecondaryBtnColor,
      },
    },
  }
}

export default function (hostConf = {}) {
  const basicColors = {
    ...colors,
    primary: hostConf.primaryColor || colors.primary,
  }

  const allColors = getAllColors(basicColors)

  return {
    ...getTheme(allColors),
  }
}

// style helpers and snippets
// media queries helpers, if arg is string, then it's bp name, otherwise it's pixel value
// ! this is base theme lib, to customize it go to src/styles
export const theme = {
  // colors,
  breakpoints: {
    origin: 1,
    mediumMobile: 414,
    largeMobile: 576,
    tablet: 768,
    mobile: 768, // our BP for RH / helpers
    desktop: 992,
    largeDesktop: 1200,
    pageWidth: 1280,
    infinity: 99999,
  },
}

export const down = value =>
  `@media (max-width: ${typeof value === 'string' ? theme.breakpoints[value] : value}px)`
export const between = (value, value2) => `@media (min-width: ${
  typeof value === 'string' ? theme.breakpoints[value] : value
}px) and
  (max-width: ${typeof value2 === 'string' ? theme.breakpoints[value2] : value2}px)`

export const up = value =>
  `@media (min-width: ${typeof value === 'string' ? theme.breakpoints[value] : value}px)`
