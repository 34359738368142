import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { compose } from 'recompose'
import { withTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import { Header } from '.'
import { APP_VERSION, appTypeConfig } from '../../common/config'
import detectOs from '../../common/lib/detectOs'
import BrowserSupport from './BrowserSupport'
import AppVersionReloader from './AppVersionReloader'
import { Link } from './Link'

const PageContainer = styled.div`
  background-color: ${({ theme, backgroundColor }) =>
    backgroundColor || theme.colors.pageBackground};
  min-height: 100%;

  padding-bottom: 200px;
`

const ContentWrapper = styled.div`
  padding: ${({ theme }) => theme.page.paddingHorizontal};
  padding-top: ${({ theme }) => theme.page.paddingTop};
  padding-bottom: ${({ theme }) => theme.page.paddingBottom};
`

const Content = styled.div`
  max-width: ${({ theme, maxWidth }) => maxWidth || theme.page.maxWidth};
  min-width: ${({ theme, minWidth }) => minWidth || theme.page.minWidth};
  margin: auto;
`

const Footer = styled.div`
  text-align: center;
  color: #b2b2b2;
  padding-bottom: 3.5rem;
  font-size: 1.4rem;
`

const SupportLink = styled.a`
  color: #b2b2b2;
`
const FooterLink = styled(Link)`
  color: #b2b2b2;
  font-size: 1.4rem;
  text-decoration: underline;
`

const ContentContainer = props => (
  <ContentWrapper>
    <Content {...props} />
  </ContentWrapper>
)

class Page extends React.Component {
  state = {
    os: 'Windows',
  }

  componentDidMount() {
    // eslint-disable-next-line react/no-did-mount-set-state
    this.setState({
      os: detectOs(),
    })
  }

  render() {
    const {
      metaTitle,
      children,
      backgroundColor,
      viewer,
      t,
      i18n: { language },
    } = this.props
    const { os } = this.state

    return (
      <PageContainer backgroundColor={backgroundColor}>
        <Helmet title={metaTitle} />
        {children}
        <Footer>
          Version {APP_VERSION}
          {appTypeConfig.versionPrefix} - {viewer ? `Login: ${viewer.username}` : null}
          {' - '}
          <SupportLink
            target="_blank"
            href={
              os === 'Mac OS'
                ? 'https://download.teamviewer.com/download/TeamViewerQS.dmg'
                : 'https://download.teamviewer.com/download/TeamViewerQS.exe'
            }
          >
            {t('Support download')} ({os})
          </SupportLink>
          {appTypeConfig.showFooterImpressum && (
            <>
              {' - '}
              <FooterLink to={Link.OWNER}>Impressum</FooterLink>
            </>
          )}
          {appTypeConfig.showFooterLegalDoc && (
            <>
              {' - '}
              <SupportLink
                target="_blank"
                href={`/public/legal/legal-${appTypeConfig.APP_TYPE}.pdf`}
              >
                {t('footer - legal doc link')}
              </SupportLink>
            </>
          )}
          {appTypeConfig.showFooterGdprDoc && (
            <>
              {' - '}
              <SupportLink
                target="_blank"
                href={`/public/gdpr/gdpr-${appTypeConfig.APP_TYPE}-${language}.html`}
              >
                GDPR
              </SupportLink>
            </>
          )}
        </Footer>
      </PageContainer>
    )
  }
}

Page.defaultProps = {
  backgroundColor: '',
}

Page.propTypes = {
  children: PropTypes.node.isRequired,
  metaTitle: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string,
}

const Extended = compose(
  connect(({ app }) => ({
    viewer: app.viewer,
  })),
  withTranslation()
)(Page)

Extended.Content = ContentContainer

export default Extended
