import React from 'react'
import PropTypes from 'prop-types'
import { switchByAppType } from '../../common/config'

const SwitchByAppType = ({ variants, props }) => {
  const Variant = switchByAppType(variants)
  return <Variant {...props} />
}

SwitchByAppType.defaultProps = {
  props: null,
}

SwitchByAppType.propTypes = {
  variants: PropTypes.object.isRequired,
  props: PropTypes.object,
}

export default SwitchByAppType
