import React from 'react'
import { FullScreen, useFullScreenHandle } from 'react-full-screen'

function withFullScreen(WrappedComponent) {
  function WithFullScreen(props) {
    const handle = useFullScreenHandle()

    return <WrappedComponent {...props} fullScreenHandle={handle} />
  }

  return WithFullScreen
}

export default withFullScreen
