import { apiGet, apiDelete, apiPost, apiPut } from '../lib/api'

export const SET_VISUALISER_SCALE_CONFIG = 'SET_VISUALISER_SCALE_CONFIG'
export const SET_TRACER_CONFIG = 'SET_TRACER_CONFIG'
export const SET_DEFAULT_TRACER = 'SET_DEFAULT_TRACER'
export const SET_TABLET_CONFIG = 'SET_TABLET_CONFIG'
export const SET_ORDERS_CONFIG = 'SET_ORDERS_CONFIG'
export const SET_PRICES_CONFIG = 'SET_PRICES_CONFIG'
export const SET_STEP2_CONFIG = 'SET_STEP2_CONFIG'
export const MERGE_CONFIG = 'MERGE_CONFIG'

export const FETCH_TRACERS = 'FETCH_TRACERS'
export const FETCH_TRACERS_START = 'FETCH_TRACERS_START'
export const FETCH_TRACERS_SUCCESS = 'FETCH_TRACERS_SUCCESS'
export const FETCH_TRACERS_ERROR = 'FETCH_TRACERS_ERROR'

export const CREATE_TRACER = 'CREATE_TRACER'
export const CREATE_TRACER_START = 'CREATE_TRACER_START'
export const CREATE_TRACER_SUCCESS = 'CREATE_TRACER_SUCCESS'
export const CREATE_TRACER_ERROR = 'CREATE_TRACER_ERROR'

export const DELETE_TRACER = 'DELETE_TRACER'
export const DELETE_TRACER_START = 'DELETE_TRACER_START'
export const DELETE_TRACER_SUCCESS = 'DELETE_TRACER_SUCCESS'
export const DELETE_TRACER_ERROR = 'DELETE_TRACER_ERROR'

export const EDIT_TRACER = 'EDIT_TRACER'
export const EDIT_TRACER_START = 'EDIT_TRACER_START'
export const EDIT_TRACER_SUCCESS = 'EDIT_TRACER_SUCCESS'
export const EDIT_TRACER_ERROR = 'EDIT_TRACER_ERROR'

export const fetchTracers = () => ({ getState, fetch }) => ({
  type: FETCH_TRACERS,
  payload: apiGet({
    fetch,
    endpoint: '/tracers',
    getState,
  }),
})

export const deleteTracer = _id => ({ getState, fetch }) => ({
  type: DELETE_TRACER,
  _id,
  payload: apiDelete({
    fetch,
    endpoint: `/tracers/${_id}`,
    getState,
  }),
})

export const editTracer = (_id, body) => ({ getState, fetch }) => ({
  type: EDIT_TRACER,
  _id,
  payload: apiPut({
    fetch,
    endpoint: `/tracers/${_id}`,
    getState,
    body,
  }),
})

export const createTracer = body => ({ getState, fetch }) => ({
  type: CREATE_TRACER,
  payload: apiPost({
    fetch,
    endpoint: '/tracers',
    getState,
    body,
  }),
})

export const setVisualiserScaleConfig = scale => ({
  type: SET_VISUALISER_SCALE_CONFIG,
  payload: {
    scale,
  },
})

export const setTracerConfig = values => ({
  type: SET_TRACER_CONFIG,
  payload: values,
})

export const setTabletConfig = values => ({
  type: SET_TABLET_CONFIG,
  payload: {
    ...values,
  },
})

export const setOrdersConfig = values => ({
  type: SET_ORDERS_CONFIG,
  payload: {
    ...values,
  },
})

export const setPricesConfig = values => ({
  type: SET_PRICES_CONFIG,
  payload: { ...values },
})

export const setStep2Config = values => ({
  type: SET_STEP2_CONFIG,
  payload: { ...values },
})

export const setDefaultTracer = _id => ({
  type: SET_DEFAULT_TRACER,
  payload: _id,
})
export const mergeConfig = mergingConfig => ({
  type: MERGE_CONFIG,
  payload: mergingConfig,
})
