import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Row } from '.'

const StyledRow = styled(Row)`
  margin-bottom: 8rem !important;
`

const CreateOrderButtonsWrapper = props =>
  <StyledRow grow="1" justifyContent="space-between" {...props} />

CreateOrderButtonsWrapper.propTypes = {
  children: PropTypes.node.isRequired,
}

export default CreateOrderButtonsWrapper
