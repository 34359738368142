import validator from 'validator'
import moment from 'moment'
import { invalidFormData } from '../../server/lib/errors'

// TODO v celém tomhle souboru je spousta zbytečných duplicit předělal bych to...

const captureStackTrace = obj => {
  if (Error.prepareStackTrace) {
    const frame = {
      isEval: () => false,
      getFileName: () => 'filename',
      getLineNumber: () => 1,
      getColumnNumber: () => 1,
      getFunctionName: () => 'functionName',
    }

    obj.stack = Error.prepareStackTrace(obj, [frame, frame, frame])
  } else {
    obj.stack = obj.name || 'Error'
  }
}

Error.captureStackTrace = Error.captureStackTrace || captureStackTrace

const getValue = (values, field) => {
  const normalizedPath = field.split('.')
  if (!normalizedPath.length) return null

  let value = values
  normalizedPath.forEach(path => {
    if (value) value = value[path]
  })
  return value || value === 0 ? String(value) : ''
}

export const isBoolean = (values, field, message) => {
  const normalizedPath = field.split('.')
  if (!normalizedPath.length) return null

  let value = values
  normalizedPath.forEach(path => {
    if (value) value = value[path]
  })

  if (value !== true && value !== false) {
    return invalidFormData({
      message: message || `${field} smí být pouze ano nebo ne`,
      detail: {
        field,
        currentValue: values[field],
      },
    })
  }
  return null
}

export const isInOptions = (values, field, options, message) => {
  const value = getValue(values, field)
  if ((value || value === 0) && !options.some(option => value === `${option}`)) {
    return invalidFormData({
      message: message || `${field} je povinný`,
      detail: {
        field,
        currentValue: values[field],
      },
    })
  }
  return null
}

export const isRequired = (values, field, message) => {
  const value = getValue(values, field)
  if (!value) {
    return invalidFormData({
      message: message || `${field} je povinný`,
      detail: {
        field,
        currentValue: values[field],
      },
    })
  }
  return null
}

export const isLength = (values, field, length, message) => {
  const value = getValue(values, field).trim()
  if (!value || !validator.isLength(value, { min: length })) {
    return invalidFormData({
      message: message || `${field} je povinný a musí mít alespoň ${length} znaků.`,
      detail: {
        field,
        currentValue: values[field],
      },
    })
  }
  return null
}

export const isMaxLength = (values, field, length, message) => {
  const value = getValue(values, field)
  if (!value || !validator.isLength(value, { max: length })) {
    return invalidFormData({
      message: message || `${field} může mít maximálně ${length} znaků.`,
      detail: {
        field,
        currentValue: values[field],
      },
    })
  }
  return null
}

export const isEmail = (values, field, message) => {
  const value = getValue(values, field)
  if (value && !validator.isEmail(value)) {
    return invalidFormData({
      message: message || `${field} není email`,
      detail: {
        field,
        currentValue: values[field],
      },
    })
  }
  return null
}

export const isArrayOfEmails = (values, field, message) => {
  const normalizedPath = field.split('.')
  if (!normalizedPath.length) return null

  let value = values
  normalizedPath.forEach(path => {
    if (value) value = value[path]
  })
  const condition =
    !!value &&
    Array.isArray(value) &&
    value.reduce(
      (isArrayOfEmails, email) => !isArrayOfEmails ? false : validator.isEmail(String(email)),
      true,
    )
  if (!condition) {
    return invalidFormData({
      message: message || `${field} není seznam emailů`,
      detail: {
        field,
        currentValue: values[field],
      },
    })
  }
  return null
}

export const isStringOfEmails = (values, field, message) => {
  const normalizedPath = field.split('.')
  if (!normalizedPath.length) return null

  let value = values
  normalizedPath.forEach(path => {
    if (value) value = value[path]
  })
  const emails = value.trim().replace(/\s/g, '').split(';')

  if (!emails.length) return null
  const condition =
    !!emails &&
    Array.isArray(emails) &&
    emails.reduce(
      (isArrayOfEmails, email) => !isArrayOfEmails ? false : validator.isEmail(email),
      true,
    )
  if (!condition) {
    return invalidFormData({
      message: message || `${field} není seznam emailů`,
      detail: {
        field,
        currentValue: values[field],
      },
    })
  }
  return null
}

export const isArrayWithLength = (values, field, length, message) => {
  const normalizedPath = field.split('.')
  if (!normalizedPath.length) return null

  let value = values
  normalizedPath.forEach(path => {
    if (value) value = value[path]
  })
  const condition = !!value && Array.isArray(value) && value.length === length
  if (!condition) {
    return invalidFormData({
      message: message || `${field} nemá správný počet položek`,
      detail: {
        field,
        currentValue: values[field],
      },
    })
  }
  return null
}

export const isArrayLongerThan = (values, field, length, message) => {
  const normalizedPath = field.split('.')
  if (!normalizedPath.length) return null

  let value = values
  normalizedPath.forEach(path => {
    if (value) value = value[path]
  })
  const condition = !!value && Array.isArray(value) && value.length > length
  if (!condition) {
    return invalidFormData({
      message: message || `${field} nemá dostatečný počet položek`,
      detail: {
        field,
        currentValue: values[field],
      },
    })
  }
  return null
}

export const isDate = (values, field, message) => {
  const value = getValue(values, field)
  if (value && !moment(value).isValid()) {
    return invalidFormData({
      message: message || `${field} není datum`,
      detail: {
        field,
        currentValue: values[field],
      },
    })
  }
  return null
}

export const isBefore = (values, field, field2, message) => {
  const value = getValue(values, field)
  const secondValue = getValue(values, field2)
  if (value && validator.isDate(value) && validator.isDate(secondValue)) {
    if (moment(value).isAfter(secondValue)) {
      return invalidFormData({
        message: message || `${field} později než ${field2}`,
        detail: {
          field,
          currentValue: values[field],
        },
      })
    }
  }
  return null
}

export const isFloat = (values, field, message) => {
  const value = getValue(values, field)
  if (value && !validator.isFloat(value)) {
    return invalidFormData({
      message: message || `${field} není číslo`,
      detail: {
        field,
        currentValue: values[field],
      },
    })
  }
  return null
}

export const isInteger = (values, field, message) => {
  const value = getValue(values, field)
  if (value && !validator.isInt(value)) {
    return invalidFormData({
      message: message || `${field} není celé číslo`,
      detail: {
        field,
        currentValue: values[field],
      },
    })
  }
  return null
}

export const isPoints = (values, field, message) => {
  const value = getValue(values, field)
  if (value && !validator.isInt(value, { min: 1, max: 9 })) {
    return invalidFormData({
      message: message || `${field} není počet bodů`,
      detail: {
        field,
        currentValue: values[field],
      },
    })
  }
  return null
}

export const isDecimalPoints = (values, field, message) => {
  const value = getValue(values, field)
  if (value && !validator.isFloat(value, { min: 1, max: 9 })) {
    return invalidFormData({
      message: message || `${field} není počet bodů`,
      detail: {
        field,
        currentValue: values[field],
      },
    })
  }
  return null
}

export const isPositiveInteger = (values, field, message) => {
  const value = getValue(values, field)
  if (!validator.isInt(value, { min: 0 })) {
    return invalidFormData({
      message: message || `${field} is not a positive whole number`,
      detail: {
        field,
        currentValue: values[field],
      },
    })
  }
  return null
}

export const isIntegerGreaterThan = (values, field, limit, message) => {
  const value = getValue(values, field)
  if (value && !validator.isInt(value, { min: limit })) {
    return invalidFormData({
      message: message || `${field} is not a positive whole number bigger than ${limit}`,
      detail: {
        field,
        currentValue: values[field],
      },
    })
  }
  return null
}

export const isFloatGreaterThan = (values, field, limit, message) => {
  const value = getValue(values, field)
  if (value && (!validator.isFloat(value, { min: limit }) || value <= limit)) {
    return invalidFormData({
      message: message || `${field} is not a number bigger than ${limit}`,
      detail: {
        field,
        currentValue: values[field],
      },
    })
  }
  return null
}

export const isFloatInRange = (values, field, range, message) => {
  const value = getValue(values, field)
  if (value && !validator.isFloat(value, { min: range.min, max: range.max })) {
    return invalidFormData({
      message: message || `${field} is not a number in range ${range}`,
      detail: {
        field,
        currentValue: values[field],
      },
    })
  }
  return null
}

export const isFloatLessThan = (values, field, limit, message) => {
  const value = getValue(values, field)
  if (value && (!validator.isFloat(value, { max: limit }) || value >= limit)) {
    return invalidFormData({
      message: message || `${field} is not a number lower than ${limit}`,
      detail: {
        field,
        currentValue: values[field],
      },
    })
  }
  return null
}

export const isPositiveFloat = (values, field, message) => {
  const value = getValue(values, field)
  if (value && !validator.isFloat(value, { min: 0 })) {
    return invalidFormData({
      message: message || `${field} is not a positive number`,
      detail: {
        field,
        currentValue: values[field],
      },
    })
  }
  return null
}

export const isRequiredValidNumber = (values, field, message) => {
  const value = getValue(values, field)
  if (!value || !validator.isFloat(value)) {
    return invalidFormData({
      message: message || `${field} is not a valid number`,
      detail: {
        field,
        currentValue: values[field],
      },
    })
  }
  return null
}

export const isDivisibleBy = (values, field, amount, message) => {
  const value = getValue(values, field)
  if (value && (!validator.isInt(value, { min: 0 }) || value % amount > 0)) {
    return invalidFormData({
      message: message || `${field} is not a positive whole number`,
      detail: {
        field,
        currentValue: values[field],
      },
    })
  }
  return null
}

export const isObjectId = (values, field, message) => {
  const value = values[field]
  if (value && !validator.isMongoId(value)) {
    return invalidFormData({
      message: message || `${field} is not in correct format`,
      detail: {
        field,
        currentValue: values[field],
      },
    })
  }
  return null
}

export const areEqual = (values, fieldA, fieldB, message) => {
  const valueA = getValue(values, fieldA)
  const valueB = getValue(values, fieldB)
  if (!validator.equals(valueA, valueB)) {
    return invalidFormData({
      message: message || `${fieldB} is not the same as ${fieldA}`,
      detail: {
        field: fieldB,
        currentValue: values[fieldB],
      },
    })
  }
  return null
}

export const areRequired = (values, fields, message) =>
  fields.map(field => isRequired(values, field, message)).filter(error => error !== null)

export const areLength = (values, fields, length, message) =>
  fields.map(field => isRequired(values, field, length, message)).filter(error => error !== null)

export const areFloats = (values, fields, message) =>
  fields.map(field => isFloat(values, field, message)).filter(error => error !== null)

export const areIntegers = (values, fields, message) =>
  fields.map(field => isInteger(values, field, message)).filter(error => error !== null)

export const arePositiveIntegers = (values, fields, message) =>
  fields.map(field => isPositiveInteger(values, field, message)).filter(error => error !== null)

export const areEmails = (values, fields, message) =>
  fields.map(field => isEmail(values, field, message)).filter(error => error !== null)

export const areDates = (values, fields, message) =>
  fields.map(field => isDate(values, field, message)).filter(error => error !== null)

export default class Validation {
  constructor(data) {
    this.data = data
    this.conditions = []
  }

  isInOptions(field, options, message) {
    this.conditions.push(data => isInOptions(data, field, options, message))
  }

  isRequired(field, message) {
    this.conditions.push(data => isRequired(data, field, message))
  }

  isLength(field, length, message) {
    this.conditions.push(data => isLength(data, field, length, message))
  }

  isMaxLength(field, length, message) {
    this.conditions.push(data => isMaxLength(data, field, length, message))
  }

  isBoolean(field, message) {
    this.conditions.push(data => isBoolean(data, field, message))
  }

  isEmail(field, message) {
    this.conditions.push(data => isEmail(data, field, message))
  }

  isArrayOfEmails(field, message) {
    this.conditions.push(data => isArrayOfEmails(data, field, message))
  }

  isStringOfEmails(field, message) {
    this.conditions.push(data => isStringOfEmails(data, field, message))
  }

  isArrayWithLength(field, length, message) {
    this.conditions.push(data => isArrayWithLength(data, field, length, message))
  }

  isArrayLongerThan(field, length, message) {
    this.conditions.push(data => isArrayLongerThan(data, field, length, message))
  }

  isDate(field, message) {
    this.conditions.push(data => isDate(data, field, message))
  }

  isBefore(field, secondField, message) {
    this.conditions.push(data => isBefore(data, field, secondField, message))
  }

  isObjectId(field, message) {
    this.conditions.push(data => isObjectId(data, field, message))
  }

  isFloat(field, message) {
    this.conditions.push(data => isFloat(data, field, message))
  }

  isRequiredValidNumber(field, message) {
    this.conditions.push(data => isRequiredValidNumber(data, field, message))
  }

  isInteger(field, message) {
    this.conditions.push(data => isInteger(data, field, message))
  }

  isPositiveInteger(field, message) {
    this.conditions.push(data => isPositiveInteger(data, field, message))
  }

  isIntegerGreaterThan(field, limit, message) {
    this.conditions.push(data => isIntegerGreaterThan(data, field, limit, message))
  }

  isPoints(field, message) {
    this.conditions.push(data => isPoints(data, field, message))
  }

  isDecimalPoints(field, message) {
    this.conditions.push(data => isDecimalPoints(data, field, message))
  }

  isPositiveFloat(field, message) {
    this.conditions.push(data => isPositiveFloat(data, field, message))
  }

  isFloatGreaterThan(field, limit, message) {
    this.conditions.push(data => isFloatGreaterThan(data, field, limit, message))
  }

  isFloatLessThan(field, limit, message) {
    this.conditions.push(data => isFloatLessThan(data, field, limit, message))
  }

  isFloatInRange(field, range, message) {
    this.conditions.push(data => isFloatInRange(data, field, range, message))
  }

  isDivisibleBy(field, amount, message) {
    this.conditions.push(data => isDivisibleBy(data, field, amount, message))
  }

  areRequired(fields, message) {
    fields.forEach(field => this.isRequired(field, message))
  }

  areLength(fields, length, message) {
    fields.forEach(field => this.isRequired(field, length, message))
  }

  areIntegers(fields, message) {
    fields.forEach(field => this.isInteger(field, message))
  }

  areFloats(fields, message) {
    fields.forEach(field => this.isFloat(field, message))
  }

  arePositiveIntegers(fields, message) {
    fields.forEach(field => this.isPositiveInteger(field, message))
  }

  areEqual(fieldA, fieldB, message) {
    this.conditions.push(data => areEqual(data, fieldA, fieldB, message))
  }

  areEmails(fields, message) {
    fields.forEach(field => this.isEmail(field, message))
  }

  areDates(fields, message) {
    fields.forEach(field => this.isDate(field, message))
  }

  areObjectIds(fields, message) {
    fields.forEach(field => this.isObjectId(field, message))
  }

  validate() {
    const results = this.conditions.map(condition => condition(this.data))
    return results.filter(result => result !== null)
  }

  // condition is validation func, that receives data and returns error or null
  addCondition(condition) {
    this.conditions.push(condition)
  }
}
