import React from 'react'
import styled from 'styled-components'

const HiddenInPrint = styled.div`
  @media print {
    display: ${({ disabled }) => disabled ? 'block' : 'none'};
  }
`

export default HiddenInPrint
