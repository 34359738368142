import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'
import { isBrowserSupported } from '../../common/lib/supportedBrowsers'

const Wrapper = styled.div`
  background: orange;
  text-align: center;
  padding: 10px 20px 10px;
  position: relative;
`

const CloseIcon = styled.span`
  padding: 10px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
`

function BrowserSupport() {
  const { t } = useTranslation()

  const [isSupported, setIsSupported] = useState(true)

  useEffect(() => {
    const isSupported = isBrowserSupported()
    setIsSupported(isSupported)
  }, [])

  return isSupported ? null : (
    <Wrapper>
      <strong>{t('Your browser is outdated!')} </strong>{' '}
      {t(
        'outdated browser warning longer text',
      )}{' '}
      <a href="https://www.google.com/chrome/" target="_blank" rel="noreferrer">
        Chrome
      </a>
      ,{' '}
      <a href="https://www.mozilla.org/en-US/firefox/new/" target="_blank" rel="noreferrer">
        Firefox
      </a>
      , {t('or')}{' '}
      <a href="https://www.microsoft.com/en-us/edge" target="_blank" rel="noreferrer">
        new Edge
      </a>
      .
      <CloseIcon
        onClick={() => {
          setIsSupported(true)
        }}
      >
        ✕
      </CloseIcon>
    </Wrapper>
  )
}

export default BrowserSupport
