import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import styled, { css } from 'styled-components'
import { withTranslation } from 'react-i18next'

import { Link, Icon } from '.'
import FieldError from './FieldError'
import { processErrorsAndShowNotifs } from '../helpers'
import { orderTypes } from '../../common/config'
import { withNotifs } from './hoc'
import { createTranslatedErrorMessage } from '../../common/helpers'
import useIsSticky from './useIsSticky'
import { FixedGap } from './Gap'
import withRouter from './hoc/withRouter'

export const Wrapper = styled.div`
  width: 100%;
  padding: 0 1rem;
  /* margin: 2rem 0 0; */
  margin: 0;
  position: sticky;
  top: 0;
  z-index: 2;
  /* transition: 0.3s; */
  /* margin-top: -0.5rem; */
  min-height: 6.2rem;
  /* this fixes bug - safari fullscreen ignores z-index for sticky elements  */
  transform: translate3d(0, 0, -1px);
`

export const Container = styled.div`
  overflow: hidden;
  background-color: white;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  max-width: ${({ theme }) => theme.page.maxWidth};
  min-width: ${({ theme }) => theme.page.minWidth};
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.07);
  box-shadow: 0px 1px 7px 1px rgb(0 0 0 / 15%);
  border-radius: 0.6rem;
  margin: 0 auto 0;
  width: 100%;
  transition: 0.3s;

  ${({ isSticky }) =>
    isSticky &&
    css`
      box-shadow: 0px 5px 13px rgba(0, 0, 0, 0.2);
      box-shadow: 0px -1px 56px rgba(0, 0, 0, 0.4);
      box-shadow: 0px -1px 56px rgba(0, 0, 0, 0.35);
      box-shadow: 0px -1px 46px rgba(0, 0, 0, 0.3);
      /* box-shadow: rgba(0, 0, 0, 0.25) 0px -1px 36px; */
      /* border-radius: 0 0 0.4rem 0.4rem; */
      &:hover {
        /* padding-top: 0.3rem; */
      }
    `}
  ${({ mildShadow }) =>
    mildShadow &&
    css`
      box-shadow: 0px 1px 2px 1px rgb(0 0 0 / 20%);
    `}
`

export const StepNumber = styled.div`
  border-radius: 20rem;
  border: solid 1px
    ${({ active, done, theme: { colors } }) =>
    active || done ? colors.primary : colors.stepDisabled};
  width: 3rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;
  background: ${({ done, theme: { colors } }) => !done ? 'white' : colors.primary};
  color: ${({ done, theme: { colors }, active }) =>
    done ? 'white' : active ? colors.primary : colors.stepDisabled};
  transition: 0.3s;
`

export const Step = styled.div`
  padding: 1.5rem;
  cursor: pointer;
  color: ${({ active, done, theme: { colors } }) =>
    active || done ? colors.primary : colors.stepDisabled};
  font-weight: bold;
  font-size: 14px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  transition: 0.3s;
  border-bottom: solid 2px
    ${({ theme: { colors }, active }) => active ? colors.primary : 'transparent'};

  ${({ isSticky }) =>
    isSticky &&
    css`
      /* margin-top: -0.5rem; */
      /* height: 53px; */
      padding-top: 1rem;
      padding-bottom: 1rem;
    `}

  &:hover {
    color: ${({ theme: { colors } }) => colors.primary};
    ${StepNumber} {
      color: ${({ theme: { colors }, done }) => !done && colors.primary};
      border-color: ${({ theme: { colors } }) => colors.primary};
    }
  }
`

// TODO duplikace v ostatních krocích
const getValidateFieldsAndRedirect = ({ history, notifs, fields, canRedirect, props }) => (
  link,
  steps,
) => {
  let valid = true
  steps.forEach(step => {
    const errors = fields.$validate({
      step,
      selectedVca: props.selectedVca,
      currentLensL: props.currentLensL,
      currentLensR: props.currentLensR,
    })
    if (errors.length > 0) {
      processErrorsAndShowNotifs(errors, step)

      // TODO leve on same page, or redirect to last passed key
      // console.log('FAILED ON STEP', step, errors)
      valid = false
    }
  })
  if (valid && canRedirect) history.push(link)
}

const Steps = ({
  wantCalculation,
  stepShapeEnabled,
  stepSummaryEnabled,
  activeStep,
  fields,
  notifs,
  history,
  validatingParamsL,
  validatingParamsR,
  t,
  ...props
}) => {
  const validateFieldsAndRedirect = getValidateFieldsAndRedirect({
    history,
    notifs,
    fields,
    canRedirect: !validatingParamsL && !validatingParamsR,
    props,
  })

  const links = [
    {
      text: t('STEP_1_NAME'),
      to: Link.CREATE_ORDER_STEP_1,
      enabled: true,
      active: activeStep === 1,
      done: activeStep > 1,
      validateSteps: [],
    },
  ]
  switch (fields.orderType.value) {
    case orderTypes.DIAMETER_ONLY: {
      if (!wantCalculation) {
        links.push({
          text: t('STEP_4_NAME'),
          to: Link.CREATE_ORDER_STEP_4,
          enabled: stepSummaryEnabled,
          active: activeStep === 4,
          validateSteps: [1],
        })
      } else {
        links.push({
          text: t('STEP_3_NAME'),
          to: Link.CREATE_ORDER_STEP_3,
          enabled: stepSummaryEnabled,
          active: activeStep === 3,
          done: activeStep > 3,
          validateSteps: [1],
        })
        links.push({
          text: t('STEP_4_NAME'),
          to: Link.CREATE_ORDER_STEP_4,
          enabled: stepSummaryEnabled,
          active: activeStep === 4,
          validateSteps: [1],
        })
      }
      break
    }
    case orderTypes.WITH_SHAPE:
    case orderTypes.WITH_FRAME:
    case orderTypes.EDGING:
    case orderTypes.EDGING_COMPLETION: {
      links.push({
        text: t('STEP_2_NAME'),
        to: Link.CREATE_ORDER_STEP_2,
        enabled: stepShapeEnabled,
        active: activeStep === 2,
        done: activeStep > 2,
        validateSteps: [1],
      })
      if (!wantCalculation) {
        links.push({
          text: t('STEP_4_NAME'),
          to: Link.CREATE_ORDER_STEP_4,
          enabled: stepSummaryEnabled,
          active: activeStep === 4,
          validateSteps: [1, 2],
        })
      } else {
        links.push({
          text: t('STEP_3_NAME'),
          to: Link.CREATE_ORDER_STEP_3,
          enabled: stepSummaryEnabled,
          active: activeStep === 3,
          done: activeStep > 3,
          validateSteps: [1, 2],
        })
        links.push({
          text: t('STEP_4_NAME'),
          to: Link.CREATE_ORDER_STEP_4,
          enabled: stepSummaryEnabled,
          active: activeStep === 4,
          validateSteps: [1, 2],
        })
      }
      break
    }
    // no default
  }

  const { isSticky, ref, positionerRef } = useIsSticky()

  return (
    <>
      <FixedGap gap="2rem" />
      <div ref={positionerRef} />

      <Wrapper ref={ref} isSticky={isSticky}>
        <Container isSticky={isSticky}>
          {links.map((l, index) => (
            <Step
              isSticky={isSticky}
              key={l.to}
              onClick={() => validateFieldsAndRedirect(l.to, l.validateSteps)}
              disabled={!l.enabled}
              active={l.active}
              done={!!l.done}
            >
              <StepNumber disabled={!l.enabled} active={l.active} done={!!l.done}>
                {l.done ? <Icon name="MdCheckmark" size="18px" /> : index + 1}
              </StepNumber>
              <div>{l.text}</div>
            </Step>
          ))}
        </Container>
      </Wrapper>
      <FixedGap gap="1rem" />
    </>
  )
}

Steps.propTypes = {
  wantCalculation: PropTypes.bool.isRequired,
  stepShapeEnabled: PropTypes.bool.isRequired,
  stepSummaryEnabled: PropTypes.bool.isRequired,
  activeStep: PropTypes.number.isRequired,
  fields: PropTypes.object.isRequired,
  notifs: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  validatingParamsL: PropTypes.bool.isRequired,
  validatingParamsR: PropTypes.bool.isRequired,
}

const enhance = compose(
  connect(
    ({ vca, orders, catalog }) => ({
      wantCalculation: vca.wantCalculation,
      stepSummaryEnabled: orders.stepSummaryEnabled, // TODO - potřebujeme toto ještě?
      stepShapeEnabled: orders.stepShapeEnabled, // TODO - potřebujeme toto ještě?
      selectedVca: vca.selectedVca,
      currentLensR: catalog.currentLensR,
      currentLensL: catalog.currentLensL,
      validatingParamsL: catalog.validatingParamsL,
      validatingParamsR: catalog.validatingParamsR,
    }),
    {},
  ),
  withNotifs,
  withRouter,
  withTranslation(),
)

export default enhance(Steps)
