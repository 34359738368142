/* @flow weak */
import { apiPost, apiGet } from '../lib/api'

export const SIGN_IN_START = 'SIGN_IN_START'
export const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS'
export const SIGN_IN_ERROR = 'SIGN_IN_ERROR'

export const SIGN_OUT = 'SIGN_OUT'

export const signIn = body => ({ getState, fetch }) => ({
  type: 'SIGN_IN',
  payload: apiPost({
    fetch,
    endpoint: 'auth/sign-in',
    getState,
    body,
  }),
})

export const oauthSignInAction = body => ({ getState, fetch }) => ({
  type: 'SIGN_IN',
  payload: apiPost({
    fetch,
    endpoint: 'auth/authorize',
    getState,
    body,
  }),
})

export const signOut = () => ({
  type: SIGN_OUT,
})
