import styled, { css } from 'styled-components'
import BasicCol from './BasicCol'

const Col = styled.div`
  position: ${({ position }) => position};
  max-width: ${({ maxWidth }) => maxWidth};
  min-width: ${({ minWidth }) => minWidth};
  width: ${({ width }) => width};
  display: ${({ isHidden }) => isHidden ? 'none' : 'flex'};
  flex-direction: column;
  flex-basis: ${props => props.width};
  flex-grow: ${props => props.grow};
  flex-shrink: ${props => props.shrink};
  align-self: ${props => props.alignSelf};
  align-items: ${props => props.alignItems};
  justify-content: ${props => props.justifyContent};
  margin-right: ${({ theme, marginRight }) => marginRight || theme.grid.spaceBetweenColumns};
  padding: ${({ padding }) => padding};
  padding-right: ${({ paddingRight }) => paddingRight};
  visibility: ${({ isInvisible }) => isInvisible ? 'hidden' : undefined};
  ${({ compact, theme }) =>
    compact &&
    css`
      margin-right: ${theme.grid.spaceBetweenCompactColumns};
    `}

  ${({ marginRight }) =>
    marginRight &&
    css`
      margin-right: ${marginRight};
    `}

  &:last-child {
    margin-right: 0;
  }
`
Col.displayName = 'Col'

Col.Modern = BasicCol

export default Col
