import { differenceInDays } from 'date-fns'
import { nanoid } from 'nanoid'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setBrowserId, sendStats } from '../../common/app/actions'
import { selectApp, selectViewerId } from '../../common/app/reducer'

function StatsUpdater(props) {
  const dispatch = useDispatch()

  const appState = useSelector(selectApp)
  const viewerId = useSelector(selectViewerId)

  useEffect(() => {
    if (!appState.browserId && appState.storageLoaded && appState.suspenseDone) {
      const browserId = nanoid()
      dispatch(setBrowserId(browserId))
    }
  }, [appState.browserId, appState.storageLoaded, appState.suspenseDone, dispatch])

  useEffect(() => {
    let timer
    if (viewerId && appState.browserId) {
      const diff = differenceInDays(new Date(), new Date(appState.lastStatsSendAt))

      // console.log('diff', diff, appState.lastStatsSendAt)

      if (!appState.lastStatsSendAt || isNaN(diff) || Math.abs(diff) >= 7) {
        // console.log('!!!!!!!setup timeout')
        timer = setTimeout(() => {
          // console.log('!!!!!!!fire tiemout')
          dispatch(sendStats())
        }, 5 * 1000)
      }
    }

    return () => {
      clearTimeout(timer)
    }
  }, [viewerId, dispatch, appState.browserId, appState.lastStatsSendAt])

  return null
}

export default StatsUpdater
