import React from 'react'
import PropTypes from 'prop-types'
import * as notifs from '../../../common/lib/notifs'

const withNotifs = WrappedComponent => {
  class WithNotifs extends React.Component {
    notifs = {
      success: options => {
        notifs.success({ ...options })
      },
      error: options => {
        notifs.error({ ...options })
      },
      warning: options => {
        notifs.warning({ ...options })
      },
      info: options => {
        notifs.info({ ...options })
      },
    }

    render() {
      return <WrappedComponent notifs={this.notifs} {...this.props} />
    }
  }

  return WithNotifs
}

export default withNotifs
