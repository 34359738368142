import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import styled from 'styled-components'
import { withTranslation } from 'react-i18next'

import i18n from '../../../common/i18n/i18n'
import { fields } from '../../../common/lib/redux-fields'
import { TextField, CheckboxField, SelectField, SwitchField, NumberField } from '../fields'
import { FieldsGroup } from '.'
import { Box, Row, Col, Button, Gap, InfoMessage, Link, Text, Icon } from '..'
import { createWorker, editWorker, fetchWorkers, deleteWorker } from '../../../common/app/actions'
import Validation from '../../../common/lib/validation'
import { withNotifs } from '../hoc'
import Popup from '../popups/Popup'
import { TableLikeRow, TableLikeRowsWrapper } from '../TableLikeRows'
import BasicRow from '../BasicRow'
import { validation } from '../../../common/validationHelpers'
import BasicCol from '../BasicCol'

export const StyledEditIconWrapper = styled.div`
  opacity: 0.5;
  color: ${p => p.theme.colors.primary};
  transition: 0.3s;
  cursor: pointer;
  /* padding: 0.5rem 0.25rem 0.5rem 0.75rem; */
  /* padding: 0.2rem 1.0rem 0.4rem 1.4rem;
  padding: 0; */
  padding: 0 0.4rem 0 1.2rem;

  &:hover {
    opacity: 1;
    /* color: ${p => p.theme.colors.primaryLight}; */
  }
`

export const StyledDeleteIconWrapper = styled.div`
  opacity: 0.5;
  color: ${p => p.theme.colors.red};
  transition: 0.3s;
  cursor: pointer;
  /* padding: 0.5rem; */
  padding: 0 0.4rem;

  &:hover {
    opacity: 1;
    /* color: ${p => p.theme.colors.redLight}; */
  }
`

class SettingsTracerForm extends Component {
  state = {
    editing: false,
  }

  popupRef = null

  componentDidMount() {
    const { fetchWorkers } = this.props
    fetchWorkers()
  }

  submitForm = async () => {
    const { createWorker, editWorker, fields, notifs, t } = this.props

    const { editing } = this.state

    fields.$validate()
    console.log('!fields.$isValid()', !fields.$isValid())
    if (!fields.$isValid()) {
      notifs.error({ title: t('saving failed') })

      return
    }
    console.log('NOT here')
    try {
      if (!editing) {
        await createWorker(fields.$values()).meta.action.payload
        notifs.success({ title: t('saving success') })
        this.popupRef.close()
        // handle history by ourselves here, since we turned off auto handling
        window.history.back()
      } else {
        await editWorker(editing, fields.$values()).meta.action.payload
        notifs.success({ title: t('saving success') })
        this.popupRef.close()
        // handle history by ourselves here, since we turned off auto handling
        window.history.back()
      }
    } catch (e) {
      notifs.error({ title: t('saving failed') })
    }
  }

  deleteWorker = async _id => {
    const { deleteWorker, notifs, t } = this.props

    try {
      await deleteWorker(_id).meta.action.payload
      notifs.success({ title: t('delete success') })
    } catch (e) {
      notifs.error({ title: t('delete error') })
    }
  }

  render() {
    const { fields, workers, t } = this.props
    const { editing } = this.state

    return (
      <>
        <Col>
          {workers && workers.length === 0 && <Text>{t('add first employee')}</Text>}
          {workers?.length > 0 && (
            <TableLikeRowsWrapper>
              {workers.map(w => (
                <TableLikeRow
                  key={w._id}
                  justifyContent="space-between"
                  padding="1.2rem 1.6rem 1.2rem 2.4rem"
                >
                  <div>{w.name}</div>
                  <BasicRow>
                    <StyledEditIconWrapper
                      onClick={e => {
                        this.setState({ editing: w._id })
                        fields.$setValue('name', w.name)
                        this.popupRef.open(e)
                      }}
                    >
                      <Icon name="MdCreate" size={20} />
                    </StyledEditIconWrapper>
                    <Popup
                      title={t('Delete worker')}
                      text={`${t('Delete worker')} ${w.name}?`}
                      closeOnOutsideClick
                      closeOnEsc
                      onClose={opts => {
                        if (opts.ok) {
                          this.deleteWorker(w._id)
                        }
                      }}
                      okText={t('Delete')}
                      closeText={t('cancel')}
                    >
                      {({ open }) => (
                        <>
                          <StyledDeleteIconWrapper onClick={open}>
                            <Icon name="IosTrash" size={20} />
                          </StyledDeleteIconWrapper>
                        </>
                      )}
                    </Popup>
                  </BasicRow>
                </TableLikeRow>
              ))}
            </TableLikeRowsWrapper>
          )}
          <Gap />

          <Row>
            <Popup
              ref={r => {
                this.popupRef = r
              }}
              maxWidth="50rem"
              minWidth="50rem"
              minHeight="50rem"
              title={editing ? t('Edit worker') : t('Add new worker')}
              text={(
                <>
                  <BasicCol>
                    <TextField label={t('Name')} required {...fields.name} />
                  </BasicCol>
                </>
              )}
              closeOnOutsideClick
              closeOnEsc
              disableAutoClose
              disableHistoryManipulation
              contentOverflow="visible"
              onClose={opts => {
                console.log('opts', opts)

                if (opts.ok) {
                  this.submitForm()
                } else {
                  this.popupRef.close()
                }
              }}
              okText={editing ? t('Save') : t('Add')}
              closeText={t('cancel')}
            >
              {({ open }) => (
                <>
                  <Button
                    primary
                    onClick={e => {
                      this.setState({ editing: false })
                      // fields.$setValue('name', '')
                      fields.$reset()
                      fields.$resetValidation()
                      open(e)
                    }}
                  >
                    {t('Add new worker')}
                  </Button>
                </>
              )}
            </Popup>
          </Row>
        </Col>
      </>
    )
  }
}

SettingsTracerForm.propTypes = {
  fetchWorkers: PropTypes.func.isRequired,
  createWorker: PropTypes.func.isRequired,
  editWorker: PropTypes.func.isRequired,
  deleteWorker: PropTypes.func.isRequired,
  workers: PropTypes.array.isRequired,
  fields: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
}

const enhance = compose(
  connect(
    state => ({
      workers: state.app.workers,
    }),
    {
      createWorker,
      editWorker,
      fetchWorkers,
      deleteWorker,
    },
  ),
  fields({
    path: 'SettingsWorkerForm',
    fields: ['name'],
    // getInitialState: ({ tracer, tracerBridgeIp, tracerKey }) => ({
    //   ...tracer,
    //   tracerBridgeIp: tracerBridgeIp || '127.0.0.1:33333',
    //   tracerKey: tracerKey || 'fileOnly',
    // }),
    validationFunc: ({ dataToValidate, allData }) => {
      const validator = new Validation(dataToValidate)

      validation({
        validator,
        specification: {
          name: {
            isRequired: true,
          },
        },
        data: allData || dataToValidate,
      })

      return validator.validate()
    },
  }),
  withNotifs,
  withTranslation(),
)

export default enhance(SettingsTracerForm)
