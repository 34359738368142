import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { Z_ASCII } from 'zlib'
import { Box, Button, InfoMessage, Row, Col } from '..'
import { TextField } from '../fields'
import { fieldsConfig } from '../../../common/schemaConfig'

export class NoteSection extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showField: props.fields.note.value,
    }
  }

  toggleNote = () => {
    const { showField } = this.state
    if (showField) this.props.fields.note.onChange({ value: '' })
    this.setState({ showField: !showField })
  }

  render() {
    const { showField } = this.state
    const { fields, t } = this.props
    if (fields.note.value && !showField) this.setState({ showField: true })
    return (
      <Box>
        <Box.Header>
          <Box.Title>{t('remark for manufacturer')}</Box.Title>
        </Box.Header>
        <Box.Content>
          <Row>
            <InfoMessage>{t('REMARK_FOR_MANUFACTURER_INFO_MESSAGE')}</InfoMessage>
          </Row>
          <Row>
            <Button onClick={this.toggleNote}>
              {showField ? t('delete remark') : t('add remark')}
            </Button>
          </Row>
          {showField && (
            <Row>
              <Col width="100%">
                <TextField {...fields.note} maxLength={fieldsConfig.note.maxLength} />
              </Col>
            </Row>
          )}
        </Box.Content>
      </Box>
    )
  }
}

NoteSection.propTypes = {
  fields: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
}

export default withTranslation()(NoteSection)
