import { bevelPositions, frameTypes, orderTypes } from '../config'

// TODO - zatím pouze kopie omegy - potřeba vše zkontrolovat a přenastavit
const REQUIRED = true
const OPTIONAL = false

export default {
  APP_TYPE: 'jzo',
  isSummaryTooltipEnabled: true,
  isCanCreateOrdersForOthersEnabled: false,
  // it has to be function
  // otherwise values are undefined
  getEnabledOrderTypes: () => ({
    [orderTypes.DIAMETER_ONLY]: orderTypes.DIAMETER_ONLY,
    [orderTypes.WITH_SHAPE]: orderTypes.WITH_SHAPE,
    [orderTypes.EDGING]: orderTypes.EDGING,
  }),
  hasLogoutInHeader: true,
  isOrderCancellationEnabled: true,
  orderCancellationTime: 1,
  isBifocalVisualisationEnabled: false,
  showShapeGeometryCenterAtStep2: true,
  showFooterImpressum: false,
  showFooterGdprDoc: false,
  showFooterLegalDoc: true, // put inside public-files/legal folder
  tracerManualCustomLanguages: ['pl'], // use customized tracer manual for these languages
  showOrdersTableIdColumn: true,
  hideOrdersSourceSwitch: true, // hide order source switch on HP
  useNativeCookieConsent: true,
  defaultCopyReferenceOnDuplication: false,
  safetyMarginStock: 0.5, // value added to radius
  safetyMarginManufacturer: 2, // value added to radius
  isMiLensEnabled: false,
  orderSanitizeStringsVariant: 'FULL', // FULL or SOFTER
  areFtpDeliveryNotesEnabled: true,
  isCircumferenceCalculationEnabled: true, // show shape circumference in step 2
  hostConf: {
    isGoToWowEnabled: false,
    wowUrl: '',
    htmlTitle: 'EyeLinkWeb',
    logo: {
      publicPath: '/public/images/logo-jzo.png?v=1',
    },
    navbarLogoMaxHeight: 6, // rem
    logoSquared: {
      publicPath: '/public/images/EyeLink-JZO.png?v=2',
      styles: {
        image: {
          maxHeight: '220px',
        },
        wrapper: {
          padding: '0',
        },
      },
    },
    favicon: {
      publicPath: '/public/favicon-jzo.ico?v=1',
    },
    areDeliveryNotesEnabled: true,
    isGetOrderPriceEnabled: true,
    fallbackLanguage: 'pl',
  },
  uniqueTranslationKeyPrefix: 'jzo;',
  versionPrefix: 'j',
  isGoToWowEnabled: false,
  languages: ['pl', 'en', 'cs'],
  isCustomDiameterEnabled: true,
  isEllipticalDiameterEnabled: false,
  isCtoEnabled: true,
  ctoOptionsCodes: ['CTO'],
  isCtoCheckedByDefault: false,
  // considerDesignShift: false, // in lens diameter calculation (getFinalRadiiByShapeAndLenses) - not used anymore
  isLensBaseEnabled: true, // base curve field in refractions section
  // select base curve field after diameter selection with options from catalog
  isBaseCurveSelectEnabled: false,
  isSphAndCylInHundredthsEnabled: false,
  sphAndCylInHundredthsGroups: [],
  isWithShapeOnlyLensesEnabled: true,
  showCalcSurfacePoints: process.env.SERVER_ENV === 'dev' || process.env.SERVER_ENV === 'local',
  isExpressOrderEnabled: false,
  isCenterThicknessEnabled: true,
  // bevel positions are defined in config where this is loaded, so we can't use before incialization, thus string contant
  step2DefaultBevelPositionForEdgingOrder: 'FRAMECURVE',
  step2AutoPolish: true, // in step 2, automatically set polish field based on frame type
  // let user decide wether spectacles (only) lenses should be polished, customizable via group
  isSpectaclesPolishEnabled: false,
  areFramesEnabled: false,
  isPinBevelEnabled: false,
  defaultDefaultChamferSettings: true,
  isUvSelectionEnabled: false,
  isDecentrationEnabled: false,
  isSoapEnabled: true,
  isSortLensOptionsByNameEnabled: true,
  isMandatoryColorPreselectEnabled: true,
  isNoteEnabled: true,
  isInternalNoteEnabled: false,
  isNewsPopupEnabled: true,
  // soapWsdlDev: 'order-import-omega-dev', // deprecated
  // soapWsdl: 'order-import-omega', // deprecated
  soapDevUrl: 'https://soap.jzo.azure.virtii.com',
  soapProdUrl: 'https://soap.eyelink.pl',

  isFrameCurveStep2Visible: true,

  arePromotionsEnabled: false,

  // enables fetching order prices from LW soap server
  // isGetOrderPriceEnabled: true must be added to hostConf
  isGetPricesFromLWSoapEnabled: true,

  // enables size adjustment field in step 2
  isShapeSizeAdjustmentEnabled: true,

  lensFocusTypes: {
    'Single vision': {
      A: 'Single vision conv',
      B: 'Single vision freeform',
      C: 'Single vision individual',
    },
    Progressive: {
      D: 'Progressive conv',
      E: 'Progressive freeform',
      F: 'Progressive individual',
    },
    Office: {
      G: 'Office conv',
      H: 'Office individual',
      I: 'Office freeform',
    },
    Bifocal: {
      J: 'Bifocal conv',
      K: 'Bifocal freeform',
      L: 'Bifocal individual',
    },
  },

  lensBrands: {
    JZO: 'JZO',
    Nikon: 'Nikon',
    VAN: 'VANBERG',
  },

  suppliersMap: {
    OME: 'OME',
    '4EY': '4 Eyes',
    // INV: 'INVU',
    // REB: 'Reebok',
    // NKA: 'Nika',
    // FUR: 'iGard',
    // NKO: 'Nikon',
  },

  orderSchemaOverride: {
    singleKeys: {
      reference: {
        field: {
          maxLength: 30,
        },
      },
      clientName: {
        field: {
          maxLength: 30,
        },
      },
    },
  },

  /**
 * [FFUJZ1]
PARAMETER_OPT=CVD;FACEFORMANGLE;PANTOSCOPIC
CVD=0;30
FACEFORMANGLE=0;45
PANTOSCOPIC=0;25

[FFUESN]
PARAMETER_OPT=CVD;FACEFORMANGLE;PANTOSCOPIC;INSET
CVD=0;30
FACEFORMANGLE=0;45
PANTOSCOPIC=0;25
INSET=0;6

[BB2]
PARAMETER_OPT=CVD;FACEFORMANGLE;PANTOSCOPIC;INSET;READDIST;CVD_APPROX;FACEFORMANGLE_APPROX;PANTOSCOPIC_APPROX;READDIST_APPROX
CVD=5;22
FACEFORMANGLE=0;25
PANTOSCOPIC=-5;25
READDIST=20;60
INSET=0;7
CVD_APPROX=9;12;15 ("Short";"Normal";"Long")
FACEFORMANGLE_APPROX=2;11 ("Normal";"Curved")
PANTOSCOPIC_APPROX=8;13 ("Normal";"Strong")
READDIST_APPROX=38;41;44("< 160";"160 - 180";"> 180")
INCOMPATIBLE1=CVD;CVD_APPROX
INCOMPATIBLE2=FACEFORMANGLE;FACEFORMANGLE_APPROX
INCOMPATIBLE3=PANTOSCOPIC;PANTOSCOPIC_APPROX
INCOMPATIBLE4=READDIST;READDIST_APPROX
 */

  // todo - nahradit cvd za bvd???
  individualMenus: {
    // true = mandatory field
    // false = hidden field
    // order matters! Higher wins if two different lenses selected
    BB2: {
      bvd: {
        isRequired: false,
        min: 5,
        max: 22,
      },
      bvdApprox: OPTIONAL,
      frameBowAngle: {
        isRequired: false,
        min: 0,
        max: 25,
      },
      frameBowAngleApprox: OPTIONAL,
      panto: {
        isRequired: false,
        min: -5,
        max: 25,
      },
      pantoApprox: OPTIONAL,
      readdist: {
        isRequired: false,
        min: 20,
        max: 60,
      },
      readdistApprox: OPTIONAL,
      inset: {
        isRequired: false,
        min: 0,
        max: 7,
      },
    },

    FFUESN: {
      bvd: {
        isRequired: false,
        min: 0,
        max: 30,
      },
      frameBowAngle: {
        isRequired: false,
        min: 0,
        max: 45,
      },
      panto: {
        isRequired: false,
        min: 0,
        max: 25,
      },
      inset: {
        isRequired: false,
        min: 0,
        max: 6,
      },
    },
    FFUJZ1: {
      bvd: {
        isRequired: false,
        min: 0,
        max: 30,
      },
      frameBowAngle: {
        isRequired: false,
        min: 0,
        max: 45,
      },
      panto: {
        isRequired: false,
        min: 0,
        max: 25,
      },
    },

    FFUJZ2: {
      bvd: {
        isRequired: false,
        min: 0,
        max: 30,
      },
      frameBowAngle: {
        isRequired: false,
        min: 0,
        max: 45,
      },
      panto: {
        isRequired: false,
        min: 0,
        max: 25,
      },
      inset: {
        isRequired: false,
        min: 0,
        max: 7,
      },
      readdist: {
        isRequired: false,
        min: 20,
        max: 60,
      },
      proglen: {
        isRequired: true,
        options: [10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20],
      },
    },
  },
  getBevelPositions: () => [
    bevelPositions.FRONT,
    bevelPositions.PERCENT,
    bevelPositions.HALF,
    bevelPositions.BACK,
    bevelPositions.FRAMECURVE,
  ],
  getFrameTypes: () => [
    frameTypes.METAL,
    frameTypes.PLASTIC,
    frameTypes.SPECIAL,
    frameTypes.NYLOR,
    frameTypes.DRILLED,
    // frameTypes.GROOVEDDRILLED,
  ],
}
