import styled, { css } from 'styled-components'
import { up } from '../app/theme'
import BasicRow from './BasicRow'

const Row = styled.div`
  display: ${({ isHidden }) => isHidden ? 'none' : 'flex'};
  /* font-size: 0; */
  flex-direction: row;
  flex-wrap: ${({ flexWrap }) => flexWrap || 'nowrap'};
  flex-shrink: ${({ shrink }) => shrink};
  flex-basis: ${({ basis }) => basis};
  align-items: ${({ alignItems }) => alignItems || 'flex-start'};
  justify-content: ${props => props.justifyContent};
  padding: ${({ padding }) => padding};
  margin-bottom: ${({ theme, marginBottom }) => marginBottom || theme.grid.spaceBetweenRows};
  margin: ${({ marginVertical }) =>
    typeof marginVertical !== 'undefined' ? `${marginVertical} 0` : undefined};
  margin-top: ${({ marginTop }) => typeof marginTop !== 'undefined' ? marginTop : undefined};
  margin: ${({ margin }) => margin};
  visibility: ${({ isInvisible }) => isInvisible ? 'hidden' : undefined};
  max-width: ${({ maxWidth }) => maxWidth};
  ${({ compact, theme }) =>
    compact &&
    css`
      margin-bottom: ${theme.grid.spaceBetweenCompactRows};
    `}
  ${({ large, theme }) =>
    large &&
    css`
      margin-bottom: ${theme.grid.spaceBetweenLargeRows};
    `}
  &:last-child {
    margin-bottom: ${({ marginVertical }) =>
    typeof marginVertical === 'undefined' ? 0 : undefined};
  }
  & > * {
    /* font-size: initial; */
  }
`
Row.displayName = 'Row'

// our current Row, how we use it elsewhere
Row.Modern = BasicRow

export default Row
