import React from 'react'
import PropTypes from 'prop-types'
import invariant from 'invariant'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { resetFields, setField, setMultipleFields } from './actions'
import { getIn } from '../../helpers'
import formValidation from '../formValidation'
import { withNotifs } from '../../../browser/components/hoc'
import { getState } from '../../../browser/main'

// Higher  order component for huge fast dynamic deeply nested universal forms.
const fields = options => WrappedComponent => {
  const {
    path = '',
    fields = [],
    getInitialState = () => ({}),
    validationFunc = () => [],
    onBlurHook = () => {},
    submitFunc,
    cleanValuesEnabled,
  } = options

  invariant(Array.isArray(fields), 'Fields must be an array.')
  invariant(typeof path === 'string', 'Path must be a string!')

  const submitFuncWrapper = props => data => submitFunc(data, props)

  // safely get field value with initial fallbacks
  // defaultValue is returned when field was not changed and does not have initial state
  const getFieldValue = (fieldKey, data, initialState, defaultValue = '') => {
    if (data && typeof data[fieldKey] !== 'undefined') {
      return data[fieldKey]
    }
    // if initialState for this field is defined (even undefined)
    if (initialState && {}.hasOwnProperty.call(initialState, fieldKey)) {
      return initialState[fieldKey]
    }
    if (defaultValue === 'undefined') {
      defaultValue = undefined
    }
    return defaultValue
  }

  // get all fields values enriched with initial values etc.
  const getFinalValues = (data, initialState, defaultValue) =>
    options.fields.reduce(
      (fields, field) => ({
        ...fields,
        [field]: getFieldValue(field, data, initialState, defaultValue),
      }),
      {},
    )

  class Fields extends React.Component {
    constructor(props) {
      super()
      this.state = {
        validationOptions: null,
      }

      this.savedInitialState = getInitialState(props)
      this.createFields()
    }

    // for debugging purposes only
    // componentDidUpdate(prevProps, prevState) {
    //   Object.entries(this.props).forEach(
    //     ([key, val]) => prevProps[key] !== val && console.log(`Prop '${key}' changed FIELDS`, path),
    //   )
    //   if (this.state) {
    //     Object.entries(this.state).forEach(
    //       ([key, val]) =>
    //         prevState[key] !== val && console.log(`State '${key}' changed FIELDS`, path),
    //     )
    //   }
    // }

    componentWillUnmount() {
      this.fields = null
    }

    // set value in redux state
    setFieldValue = (fieldKey, value) => {
      this.props.setField([path, fieldKey], value)
      this.updateInnerFields()
      this.forceUpdate()
    }

    // safely returns all redux data (user changed)
    getReduxData = () => {
      // sedly multiple action dispatch wont trigger render for every action,
      // therefore we can't rely on $getValues after immediate action dispatching,
      // so - we need to get state directly and update this.values withou rerender
      const { fields: allFieldsState = {} } = getState()
      return allFieldsState[path]
    }

    getFinalData = () => {
      if (!this.fields) {
        return null // not ready yet
      }
      // get user changed data first
      const changedData = this.getReduxData()

      // snapshot of saved initial data
      const { savedInitialState } = this

      // all values
      const values = getFinalValues(changedData, savedInitialState)

      // only changed or initial values (no empty strings)
      let cleanValues = null
      if (cleanValuesEnabled) {
        cleanValues = getFinalValues(changedData, savedInitialState, 'undefined')
      }

      return {
        values,
        cleanValues,
      }
    }

    validateField = field => {
      const errors = validationFunc({
        ...this.props,
        // TODO error cannot red lensR of undefined
        // this.fields, tedy musí být někdy undefined
        dataToValidate: { [field]: this.fields[field].value },
        allData: this.values,
        options: this.state.validationOptions,
        props: this.props,
      })
      // this.fields = { ...this.fields } // Ensure rerender for pure components.
      this.fields[field].errors =
        errors && errors.filter(error => error.detail && error.detail.field === field)
      this.forceUpdate()
    }

    mapErrorsToFields = errors => {
      Object.keys(this.formFields).forEach(key => {
        this.fields[key].errors = []
      })
      errors.forEach(e => {
        if (e.detail && e.detail.field) {
          this.fields[e.detail.field].errors.push(e)
        }
      })
    }

    onChange = e => {
      const target = e.target || e
      const { type, checked, value } = target
      const isCheckbox = type && type.toLowerCase() === 'checkbox'
      const name = e.name || target.name
      // console.log('target.name', name, value, target, checked)
      this.setFieldValue(name, isCheckbox ? checked : value)
    }

    onBlur = e => {
      const target = e.target || e
      const name = e.name || target.name
      // console.log('eeee', name, target)
      this.validateField(name)
    }

    createFields() {
      this.formFields = options.fields.reduce(
        (fields, fieldName) => ({
          ...fields,
          [fieldName]: {
            name: fieldName,
            onChange: e => this.onChange({ ...e, target: e, name: fieldName }),
            onBlur: e => this.onBlur({ ...e, target: e, name: fieldName }),
            errors: [],
          },
        }),
        {},
      )

      const dispatchMultipleFieldUpdate = options => {
        const transformedOptions = options.map(({ fieldName, value }) => ({
          path: [path, fieldName],
          value,
        }))
        this.props.setMultipleFields(transformedOptions)
        this.updateInnerFields()
        this.forceUpdate()
      }

      this.fields = {
        ...this.formFields,
        // we can call handleBlur directly, suitable for hijacking standard onBlur
        $handleBlur: this.onBlur,
        // same as $handleBlur
        $handleChange: this.onChange,
        // returns all values with default '' value if not set
        $values: () => this.values,
        // returns only changed or initial values
        $cleanValues: () => this.cleanValues,
        // regenerate savedInitialState with current props and re-render
        $refreshInitialState: () => {
          this.savedInitialState = getInitialState(this.props)
          this.forceUpdate()
        },
        $setValue: (field, value) => this.setFieldValue(field, value),
        $setMultipleValues: options => {
          dispatchMultipleFieldUpdate(options)
        },
        // deletes everything in redux
        $reset: () => {
          this.props.resetFields([path])
        },
        // reset all fields to theirs initial state or empty (undefined) values
        $resetToInitial: () => {
          const initialState = getInitialState(this.props)
          // console.log('initialState', initialState)

          dispatchMultipleFieldUpdate(
            Object.keys(initialState).map(key => ({
              fieldName: key,
              value: initialState[key],
            })),
          )
        },
        $validate: (options, rest) => {
          const validationOptions = {
            dataToValidate: this.values,
            allData: this.values,
            options: { ...this.state.validationOptions, ...options },
            props: this.props,
            ...rest,
          }
          const errors = validationFunc(validationOptions)
          this.mapErrorsToFields(errors)
          this.forceUpdate()
          return errors
        },
        $resetValidation: () => {
          const errors = []
          this.mapErrorsToFields(errors)
          this.forceUpdate()
        },
        $validateFunc: ({
          dataToValidate = this.values,
          options = this.state.validationOptions,
          ...rest
        }) =>
          validationFunc({
            dataToValidate,
            allData: this.values,
            options,
            props: this.props,
            ...rest,
          }),
        $setValidationOptions: validationOptions => {
          this.setState(state =>({
            validationOptions: {
              ...state.validationOptions,
              ...validationOptions,
            },
          }))
          this.fields = { ...this.fields } // Ensure rerender for pure components.
        },
        $getValidationOptions: () => this.state.validationOptions,
        $isValid: () => {
          const result = validationFunc({
            dataToValidate: this.values,
            allData: this.values,
            options: this.state.validationOptions,
            props: this.props,
          })
          return !(result && result.length > 0)
        },
        $submit: async (arg1, arg2) => {
          /* TODO - pokud předám do komponenty přímo onClick={fields.$submit}
            tak to nebude fungovat, protože jako arg1 se předá event objekt
          */
          const data = typeof arg1 === 'object' ? arg1 : this.fields.$values()
          const submitFunc = typeof arg1 === 'function' ? arg1 : arg2
          // TODO - toto není ideální, protože se validace provede ještě jednou v submitu
          const validationErrors = validationFunc({
            dataToValidate: this.values,
            allData: this.values,
            options: this.state.validationOptions,
            props: this.props,
          })
          this.mapErrorsToFields(validationErrors)

          try {
            // TODO: select fetch error????
            const submitResult = await this.props.submit(data, submitFunc)
            return submitResult
          } catch (error) {
            throw error
          }
        },
      }
    }

    updateInnerFields = () => {
      const {
        submit,
        submitting, // eslint-disable-line
        submitSuccessfull, // eslint-disable-line
        submitFailed, // eslint-disable-line
        validating, // eslint-disable-line
        validationSuccessfull, // eslint-disable-line
        validationFailed, // eslint-disable-line
        errors, // eslint-disable-line
        errorMessage, // eslint-disable-line
      } = this.props

      // first get data with default values
      const finals = this.getFinalData()

      if (!finals) {
        // fields are not ready yet, skip this update
        console.debug('fields are not ready yet, skip this update')
        return
      }
      const { values, cleanValues } = finals
      // save it to future reference
      this.values = values
      this.cleanValues = cleanValues

      // update value in each field object
      options.fields.forEach(fieldKey => {
        this.fields[fieldKey].value = this.values[fieldKey]
      })

      const updatedDynamicsProps = {
        $submitting: submitting,
        $submitSuccessfull: submitSuccessfull,
        $submitFailed: submitFailed,
        $validating: validating,
        $validationSuccessfull: validationSuccessfull,
        $validationFailed: validationFailed,
        $errors: errors,
        $errorMessage: errorMessage,
      }
      Object.keys(updatedDynamicsProps).forEach(key => {
        this.fields[key] = updatedDynamicsProps[key]
      })
    }

    render() {
      const {
        submit, // eslint-disable-line
        submitting, // eslint-disable-line
        submitSuccessfull, // eslint-disable-line
        submitFailed, // eslint-disable-line
        validating, // eslint-disable-line
        validationSuccessfull, // eslint-disable-line
        validationFailed, // eslint-disable-line
        errors, // eslint-disable-line
        errorMessage, // eslint-disable-line
        ...restProps
      } = this.props
      // console.log('FIELDS RENDER!', path)

      this.updateInnerFields()

      return <WrappedComponent {...restProps} fields={{ ...this.fields }} />
    }
  }

  return compose(
    connect(
      state => ({
        // fields: state.fields,
        innerFields: state.fields[path],
      }),
      {
        resetFields,
        setField,
        setMultipleFields,
      },
    ),
    withNotifs,
    formValidation(props => ({ validationFunc, submitFunc: submitFuncWrapper(props) })),
  )(Fields)
}

export default fields
