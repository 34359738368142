import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Gap = styled.div`
  flex: 0 0 ${props => props.gap};
  width: ${props => props.gap};
`

export const FixedGap = styled.div`
  flex: 0 0 ${props => props.gap};
  width: ${props => props.gap};
  height: ${props => props.gap};
`

Gap.defaultProps = {
  gap: '1rem',
}

Gap.propTypes = {
  gap: PropTypes.string,
}

export default Gap
