import React from 'react'
// import styled from 'styled-components'

import Text from './Text'
import Icon from './Icon'
import Row from './Row'
import Col from './Col'
import { getTheme } from '../app/App'

const InfoMessage = props => (
  <div>
    <Row alignItems="center">
      <Col compact marginRight="0.5rem">
        <div>
          <Icon size={24} name="IosInformationCircleOutline" color={getTheme().colors.primary} />
        </div>
      </Col>
      <Col>
        <Text {...props} />
      </Col>
    </Row>
  </div>
)

export default InfoMessage
