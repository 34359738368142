import React from 'react'
import Icon from 'react-icon-base'

const Save = props => (
  <Icon viewBox="-1 -1 23 23" {...props}>
    <path d="M1.125 0H0.375C0.16875 0 0 0.16875 0 0.375V3.75C0 3.95625 0.35625 4.125 0.5625 4.125H1.125C1.33125 4.125 1.5 3.95625 1.5 3.75V0.375C1.5 0.16875 1.33125 0 1.125 0Z" transform="translate(8.86426 0.75)" />
    <path d="M17.3391 3.09844L14.9062 0.665625C14.4844 0.24375 13.9078 0 13.3078 0H11.9953C11.5828 0 11.2453 0.342187 11.2453 0.759375V4.50937C11.2453 4.92188 10.9078 5.25937 10.4953 5.25937H4.5C4.0875 5.25937 3.75 4.92188 3.75 4.50937V0.759375C3.75 0.346875 3.4125 0 3 0H1.5C0.675 0 0 0.675 0 1.5V16.5C0 17.325 0.675 18 1.5 18H16.5C17.325 18 18 17.325 18 16.5V4.6875C18 4.09219 17.7609 3.52031 17.3391 3.09844ZM12.0938 15.7547C12.0938 16.1672 11.7188 16.5047 11.2594 16.5047H3.74063C3.28125 16.5047 2.90625 16.1672 2.90625 15.7547V11.2547C2.90625 10.8422 3.28125 10.5047 3.74063 10.5047H11.2594C11.7188 10.5047 12.0938 10.8422 12.0938 11.2547V15.7547Z" transform="translate(0.239258 0.75)" />
  </Icon>
)

export default Save