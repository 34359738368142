import React from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Button, LinkButton, Row, Col, Box } from '../components'
import { withNotifs } from '../components/hoc'
import { fetchUsers, fetchOrdersState } from '../../common/admin/actions'

const fetch = async (action, notifs) => {
  try {
    const response = await action().meta.action.payload
    if (response && [200, 204].includes(response.code)) {
      notifs.success({ title: 'Úspěšně aktualizováno' })
    } else {
      throw new Error()
    }
  } catch (error) {
    notifs.error({ title: 'Došlo k chybě' })
  }
}

const AdminPage = ({ fetchUsers, fetchOrdersState, notifs }) => (
  <div style={{ padding: '5rem' }}>
    <Box>
      <Box.Header>
        <Box.Title>Aktualizace dat</Box.Title>
      </Box.Header>
      <Box.Content>
        <Row>
          <Col>
            <Button onClick={() => fetch(fetchUsers, notifs)} primary large>
              Aktualizovat uživatele
            </Button>
          </Col>
          <Col>
            <Button onClick={() => fetch(fetchOrdersState, notifs)} primary large>
              Aktualizovat stav objednávek
            </Button>
          </Col>
        </Row>
      </Box.Content>
    </Box>

    <Row style={{ marginTop: '5rem' }}>
      <LinkButton leftIcon="IosArrowBack" large href="/keystone/" external>
        Zpět do Keystone
      </LinkButton>
    </Row>
  </div>
)

AdminPage.propTypes = {
  fetchUsers: PropTypes.func.isRequired,
  fetchOrdersState: PropTypes.func.isRequired,
  notifs: PropTypes.object.isRequired,
}

const enhance = compose(
  connect(
    ({ app }) => ({
      viewer: app.viewer,
    }),
    {
      fetchUsers,
      fetchOrdersState,
    },
  ),
  withNotifs,
)

export default enhance(AdminPage)
