import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { withTranslation } from 'react-i18next'
import { Col, Gap, Link, Page, Text } from '../components'
import Title from '../components/Title'

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 10rem;
  ${({ theme: { colors } }) => css`
    color: ${colors.text2};
    background: ${colors.pageBackground};
  `}
`

// eslint-disable-next-line react/prefer-stateless-function
class OwnerPage extends Component {
  render() {
    const { t } = this.props
    return (
      <Page metaTitle={t('settings')}>
        <Page.Content>
          <Col>
            <Title.PageTitle>Impressum</Title.PageTitle>
            <Text>
              <div dangerouslySetInnerHTML={{ __html: t('impressum rich text') }} />
            </Text>
            <Gap gap="4rem" />
            <Link primary to="/">
              {t('Back to homepage')}
            </Link>
          </Col>
        </Page.Content>
      </Page>
    )
  }
}

OwnerPage.propTypes = {
  t: PropTypes.func.isRequired,
}

export default withTranslation()(OwnerPage)
