import React from 'react'
import Icon from 'react-icon-base'

const CheckboxUnchecked = props => (
  <Icon viewBox="0 0 16 16" {...props}>
    <rect
      x="1"
      y="1"
      width="14"
      height="14"
      rx="3"
      fill="white"
      stroke="#BDBDBD"
      strokeWidth="1.5"
    />
  </Icon>
)

export default CheckboxUnchecked
